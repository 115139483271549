<template>
  <card>
    <DeleteConfirmation
      :currentDeleteMethod="deleteCustomerHost"
      @delete="$event()"
    />
    <div class="row">
      <div class="col-md-12">
        <button
          class="btn btn-primary btn-fill"
          @click.prevent="handleAddNew()"
        >
          Create New Group
        </button>
      </div>
      <div class="col-md-12">
        <card title="Available Groups">
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-default mb-3"
                style="width: 200px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-default"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
              <el-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
              />
            </div>
            <div class="col-sm-12">
              <el-table stripe style="width: 100%;" :data="queriedData" border>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="100" fixed="right" label="Actions">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-warning btn-simple btn-link"
                      @click="handleEdit(props.$index, props.row)"
                      ><i class="fa fa-edit"></i
                    ></a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </card>
      </div>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { FormGroupInput as FgInput, Switch as LSwitch } from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    LSwitch,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["notification_key_name"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  data() {
    return {
      dummyData: [],
      tableData: [],
      currentHost: null,
      notificationHostArray: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["notification_key_name"],
      tableColumns: [
        {
          prop: "id",
          label: "Id",
          minWidth: 250
        },
        {
          prop: "notification_key_name",
          label: "Key Name",
          minWidth: 200
        }
      ],
      fuseSearch: null,
      curData: {
        isActive: true,
        providerId: "",
        configObj: []
      }
    };
  },
  created() {
    this.$store
      .dispatch("getGroupList")
      .then(res => {
        this.tableData = res;
      })
      .catch(() => {});
  },
  methods: {
    handleEdit: function(index, props) {
      this.$router.push({
        name: "Edit GroupNotification",
        params: { id: props.id }
      });
    },
    handleAddNew() {
      this.$router.push({
        name: "Edit GroupNotification",
        params: { id: "-1" }
      });
    },
    openImageDialog(evt, type) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        file = this.$refs.thumbnailInput[0];
      }
      if (file) {
        file.click();
      }
    },
    deleteImage(type) {
      if (type == "thumbnail") {
        this.resetUrl("https://dummyimage.com/640x360/a1a1a1/fff", type);
      } else {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
    },
    resetUrl: function(url, type) {
      if (type == "thumbnail") {
        this.curData.configObj.notification.image = url;
      }
    },
    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.resetUrl(url, type);
            })
            .catch(() => {
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    sendNotification() {
      var data = {};
      if (!this.getEditAuthConfig()) {
        data.isActive = true;
        data.providerId = this.curData.providerId;
        data.configObj = {};
        for (var i = 0; i < this.curData.configObj.length; i++) {
          var item = this.curData.configObj[i];
          data.configObj[item.key] = item.value;
        }
        this.$store
          .dispatch("createAuthProviderConfig", data)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Auth Config created Successfully!",
              type: "success"
            });
            this.getAuthConfigList();
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Create Auth Config Failed!",
              type: "warning"
            });
          });
      } else {
        var req = {};
        data = this.getEditAuthConfig();
        data.isActive = this.curData.isActive;
        data.configObj = {};
        for (var i = 0; i < this.curData.configObj.length; i++) {
          var item = this.curData.configObj[i];
          data.configObj[item.key] = item.value;
        }
        req.id = data.id;
        req.data = data;
        //  this.$store.dispatch("updateAuthProviderConfig",req).then(response=> {
        //     this.$notify({
        //         title: 'Success',
        //         message: 'Auth Config updated Successfully!',
        //         type: 'success'
        //     });
        //     this.getAuthConfigList();
        // }).catch(()=>{
        //     this.$notify({
        //         title: 'Error',
        //         message: 'Update Auth Config Failed!',
        //         type: 'warning'
        //     });
        // });
      }
    },
    setConfigData(host) {
      var id = host.id;
      var hostConfig = host.configObj;
      var pData = this.getEditAuthConfig();
      this.curData.configObj = [];
      if (pData) {
        this.curData.isActive = pData.isActive;
      } else {
        this.curData.isActive = true;
      }
      if (hostConfig) {
        for (var key in hostConfig) {
          var item = {};
          item.label = hostConfig[key];
          item.key = key;
          if (pData && pData.configObj && pData.configObj[item.key]) {
            item.value = pData.configObj[item.key];
          } else {
            item.value = "";
          }
          this.curData.configObj.push(item);
        }
      }
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      var host = this.notificationHostArray[tabIndex];
      if (host) {
        this.curData = host;
      }
    },
    getEditAuthConfig() {
      if (this.tableData && this.tableData.length > 0) {
        var pId = this.curData.providerId;
        if (pId) {
          for (var i = 0; i < this.tableData.length; i++) {
            var providerId = this.tableData[i].providerId;
            if (pId == providerId) {
              return this.tableData[i];
            }
          }
        }
      }
      return null;
    },
    deleteCustomerHost() {}
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mt-20 {
  margin-top: 20px;
}
.thumbnail {
  position: absolute;
  float: left;
  height: 110px;
}

.thumb-div {
  padding-left: 10px;
  min-height: 160px;
}

.uploadthumbbtn {
  position: absolute;
  float: left;
  margin-top: 0px;
  margin-left: 200px;
}

.deletethumbbtn {
  position: absolute;
  float: left;
  margin-top: 50px;
  margin-left: 200px;
}

.delete-lang-btn {
  float: right;
  margin-right: 30px;
}
</style>
