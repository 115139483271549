<template>
  <b-modal
    id="confirmDelete"
    title="Confirmation"
    ref="deleteConfirmation"
    ok-variant="danger"
    @ok="DeleteMethod()"
  >
    <p>Are you sure to delete?</p>
  </b-modal>
</template>
<script>
export default {
  name: "DeleteConfirmation",
  props: ["currentDeleteMethod"],
  methods: {
    DeleteMethod() {
      this.$emit("delete", this.currentDeleteMethod);
    }
  }
};
</script>
<style></style>
