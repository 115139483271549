<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form method="#" action="#">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Reset your password?</h3>
              </div>
              <div>
                <fg-input v-validate="'required'" name="oldpassword" type="password" placeholder="Old Password" ref="oldpassword" v-model="model.oldpassword"></fg-input>
                <fg-input v-validate="'required'" name="password" type="password" placeholder="Password" ref="password" v-model="model.password"></fg-input>
              <div class="passwordlength">
                <p v-if="errors.has('min')">{{ errors.first('min')}}</p>
              </div>
              <fg-input v-validate="'required|confirmed:password'" name="password_confirmation" type="password" placeholder="Password, Again" data-vv-as="password" v-model="model.confirmpassword"></fg-input>
              <div class="passwordconfirm">
                <div class="alert alert-danger" v-show="errors.any()">
                <div v-if="errors.has('password')">
                  {{ errors.first('password') }}
                </div>
                <div v-if="errors.has('password_confirmation')">
                  {{ errors.first('password_confirmation') }}
                </div>
              </div>
              </div>
              </div>
              <div class="text-center">
                <button type="button" class="btn btn-fill btn-info btn-round btn-wd " @click="handleSubmit">Send Password</button>
                <br>
                <div class="forgot">
                  <router-link to="/login" class="card-category">
                    click here to Login
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  export default {
    components: {
      LCheckbox,
      FadeRenderTransition,
      AuthLayout
    },
    data () {
      return {
        model :{
          oldpassword: '',
          password: '',
          confirmpassword: '',
        },
      }
    },
    methods: {
      handleSubmit (){
        var self = this;
        this.$validator.validate().then(valid => {
                if (valid) {
                  var shajs = require('sha.js');
                  var oldpassword = shajs('sha256').update(this.model.oldpassword).digest('hex');
                  var newpassword = shajs('sha256').update(this.model.password).digest('hex');
                  var dataToSend ={
                    "current_password":oldpassword,
                    "new_password":newpassword,
                  }
                  this.$store.dispatch("updateAdminPassword",dataToSend).then(data=>{
                    alert("updated")
                  }).catch(()=>{
                    
                  });

                }
            });
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>
<style>
  .navbar-nav .nav-item p{
    line-height: inherit;
    margin-left: 5px;
  }
</style>
