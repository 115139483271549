<template>
  <div class="row">
    <div class="col-12">
      <card title="Customers">
        <div>
          <DeleteConfirmation
            :currentDeleteMethod="deleteCustomer"
            @delete="$event()"
          />
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div class="add-button">
            <button class="btn btn-primary btn-fill" @click="addUser">
              Add
            </button>
          </div>
          <div class="col-sm-12">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    v-b-modal.confirmDelete
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  created() {
    this.$store.dispatch("getCustomers");
  },
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },

  data() {
    return {
      selectedId: "",
      selectedDeleteId: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["customername", "platform"],
      tableColumns: [
        {
          prop: "uid",
          label: "ID",
          minWidth: 150
        },
        {
          prop: "name",
          label: "Customer Name",
          minWidth: 200
        },
        {
          prop: "status",
          label: "Status",
          minWidth: 200
        },
        {
          prop: "Platforms",
          label: "Platforms",
          minWidth: 120
        }
      ],
      fuseSearch: null
    };
  },

  computed: {
    tableData() {
      var obj = [];
      var obj = this.$store.state.admin.customers;
      return obj;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["name"] }).search(
          this.searchQuery
        );
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["name"] });
  },

  methods: {
    handleEdit: function(index, props) {
      //alert("Edit = " + JSON.stringify(props));
      this.$router.push({ name: "Edit Customer", params: { id: props.id } });
    },

    handleDelete: function(index, props) {
      this.selectedDeleteId = props.id;
      // 			var data = {
      // 				index : index,
      //  			};
      // // 			this.tableData.splice(index, index+1)

      // 			this.$store.commit('deleteCustomer' , data);
      // 			this.$router.push({name:'Customer'});
    },
    deleteCustomer() {
      if (this.selectedDeleteId) {
        this.$store
          .dispatch("deleteCustomerByID", this.selectedDeleteId)
          .then(data => {
            this.$store
              .dispatch("getCustomers")
              .then(data => {
                this.$router.push({ name: "Customer" });
              })
              .catch(() => {});
          })
          .catch(() => {});
      }
    },

    addUser: function() {
      var data = {
        name: "",
        platforms: [],
        uid: this.$uuid.v4(),
        status: false,
        saved: false
      };
      //	this.$store.dispatch('createCustomer' , data);
      this.$router.push({ name: "Edit Customer", params: { id: "-1" } });
    },
    getCSV(arry) {
      var CSVstr = "";
      for (var i = 0; i < arry.platforms.length; i++) {
        CSVstr = "," + arry.platforms[i].name;
      }
      return CSVstr;
    }
  }
};
</script>

<style scoped>
.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
}
</style>
