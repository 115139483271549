var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_vm._v(" "),_c('side-bar',{scopedSlots:_vm._u([{key:"links",fn:function(props){return [(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{key:"2",attrs:{"link":{
          name: 'Customers',
          icon: 'nc-icon nc-badge',
          path: '/super/customers'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{key:"3",attrs:{"link":{
          name: 'Admins',
          icon: 'nc-icon nc-key-25',
          path: '/super/users'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN' || 'EDITOR')?_c('sidebar-item',{key:"4",attrs:{"link":{
          name: 'Videos',
          icon: 'nc-icon nc-button-play',
          path: '/videos/all'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN' || 'EDITOR')?_c('sidebar-item',{key:"5",attrs:{"link":{
          name: 'Catalogue',
          icon: 'nc-icon nc-grid-45',
          path: '/catalogue/all'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN' || 'SUPPORT')?_c('sidebar-item',{key:"1",attrs:{"link":{
          name: 'Users',
          icon: 'nc-icon nc-circle-09',
          path: '/admin/users'
        }}}):_vm._e(),_vm._v(" "),_c('sidebar-item',{key:"10",attrs:{"link":{ name: 'Monetization', icon: 'nc-icon nc-money-coins' },"hasSubMenu":_vm.isMenu}},[(_vm.getUserRole() == 'ADMIN' || 'EDITOR')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'SVOD', path: '/monetization/svod/list' }}}):_vm._e()],1),_vm._v(" "),(_vm.getUserRole() == 'ADMIN' || 'EDITOR')?_c('sidebar-item',{key:"11",attrs:{"link":{
          name: 'Blogs',
          icon: 'nc-icon nc-paper-2',
          path: '/blogs/all'
        }}}):_vm._e(),_vm._v(" "),_c('sidebar-item',{key:"12",attrs:{"link":{
          name: 'Administration',
          icon: 'nc-icon nc-settings-gear-64'
        },"hasSubMenu":_vm.isMenu}},[(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Payment Gateways',
            path: '/settings/paymentgateways'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Authentication Provider',
            path: '/settings/authprovider'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Analytics Provider', path: '/settings/analytics' }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Access Management',
            icon: 'nc-icon nc-badge',
            path: '/administration/all'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Payment Gateways',
            path: '/settings/paymentgatewayconfig'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Authentication Provider',
            path: '/settings/authproviderconfig'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Video Hosting Provider',
            path: '/settings/customerhost'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Analytics Provider',
            path: '/settings/analyticsconfig'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Whitelisting',
            path: '/settings/whitelisting'
          }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() != 'SUPER_ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Email Triggers', path: '/settings/sendgrid' }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Add Host Providers', path: '/settings/videohost' }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Notification',
            path: '/settings/notificationconfig'
          }}}):_vm._e(),_vm._v(" "),(false)?_c('sidebar-item',{attrs:{"link":{ name: 'Actions', path: '/settings/actions' }}}):_vm._e()],1),_vm._v(" "),_c('sidebar-item',{key:"13",attrs:{"link":{ name: 'report', icon: 'nc-icon nc-chart-bar-32' },"hasSubMenu":_vm.isMenu}},[_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'User Report', path: '/report/user' }}}),_vm._v(" "),_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Subscriber Report', path: '/report/subscriber' }}}),_vm._v(" "),_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{
            name: 'Payment Gateway Report',
            path: '/report/paymentgateway'
          }}}),_vm._v(" "),_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Video Views Report', path: '/report/video' }}}),_vm._v(" "),_c('sidebar-item',{staticClass:"childSideBar",attrs:{"link":{ name: 'Error Logs Report', path: '/report/errorlogs' }}})],1),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{key:"6",attrs:{"link":{
          name: 'Templates',
          icon: 'nc-icon nc-atom',
          path: '/templates/all'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{key:"7",attrs:{"link":{
          name: 'Products',
          icon: 'nc-icon nc-app',
          path: '/products/all'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'SUPER_ADMIN')?_c('sidebar-item',{key:"14",attrs:{"link":{
          name: 'Ad Provider',
          icon: 'nc-icon nc-app',
          path: '/adprovider/all'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{key:"8",attrs:{"link":{
          name: 'Choose Product',
          icon: 'nc-icon nc-app',
          path: '/products/choose'
        }}}):_vm._e(),_vm._v(" "),(_vm.getUserRole() == 'ADMIN')?_c('sidebar-item',{key:"9",attrs:{"link":{
          name: 'My Websites',
          icon: 'nc-icon nc-cart-simple',
          path: '/orders/all'
        }}}):_vm._e()]}}])},[_c('mobile-menu')],1),_vm._v(" "),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_vm._v(" "),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_vm._v(" "),_c('content-footer')],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }