<template>
  <card>
    <b-modal id="metadatas" ref="modal" title="Manage Meta Data">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <fg-input label="Key" v-model="currentMetaData.key"></fg-input>
          </div>
          <div class="col-sm-5">
            <fg-input label="Value" v-model="currentMetaData.value"></fg-input>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-primary meta-button" @click="addMetaData()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-bigboy"
          style="width: 100%;"
          :data="metaData"
          border
        >
          <el-table-column min-width="120" label="Key">
            <template slot-scope="{ row }">
              {{ row.key }}
            </template>
          </el-table-column>
          <el-table-column min-width="140" label="Value">
            <template slot-scope="{ row }">
              {{ row.value }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="props">
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="deleteMetaData(props.$index)"
                ><i class="fa fa-trash"></i
              ></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <div class="uploadingOverlay" v-if="uploading">
      <l-spinner
        class="upload-spinner"
        :main="true"
        :title="spinnerTip"
        size="medium"
      ></l-spinner>
    </div>
    <b-modal
      id="templateComponentDialog"
      ref="templateComponentDialog"
      @ok="saveComponent"
      @cancel="exitComponentDialog"
      :title="getComponentDialogTitle()"
    >
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input
              label="Name"
              v-model="currentComponentData.name"
              placeholder="Name"
            ></fg-input>
          </div>
          <div class="col-md-12">
            <div class="col-md-12 col-12 thumb-div">
              <h5 class="card-title">Thumbnail</h5>
              <img class="thumbnail" :src="currentComponentData.thumbnail" />
              <div class="uploadthumbbtn">
                <button
                  class="btn btn-primary"
                  @click="openImageDialog($event, 'component')"
                >
                  Upload
                </button>
              </div>
              <div class="deletethumbbtn">
                <button class="btn btn-default">Delete</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal
      id="templateFeatureDialog"
      ref="templateFeatureDialog"
      @ok="saveFeature"
      :title="getFeatureDialogTitle()"
    >
      <form>
        <div class="row">
          <div class="col-md-12">
            <fg-input
              label="Name"
              v-model="currentFeature.name"
              placeholder="Name"
            ></fg-input>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Status</label>
            <el-switch
              style="margin-left: 10px;"
              v-model="currentFeature.status"
            ></el-switch>
          </div>
        </div>
      </form>
    </b-modal>
    <div class="row">
      <div class="col-md-6">
        <el-select
          :multiple="true"
          class="select-primary extralarge"
          size="large"
          v-model="multipleSelections"
          placeholder="Select templates"
        >
          <el-option
            v-for="item in templateTypes"
            class="select-primary"
            :value="item.id"
            :label="item.label"
            :key="item.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="col-md-6">
        <div class="switch enableSwitch">
          <l-switch
            on-text="ON"
            v-model="templateData.isEnabled"
            off-text="OFF"
          ></l-switch>
        </div>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Template Name"
          v-model="templateData.name"
          placeholder="Template Name"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Price"
          v-model="templateData.price"
          type="number"
          placeholder="Price"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Slug"
          v-model="templateData.slug"
          placeholder="Slug "
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Type"
          v-model="templateData.type"
          placeholder="Type"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Asset files"
          v-model="templateData.components.assetFiles"
          placeholder="Type"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Modules "
          v-model="templateData.components.modules"
          placeholder="Type"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input
          label="Customer id "
          v-model="templateData.customerId"
          placeholder="Type"
        ></fg-input>
      </div>

      <div class="col-md-6">
        <div class="row">
          <fg-input
            class="col-sm-12 col-12 subtitlecontainer"
            label="Description"
          >
            <textarea
              class="form-control"
              v-model="templateData.description"
              placeholder="Description"
              rows="3"
            ></textarea>
          </fg-input>
        </div>
      </div>

      <div class="col-md-12">
        <h3>Screenshots</h3>
        <button
          class="btn btn-primary btn-fill"
          style="margin-left: 10px;"
          @click="addScreenshot()"
        >
          Add
        </button>
        <div class="row">
          <div class="col-md-6" v-for="(thumb, index) in screenshotData" :key="index">
            <div class="col-md-12 col-12 thumb-div">
              <h5 class="card-title">Thumbnail</h5>
              <img class="thumbnail" :src="thumb" />
              <div class="uploadthumbbtn">
                <button
                  class="btn btn-primary"
                  @click="openImageDialog($event, 'thumbnail', $index)"
                >
                  Upload
                </button>
              </div>
              <div class="deletethumbbtn">
                <button
                  class="btn btn-default"
                  @click="deleteScreenshot($index)"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <h3>Parent Thumbnail</h3>
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-12 col-12 thumb-div">
              <h5 class="card-title">Thumbnail</h5>
              <img class="thumbnail-parent" :src="templateData.thumbnail" />
              <div class="uploadthumbbtn">
                <button
                  class="btn btn-primary"
                  @click="openImageDialog($event, 'parentthumbnail')"
                >
                  Change
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="add-button" style="padding-left:15px">
          <button class="btn btn-primary btn-fill" @click="addComponent()">
            Add
          </button>
        </div>
        <div class="col-sm-12">
          <el-table
            class="table-bigboy"
            style="width: 100%;"
            :data="componentsAndThumbnails"
            border
          >
            <el-table-column min-width="220" label="Component Name">
              <template slot-scope="{ row }">
                {{ row.name }}
              </template>
            </el-table-column>
            <el-table-column min-width="220" label="THUMB">
              <template slot-scope="{ row }">
                <div class="img-container">
                  <img :src="row.thumbnail" alt="Agenda" />
                </div>
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  @click="editComponent(props)"
                  class="btn-warning btn-simple btn-link"
                  ><i class="fa fa-edit"></i
                ></a>
                <a
                  v-tooltip.top-center="'Delete'"
                  @click="deleteComponent(props)"
                  class="btn-danger btn-simple btn-link"
                  ><i class="fa fa-trash"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="col-md-12" style="margin:10px 0px;">
        <div class="add-button" style="padding-left:15px">
          <button class="btn btn-primary btn-fill" @click="addFeature()">
            Add
          </button>
        </div>
        <div class="col-sm-12">
          <el-table
            class="table-bigboy"
            style="width: 100%;"
            :data="featureData"
            border
          >
            <el-table-column min-width="220" label="Feature Name">
              <template slot-scope="{ row }">
                {{ row.name }}
              </template>
            </el-table-column>
            <el-table-column min-width="220" label="Status">
              <template slot-scope="{ row }">
                {{ row.status }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  class="btn-warning btn-simple btn-link"
                  @click="editFeature(props)"
                  ><i class="fa fa-edit"></i
                ></a>
                <a
                  v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="deleteFeature(props)"
                  ><i class="fa fa-trash"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="manage-tag" v-b-modal.metadatas>Manage Tags</p>
        </div>
      </div>
      <div class="col-md-12">
        <button class="btn btn-primary btn-simple" @click="handleSaveBtn">
          Save
        </button>
      </div>
      <input
        type="file"
        ref="thumbnailInput"
        accept=".jpg, .png, .jpeg, .gif"
        v-bind:style="{ display: 'none' }"
        @change="uploadPicture($event, 'thumbnail')"
      />
      <input
        type="file"
        ref="parentthumbnailInput"
        accept=".jpg, .png, .jpeg, .gif"
        v-bind:style="{ display: 'none' }"
        @change="uploadPicture($event, 'parentthumbnail')"
      />
      <input
        type="file"
        ref="componentThumbInput"
        accept=".jpg, .png, .jpeg, .gif"
        v-bind:style="{ display: 'none' }"
        @change="uploadPicture($event, 'component')"
      />
    </div>
  </card>
</template>
<script>
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Slider,
  Switch
} from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Loading } from "element-ui";

export default {
  components: {
    LSwitch,
    LSpinner,
    bModal,
    bModalDirective,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  data() {
    return {
      metaData: [],
      currentMetaData: {
        key: "",
        value: ""
      },
      multipleSelections: "",
      createMode: true,
      uploading: false,
      componentDialogEdit: false,
      featureDialogEdit: false,
      componentsAndThumbnails: [],
      spinnerTip: "Uploading Images...",
      templateData: {
        productIds: [],
        screenshot: [],
        configs: [
          {
            param: "logo",
            name: "Logo",
            value: "http://logo",
            readOnly: false
          }
        ],
        isActive: true,
        name: "",
        slug: "",
        description: "",
        thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff",
        type: "website",
        components: {
          assetFiles: "",
          modules: ""
        },
        customerId: null,
        createdBy: "",
        metaData: {},
        id: "",
        products: null,
        customer: null,
        price: ""
      },
      templateTypes: [],
      currentComponentIndex: -1,
      currentFeatureIndex: -1,
      currentScreenshotIndex: -1,
      currentComponentData: {
        name: "",
        thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff"
      },
      currentFeature: {
        name: "",
        status: false
      },
      screenshotData: ["https://dummyimage.com/640x360/a1a1a1/fff"],
      featureData: [],
      thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff"
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.getProductlist();
    if (!this.$route.params.id || this.$route.params.id == -1) {
      this.createMode = true;
    } else {
      this.createMode = false;
      this.getTemplate();
    }
  },
  methods: {
    getProductlist: function() {
      self = this;
      self.$store.dispatch("getProductList").then(data => {
        self.templateTypes = data.map(function(item) {
          let data = {
            label: item.name,
            value: item.name,
            id: item.id
          };
          return data;
        });
      });
    },
    getTemplate: function() {
      this.$store
        .dispatch("getTemplateWithId", this.$route.params.id)
        .then(res => {
          this.templateData = res;
          // this.featureData = JSON.parse(JSON.stringify(res.features));
          // this.componentsAndThumbnails = JSON.parse(JSON.stringify(res.componentsAndThumbnails));
          this.screenshotData = JSON.parse(JSON.stringify(res.screenshots));
          this.multipleSelections = res.productIds;
          var metaData = res.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            this.metaData.push(obj);
          }
          //this.multipleSelections = data.productIds;
        })
        .catch(err => {});
    },
    handleSaveBtn: function() {
      var formData = JSON.parse(JSON.stringify(this.templateData));
      formData.metaData = {};
      for (var j = 0; j < this.metaData.length; j++) {
        var key = this.metaData[j].key;
        formData.metaData[key] = this.metaData[j].value;
      }
      formData.screenshots = [];
      formData.screenshots = this.screenshotData;
      formData.productIds = this.multipleSelections;
      let loadingInstance = Loading.service({ fullscreen: true });
      if (this.createMode) {
        this.$store
          .dispatch("createTemplate", formData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/templates/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      } else {
        this.$store
          .dispatch("updateTemplate", formData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/templates/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      }
    },
    getFeatureDialogTitle: function() {},
    getComponentDialogTitle: function() {},
    exitComponentDialog: function() {},
    addScreenshot: function() {
      var thumbnail = "https://dummyimage.com/640x360/a1a1a1/fff";
      this.screenshotData.push(thumbnail);
    },
    addComponent: function() {
      this.componentDialogEdit = false;
      this.currentComponentData = {
        name: "",
        thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff"
      };
      this.$refs.templateComponentDialog.show();
    },
    saveComponent: function() {
      if (this.componentDialogEdit) {
        this.componentsAndThumbnails.splice(this.currentComponentIndex, 1);
        this.componentsAndThumbnails.splice(
          this.currentComponentIndex,
          0,
          JSON.parse(JSON.stringify(this.currentComponentData))
        );
      } else {
        this.componentsAndThumbnails.push(this.currentComponentData);
      }
    },
    addFeature: function() {
      this.featureDialogEdit = false;
      this.currentFeature = {
        name: "",
        status: false
      };
      this.$refs.templateFeatureDialog.show();
    },
    deleteComponent: function(props) {
      this.componentsAndThumbnails.splice(props.$index, 1);
    },
    editComponent: function(props) {
      this.componentDialogEdit = true;
      this.currentComponentIndex = props.$index;
      this.currentComponentData = JSON.parse(JSON.stringify(props.row));
      this.$refs.templateComponentDialog.show();
    },
    deleteFeature: function(props) {
      this.featureData.splice(props.$index, 1);
    },
    editFeature: function(props) {
      this.featureDialogEdit = true;
      this.currentFeatureIndex = props.$index;
      this.currentComponentData = JSON.parse(JSON.stringify(props.row));
      this.$refs.templateFeatureDialog.show();
    },
    saveFeature: function() {
      if (this.featureDialogEdit) {
        this.featureData.splice(this.currentFeatureIndex, 1);
        this.featureData.splice(
          this.currentFeatureIndex,
          0,
          JSON.parse(JSON.stringify(this.currentFeature))
        );
      } else {
        this.featureData.push(this.currentFeature);
      }
    },
    deleteScreenshot: function(index) {
      this.screenshotData.splice(index, 1);
    },
    exitFeatureDialog: function() {},
    openImageDialog(evt, type, index) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        this.currentScreenshotIndex = index;
        file = this.$refs.thumbnailInput;
      } else if (type == "parentthumbnail") {
        file = this.$refs.parentthumbnailInput;
      } else {
        file = this.$refs.componentThumbInput;
      }
      if (file) {
        file.click();
      }
    },
    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      self.uploading = true;
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.uploading = false;
              this.manageImage(type, url);
            })
            .catch(() => {
              self.uploading = false;
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          self.uploading = false;
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    manageImage(type, url) {
      if (type == "thumbnail") {
        if (this.currentScreenshotIndex != -1)
          this.screenshotData[this.currentScreenshotIndex] = url;
      } else if (type == "parentthumbnail") {
        this.templateData.thumbnail = url;
      } else {
        this.currentComponentData.thumbnail = url;
      }
    },
    addMetaData: function() {
      this.metaData.push(JSON.parse(JSON.stringify(this.currentMetaData)));
      this.currentMetaData = {
        key: "",
        value: ""
      };
    },
    deleteMetaData: function(index) {
      this.metaData.splice(index, 1);
    }
  }
};
</script>
<style scoped>
.thumbnail {
  position: absolute;
  float: left;
  height: 110px;
}
.thumbnail-parent {
  position: absolute;
  float: left;
  height: 110px;
  width: 150px;
}

.thumb-div {
  padding-left: 10px;
  min-height: 160px;
}

.uploadthumbbtn {
  position: absolute;
  float: left;
  margin-top: 0px;
  margin-left: 200px;
}

.deletethumbbtn {
  position: absolute;
  float: left;
  margin-top: 50px;
  margin-left: 200px;
}

#templateComponentDialog .modal-dialog {
  max-width: 400px !important;
}
</style>
