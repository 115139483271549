<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteAddedSubscription"
      ref="deleteConfirmation"
      @delete="$event()"
    />
    <b-modal
      size="lg"
      id="newConfigModal"
      ref="modal"
      @ok="selectSubscriptions"
      title="Select subscriptions"
      ok-title="Add"
    >
      <div class="largepopup">
        <form @submit.stop.prevent="handleNewConfig">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive table-full-width">
                <el-table :data="availableSubscriptions">
                  <el-table-column width="65" label="" property="id">
                    <template slot-scope="props">
                      <l-checkbox
                        :checked="isChecked(props)"
                        @input="checkChanged($event, props.row)"
                      ></l-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Package"
                    property="package.title"
                  ></el-table-column>
                  <el-table-column
                    label="Subscription"
                    property="title"
                  ></el-table-column>
                  <el-table-column label="Price" property="price">
                    <template slot-scope="props">
                      {{ getPriceCurrency(props.row) }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="Slug Name"
                    property="slug_name"
                  ></el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </b-modal>

    <div class="col-md-12">
      <card>
        <div class="add-button">
          <el-button
            type="primary"
            icon="el-icon-plus"
            v-b-modal.newConfigModal
            :disabled="!userId"
            >Add Package
          </el-button>
        </div>
        <el-table class="table-striped" :data="addedSubscriptions">
          <el-table-column minWidth="90" prop="package.title" label="Package">
          </el-table-column>
          <el-table-column
            minWidth="90"
            prop="subscription.title"
            label="Subscription"
          >
          </el-table-column>
          <el-table-column minWidth="60" prop="type" label="Type">
          </el-table-column>
          <el-table-column minWidth="60" prop="status" label="Status">
            <template slot-scope="props">
              {{ getStatus(props.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="Start Date">
            <template slot-scope="props">
              {{ getStartDate(props.row) }}
            </template>
          </el-table-column>
          <el-table-column prop="updatedAt" label="Expiry/Renewal Date">
            <template slot-scope="props">
              {{ getExpiryDate(props.row) }}
            </template>
          </el-table-column>
          <el-table-column minWidth="100" label="Expiry Date (Manual Sub Only)">
            <template slot-scope="props">
              <fg-input v-if="props.row.type == 'MANUAL'">
                <el-date-picker
                  v-if="props.row.type == 'MANUAL'"
                  v-model="props.row.expiry_date"
                  type="datetime"
                  placeholder="Datetime picker here"
                  @change="expireDateChange(props.row)"
                  :picker-options="pickerOptions1"
                >
                </el-date-picker>
              </fg-input>
            </template>
          </el-table-column>
          <el-table-column minWidth="50">
            <template slot-scope="props">
              <a
                class="btn-danger btn-simple btn-link"
                @click="openDeleteDialog(props.$index, props.row)"
              >
                <i class="fa fa-trash"></i>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, DatePicker, TimeSelect, Button } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Checkbox as LCheckbox } from "../../../../../src/components";
import countries from "../../../../config/countries.json";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import moment from "moment";
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LCheckbox,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    DeleteConfirmation,
    [Button.name]: Button
  },
  props: ["userId", "userInfo"],
  data() {
    return {
      tableColumns: [],
      subscriptionDatas: [],
      addedSubscriptions: [],
      selectedSubscriptions: [],
      countries: [],
      pickerOptions1: {
        shortcuts: [
          {
            text: "Today",
            onClick(picker) {
              picker.$emit("pick", new Date());
            }
          },
          {
            text: "Yesterday",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            }
          },
          {
            text: "A week ago",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            }
          }
        ]
      },
      dateTimePicker: ""
    };
  },
  watch: {
    "userInfo.subscriptions": function() {
      this.addedSubscriptions = this.userInfo.subscriptions;
    }
  },
  computed: {
    availableSubscriptions() {
      var data = JSON.parse(JSON.stringify(this.subscriptionDatas));
      var subscriptions = this.addedSubscriptions;
      if (Array.isArray(subscriptions) && subscriptions.length >= 1) {
        for (var i = 0; i < subscriptions.length; i++) {
          var subId = subscriptions[i].sub_id;
          var index = this.findWithAttr(data, "sub_id", subId);
          if (index >= 0) {
            data.splice(index, 1);
          }
        }
      }
      return data;
    }
  },
  created() {
    this.countries = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.$store
      .dispatch("getSubscriptionList")
      .then(data => {
        this.subscriptionDatas = data;
        this.addedSubscriptions = this.userInfo.subscriptions;
      })
      .catch(() => {});
  },
  methods: {
    selectSubscriptions() {
      var req = {};
      req.id = this.userId;
      req.data = this.selectedSubscriptions;
      var data = JSON.parse(JSON.stringify(this.subscriptionDatas));
      this.$store
        .dispatch("AddSubscriptionToUser", req)
        .then(response => {
          var subs = response.data.subscriptions;
          for (var i = 0; i < subs.length; i++) {
            var id = subs[i].sub_id;
            var index = this.findWithAttr(data, "sub_id", id);
            var item = data[index];

            item.subscription = {};
            item.subscription.title = item.title;
            item.id = subs[i].id;
            item.type = subs[i].type;

            this.addedSubscriptions.push(item);
          }
          this.selectedSubscriptions = [];
          this.$notify({
            title: "Success",
            message: "Subscription added Successfully!",
            type: "success"
          });
        })
        .catch(() => {});
    },
    isChecked(prop) {
      return false;
    },
    deleteAddedSubscription() {
      if (this.deleteAddedId) {
        var req = {};
        req.id = this.userId;
        req.subId = this.deleteAddedId;
        this.$store
          .dispatch("deleteSubscriptionToUser", req)
          .then(response => {
            this.addedSubscriptions.splice(this.deleteAddedIndex, 1);
          })
          .catch(() => {});
      }
    },
    getPriceCurrency: function(row) {
      var price = row.price;
      var currency = row.currency;
      return price + "   " + currency;
    },

    checkChanged(val, row) {
      if (row.id) {
        var result = this.selectedSubscriptions.filter(obj => {
          return obj.sub_id === row.id;
        });
        if (val) {
          if (result.length <= 0) {
            var obj = {};
            obj.sub_id = row.id;
            obj.packageId = row.packageId;
            obj.promo_code = "";
            obj.payment_gateway = {};
            obj.metaData = {};
            obj.status = true;
            obj.expiry_date = "";
            obj.type = "MANUAL";
            this.selectedSubscriptions.push(obj);
          }
        } else {
          if (result.length > 0) {
            var index = this.findWithAttr(
              this.selectedSubscriptions,
              "sub_id",
              row.id
            );
            if (index >= 0) {
              this.selectedSubscriptions.splice(index, 1);
            }
          }
        }
      }
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    openDeleteDialog(index, row) {
      this.deleteAddedId = row.id;
      this.deleteAddedIndex = index;
      this.$refs["deleteConfirmation"].$refs["deleteConfirmation"].show();
    },
    expireDateChange(row) {
      var req = {};
      req.appUserId = this.userId;
      req.subscriptionId = row.id;
      req.data = {};
      req.data.expiry_date = row.expiry_date;
      this.$store
        .dispatch("updateExpireData", req)
        .then(response => {})
        .catch(() => {});
    },
    getMultiCountryName: function(row) {
      var countries = row.country;
      var str = "";
      if (Array.isArray(countries)) {
        for (var i = 0; i < countries.length; i++) {
          var country = countries[i];
          var cvar = this.countries.find(function(element) {
            return element.value == country;
          });
          if (cvar) {
            if (i != 0) {
              str = str + " , ";
            }
            str = str + cvar.label;
          }
        }
      }
      return str;
    },
    handleNewConfig() {},
    getStartDate(row) {
      if (row.createdAt) {
        return moment(row.createdAt, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    getExpiryDate(row) {
      if (row.expiry_date) {
        return moment(row.expiry_date, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    getStatus(row) {
      if (row.status == true) {
        return "Active";
      } else if (row.status == false) {
        return "InActive";
      } else {
        return "InActive";
      }
    }
  }
};
</script>

<style scoped>
.add-button {
  float: left;
  padding-bottom: 10px;
}

.tableContent {
  position: absolute;
  padding-top: 50px;
}
</style>
