<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteCatalogue"
      @delete="$event()"
    />
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Catalogue
          </span>
        </div>
        
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
        <div class="filterWrapper">
          <el-select
                  class="select-default"
                  size="large"
                  placeholder="Catalogue Type"
                  v-model="catalogue_type"
                  name="catalogueType"
                  >
                  <el-option
                    v-for="option in cataloguetypes"
                    class="select-default"
                    :value="option.value"
                    :label="option.label"
                    :key="option.label"
                  >
                  </el-option>
          </el-select>
          
        </div>
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              ref="searchKey"
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              @input="debounceInput"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select class="select-default ml-1" v-model="pagination.perPage" style="width: 80px; "
              placeholder="Per page" :change="changeperpage(pagination.perPage)">
              <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>

          <div class="mx-2 mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addUser"
              >Add Catalogue
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-sm-12 p-3">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="100" label="Status">
                <template slot-scope="props">
                  <span @click="handleSwitch(props.$index, props.row)">
                    <l-switch
                      :value="getIsSDiableTag(props.row)"
                      class="status"
                    ></l-switch>
                  </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="100" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    v-b-modal.confirmDelete
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of
              {{ pagination.total }} entries
            </p>
          </div>
          <l-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total" @input="clickCallback(pagination.currentPage)"></l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { Switch as LSwitch } from "src/components";
import Fuse from "fuse.js";
import { debounce } from "debounce";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    DeleteConfirmation
  },

  data() {
    return {
      selectedCId: -1,
      
      searchQuery: "",
      propsToSearch: ["name", "tags"],
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200
        },
        {
          prop: "type",
          label: "Type",
          minWidth: 200
        }
      ],
      fuseSearch: null,
      cataloguetypes: [
        {
          value:"",
          label:"All"
        },
        {
          value: "Category",
          label: "Category"
        },
        {
          value: "Playlist",
          label: "Playlist"
        },
        {
          value: "Root",
          label: "Root"
        },
        {
          value: "Season",
          label: "Season"
        },
        {
          value: "Series",
          label: "Series"
        }
        
        
        
      ],
      catalogue_type:""

    };
  },

  computed: {
    pagination() {
      return this.$store.getters.getCataloguePagination;
    },
    tableData() {
      if(this.catalogue_type.length>0){
        return this.$store.state.catalogue.catalogueList.filter((item)=>item.type===this.catalogue_type)
      }
      return this.$store.state.catalogue.catalogueList;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      // if (this.searchQuery !== "") {
      //   result = new Fuse(this.tableData, { keys: ["name"] }).search(
      //     this.searchQuery
      //   );
      // }
      //this.pagination.total = result.length;
     // return result.slice(this.from, this.to);
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (highBound > this.pagination.perPage) {
        highBound = this.pagination.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {
    this.$store.commit("setLoader", {
      show: true,
      text: "Fetching Catalogue..."
    });
    
    this.$store
      .dispatch("getCatalogueList")
      .then(response => {
        this.$store.commit("setLoader", { show: false, text: "" });
      })
      .catch(err => {
        this.$store.commit("setLoader", { show: false, text: "" });
      });
  },
  mounted() {},
  beforeDestroy(){
   // this.$store.commit("setCatalogueList",{_list:[]})
   this.$store.commit("setCatalogueSearchText", "");
  },

  methods: {
    clickCallback: function (pagNum) {
      if (this.$store.state.catalogue.inputPage != pagNum) {
        this.$store.commit("setCatalogueInputPage", pagNum);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Catalogues..."
        });
        this.$store.dispatch("getCatalogueList").then(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        });
        return true;
      }
    },
    changeperpage: function (perPage) {
      if (this.$store.state.catalogue.inputSize != perPage) {
        this.$store.commit("setCatalogueInputPage", 1);
        this.$store.commit("setCatalogueInputPerpage", perPage);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Catalogues..."
        });
        this.$store
          .dispatch("getCatalogueList")
          .then(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          })
          .catch(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          });
        return true;
      }
    },
    debounceInput: debounce(function () {
     this.searchCatalogue();
    }, 800),
    handleEdit: function(index, props) {
      this.$router.push({ name: "Edit Catalogue", params: { id: props.id } });
    },
    searchCatalogue: function () {
      this.$store.commit("setLoader", {
        show: true,
        text: "Searching"
      });
      this.$store.commit("setCatalogueInputPage", 1);
      this.$store.commit("setCatalogueSearchText", this.$refs.searchKey.value);
      this.$store
        .dispatch("getCatalogueList")
        .then(response => {
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
      return true;
    },
    handleWatchVideo: function(index, props) {},

    handleDelete: function(index, props) {
      this.selectedCId = props.id;
    },

    addUser: function() {
      this.$router.push({ name: "Edit Catalogue", params: { id: "-1" } });
    },
    deleteCatalogue: function() {
      if (this.selectedCId && this.selectedCId != -1) {
        this.$store
          .dispatch("deleteCatalogue", this.selectedCId)
          .then(data => {
            this.$store.dispatch("getCatalogueList");
          })
          .catch(() => {});
      }
    },
    getStatus(row) {
      if (row.isActive) return "Active";
      return "InActive";
    },
    getIsSDiableTagText: function(item) {
      if (item.isActive == true) {
        return "TRUE";
      } else {
        return "FALSE";
      }
    },
    getIsSDiableTag: function(item) {
      if (item.isActive == true) {
        return true;
      } else {
        return false;
      }
    },
    handleSwitch: function(index, props) {
      if (props.id) {
        if (index >= 0) {
          // var data = this.$store.state.catalogue.catalogueList[index];
          var data = props;
          var req = {};
          if (data.isActive == false) {
            data.isActive = true;
          } else {
            data.isActive = false;
          }
          var DataToSend = {
            isActive: data.isActive,
            id: data.id,
            _isDeleted: true
          };
          req.id = data.id;
          req.data = DataToSend;
          self = this;
          if (data.id) {
            this.$store
              .dispatch("updateCatalogue", req)
              .then(response => {
                this.$store.dispatch("getCatalogueList");
                this.$notify({
                  title: "Success",
                  message:
                    "Catalogue Updated Successfully!",
                  type: "success"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Catalogue Failed!",
                  type: "warning"
                });
                return;
              });
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 500;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
.filterWrapper{
margin-right: 10px;
width: 120px;
}
</style>
