import DashboardLayout from "src/pages/Dashboard/Layout/DashboardLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";
// Dashboard pages
import Overview from "src/pages/Dashboard/Dashboard/Overview.vue";
import Stats from "src/pages/Dashboard/Dashboard/Stats.vue";

// Pages
import User from "src/pages/Dashboard/Pages/UserProfile.vue";
import TimeLine from "src/pages/Dashboard/Pages/TimeLinePage.vue";
import EmailVerify from "src/pages/Dashboard/Pages/EmailVerify.vue";
import Login from "src/pages/Dashboard/Pages/Login.vue";
import Register from "src/pages/Dashboard/Pages/Register.vue";
import Resetpassword from "src/pages/Dashboard/Pages/Resetpassword.vue";
import Repassword from "src/pages/Dashboard/Pages/Repassword.vue";
import Lock from "src/pages/Dashboard/Pages/Lock.vue";
import ForgotPass from "src/pages/Dashboard/Pages/ForgotPassword.vue";

import EditTheme from "src/pages/Dashboard/Pages/EditTheme.vue";
// Components pages
import Buttons from "src/pages/Dashboard/Components/Buttons.vue";
import GridSystem from "src/pages/Dashboard/Components/GridSystem.vue";
import Panels from "src/pages/Dashboard/Components/Panels.vue";
const SweetAlert = () =>
  import("src/pages/Dashboard/Components/SweetAlert.vue");
import Notifications from "src/pages/Dashboard/Components/Notifications.vue";
import Icons from "src/pages/Dashboard/Components/Icons.vue";
import Typography from "src/pages/Dashboard/Components/Typography.vue";

import Users from "src/pages/Dashboard/users/UserProfile.vue";
import SuperAdmin from "src/pages/Dashboard/Super/User.vue";
import SuperCustomer from "src/pages/Dashboard/Super/Customer.vue";
import EditSuperCustomer from "src/pages/Dashboard/Super/EditCustomer.vue";
import EditSuperUser from "src/pages/Dashboard/Super/EditUser.vue";
import Administration from "src/pages/Dashboard/Administration/Administration.vue";
import EditAdministration from "src/pages/Dashboard/Administration/EditAdministration.vue";

import EditUser from "src/pages/Dashboard/users/EditUserProfile.vue";
//import DashboardReport from "src/pages/Dashboard/DashboardReport/DashboardReport.vue";

import AllVideos from "src/pages/Dashboard/Videos/Videos.vue";
import EditVideo from "src/pages/Dashboard/Videos/EditVideo.vue";

import AllBlogs from "src/pages/Dashboard/Blogs/AllBlogs.vue";
import EditBlog from "src/pages/Dashboard/Blogs/EditBlog.vue";

import OVP from "src/pages/Dashboard/Settings/ovp.vue";
import EmailTemplates from "src/pages/Dashboard/Settings/EmailTemplates.vue";
import PaymentGateways from "src/pages/Dashboard/Settings/PaymentGateways.vue";
import AuthProvider from "src/pages/Dashboard/Settings/AuthProvider.vue";
import PaymentGateWayConfig from "src/pages/Dashboard/Settings/PaymentConfig.vue";
import AuthProviderConfig from "src/pages/Dashboard/Settings/AuthProviderConfig.vue";
import VideoHostProvider from "src/pages/Dashboard/Settings/AdminVideoHost.vue";
import CustomerProvider from "src/pages/Dashboard/Settings/CustomerProvider.vue";
import SendGrid from "src/pages/Dashboard/Settings/SendGrid.vue";
import Analytics from "src/pages/Dashboard/Settings/Analytics.vue";
import AnalyticsConfig from "src/pages/Dashboard/Settings/AnalyticsConfig.vue";
import Whitelisting from "src/pages/Dashboard/Settings/WhitelistAppUsers.vue";
import AddWhitelistAppUser from "src/pages/Dashboard/Settings/AddWhitelistAppUser.vue"
import Actions from "src/pages/Dashboard/Settings/Actions.vue";
import NotificationsProvider from "src/pages/Dashboard/Notifications/Notifications.vue";
import NotificationConfig from "src/pages/Dashboard/Settings/Notification.vue";
import EditGroupNotification from "src/pages/Dashboard/Notifications/EditGroupNotification.vue";
import SendAll from "src/pages/Dashboard/Notifications/SendAll.vue";

import SVOD from "src/pages/Dashboard/Monetization/SVOD.vue";
import AVOD from "src/pages/Dashboard/Monetization/AVOD.vue";
import Promotion from "src/pages/Dashboard/Monetization/promotion.vue";
import EditSVOD from "src/pages/Dashboard/Monetization/EditSVOD.vue";
import EditAVOD from "src/pages/Dashboard/Monetization/EditAVOD.vue";
import AVODConfig from "src/pages/Dashboard/Monetization/AVODConfig.vue";
import Catalogue from "src/pages/Dashboard/Catalogue/catalogue.vue";
import EditCatalogue from "src/pages/Dashboard/Catalogue/EditCatalogue.vue";

import Products from "src/pages/Dashboard/Products/Products.vue";
import EditProduct from "src/pages/Dashboard/Products/EditProduct.vue";
import ChooseProduct from "src/pages/Dashboard/Products/ChooseProduct.vue";

import AdProviderList from "src/pages/Dashboard/AdProvider/AdProviderList.vue";
import EditAdProvider from "src/pages/Dashboard/AdProvider/EditAdProvider.vue";

import Templates from "src/pages/Dashboard/Templates/Templates.vue";
import EditTemplate from "src/pages/Dashboard/Templates/EditTemplate.vue";
import ChooseTemplate from "src/pages/Dashboard/Templates/ChooseTemplate.vue";

import OrderList from "src/pages/Dashboard/Orders/OrderList.vue";

import Report from "src/pages/Dashboard/Report/Report.vue";
import UserReport from "src/pages/Dashboard/Report/UserReport.vue";
import VideoReport from "src/pages/Dashboard/Report/VideoReport.vue";
import ErrorLogsReports from "src/pages/Dashboard/Report/ErrorLogsReports.vue";
import SubscriberReport from "src/pages/Dashboard/Report/SubscriberReport.vue";
import PaymentGatewayReport from "src/pages/Dashboard/Report/PaymentGatewayReport.vue";
// Forms pages
const RegularForms = () => import("src/pages/Dashboard/Forms/RegularForms.vue");
const ExtendedForms = () =>
  import("src/pages/Dashboard/Forms/ExtendedForms.vue");
const ValidationForms = () =>
  import("src/pages/Dashboard/Forms/ValidationForms.vue");
const Wizard = () => import("src/pages/Dashboard/Forms/Wizard.vue");

// TableList pages
const RegularTables = () =>
  import("src/pages/Dashboard/Tables/RegularTables.vue");
const ExtendedTables = () =>
  import("src/pages/Dashboard/Tables/ExtendedTables.vue");
const PaginatedTables = () =>
  import("src/pages/Dashboard/Tables/PaginatedTables.vue");
// Maps pages
const GoogleMaps = () => import("src/pages/Dashboard/Maps/GoogleMaps.vue");
const FullScreenMap = () =>
  import("src/pages/Dashboard/Maps/FullScreenMap.vue");
const VectorMaps = () => import("src/pages/Dashboard/Maps/VectorMapsPage.vue");

// Calendar
const Calendar = () => import("src/pages/Dashboard/Calendar/CalendarRoute.vue");
// Charts
const Charts = () => import("src/pages/Dashboard/Charts.vue");

let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      component: Buttons
    },
    {
      path: "grid-system",
      name: "Grid System",
      component: GridSystem
    },
    {
      path: "panels",
      name: "Panels",
      component: Panels
    },
    {
      path: "sweet-alert",
      name: "Sweet Alert",
      component: SweetAlert
    },
    {
      path: "notifications",
      name: "Notifications",
      component: Notifications
    },
    {
      path: "icons",
      name: "Icons",
      component: Icons
    },
    {
      path: "typography",
      name: "Typography",
      component: Typography
    }
  ]
};
let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/regular",
  children: [
    {
      path: "regular",
      name: "Regular Forms",
      component: RegularForms
    },
    {
      path: "extended",
      name: "Extended Forms",
      component: ExtendedForms
    },
    {
      path: "validation",
      name: "Validation Forms",
      component: ValidationForms
    },
    {
      path: "wizard",
      name: "Wizard",
      component: Wizard
    }
  ]
};

let tablesMenu = {
  path: "/table-list",
  component: DashboardLayout,
  redirect: "/table-list/regular",
  children: [
    {
      path: "regular",
      name: "Regular Tables",
      component: RegularTables
    },
    {
      path: "extended",
      name: "Extended Tables",
      component: ExtendedTables
    },
    {
      path: "paginated",
      name: "Paginated Tables",
      component: PaginatedTables
    }
  ]
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  children: [
    {
      path: "google",
      name: "Google Maps",
      component: GoogleMaps
    },
    {
      path: "full-screen",
      name: "Full Screen Map",
      component: FullScreenMap
    },
    {
      path: "vector-map",
      name: "Vector Map",
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      component: User
    },
    {
      path: "timeline",
      name: "Timeline Page",
      component: TimeLine
    }
  ]
};

let loginPage = {
  path: "/login",
  name: "Login",
  component: Login
};
let EmailVerifyPage = {
  path: "/emailverify",
  name: "EmailVerify",
  component: EmailVerify
};
let registerPage = {
  path: "/register",
  name: "Register",
  component: Register
};

let forgotPasswordPage = {
  path: "/forgotpassword",
  name: "ForgotPassword",
  component: ForgotPass
};
let resetPasswordPage = {
  path: "/resetpassword",
  name: "Resetpassword",
  component: Resetpassword
};
let rePassword = {
  path: "/repassword",
  name: "Repassword",
  component: Repassword
};

let lockPage = {
  path: "/lock",
  name: "Lock",
  component: Lock
};

let editTheme = {
  path: "/edittheme/:id",
  name: "Edit Theme",
  component: EditTheme
};
// let dashboardReport = {
//   path: "/dashboardreport",
//   name: "Dashboard",
//   redirect: "/dashboardreport/all",
//   component: DashboardLayout,
//   children: [
//     {
//       path: "all",
//       name: "All DashboardReport",
//       component: DashboardReport
//     }
//   ]
// };

let videosPage = {
  path: "/videos",
  name: "Videos",
  redirect: "/videos/all",
  component: DashboardLayout,
  children: [
    {
      path: "edit/:id",
      name: "Edit Video",
      component: EditVideo,
      props: true
    },
    {
      path: "create",
      name: "Create Video",
      component: EditVideo
    },
    {
      path: "all",
      name: "All Videos",
      component: AllVideos
    }
  ]
};

let administration = {
  path: "/administration",
  name: "Administration",
  redirect: "/administration/all",
  component: DashboardLayout,
  children: [
    {
      path: "edit/:id",
      name: "Edit Administration",
      component: EditAdministration,
      props: true
    },
    {
      path: "create",
      name: "Create Administration",
      component: Administration
    },
    {
      path: "all",
      name: "All Administration",
      component: Administration
    }
  ]
};
let blogPage = {
  path: "/blogs",
  name: "Blogs",
  redirect: "/blogs/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All Blogs",
      component: AllBlogs
    },
    {
      path: "edit/:id",
      name: "Edit Blog",
      component: EditBlog,
      props: true
    }
  ]
};

let cataloguePage = {
  path: "/catalogue",
  name: "Catalogue",
  redirect: "/catalogue/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All Catalogue",
      component: Catalogue
    },
    {
      path: "edit/:id",
      name: "Edit Catalogue",
      component: EditCatalogue,
      props: true
    }
  ]
};

let templatePage = {
  path: "/templates",
  name: "Template",
  redirect: "/templates/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All Templates",
      component: Templates
    },
    {
      path: "edit/:id",
      name: "Edit Template",
      component: EditTemplate,
      props: true
    },
    {
      path: "choose/:id",
      name: "Choose Template",
      component: ChooseTemplate,
      props: true
    }
  ]
};

let productPage = {
  path: "/products",
  name: "Product",
  redirect: "/products/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All Products",
      component: Products
    },
    {
      path: "edit/:id",
      name: "Edit Product",
      component: EditProduct,
      props: true
    },
    {
      path: "choose",
      name: "Choose Template",
      component: ChooseProduct
    }
  ]
};

let OrderPage = {
  path: "/orders",
  name: "Orders",
  redirect: "/orders/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All Orders",
      component: OrderList
    }
  ]
};

let AdProviderPage = {
  path: "/adprovider",
  name: "AdProvider",
  redirect: "/adprovider/all",
  component: DashboardLayout,
  children: [
    {
      path: "all",
      name: "All AdProvider",
      component: AdProviderList
    },
    {
      path: "edit/:id",
      name: "Edit AdProvider",
      component: EditAdProvider,
      props: true
    }
  ]
};

let monetizationPage = {
  path: "/monetization",
  name: "Monetization",
  redirect: "/monetization/svod",
  component: DashboardLayout,
  children: [
    {
      path: "svod/list",
      name: "SVOD",
      component: SVOD
    },
    {
      path: "svod/edit/:id",
      name: "Edit SVOD",
      component: EditSVOD
    },
    {
      path: "avod/list",
      name: "AVOD",
      component: AVOD
    },
    {
      path: "avod/edit/:id",
      name: "Edit AVOD",
      component: EditAVOD
    },
    {
      path: "avodconfig/list",
      name: "AVOD CONFIG",
      component: AVODConfig
    },

    {
      path: "promotion",
      name: "Promotion",
      component: Promotion
    }
  ]
};

const routes = [
  {
    path: "/",
    //  redirect: '/admin/overview'
    redirect: "/login"
  },

  componentsMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  EmailVerifyPage,
  registerPage,
  forgotPasswordPage,
  resetPasswordPage,
  rePassword,
  lockPage,
  editTheme,
  videosPage,
  administration,
  blogPage,
  cataloguePage,
  templatePage,
  productPage,
  OrderPage,
  AdProviderPage,
  monetizationPage,
  {
    path: "/super",
    component: DashboardLayout,
    children: [
      {
        path: "customers",
        name: "Customer",
        component: SuperCustomer
      },
      {
        path: "users",
        name: "Admins",
        component: SuperAdmin
      },
      {
        path: "edituser/:id",
        name: "Edit Admin",
        component: EditSuperUser,
        props: true
      },
      {
        path: "editcustomer/:id",
        name: "Edit Customer",
        component: EditSuperCustomer,
        props: true
      }
    ]
  },
  {
    path: "/report",
    name: "report",
    component: DashboardLayout,
    redirect: "/report/overview",
    children: [
      {
        path: "overview",
        name: "reportgraph",
        component: Report
      },
      {
        path: "user",
        name: "userreport",
        component: UserReport
      },
      {
        path: "video",
        name: "videoreport",
        component: VideoReport
      },
      {
        path: "errorlogs",
        name: "errorlogsreports",
        component: ErrorLogsReports
      },
      {
        path: "subscriber",
        name: "subscriberreport",
        component: SubscriberReport
      },
      {
        path: "paymentgateway",
        name: "paymentgatewayreport",
        component: PaymentGatewayReport
      }
    ]
  },
  {
    path: "/admin",
    name: "Users",
    component: DashboardLayout,
    redirect: "/admin/users",
    children: [
      {
        path: "overview",
        name: "Overview",
        component: Overview
      },
      {
        path: "stats",
        name: "Stats",
        component: Stats
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts
      },
      {
        path: "users",
        name: "Users",
        component: Users
      },
      {
        path: "edituser/:id",
        name: "Edit User",
        component: EditUser,
        props: true
      },
      {
        path: "adduser",
        name: "Add User",
        component: EditUser
      }
    ]
  },
  {
    path: "/settings",
    name: "Settings",
    component: DashboardLayout,
    children: [
      {
        path: "sendgrid",
        name: "Send Grid",
        component: SendGrid
      },
      {
        path: "ovp",
        name: "OVP",
        component: OVP
      },
      {
        path: "emailtemplates",
        name: "Email Templates",
        component: EmailTemplates
      },
      {
        path: "paymentgateways",
        name: "Payment Gateways",
        component: PaymentGateways
      },
      {
        path: "paymentgatewayconfig",
        name: "Payment Config",
        component: PaymentGateWayConfig
      },
      {
        path: "authprovider",
        name: "Auth Provider",
        component: AuthProvider
      },
      {
        path: "authproviderconfig",
        name: "AuthProvider Config",
        component: AuthProviderConfig
      },
      {
        path: "analytics",
        name: "Analytics",
        component: Analytics
      },
      {
        path: "analyticsconfig",
        name: "AnalyticsConfig",
        component: AnalyticsConfig
      },
      {
        path: "videohost",
        name: "Video Host Provider",
        component: VideoHostProvider
      },
      {
        path: "customerhost",
        name: "Customer Provider",
        component: CustomerProvider
      },

      {
        path: "actions",
        name: "Actions",
        component: Actions
      },
      {
        path: "notifications",
        name: "Send Notifications",
        component: NotificationsProvider
      },
      {
        path: "editgroupnotification/:id",
        name: "Edit GroupNotification",
        component: EditGroupNotification
      },
      {
        path: "notifications/sendtoall",
        name: "Send All",
        component: SendAll
      },
      {
        path: "whitelisting",
        name: "Whitelisting",
        component: Whitelisting
      },
      {
        path: "addwhitelistappuser",
        name: "AddWhitelistAppUser",
        component: AddWhitelistAppUser
      },
      {
        path: "notificationconfig",
        name: "Notification Config",
        component: NotificationConfig
      }
    ]
  },
  { path: "*", component: NotFound }
];
export default routes;
