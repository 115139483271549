<template>
  <div class="user">
    <div>
      <a
        data-toggle="collapse"
        :aria-expanded="!isClosed"
        @click.stop="toggleMenu"
        href="#"
      >
        <!--
           <span>
             {{title}}
             <b class="caret"></b>
          </span>
        -->
      </a>
      <span class="username">{{ getUserName() }} </span>
      <div class="clearfix"></div>
      <!--<div>
        <el-collapse-transition>
          <ul class="nav" v-show="!isClosed">
            <slot>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">MP</span>
                  <span class="sidebar-normal">My Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">EP</span>
                  <span class="sidebar-normal">Edit Profile</span>
                </a>
              </li>
              <li>
                <a class="profile-dropdown" href="#pablo">
                  <span class="sidebar-mini">S</span>
                  <span class="sidebar-normal">Settings</span>
                </a>
              </li>
            </slot>
          </ul>
        </el-collapse-transition>
      </div>-->
    </div>
  </div>
</template>
<script>
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import UserService from "../../../../service/userService";

export default {
  components: {
    [CollapseTransition.name]: CollapseTransition
  },
  props: {
    title: {
      type: String,
      default: "Tania Andrew"
    }
  },
  data() {
    return {
      isClosed: true
    };
  },
  mounted() {},
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
    getUserName() {
      return UserService.getUserName();
    }
  }
};
</script>
<style>
.collapsed {
  transition: opacity 1s;
}
.username {
  text-align: center;
  word-break: break-all;
  white-space: normal;
}
</style>
