<template>
  <div class="dialog-loading__container">
    <p class="dialog-loading__text" ref="tips" :class="{'medium-text':size=='medium'}" v-if="main">{{title}}</p>
    <div class="dialog-spinner" :class="{'medium-spinner':size=='medium', 'bottom-placed': main}">
      <div :class="{'dialog-spinner__bg light':main, 'dialog-spinner__bg':!main}"></div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "spinner",
    props: ["title", "size", "main"],
    mounted() {

    },
    methods: {}
  };
</script>

<style>
  .dialog-loading__container {
    text-align: center;
  }

  .dialog-spinner {
    animation: spin 0.5s infinite linear;
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto 20px auto;
  }

  .dialog-spinner::after {
    background: linear-gradient(to top, rgba(201, 205, 207, 0.5) 0%, rgba(201, 205, 207, 0) 100%);
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
  }

  .dialog-spinner::before {
    background: linear-gradient(to bottom, #c9cdcf 0%, rgba(201, 205, 207, 0.5) 100%);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
  }

  .dialog-spinner__bg {
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    width: calc(100% - (2px * 2));
    height: calc(100% - (2px * 2));
    background: white;
    border-radius: 100%;
  }

  .dialog-spinner__bg.light {
    background: #ebeced;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .dialog-loading__text {
    width: 32%;
    margin: 0 auto;
    font-family: "Open Sans", sans-serif;
    color: #ebeced;
    font-weight: 700;
    line-height: 1.5em;
  }

  .medium-spinner {
    width: 50px;
    height: 50px;
  }

  .medium-text {
    font-size: 21px;
  }

  .bottom-placed{
    margin-top: 30px;
  }
</style>
