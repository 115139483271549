<template>
  <div>
    <div>
      <span class="titleCard mb-2">
        Email Triggers
      </span>
    </div>
    <card>
      <div class="row">
        <div class="col-md-8">
          <fg-input type="text" v-model="api_key" label="Api Key"></fg-input>
        </div>
        <div class="col-md-4">
          <l-switch
            v-model="isActive"
            on-text="ON"
            off-text="OFF"
            class="float-right"
          ></l-switch>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            v-model="defaultFromName"
            label="From Name"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            v-model="defaultFromEmail"
            label="From Email"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            v-model="defaultReplyToName"
            label="Reply Name"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            v-model="defaultReplyTo"
            label="Reply Email"
          ></fg-input>
        </div>
      </div>
      <div>
        <el-collapse>
          <el-collapse-item
            v-for="item in activeTemplates"
            :title="item.title"
            :key="item.type"
          >
            <div class="row px-3">
              <div class="col-md-12 mt-2">
                <l-switch
                  v-model="item.isEnable"
                  on-text="ON"
                  off-text="OFF"
                  class="float-right"
                ></l-switch>
              </div>
              <div class="col-sm-6">
                <fg-input
                  type="text"
                  label="Template ID"
                  v-model="item.template_id"
                ></fg-input>
              </div>

              <div class="col-sm-6">
                <fg-input
                  type="text"
                  label="From Name"
                  v-model="item.fromName"
                ></fg-input>
              </div>
              <div class="col-sm-6">
                <fg-input
                  type="text"
                  label="From Email"
                  v-model="item.fromEmail"
                ></fg-input>
              </div>
              <div class="col-sm-6">
                <fg-input label="Message">
                  <textarea
                    rows="5"
                    class="form-control border-input"
                    placeholder="Template substituion JSON"
                    name="Description"
                    v-model="item.substitution_json"
                  >
                  </textarea>
                </fg-input>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div class="float-left mt-3">
        <el-button
          type="primary"
          @click="saveConfiguration($event)"
          class="btn btn-info btn-fill"
        >
          Save Configuration
        </el-button>
      </div>
    </card>
  </div>
</template>
<script>
import { Select, Option, Collapse, CollapseItem, Button } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";

export default {
  created() {
    this.$store
      .dispatch("getSendGridList")
      .then(response => {
        if (!response || response.length < 1) {
          this.editFlag = false;
        } else {
          var data = response[0];
          if (data) {
            this.api_key = data.apiKey;
            this.defaultFromName = data.defaultFromName;
            this.defaultFromEmail = data.defaultFromEmail;
            this.defaultReplyToName = data.defaultReplyToName;
            this.defaultReplyTo = data.defaultReplyTo;
            this.isActive = data.isActive;
            this.id = data.id;
            var configObj = JSON.parse(JSON.stringify(data.configObj));
            for (var i = 0; i < this.activeTemplates.length; i++) {
              var item = this.activeTemplates[i];
              var index = this.findWithAttr(configObj, "type", item.type);
              if (index >= 0) {
                if (configObj[index].template_id) {
                  this.activeTemplates[i].template_id =
                    configObj[index].template_id;
                }
                if (configObj[index].substitution_json) {
                  this.activeTemplates[i].substitution_json =
                    configObj[index].substitution_json;
                }
                if (configObj[index].fromName) {
                  this.activeTemplates[i].fromName = configObj[index].fromName;
                }
                if (configObj[index].fromEmail) {
                  this.activeTemplates[i].fromEmail =
                    configObj[index].fromEmail;
                }
                if (configObj[index].isEnable) {
                  this.activeTemplates[i].isEnable = configObj[index].isEnable;
                }
              }
            }
          }
          this.editFlag = true;
        }
      })
      .catch(() => {});
  },
  computed: {},
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LCheckbox,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem
  },
  watch: {},
  data() {
    return {
      id: "",
      editFlag: false,
      api_key: "",
      defaultFromName: "",
      defaultFromEmail: "",
      defaultReplyTo: "",
      defaultReplyToName: "",
      isActive: false,
      activeTemplates: [
        {
          isEnable: false,
          title: "Marketing Campaign",
          type: "marketing-campaign",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Welcome Message - Registered User",
          type: "registered-user",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Welcome Message - Added Manually",
          type: "manual-user",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Manual Subscription - Changes",
          type: "manual-subscription",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Manual Subscription - Expiration",
          type: "manual-subscription-expiry",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Trial Expiration",
          type: "trial-expiry",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Subscription Invoice",
          type: "subscription-invoice",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Subscription Payment Issue",
          type: "subscription-payment-issue",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Subscription Cancellation",
          type: "subscription-cancellation",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "New Contents Notification",
          type: "new-content-notification",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Weekly Summary of New Contents",
          type: "weekly-content-summary",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "New Category Added",
          type: "new-category",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "New Subscription Added",
          type: "new-subscription-added",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Subscription Price Change",
          type: "subscription-price-change",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "New Feature Release",
          type: "new-feature-release",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "New App Release",
          type: "new-app-release",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        },
        {
          isEnable: false,
          title: "Reset Password",
          type: "user-reset-password",
          template_id: "",
          substitution_json: "",
          fromName: "",
          fromEmail: ""
        }
      ]
    };
  },
  methods: {
    saveConfiguration(event) {
      var req = {};
      req.customerId = this.$store.state.customerId;
      req.apiKey = this.api_key;
      req.defaultFromName = this.defaultFromName;
      req.defaultFromEmail = this.defaultFromEmail;
      req.defaultReplyToName = this.defaultReplyToName;
      req.defaultReplyTo = this.defaultReplyTo;
      req.isActive = this.isActive;
      req.configObj = [];
      for (var i = 0; i < this.activeTemplates.length; i++) {
        var item = {};
        item.type = this.activeTemplates[i].type;
        item.template_id = this.activeTemplates[i].template_id;
        item.substitution_json = this.activeTemplates[i].substitution_json;
        item.fromName = this.activeTemplates[i].fromName;
        item.fromEmail = this.activeTemplates[i].fromEmail;
        item.isEnable = this.activeTemplates[i].isEnable;
        req.configObj.push(item);
      }
      if (!this.editFlag) {
        this.$store
          .dispatch("createSendGrid", req)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Successfully Saved!",
              type: "success"
            });
          })
          .catch(() => {
            event.preventDefault();
            return;
            this.$notify({
              title: "Error",
              message: "Failed to Save!",
              type: "warning"
            });
          });
      } else {
        var updateReq = {};
        updateReq.data = req;
        updateReq.id = this.id;
        this.$store
          .dispatch("updateSendGrid", updateReq)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Successfully Updated!",
              type: "success"
            });
          })
          .catch(() => {
            event.preventDefault();
            return;
            this.$notify({
              title: "Error",
              message: "Failed to Update!",
              type: "warning"
            });
          });
      }
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    }
  }
};
</script>

<style scoped>
.sendgrid-btn {
  padding: 10px;
}
.btn-grid {
  float: right;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 12px;
}
</style>
