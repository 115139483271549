<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteTvod"
      ref="deleteTvodConfirmation"
      @delete="$event()"
    />
    <b-modal
      id="tvoidModal"
      v-model="showTvod"
      ref="modal"
      @ok="saveTvod"
      @cancel="exitDialog"
      title="New Configuration"
      ok-title="Save"
    >
      <form @submit.stop.prevent="handleNewConfig">
        <div>
          <span class="labelText">Currency</span>
          <div>
            <el-select
              class="col-sm-12 col-12 p-0"
              size="large"
              :filterable="true"
              placeholder="Currency"
              v-model="curData.currency"
            >
              <el-option
                v-for="curr in currencies"
                class="select-danger"
                :value="curr.value"
                :label="curr.label"
                :key="curr.label"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div>
          <span class="labelText">Price</span>
          <div>
            <b-form-input
              type="number"
              step="0.01"
              placeholder="Config Value"
              title="Config Value"
              v-model="curData.price"
            >
            </b-form-input>
          </div>
        </div>
        <div>
          <span class="labelText">Country</span>
          <div>
            <treeselect
              v-model="curData.country"
              :value-consists-of="valueConsistsOf"
              :multiple="true"
              :options="countries"
            />
            <!--
						<el-select class="select-default subtitlecontainer select-primary" :multiple="true" size="large" placeholder="Country" v-model="curData.country">
							<el-option v-for="count in countries" class="select-danger" :value="count.value" :label="count.label" :key="count.label">
							</el-option>
						</el-select>
						-->
          </div>
        </div>
      </form>
    </b-modal>

    <div class="col-12">
      <!-- <card title=""> -->
      <div>
        <!-- <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-default mb-3"
            style="width: 200px"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-input
            type="search"
            class="mb-3"
            style="width: 200px"
            placeholder="Search records"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div> -->
        <div class="add-button">
          <el-checkbox v-model="setting.TVOD" size="medium">
            <p>TVOD</p>
          </el-checkbox>
          <el-button
            type="primary"
            icon="el-icon-plus"
            v-if="setting.TVOD"
            @click="createTvod"
            >Add Price
          </el-button>
        </div>
        <div class="col-sm-12 p-3">
          <el-table
            stripe
            style="width: 100%;"
            :data="queriedData"
            border
            v-if="setting.TVOD && dMode != 'create'"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="500" fixed="right" label="Country">
              <template slot-scope="props">
                <div>{{ getMultiCountryName(props.row) }}</div>
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  class="btn-warning btn-simple btn-link"
                  v-b-modal.tvoidModal
                  @click="handleEdit(props.$index, props.row)"
                  ><i class="fa fa-edit"></i
                ></a>
                <a
                  v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="handleDelete(props.$index, props.row)"
                  ><i class="fa fa-trash"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-between justify-content-between "
        v-if="setting.TVOD && dMode != 'create'"
      >
        <div>
          <p class="card-category ml-2">
            Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          </p>
        </div>
        <l-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </l-pagination>
      </div>
      <!-- </card> -->
    </div>
  </div>
</template>

<script>
import appConfigs from "../data/AppConfig";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Button,
  Checkbox
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import countries from "../../../config/countries.json";
import currencies from "../../../config/currency.json";
import { formatForSelectFromJson } from "../../../service/utility";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
//const apps = appConfigs.config;

export default {
  props: ["videoId", "setting"],
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    appConfigs,
    bModal,
    bModalDirective,
    Treeselect,
    DeleteConfirmation
  },
  data() {
    return {
      appIndex: 0,
      showTvod: false,
      newConfigName: "",
      newConfigParam: "",
      newConfigValue: "",
      valueConsistsOf: "ALL_WITH_INDETERMINATE",
      dMode: "",
      curData: {
        country: []
      },
      apps: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["configname", "configparam", "configvalue"],
      currencies: [],
      countries: [],
      tableColumns: [
        {
          prop: "price",
          label: "Price",
          minWidth: 100
        },
        {
          prop: "currency",
          label: "Currency",
          minWidth: 100
        }
      ],
      fuseSearch: null
    };
  },
  computed: {
    pagedData() {
      return this.apps.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.apps;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.apps.length;
      return this.apps.length;
    }
  },
  created() {
    this.countries = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.currencies = formatForSelectFromJson(currencies);
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["configname", "configparam", "configvalue"]
    });
    if (this.videoId) {
      this.$store
        .dispatch("getTVODList", this.videoId)
        .then(response => {
          this.apps = response;
        })
        .catch(() => {});
    } else {
      this.dMode = "create";
    }
  },
  watch: {
    videoId: function() {}
  },
  methods: {
    handleEdit: function(index, props) {
      this.curData = props;
      this.dMode = "edit";
      this.showTvod = true;
    },

    handleDelete: function(index, props) {
      this.curData = props;
      this.dMode = "delete";
      this.$refs["deleteTvodConfirmation"].$refs["deleteConfirmation"].show();
    },

    createTvod: function() {
      if (this.videoId) {
        this.addUser();
        this.$refs["modal"].show();
      } else {
        this.$notify({
          title: "Update",
          message:
            "In order to add TVOD, please create the video first, then click edit and add TVOD",
          type: "warning"
        });
      }
    },

    addUser: function() {
      this.curData = { country: [] };
      this.dMode = "";
      this.showTvod = true;
    },
    saveTvod: function(evt) {
      var req = {};
      req.data = {};
      req.id = this.videoId;
      if (this.dMode == "") {
        req.data = this.curData;
        this.$store
          .dispatch("createTvodOnVideo", req)
          .then(response => {
            this.apps.push(JSON.parse(JSON.stringify(response)));
            this.curData = { country: [] };
            this.dMode = "";
          })
          .catch(() => {});
      } else if (this.dMode == "edit") {
        req.tId = this.curData.id;
        req.data.country = this.curData.country;
        req.data.currency = this.curData.currency;
        req.data.price = this.curData.price;
        this.$store
          .dispatch("updateTvodOnVideo", req)
          .then(response => {})
          .catch(() => {});
      }
    },
    deleteTvod: function() {
      var req = {};
      req.id = this.videoId;
      req.tId = this.curData.id;
      if (this.dMode == "delete") {
        this.$store
          .dispatch("deleteTvodOnVideo", req)
          .then(response => {
            var index = this.apps.indexOf(this.curData);
            this.apps.splice(index, 1);
            this.curData = { country: [] };
          })
          .catch(() => {});
      }
    },
    exitDialog: function() {
      this.curData = { country: [] };
      this.dMode = "";
    },
    clearNewConfigName: function() {
      this.newConfigName = "";
    },
    getMultiCountryName: function(row) {
      var countries = row.country;
      var str = "";
      if (Array.isArray(countries)) {
        for (var i = 0; i < countries.length; i++) {
          var country = countries[i];

          var cvar = "";

          for (var j = 0; j < this.countries.length; j++) {
            var continent = this.countries[j].children;
            for (var k = 0; k < continent.length; k++) {
              if (country == continent[k].id) {
                cvar = continent[k];
                k = continent.length;
                j = countries.length;
              }
            }
          }

          if (cvar) {
            if (i != 0) {
              str = str + " , ";
            }
            str = str + cvar.label;
          }
        }
      }
      return str;
    }
  }
};
</script>

<style scoped>
.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
  width: 200px;
}
.labelText {
  font-size: 12px;
  color: #9a9a9a;
  margin-bottom: 5px !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}
</style>
