<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteCustomerHost"
      @delete="$event()"
    />

    <div class="col-md-12">
      <card v-if="showSendCard">
        <div class="row">
          <fg-input
            class="col-md-10"
            label="Title"
            v-model="curData.configObj.notification.title"
          ></fg-input>
        </div>
        <div class="row">
          <div class="col-md-6">
            <fg-input label="Body" show-word-limit="true">
              <textarea
                class="form-control"
                v-model="curData.configObj.notification.body"
                placeholder="Body"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
          <div class="col-md-6 col-6  thumbDiv">
            <span class="titleThumb mb-2">
              Thumbnail
            </span>

            <div>
              <div
                class=" ml-2 thumbNail"
                :style="{
                  backgroundImage:
                    'url(' + curData.configObj.notification.image + ')'
                }"
              ></div>
            </div>
            <div class="row mt-2 mx-0" style="width: 300px">
              <div class="col-4 ">
                <el-button
                  type="primary"
                  class="uploadThumbBtn"
                  icon="el-icon-upload"
                  @click="openImageDialog($event, 'thumbnail')"
                >
                  Upload
                </el-button>
              </div>
              <div class="col-4 ">
                <el-button
                  type="danger"
                  class="deleteThumbBtn"
                  icon="el-icon-delete"
                  @click.prevent="deleteImage('thumbnail')"
                >
                  Delete
                </el-button>
              </div>
            </div>
          </div>
          <input
            type="file"
            ref="thumbnailInput"
            accept=".jpg, .png, .jpeg, .gif"
            v-bind:style="{ display: 'none' }"
            @change="uploadPicture($event, 'thumbnail')"
          />
        </div>
        <div class="row mt-3">
          <div class="col-md-12 col-12">
            <card title="Manage Meta Data">
              <ManageMetaData :metaData="metaData"></ManageMetaData>
            </card>
          </div>
        </div>
        <div class="row saveNotification">
          <el-button
            type="primary"
            style="width: 150px;"
            @click.prevent="sendNotification()"
          >
            Send
          </el-button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";
import ManageMetaData from "../Components/ManageMetaData";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    LCheckbox,
    DeleteConfirmation,
    ManageMetaData
  },
  data() {
    return {
      metaData: [],

      currentGroup: null,
      groupList: [],
      showSendCard: false,
      groupname: "",
      searchBySub: false,
      searchSubcriptionId: null,
      searchErr: false,
      errorText: "incorrect Search",
      availList: [],
      filtertypes: [
        {
          value: "country",
          label: "Country"
        },
        {
          value: "subscriptionId",
          label: "subcription Id"
        },
        {
          value: "status",
          label: "status"
        }
      ],
      filterKey: "",
      tableData: [],
      filterUsersearchQuery: null,
      currentHost: null,
      notificationHostArray: [],
      notification_key_name: "",
      userpagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 25, 50, 100],
        usertotal: 0
      },
      usersearchQuery: "",
      userpropsToSearch: ["username", "email"],
      usertableColumns: [
        {
          prop: "username",
          label: "Username",
          minWidth: 250
        },
        {
          prop: "id",
          label: "Id",
          minWidth: 250
        },
        {
          prop: "first_name",
          label: "First Name",
          minWidth: 200
        },
        {
          prop: "last_name",
          label: "Last Name",
          minWidth: 200
        }
      ],
      userfuseSearch: null,
      usertableData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      curData: {
        isActive: true,
        providerId: "",
        configObj: []
      }
    };
  },
  computed: {
    userpagedData() {
      return this.usertableData.slice(this.userfrom, this.userto);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the userpagination on the server and display the data retrieved userfrom server instead.
     * @returns {computed.userpagedData}
     */
    userqueriedData() {
      let result = this.usertableData;
      if (this.usersearchQuery !== "") {
        result = new Fuse(this.usertableData, {
          keys: ["username", "id", "first_name", "last_name"]
        }).search(this.usersearchQuery);
      }
      this.userpagination.usertotal = result.length;
      return result.slice(this.userfrom, this.userto);
    },
    userto() {
      let highBound = this.userfrom + this.userpagination.perPage;
      if (this.usertotal < highBound) {
        highBound = this.usertotal;
      }
      return highBound;
    },
    userfrom() {
      return (
        this.userpagination.perPage * (this.userpagination.currentPage - 1)
      );
    },
    usertotal() {
      this.userpagination.usertotal = this.usertableData.length;
      return this.usertableData.length;
    }
  },
  created() {
    this.notificationHostArray = this.$store.state.notifications.providers;
    var host = this.notificationHostArray[0];
    if (host) {
      this.curData = host;
    }

    var id = this.$route.params.id;
    if (id == "-1") {
      this.showSendCard = false;
    } else {
      this.showSendCard = true;
    }
  },
  methods: {
    restore: function() {
      this.notificationHostArray = this.$store.state.notifications.providers;
      var host = this.notificationHostArray[0];
      if (host) {
        this.curData = host;
      }
    },
    deleteMetaData: function(index) {
      this.metaData.splice(index, 1);
    },
    addMetaData: function() {
      this.metaData.push(JSON.parse(JSON.stringify(this.currentMetaData)));
      this.currentMetaData = {
        key: "",
        value: ""
      };
    },
    getCurrentGroup(currentId) {
      this.currentGroup = this.groupList.find(ele => {
        return (ele.id = currentId);
      });
      this.availList = this.currentGroup.userId;
      this.groupname = this.currentGroup.notification_key_name;
    },
    getname() {
      var id = this.$route.params.id;
      if (id == "-1") {
        return "Create Group";
      } else {
        return "Update Group";
      }
    },
    inputFocus() {
      this.searchErr = false;
    },
    filterAPI() {
      let query = "";
      if (this.filterKey != "" && this.filterUsersearchQuery != "") {
        if (this.searchBySub && this.searchSubcriptionId != "") {
          query =
            "subscriptionId=" +
            this.searchSubcriptionId +
            "&search[0][key]=" +
            this.filterKey +
            "&search[0][value]=" +
            this.filterUsersearchQuery;
        } else {
          query =
            "search[0][key]=" +
            this.filterKey +
            "&search[0][value]=" +
            this.filterUsersearchQuery;
        }
        this.$store
          .dispatch("filterUserByQuery", query)
          .then(response => {
            self.setUserTableData(response.data);
          })
          .catch(() => {});
      }
    },
    isChecked(props) {
      if (this.availList) {
        return this.availList.includes(props.row.id);
      } else {
        return false;
      }
    },
    resetAlluser() {
      this.availList = [];
      this.getpageData();
    },
    checkChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.availList.includes(id)) {
            this.availList.push(id);
          }
        } else {
          if (this.availList.includes(id)) {
            var index = this.availList.indexOf(id);
            this.availList.splice(index, 1);
          }
        }
      }
    },
    selectAllChange(val) {
      if (val) {
        this.availList = [];
        for (var i = 0; i < this.usertableData.length; i++) {
          if (!this.availList.includes(this.usertableData[i].id)) {
            this.availList.push(this.usertableData[i].id);
          }
        }
      } else {
        this.availList = [];
      }
    },
    searchBysubcription(val) {
      if (val) {
        this.searchBySub = true;
      } else {
        this.searchBySub = false;
      }
    },
    userisChecked(props) {
      if (this.availList) {
        return this.availList.includes(props.row.id);
      } else {
        return false;
      }
    },
    usercheckChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.availList.includes(id)) {
            this.availList.push(id);
          }
        } else {
          if (this.availList.includes(id)) {
            var index = this.availList.indexOf(id);
            this.availList.splice(index, 1);
          }
        }
      }
    },
    getpageData() {
      self = this;
      this.$store
        .dispatch("getAdminAppUserList")
        .then(response => {
          self.setUserTableData(response);
        })
        .catch(() => {});
    },
    setUserTableData(res) {
      this.usertableData = res;
    },
    openImageDialog(evt, type) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        file = this.$refs.thumbnailInput;
      }
      if (file) {
        file.click();
      }
    },
    deleteImage(type) {
      if (type == "thumbnail") {
        this.resetUrl("https://dummyimage.com/640x360/a1a1a1/fff", type);
      } else {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
    },
    resetUrl: function(url, type) {
      if (type == "thumbnail") {
        this.curData.configObj.notification.image = url;
      }
    },
    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.resetUrl(url, type);
            })
            .catch(() => {
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    sendNotification() {
      var Data = {};
      for (var i = 0; i < this.metaData.length; i++) {
        Data["" + this.metaData[i].key] = this.metaData[i].value;
      }
      Data["click_action"] = "FLUTTER_NOTIFICATION_CLICK";
      var DataToSend = {
        message: {
          topic: "All",
          notification: {
            title: this.curData.configObj.notification.title,
            body: this.curData.configObj.notification.body,
            image: this.curData.configObj.notification.image
          },
          data: Data
        }
      };
      this.$store
        .dispatch("sendNotificationToGroup", DataToSend)
        .then(res => {
          this.$notify({
            title: "Success",
            message: "Send Successfully!",
            type: "success"
          });
          this.restore();
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed Sending",
            type: "warning"
          });
        });
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      var host = this.notificationHostArray[tabIndex];
      if (host) {
        this.curData = host;
      }
    },
    deleteCustomerHost() {}
  },
  mounted() {}
};
</script>

<style scoped>
.subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mt-20 {
  margin-top: 20px;
}

.thumbNail {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}
.thumbDiv {
  min-height: 160px;
}
.uploadThumbBtn {
  padding: 10px 10px;
}
.deleteThumbBtn {
  padding: 10px 10px;
}
input::placeholder {
  font-size: 12px;
  opacity: 1;
}
.titleThumb {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin-left: 10px;
}
.saveNotification {
  float: left;
  position: relative;
  left: 14px;
  bottom: 22px;
}
</style>
