<template>
    <b-modal
      id="confirmManualSyncVideos"
      title="Manual Sync Videos Confirmation"
      ref="manualSyncConfirmation"
      ok-variant="primary"
      @ok="ManualSyncVideosMethod()"
    >
      <p>Are you sure you want to sync videos from Vimeo ?</p>
    </b-modal>
  </template>
  <script>
  export default {
    name: "ManualSyncVideosConfirmation",
    props: [],
    methods: {
      ManualSyncVideosMethod() {
        this.$emit("manualSync");
      }
    }
  };
  </script>
  <style></style>