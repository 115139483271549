<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <side-bar>
      <mobile-menu></mobile-menu>
      <template slot-scope="props" slot="links">
        <sidebar-item
          v-if="getUserRole() == 'SUPER_ADMIN'"
          :link="{
            name: 'Customers',
            icon: 'nc-icon nc-badge',
            path: '/super/customers'
          }"
          key="2"
        >
        </sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'SUPER_ADMIN'"
          :link="{
            name: 'Admins',
            icon: 'nc-icon nc-key-25',
            path: '/super/users'
          }"
          key="3"
        >
        </sidebar-item>
        <!-- <sidebar-item
          v-if="getUserRole() == 'ADMIN' || 'EDITOR'"
          :link="{
            name: 'Dashboard',
            icon: 'nc-icon nc-chart-pie-35',
            path: '/dashboardreport/all'
          }"
          key="16"
        >
        </sidebar-item> -->
        <sidebar-item
          v-if="getUserRole() == 'ADMIN' || 'EDITOR'"
          :link="{
            name: 'Videos',
            icon: 'nc-icon nc-button-play',
            path: '/videos/all'
          }"
          key="4"
        >
        </sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'ADMIN' || 'EDITOR'"
          :link="{
            name: 'Catalogue',
            icon: 'nc-icon nc-grid-45',
            path: '/catalogue/all'
          }"
          key="5"
        ></sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'ADMIN' || 'SUPPORT'"
          :link="{
            name: 'Users',
            icon: 'nc-icon nc-circle-09',
            path: '/admin/users'
          }"
          key="1"
        >
        </sidebar-item>

        <sidebar-item
          :link="{ name: 'Monetization', icon: 'nc-icon nc-money-coins' }"
          :hasSubMenu="isMenu"
          key="10"
        >
          <sidebar-item
            v-if="getUserRole() == 'ADMIN' || 'EDITOR'"
            :link="{ name: 'SVOD', path: '/monetization/svod/list' }"
            class="childSideBar"
          ></sidebar-item>
          <!-- <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'AVOD',
              path: '/monetization/avodconfig/list'
            }"
            class="childSideBar"
          ></sidebar-item> -->
          <!-- <sidebar-item
            v-if="getUserRole() == 'SUPER_ADMIN'"
            :link="{ name: 'AVOD', path: '/monetization/avod/list' }"
            class="childSideBar"
          ></sidebar-item> -->

          <!--<sidebar-item :link="{name: 'Promotion', path: '/monetization/promotion'}"></sidebar-item>-->
        </sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'ADMIN' || 'EDITOR'"
          :link="{
            name: 'Blogs',
            icon: 'nc-icon nc-paper-2',
            path: '/blogs/all'
          }"
          key="11"
        ></sidebar-item>

        <sidebar-item
          :link="{
            name: 'Administration',
            icon: 'nc-icon nc-settings-gear-64'
          }"
          :hasSubMenu="isMenu"
          key="12"
        >
          <!--<sidebar-item :link="{name: 'Email Templates', path: '/settings/emailtemplates'}"></sidebar-item>-->

          <sidebar-item
            v-if="getUserRole() == 'SUPER_ADMIN'"
            :link="{
              name: 'Payment Gateways',
              path: '/settings/paymentgateways'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'SUPER_ADMIN'"
            :link="{
              name: 'Authentication Provider',
              path: '/settings/authprovider'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'SUPER_ADMIN'"
            :link="{ name: 'Analytics Provider', path: '/settings/analytics' }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Access Management',
              icon: 'nc-icon nc-badge',
              path: '/administration/all'
            }"
            class="childSideBar"
          >
          </sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Payment Gateways',
              path: '/settings/paymentgatewayconfig'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Authentication Provider',
              path: '/settings/authproviderconfig'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Video Hosting Provider',
              path: '/settings/customerhost'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Analytics Provider',
              path: '/settings/analyticsconfig'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Whitelisting',
              path: '/settings/whitelisting'
            }"
            class="childSideBar"
          ></sidebar-item>
          <!-- <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{ name: 'Whitelist IP', path: '/settings/whitelistip' }"
          ></sidebar-item> -->
          <sidebar-item
            v-if="getUserRole() != 'SUPER_ADMIN'"
            :link="{ name: 'Email Triggers', path: '/settings/sendgrid' }"
            class="childSideBar"
          ></sidebar-item>
          <!-- <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Push Notification',
              path: '/settings/notifications/sendtoall'
            }"
          ></sidebar-item> -->

          <sidebar-item
            v-if="getUserRole() == 'SUPER_ADMIN'"
            :link="{ name: 'Add Host Providers', path: '/settings/videohost' }"
            class="childSideBar"
          ></sidebar-item>

          <sidebar-item
            v-if="getUserRole() == 'ADMIN'"
            :link="{
              name: 'Notification',
              path: '/settings/notificationconfig'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            v-if="false"
            :link="{ name: 'Actions', path: '/settings/actions' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: 'report', icon: 'nc-icon nc-chart-bar-32' }"
          :hasSubMenu="isMenu"
          key="13"
        >
          <!-- <sidebar-item
            :link="{ name: 'Dashboard', path: '/report/overview' }"
            class="childSideBar"
          ></sidebar-item> -->
          <sidebar-item
            :link="{ name: 'User Report', path: '/report/user' }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Subscriber Report', path: '/report/subscriber' }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: 'Payment Gateway Report',
              path: '/report/paymentgateway'
            }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Video Views Report', path: '/report/video' }"
            class="childSideBar"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: 'Error Logs Report', path: '/report/errorlogs' }"
            class="childSideBar"
          ></sidebar-item>
        </sidebar-item>

        <sidebar-item
          v-if="getUserRole() == 'SUPER_ADMIN'"
          :link="{
            name: 'Templates',
            icon: 'nc-icon nc-atom',
            path: '/templates/all'
          }"
          key="6"
        ></sidebar-item>

        <sidebar-item
          v-if="getUserRole() == 'SUPER_ADMIN'"
          :link="{
            name: 'Products',
            icon: 'nc-icon nc-app',
            path: '/products/all'
          }"
          key="7"
        ></sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'SUPER_ADMIN'"
          :link="{
            name: 'Ad Provider',
            icon: 'nc-icon nc-app',
            path: '/adprovider/all'
          }"
          key="14"
        ></sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'ADMIN'"
          :link="{
            name: 'Choose Product',
            icon: 'nc-icon nc-app',
            path: '/products/choose'
          }"
          key="8"
        ></sidebar-item>
        <sidebar-item
          v-if="getUserRole() == 'ADMIN'"
          :link="{
            name: 'My Websites',
            icon: 'nc-icon nc-cart-simple',
            path: '/orders/all'
          }"
          key="9"
        ></sidebar-item>
        <!--
        <sidebar-item :link="{name: 'Dashboard', icon: 'nc-icon nc-chart-pie-35', path: '/admin/overview'}">
        </sidebar-item>
        <sidebar-item :link="{name: 'Components', icon: 'nc-icon nc-app'}">
          <sidebar-item :link="{name: 'Buttons', path: '/components/buttons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Grid System', path: '/components/grid-system'}"></sidebar-item>
          <sidebar-item :link="{name: 'Panels', path: '/components/panels'}"></sidebar-item>
          <sidebar-item :link="{name: 'Sweet Alert', path: '/components/sweet-alert'}"></sidebar-item>
          <sidebar-item :link="{name: 'Notifications', path: '/components/notifications'}"></sidebar-item>
          <sidebar-item :link="{name: 'Icons', path: '/components/icons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Typography', path: '/components/typography'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Forms', icon: 'nc-icon nc-notes'}">
          <sidebar-item :link="{name: 'Regular Forms', path: '/forms/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Forms', path: '/forms/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Validation Forms', path: '/forms/validation'}"></sidebar-item>
          <sidebar-item :link="{name: 'Wizard', path: '/forms/wizard'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Tables', icon: 'nc-icon nc-paper-2'}">
          <sidebar-item :link="{name: 'Regular Tables', path: '/table-list/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Tables', path: '/table-list/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Paginated Tables', path: '/table-list/paginated'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Maps', icon: 'nc-icon nc-pin-3'}">
          <sidebar-item :link="{name: 'Google Maps', path: '/maps/google'}"></sidebar-item>
          <sidebar-item :link="{name: 'Full Screen Maps', path: '/maps/full-screen'}"></sidebar-item>
          <sidebar-item :link="{name: 'Vector Maps', path: '/maps/vector-map'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Charts', icon: 'nc-icon nc-chart-bar-32', path: '/admin/charts'}"></sidebar-item>
        <sidebar-item :link="{name: 'Calendar', icon: 'nc-icon nc-single-copy-04', path: '/admin/calendar'}"></sidebar-item>
        <sidebar-item :link="{name: 'Pages', icon: 'nc-icon nc-puzzle-10'}">
          <sidebar-item :link="{name: 'User Page', path: '/pages/user'}"></sidebar-item>
          <sidebar-item :link="{name: 'Login Page', path: '/login'}"></sidebar-item>
          <sidebar-item :link="{name: 'Register', path: '/register'}"></sidebar-item>
          <sidebar-item :link="{name: 'Lock Screen Page', path: '/lock'}"></sidebar-item>
        </sidebar-item>
        -->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import UserMenu from "./Extra/UserMenu.vue";
import UserService from "../../../service/userService";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    UserMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    getUserRole() {
      return UserService.getUserRole();
    }
  },
  data() {
    return {
      isMenu: true
    };
  }
};
</script>
<style lang="scss">
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}

.is-invalid input {
  border-color: #dc3545 !important;
}

.is-invalid.el-select .el-input input {
  border-color: #dc3545 !important;
}

.is-invalid label {
  color: #dc3545 !important;
}

.enableSwitch {
  float: right;
}

.manage-tag {
  font-size: 18px;
  font-weight: 400;
  text-decoration: underline;
  color: #447df7 !important;
  margin-left: 10px;
  cursor: pointer;
}

.meta-button {
  float: right;
  margin-top: 25px;
}
.upload-spinner {
  position: relative;
  top: 30%;
}

.uploadingOverlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999;
}
.childSideBar {
  margin-left: 28px !important;
}
</style>
