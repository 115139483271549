<template>
  <div>
    <div>
      <span class="titleCard mb-2">
        Dashboard
      </span>
    </div>
    <div class="row mt-3">
      <div class="col-xl-3 col-md-6">
        <stats-card :title="totalUser.value" :subTitle="totalUser.title">
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ totalUser.hike }} %</span>

            <div v-if="totalUser.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="totalUser.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card
          :title="totalActiveSubscriber.value"
          :subTitle="totalActiveSubscriber.title"
        >
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ totalActiveSubscriber.hike }} %</span>

            <div v-if="totalActiveSubscriber.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="totalActiveSubscriber.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card
          :title="todayPageView.value"
          :subTitle="todayPageView.title"
        >
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ todayPageView.hike }} %</span>

            <div v-if="todayPageView.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="todayPageView.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card
          :title="totalCatalogues.value"
          :subTitle="totalCatalogues.title"
        >
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ totalCatalogues.hike }} %</span>

            <div v-if="totalCatalogues.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="totalCatalogues.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card :title="totalVideo.value" :subTitle="totalVideo.title">
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ totalVideo.hike }} %</span>

            <div v-if="totalVideo.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="totalVideo.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card :title="newUsers.value" :subTitle="newUsers.title">
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ newUsers.hike }} %</span>

            <div v-if="newUsers.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="newUsers.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <stats-card
          :title="totalWatchedMinutes.value"
          :subTitle="totalWatchedMinutes.title"
        >
          <div slot="header" class="percentContent pb-4">
            <span class="percentText">{{ totalWatchedMinutes.hike }} %</span>

            <div v-if="totalWatchedMinutes.hike > 0">
              <img src="static/img/up.jpg" alt="" class="downHikeImg" />
            </div>
            <div v-if="totalWatchedMinutes.hike < 0">
              <img src="static/img/down.jpeg" alt="" class="downHikeImg" />
            </div>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6" id="totalsubscriber-pie-chart">
        <chart-card
          :chart-data="getPieChartData"
          chart-type="Pie"
          :chartOptions="pieChart.option"
        >
          <template slot="header">
            <h4 class="card-title">Subscribers by Payment Gateway</h4>
            <h5 style="margin-top:20px;color:#FB404B">
              No Data Available!
            </h5>
          </template>
          <template slot="footer">
            <div class="legend">
              <div v-for="(pieItem, index) in pieLegendData" :key="index">
                <i class="fa fa-circle" :class="getLegendClass(index)"></i>
                {{ pieItem }}
              </div>
            </div>
            <hr />
          </template>
        </chart-card>
      </div>
      <div class="col-md-6">
        <card class="boxShadow">
          <div class="d-flex justify-content-between col-12">
            <span class="">
              Visits by various devices
            </span>
            <el-select
              class="select-default monthlyData"
              placeholder="Monthly Data"
            >
              <el-option class="select-default">Monthly Data</el-option>
            </el-select>
          </div>
          <div class="col-12 mt-2">
            <el-table :data="deviceTableData" style="width: 100%">
              <el-table-column prop="devices" label="Devices" width="120">
              </el-table-column>
              <el-table-column prop="visits" label="Visits" width="120">
              </el-table-column>
              <el-table-column
                prop="averageTime"
                label="Average Time"
                width="150"
              >
              </el-table-column>
            </el-table>
          </div>
        </card>
      </div>
      <div class="col-md-8" v-if="noMixPanelToken && !isLineReady">
        <h4 style="color:#FB404B !important">
          Please configure Mixpanel to see this data!
        </h4>
      </div>

      <div class="col-md-4" id="weeklypageviewbydevice" v-if="isLineReady">
        <chart-card
          :chart-data="lineChart.data"
          :chart-options="lineChart.options"
          :responsive-options="lineChart.responsiveOptions"
        >
          <template slot="header">
            <h4 class="card-title">Weekly page views by device</h4>
            <p class="card-category">24 Hours performance</p>
          </template>
          <template slot="footer">
            <span v-for="(lineItem, index) in lineLegendData" :key="index"
              ><i class="fa fa-circle" :class="getLegendClass(index)"></i>
              {{ lineItem }}</span
            >
            <hr />
            <div class="stats">
              <i class="fa fa-history"></i> Updated 3 minutes ago
            </div>
          </template>
        </chart-card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12" id="viewsbyvideochart" v-if="isBarReady">
        <chart-card
          :chart-data="barChart.data"
          :chart-options="barChart.options"
          :chart-responsive-options="barChart.responsiveOptions"
          chart-type="Bar"
        >
          <template slot="header">
            <h4 class="card-title">Views by videos</h4>
          </template>
          <template slot="footer">
            <hr />
          </template>
        </chart-card>
      </div>
    </div>

    <div>
      <card class="boxShadow">
        <div class="row">
          <div class="col-8">
            <div class="mb-3">
              Global Users by Top Locations who have singed up
            </div>
            <l-map style="height: 450px" :zoom="zoom" :center="center">
              <l-tile-layer :url="url"></l-tile-layer>
              <l-marker
                :lat-lng="degree.latLong"
                v-for="(degree, index) in markerLatLng"
                :key="index"
              >
                <l-icon
                  :icon-size="dynamicSize"
                  icon-url="static/img/record.svg"
                  :className="degree.color"
                >
                </l-icon>
                <l-tooltip>{{ degree.name }}</l-tooltip>
              </l-marker>
            </l-map>
          </div>
          <div class="col-4 mt-5">
            <div
              v-for="(country, index) in markerLatLng"
              :key="index"
              class="d-flex justify-content-star"
            >
              <div
                class="bullet"
                :style="{ backgroundColor: country.color }"
              ></div>
              <div class="pl-2 countryText">
                <span>{{ country.name }}</span> :
                <span class="pl-">{{ country.count }}</span>
              </div>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox
} from "src/components/index";
import { Loading, Table, TableColumn, Select, Option } from "element-ui";
import Dropdown from "../../../components/Dropdown.vue";

import { LMap, LTileLayer, LMarker, LIcon, LTooltip } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import countryjs from "country-js";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    Dropdown,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LTooltip,
    Card
  },
  computed: {
    getPieChartData: function() {
      return this.pieChart.data;
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    }
  },
  data() {
    return {
      totalUsers: "0",
      totalActiveSubscribers: "0",
      totalWatchMins: "0",
      todayPageView: "0",
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      pieLegendData: [],
      lineLegendData: [],
      isPieReady: false,
      isBarReady: false,
      isLineReady: false,
      noMixPanelToken: false,
      pieChart: {
        data: {
          labels: [],
          series: []
        },
        option: {
          donut: true,
          donutWidth: 30
        }
      },
      lineChart: {
        data: {
          labels: [],
          series: []
        },
        options: {
          low: 0,
          high: 8,
          showArea: false,
          height: "245px",
          axisX: {
            showGrid: false
          },
          lineSmooth: true,
          showLine: true,
          showPoint: true,
          fullWidth: true,
          chartPadding: {
            right: 50
          }
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      barChart: {
        data: {
          labels: [],
          series: []
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: false
          },
          height: "245px"
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc(value) {
                  return value[0];
                }
              }
            }
          ]
        ]
      },
      tableData: {
        data: [
          {
            title:
              'Sign contract for "What are conference organizers afraid of?"',
            checked: false
          },
          {
            title:
              "Lines From Great Russian Literature? Or E-mails From My Boss?",
            checked: true
          },
          {
            title:
              "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
            checked: true
          },
          {
            title: "Create 4 Invisible User Experiences you Never Knew About",
            checked: false
          },
          { title: 'Read "Following makes Medium better"', checked: false },
          { title: "Unfollow 5 enemies from twitter", checked: false }
        ]
      },
      deviceTableData: [
        {
          devices: "vizio",
          visits: "3486",
          averageTime: "00:57:67"
        },
        {
          devices: "iOS",
          visits: "2451",
          averageTime: "01:03:67"
        },
        {
          devices: "AndroidTv",
          visits: "6214",
          averageTime: "01:06:52"
        },
        {
          devices: "Web App",
          visits: "3642",
          averageTime: "02:57:67"
        }
      ],
      totalUser: {
        title: "Total Users",
        value: "7500",
        hike: "64"
      },
      totalActiveSubscriber: {
        title: "Total Active Subscribers",
        value: "100",
        hike: "-18"
      },
      todayPageView: {
        title: "PageView",
        value: "8000",
        hike: "54"
      },
      totalCatalogues: {
        title: "Number of Users",
        value: "50",
        hike: "-20"
      },
      totalVideo: {
        title: "Total Video",
        value: "5500",
        hike: "50"
      },
      newUsers: {
        title: "New users singnup's",
        value: "7500",
        hike: "44"
      },
      totalWatchedMinutes: {
        title: "Total Watched Minutes",
        value: "250",
        hike: "-10"
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 3,
      center: [55.3781, 3.436],
      icon: L.icon({
        iconUrl: "/images/baseball-marker.png",
        iconSize: [22, 27]
      }),
      markerLatLng: [
        {
          name: "India",
          count: "1946",
          latLong: [20.5937, 78.9629],
          color: "red"
        },
        {
          name: "USA",
          count: "2946",
          latLong: [39.91, -77.02],
          color: "blue"
        },
        {
          name: "UK",
          count: "1946",
          latLong: [55.3781, 3.436],
          color: "green"
        },
        {
          name: "Australia",
          count: "986",
          latLong: [-35.2809, 149.13],
          color: "purple"
        },
        {
          name: "Germany",
          count: "4652",
          latLong: [52.3, 13.25],
          color: "yellow"
        },
        {
          name: "Canada",
          count: "923",
          latLong: [45.27, -75.42],
          color: "orange"
        },
        {
          name: "China",
          count: "923",
          latLong: [39.55, 116.2],
          color: "indigo"
        },
        {
          name: "Africa",
          count: "2923",
          latLong: [30.5595, 22.9375],
          color: "red"
        }
      ]
    };
  },

  created() {
    let loadingInstance = Loading.service({ fullscreen: true });
    this.$store
      .dispatch("getMixPanelToken")
      .then(() => {
        this.getTotalWatchedMinutes();
        this.getWeeklyPageByDevice();
        this.getViewsByVideo();
        this.getTodayPageView();
        this.getTotalUsers();
        this.getActiveSubscribers();
        this.getSubscriberByPaymentGateWay();
        loadingInstance.close();
      })
      .catch(() => {
        this.noMixPanelToken = true;
        loadingInstance.close();
      });
    // this.getTotalWatchedMinutes();
    // this.getTodayPageView();
    // this.getTotalUsers();
    // this.getActiveSubscribers();
    // this.getSubscriberByPaymentGateWay();
  },
  methods: {
    getMinutes: function(mins) {
      return Math.round(Number(mins) / 60);
    },
    getTodayPageView: function() {
      this.$store
        .dispatch("getTodayPageView")
        .then(res => {
          this.todayPageView = String(res.legend_size);
        })
        .catch(() => {});
    },
    getTotalUsers: function() {
      var payload = {};
      this.$store
        .dispatch("getTotalUserCount", payload)
        .then(res => {
          this.totalUsers = String(res.count);
        })
        .catch(() => {});
    },
    getActiveSubscribers: function() {
      this.$store
        .dispatch("getActiveSubscribers")
        .then(res => {
          this.totalActiveSubscribers = String(res.count);
        })
        .catch(() => {});
    },
    getTotalWatchedMinutes: function() {
      this.$store
        .dispatch("getTotalWatchedMinutes")
        .then(res => {
          if (res[0]) this.totalWatchMins = String(res[0]);
        })
        .catch(() => {});
    },
    getSubscriberByPaymentGateWay: function() {
      this.$store
        .dispatch("getSubscriberByPaymentGateWay")
        .then(res => {
          var self = this;
          this.pieLegendData = [];
          var totalSum = 0;
          Object.keys(res).forEach(function(key, index) {
            var legendItem = "";
            if (key == "null") {
              legendItem = "other";
            } else {
              legendItem = key;
            }
            self.pieChart.data.series.push(res[key]);
            totalSum = totalSum + res[key];
            self.pieLegendData.push(legendItem);
          });
          for (var i = 0; i < this.pieChart.data.series.length; i++) {
            var item = this.pieChart.data.series[i];
            var value = (this.pieChart.data.series[i] / totalSum) * 100;
            var percentage = Math.round(value * 10) / 10 + "%";
            this.pieChart.data.labels.push(percentage);
          }
          this.isPieReady = true;
        })
        .catch(() => {
          this.isPieReady = true;
        });
    },
    getWeeklyPageByDevice: function() {
      this.$store
        .dispatch("getWeeklyPageByDevice")
        .then(res => {
          var showValues = [];
          this.lineLegendData = [];
          for (var i = 0; i < res.length; i++) {
            var date = res[i].key[0];
            this.lineChart.data.labels.push(date);
            if (res[i].key[1]) {
              if (!this.lineLegendData.includes(res[i].key[1])) {
                this.lineLegendData.push(res[i].key[1]);
              }
            }
            showValues.push(res[i].value);
          }
          this.lineChart.data.series.push(showValues);
          this.isLineReady = true;
        })
        .catch(() => {
          this.isLineReady = true;
        });
    },
    getViewsByVideo: function() {
      this.$store
        .dispatch("getViewsByVideo")
        .then(res => {
          if (res[0]) {
            if (res[0].length) {
              var valueItem = [];
              for (var i = 0; i < res[0].length; i++) {
                var item = res[0][i];
                this.barChart.data.labels.push(item.key[0]);

                valueItem.push(this.getMinutes(item.value));
              }
              this.barChart.data.series.push(valueItem);
            }
          }
          this.isBarReady = true;
        })
        .catch(() => {
          this.isBarReady = true;
        });
    },
    getLegendClass(index) {
      var array = [
        "text-info",
        "text-danger",
        "text-warning",
        "text-muted",
        "text-fifth",
        "text-sixth"
      ];
      if (array[index]) return array[index];
      return "";
    }
  }
};
</script>
<style lang="scss">
#viewsbyvideochart,
#weeklypageviewbydevice {
  .ct-horizontal {
    word-break: break-word !important;
    height: 90px !important;
    font-size: 10px !important;
  }
}

#totalsubscriber-pie-chart {
  .ct-series-d .ct-slice-pie {
    fill: grey !important;
  }
}

.text-fifth {
  color: #87cb16 !important;
}

.text-sixth {
  color: #1f77d0 !important;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 15px;
}
.percentText {
  font-size: 12px;
  color: red;
}
.monthlyData {
  border-radius: 8px;
  width: 100px;
}
.downHikeImg {
  width: 48px;
  height: 48px;
}
.dashboardMap {
  margin-top: 20px;
  width: 100%;
  height: 350px;
}
.leaflet-control-attribution a {
  display: none;
}
.percentContent {
  position: relative;
  right: 20px;
}
.bullet {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.countryText {
  position: relative;
  bottom: 7px;
}
.boxShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>
