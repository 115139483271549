<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteMetaData"
      ref="deleteMetaDataConfirmation"
      @delete="$event()"
    />
    <div class="col-sm-12 ml-2">
      <div class="row" style="width:650px;">
        <div class="col-sm-4 ">
          <fg-input label="Key" v-model="currentMetaData.key"></fg-input>
        </div>
        <div class="col-sm-4">
          <fg-input label="Value" v-model="currentMetaData.value"></fg-input>
        </div>
        <div class="col-sm-4">
          <el-button
            type="primary"
            class="addMetaData"
            icon="el-icon-plus"
            @click="addMetaData()"
            >Add MetaData
          </el-button>
        </div>
      </div>
    </div>
    <div class="col-sm-12 p-3">
      <el-table :data="metaData" border :row-class-name="isBannerImg">
        <el-table-column :max-width="100" label="Key">
          <template slot-scope="{ row }">
            {{ row.key }}
          </template>
        </el-table-column>
        <el-table-column :max-width="100" label="Value">
          <template slot-scope="{ row }">
            {{ row.value }}
          </template>
        </el-table-column>
        <el-table-column :max-width="50" label="Actions">
          <template slot-scope="props" >
            <a
              v-tooltip.top-center="'Edit'"
              class="btn-warning btn-simple btn-link"
              @click="editMetaData(props.$index, props.row)"
              ><i class="fa fa-edit"></i
            ></a>
            <a
              v-tooltip.top-center="'Delete'"
              class="btn-danger btn-simple btn-link"
              @click="handleDelete(props.$index, props.row)"
              ><i class="fa fa-trash"></i
            ></a>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <b-modal
      id="metadatas"
      ref="modal"
      title="Edit Meta Data"
      @ok="checkMetadataValue"
    >
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <fg-input label="Key" v-model="editMetaDataValue.key"></fg-input>
          </div>
          <div class="col-sm-4">
            <fg-input
              label="Value"
              v-model="editMetaDataValue.value"
            ></fg-input>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import appConfigs from "../data/AppConfig";
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import Treeselect from "@riophae/vue-treeselect";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Vue from "vue";

//const apps = appConfigs.config;

export default {
  props: ["metaData"],
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    appConfigs,
    bModal,
    bModalDirective,
    Treeselect,
    DeleteConfirmation
  },
  data() {
    return {
      currentMetaData: {
        key: "",
        value: ""
      },
      editMetaDataValue: {
        key: "",
        value: ""
      },
      editMetaDataIndex: "",
      deleteMetaDataIndex: "",
      isBanner:true,
      bannerDisplay:null
      
    };
  },
  
  methods: {
    addMetaData: function() {
      if (this.currentMetaData.key == "" || this.currentMetaData.value == "") {
        this.$notify({
          title: "Error",
          message: "MetaData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      this.metaData.push(JSON.parse(JSON.stringify(this.currentMetaData)));
      this.currentMetaData = {
        key: "",
        value: ""
      };
    },
    editMetaData: function(index, row) {
      this.$refs.modal.show("#metadatas");
      this.editMetaDataValue = JSON.parse(JSON.stringify(row));
      // this.editMetaDataValue = row;
      this.editMetaDataIndex = index;
      //this.metaData[this.editMetaDataIndex] = this.editMetaDataValue;
    },
    checkMetadataValue: function(index, row) {
      if (
        this.editMetaDataValue.key == "" ||
        this.editMetaDataValue.value == ""
      ) {
        this.$notify({
          title: "Error",
          message: "MetaData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      Vue.set(this.metaData, this.editMetaDataIndex, this.editMetaDataValue);
      //this.metaData[this.editMetaDataIndex] = this.editMetaDataValue;
    },
    handleDelete: function(index, props) {
      this.deleteMetaDataIndex = index;
      this.$refs["deleteMetaDataConfirmation"].$refs[
        "deleteConfirmation"
      ].show();
    },
    deleteMetaData: function() {
      this.metaData.splice(this.deleteMetaDataIndex, 1);
    },
    

    isBannerImg({row, rowIndex}) {
        if(row.key==="bannerImage" || row.key==="mobileBannerImage") {
          return 'bannerImg-row';
        } else {
          return 'metadata-row';
        }
      }
  }
};
</script>

<style scoped>
.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
  width: 200px;
}
.addMetaData {
  margin-top: 27px;
}
 

</style>
