<template>
  <div class="row">
    <b-modal id="metadatas" ref="dataModal">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-12">
            <fg-input class="col-sm-12 col-12" label="Data JSON">
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                rows="5"
                v-model="textareaData"
              ></textarea>
            </fg-input>

            <el-button
              class="copyBtn"
              icon="el-icon-document-copy"
              v-clipboard="textareaData"
              @success="onCopytextareaData"
              @error="onErrortextareaData"
              type="success"
              plain
              circle
            ></el-button>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="default"
            size="sm"
            class="float-right"
            @click="hideDataModal"
          >
            Cancel
          </b-button>
        </div>
      </template>
    </b-modal>
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Error Report
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-12">
                <download-excel
                  class="btn btn-default float-right"
                  :data="this.data"
                  :fields="json_fields"
                  type="csv"
                  name="filename.xls"
                >
                  Download CSV
                </download-excel>
              </div>
              <!--                             <div class="col-md-4">
                                <label>From</label>
                                <el-date-picker v-model="startDate" type="date">
                                </el-date-picker>
                            </div>
                            <div class="col-md-4">
                                <label>To</label>
                                <el-date-picker v-model="endDate" type="date">
                                </el-date-picker>
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-primary btn-fill" :disabled="!startDate || !endDate" style="margin-top:2em;" @click="getVideoReport">Get Report</button>
                            </div> -->
            </div>
          </div>

          <div class="col-sm-12 p-3">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="180" label="Created At">
                <template slot-scope="props">
                  {{ getCreatedAt(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Result">
                <template slot-scope="props">
                  {{ getResult(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="View Data">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'View Data'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-file-text-o"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Button
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { Loading } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import moment from "moment";
import Fuse from "fuse.js";
import downloadexcel from "vue-json-excel";

export default {
  name: "ErrorLogsReports",
  components: {
    bModal,
    bModalDirective,
    downloadexcel,
    LPagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  computed: {},
  data() {
    return {
      json_fields: {
        ip: "ip",
        module: "module",
        customMessage: "customMessage",
        errorMessage: "errorMessage",
        createdAt: "createdAt"
      },

      startDate: null,
      endDate: null,
      data: [],
      noMixPanelToken: false,
      count: 0,
      currentData: "",
      textareaData: "",
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",

      tableColumns: [
        {
          prop: "ip",
          label: "IP",
          minWidth: 150
        },
        {
          prop: "module",
          label: "Module",
          minWidth: 180
        },
        {
          prop: "customMessage",
          label: "Custom Message",
          minWidth: 180
        },
        {
          prop: "errorMessage",
          label: "Error Message",
          minWidth: 180
        }
      ]
    };
  },
  computed: {
    tableData() {
      return this.data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["ip", "module", "customMessage", "errorMessage", "createdAt"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {
    let loadingInstance = Loading.service({ fullscreen: true });
    // this.$store.dispatch("getMixPanelToken").then(()=>{
    // loadingInstance.close();
    // }).catch(()=>{
    //   this.noMixPanelToken = true;
    //   loadingInstance.close();
    // });
    this.getVideoReport();
  },
  methods: {
    handleEdit: function(index, row) {
      this.currentData = JSON.parse(JSON.stringify(row));
      this.textareaData = JSON.stringify(this.currentData.data);
      this.$refs.dataModal.show();
    },
    getStringResolve: function(bol) {
      if (bol) {
        return "Resolved";
      } else {
        return "Unresolved";
      }
    },
    getCreatedAt: function(row) {
      if (row.createdAt) {
        return moment(row.createdAt, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    getResult: function(row) {
      if (row.resolved) {
        return row.resolved;
      } else {
        return "NA";
      }
    },
    onCopytextareaData: function(e) {
      this.$notify({
        title: "Message",
        message: "Text copied",
        type: "success"
      });
    },
    onErrortextareaData: function(e) {
      this.$notify({
        title: "Error",
        message: "Failed to copy texts",
        type: "warning"
      });
    },
    hideDataModal: function(e) {
      this.$refs["dataModal"].hide();
    },
    getVideoReport: function() {
      var form = {};
      var self = this;
      // form.fromDate = moment(this.startDate).format("YYYY-MM-DD");
      // form.toDate = moment(this.endDate).format("YYYY-MM-DD");
      // if(moment(this.startDate).isBefore(moment(this.endDate))){
      //     form.fromDate = moment(this.startDate).format("YYYY-MM-DD");
      //     form.toDate = moment(this.endDate).format("YYYY-MM-DD");
      // }else{
      //   this.$notify({
      //      title: 'Error',
      //      message: 'select appropriate dates!',
      //      type: 'warning'
      //   });
      //   return false;
      // }
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("getErrorLogConfigList")
        .then(res => {
          loadingInstance.close();
          if (res) {
            if (res.length) {
              self.data = res;
              // var valueItem = [];
              for (var i = 0; i < res.length; i++) {
                self.data[i].resolved = self.getStringResolve(res[i].resolved);
                // self.data.push(item);
              }
            }
          }
        })
        .catch(() => {
          loadingInstance.close();
        });
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 15px;
}
.copyBtn {
  position: relative;
  display: flex;
  float: right;
  bottom: 48px;
  right: 24px;
  padding: 5px;
}
</style>
