<template>
  <div>
    <div slot="header">
      <h4 class="titleCard mb-2">{{ pageTitle }}</h4>
    </div>

    <card>
      <div class="row">
        <div class="col-md-4">
          <fg-input
            placeholder="Username"
            label="Username"
            v-model="user.username"
          ></fg-input>
        </div>
        <div class="col-md-4">
          <fg-input
            placeholder="Email"
            label="Email"
            v-model="user.email"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div v-if="createMode == true" class="col-md-4">
          <fg-input
            placeholder="Password"
            type="password"
            label="Password"
            v-model="user.password"
          ></fg-input>
        </div>

        <div class="col-md-4">
          <fg-input label="Admin Role">
            <el-select
              class="select-primary"
              v-model="multipleSelections"
              placeholder="Select Role to provide access"
            >
              <el-option
                v-for="cust in AdminRoleList"
                class="select-info"
                :value="cust.key"
                :label="cust.key"
                :key="cust.key"
              >
              </el-option>
            </el-select>
          </fg-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="float-left">
            <button
              v-if="createMode == true"
              class="btn btn-primary btn-fill"
              @click.prevent="updateProfile"
            >
              Save
            </button>
            <button
              v-else
              class="btn btn-primary btn-fill"
              @click.prevent="updateProfile"
            >
              Update
            </button>
            <button
              class="btn btn-default btn-simple"
              @click.prevent="handleCancle"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import { Select, Option } from "element-ui";

export default {
  components: {
    FgInput,
    [Select.name]: Select,
    [Option.name]: Option
  },

  data() {
    return {
      AdminRoleList: [],
      rolesList: [],
      createMode: false,
      customers: {},
      idParam: this.$route.params.id,
      user: {},
      multipleSelections: ""
    };
  },
  created() {
    self = this;
    this.id = this.$route.params.id;
    this.$store.dispatch("getUserAdminRoleList").then(res => {
      if (res) {
        this.AdminRoleList = res;
      }
      if (!this.id || this.id == -1) {
        this.createMode = true;
        self.user = {
          username: "",
          email: "",
          password: "",
          role: ""
        };
      } else {
        this.createMode = false;
        this.$store
          .dispatch("getUserAdminById", this.id)
          .then(data => {
            self.user = {
              id: data.id, //for update
              username: data.username,
              email: data.email,
              role: data.role
            };
            this.multipleSelections = data.role;
          })
          .catch(() => {});
      }
    });
  },
  methods: {
    handleCancle(e) {
      this.$router.push({ name: "All Administration" });
      this.createMode = false;
    },
    updateProfile(e) {
      self = this;

      if (this.createMode == true) {
        var shajs = require("sha.js");
        var password = shajs("sha256")
          .update(this.user.password)
          .digest("hex");
        this.user.password = password;
        this.user.role = this.multipleSelections;
        this.$store
          .dispatch("registerUserAdmin", this.user)
          .then(data => {
            this.$notify({
              title: "Success",
              message: "User created Successfully!",
              type: "success"
            });
            self.$router.push({ name: "All Administration" });
          })
          .catch(function(error) {
          });
      } else {
        this.user.role = this.multipleSelections;
        this.$store
          .dispatch("updateUserAdmin", this.user)
          .then(data => {
            this.$notify({
              title: "Success",
              message: "User Updated Successfully!",
              type: "success"
            });
            self.$router.push({ name: "All Administration" });
            this.createMode = false;
          })
          .catch(function(error) {
          });
      }
    }
  },
  computed: {
    isAdd() {
      return this.$route.path.indexOf("-1") > -1;
    },
    pageTitle() {
      return this.isAdd ? ` Add Access Management ` : "Edit Access Management";
    }
  }
};
</script>

<style scoped>
input::placeholder {
  font-size: 12px;
  opacity: 1;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
</style>
