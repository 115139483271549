<template>
  <card class="card-stats">
    <div class="row">
      <div class="col-7" v-if="$slots.content || title">
        <div>
          <slot name="content">
            <p v-if="subTitle" class="card-category">
              {{ subTitle }}
            </p>
            <h4 v-if="title" class="card-title text-primary mt-2">
              {{ title }}
            </h4>
          </slot>
        </div>
      </div>
      <div class="col-5" v-if="$slots.header">
        <div class="text-right">
          <slot name="header"></slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </card>
</template>
<script>
import Card from "./Card.vue";
export default {
  name: "stats-card",
  components: {
    Card
  },
  props: {
    title: String,
    subTitle: String
  }
};
</script>
<style>
.card-stats {
  width: 250px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
</style>
