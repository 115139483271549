<template>
  <div>
    <div slot="header">
      <h4 class="titleCard mb-2">{{ pageTitle }}</h4>
    </div>
    <card>
      <form class="svod-form" @submit.prevent="AddProvider">
        <div class="row">
          <div class="col-md-6">
            <fg-input
              v-validate="'required'"
              name="packageTitle"
              class="col-md-12"
              label="Title"
              placeholder="Title"
              v-model="currentData.name"
            ></fg-input>
            <fg-input
              v-validate="'required'"
              name="packageTitle"
              class="col-md-12"
              label="Slug"
              placeholder="Slug"
              v-model="currentData.slug"
            ></fg-input>
            <fg-input class="col-md-12" label="Description">
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                v-model="currentData.description"
                rows="3"
              ></textarea>
            </fg-input>

            <card title="Manage Meta Data">
              <div class="row">
                <fg-input
                  class="col-sm-5"
                  label="Key"
                  v-model="currentConfigData.key"
                ></fg-input>
                <fg-input
                  class="col-sm-5"
                  label="Value"
                  v-model="currentConfigData.value"
                ></fg-input>
                <div class="col-md-2 addBtn">
                  <button
                    class="btn btn-primary"
                    @click.prevent="addConfigData()"
                  >
                    Add
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <el-table
                    class="table-bigboy"
                    style="width: 100%;"
                    :data="configData"
                    border
                  >
                    <el-table-column min-width="60" label="Key">
                      <template slot-scope="{ row }">
                        {{ row.key }}
                      </template>
                    </el-table-column>
                    <el-table-column min-width="140" label="Value">
                      <template slot-scope="{ row }">
                        {{ row.value }}
                      </template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="props" label="Action">
                        <a
                          v-tooltip.top-center="'Edit'"
                          class="btn-warning btn-simple btn-link"
                          @click="handleEditConfigData(props.$index, props.row)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          v-tooltip.top-center="'Delete'"
                          class="btn-danger btn-simple btn-link"
                          @click="handleDeleteConfigData(props.$index)"
                          ><i class="fa fa-trash"></i
                        ></a>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </card>
            <div class="col-md-6">
              <button class="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </div>
        <DeleteConfirmation
          :currentDeleteMethod="deleteConfigData"
          ref="confirmDeletConfigData"
          @delete="$event()"
        />
      </form>
      <b-modal
        id="configdatas"
        ref="modal"
        title="Edit Config Data"
        @ok="checkConfigDataValue"
        ok-title="Save"
      >
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4">
              <fg-input label="Key" v-model="editConfigData.key"></fg-input>
            </div>
            <div class="col-sm-4">
              <fg-input label="Value" v-model="editConfigData.value"></fg-input>
            </div>
          </div>
        </div>
      </b-modal>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput, Switch as LSwitch } from "src/components";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Slider
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import Vue from "vue";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";

export default {
  components: {
    FgInput,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LPagination,
    [Slider.name]: Slider,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["title", "description", "price", "duration"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    isAdd() {
      return this.$route.path.indexOf("-1") > -1;
    },
    pageTitle() {
      return this.isAdd
        ? ` Add Avod
`
        : "Edit Avod";
    }
  },
  data() {
    return {
      createMode: true,
      valueConsistsOf: "ALL_WITH_INDETERMINATE",
      submitted: false,
      providers: [],
      dialogSubmitted: false,
      sliders: {
        simple: 30
      },
      tableData: [],
      configData: [],
      currentConfigData: {
        key: "",
        value: ""
      },
      editConfigData: {
        key: "",
        value: ""
      },
      editConfigDataIndex: "",
      deleteConfigDataIndex: "",
      currentData: {
        name: "",
        slug: "",
        description: "",
        configObj: {}
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["name", "subid", "id"],
      tableColumns: [
        {
          prop: "title",
          label: "Name",
          minWidth: 150
        },
        {
          prop: "price",
          label: "Price",
          minWidth: 100
        },
        {
          prop: "duration",
          label: "Duration",
          minWidth: 100
        },
        {
          prop: "slug",
          label: "Slug Name",
          minWidth: 150
        }
      ]
    };
  },

  created() {
    if (!this.$route.params.id || this.$route.params.id == -1) {
      this.createMode = true;
    } else {
      this.createMode = false;
      this.$store
        .dispatch("getAvodProviderById", this.$route.params.id)
        .then(res => {
          this.currentData = res;
          this.currentData.name = res.name;
          this.currentData.slug = res.slug;
          this.currentData.description = res.description;
          var configObj = res.configObj;
          for (var key in configObj) {
            var obj = {};
            obj.key = key;
            obj.value = configObj[key];
            this.configData.push(obj);
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    AddProvider: function() {
      self = this;
      for (var i = 0; i < this.configData.length; i++) {
        this.currentData.configObj[this.configData[i].key] = this.configData[
          i
        ].value;
      }
      if (this.createMode) {
        this.$store
          .dispatch("createAvodProvider", this.currentData)
          .then(res => {
            self.$router.push({ name: "AVOD" });
            this.$notify({
              title: "Success",
              message: "Created Successfully",
              type: "success"
            });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Created Provider Failed!",
              type: "warning"
            });
            return;
          });
      } else {
        var req = {};
        req = this.currentData;
        req.id = this.$route.params.id;
        this.$store
          .dispatch("updateAvodProvider", req)
          .then(res => {
            self.$router.push({ name: "AVOD" });
            this.$notify({
              title: "Done",
              message: "Updated Successfully",
              type: "info"
            });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Update Provider Failed!",
              type: "warning"
            });
            return;
          });
      }
    },

    addConfigData() {
      if (
        this.currentConfigData.key == "" ||
        this.currentConfigData.value == ""
      ) {
        this.$notify({
          title: "Error",
          message: "ConfigData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      this.configData.push(JSON.parse(JSON.stringify(this.currentConfigData)));
      this.currentConfigData = {
        key: "",
        value: ""
      };
    },
    handleDeleteConfigData(index) {
      this.deleteConfigDataIndex = index;
      this.$refs["confirmDeletConfigData"].$refs["deleteConfirmation"].show();
    },
    deleteConfigData() {
      this.configData.splice(this.deleteConfigDataIndex, 1);
    },
    handleEditConfigData: function(index, row) {
      this.$refs.modal.show("#configdatas");
      this.editConfigData = JSON.parse(JSON.stringify(row));

      this.editConfigDataIndex = index;
    },
    checkConfigDataValue: function(index, row) {
      if (this.editConfigData.key == "" || this.editConfigData.value == "") {
        this.$notify({
          title: "Error",
          message: "ConfigData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      Vue.set(this.configData, this.editConfigDataIndex, this.editConfigData);
    }
  }
};
</script>

<style scoped>
.subcontainer {
  padding-top: 25px;
}

.subtitlecontainer {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.extralarge {
  width: 100%;
}

.card form.svod-form [class*="col-"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.width_class {
  width: 100%;
  margin-left: 0px;
}
.addBtn {
  margin-top: 28px;
}
</style>
