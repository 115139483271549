<template>
    <div>
        <aside id="theme-setting">
            <div class="theme-setting-header">
                <label> Edit Theme </label>
                <button class="btn" @click="exitEditTheme">Exit</button>
                <button class="btn btn-primary" @click="publishChange">Publish</button>
            </div>
            <div class="theme-pre-header">
                <button class="btn" @click="previewTheme"><i class="fa fa-edit"></i>Preview</button>
                <button class="btn" @click="revertTheme"><i class="fa fa-repeat"></i>Revert</button>
            </div>
            <el-collapse v-model="activeNames" @change="handleChange" class="config-collapse" accordion>
                <el-collapse-item v-for="sData in settingData" :title="sData.label" :name="sData.value">
                    <div v-for="configData in sData.configs">
                        <label class="theme-config-name">{{configData.name}}</label>
                        <div v-if="configData.type=='color'" style="display:flex;">
                            <el-color-picker v-model="configData.value" style="float:left;" @change="savePreviewConfig('color')"></el-color-picker>
                            <b-form-input class="color-value" v-model="configData.value"></b-form-input>
                        </div>
                        <div v-if="configData.type=='text'">
                            <textarea class="form-control" rows="3" v-model="configData.value" @focus="elementFocused($event,configData)" @blur="elementFocusRemoved($event,configData)"></textarea>
                        </div>

                        <div v-if="configData.type=='bool'">
                           <l-switch v-model="configData.value" on-text="ON" off-text="OFF"></l-switch>
                        </div>
                        
                        <div v-if="configData.type=='image'">
                            <b-form-input v-model="configData.value"></b-form-input>
                            <div @click="openImageDialog($event,configData)" style="position:relative;">
                                <img :src="getImageUrl(configData.value)" class="config-img"></img>
                                <i class="el-icon-upload upload-config-image"></i>
                            </div>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </aside>
        <div id="theme-content">
            <iframe id="pretheme" ref="theme" :src="themeUrl"></iframe>
        </div>
        <input type="file" ref="configImageInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{display:'none'}" @change="uploadPicture"
        />
    </div>
</template>
<script>
 import * as properties from '../../../properties'
 import { Collapse, CollapseItem ,ColorPicker,Loading } from 'element-ui'
 import {FormGroupInput as FgInput,Switch as LSwitch} from 'src/components'

  export default {
    components: {
        LSwitch,
        [Collapse.name]: Collapse,
      	[CollapseItem.name]: CollapseItem,
        [ColorPicker.name]: ColorPicker
    },
    data () {
      return{
          id: null,
          settingData: [],
          activeNames: "",
          activeContent: {},
          themeUrl: properties.previewSite,
          data: {},
          platforms: [],
          currentConfigData: null
      }
    },
    methods: {
        handleChange(name) {
            this.activeContent = this.settingData.find(data => data.value == name);
            if(this.activeContent) {
                this.themeUrl = this.activeContent.url;
            }
        },
        setConfigs() {
            for(var i=0;i<this.settingData.length;i++) {
                var configs = this.platforms.filter(config=> config.type && config.section==this.settingData[i].value);
                this.settingData[i].configs = configs;
            }
        },
        openImageDialog(evt,configData) {
            evt.stopPropagation();
			evt.preventDefault();
            this.currentConfigData = configData;
			var file = this.$refs.configImageInput;
			if(file) {
				file.click();
			}
        },
        publishChange() {
            var customer = JSON.parse(JSON.stringify(this.data));
            if(customer && customer.platforms && customer.platforms[0]) {
                customer.platforms[0].configs = this.getChangedPlatForms();
            }
            var loadingInstance = Loading.service({ fullscreen: true });	
            this.$store.dispatch("updateCustomer",customer).then(data => {	
                loadingInstance.close();			
				this.$router.push({name:'Customer'});
            }).catch(() => {
                loadingInstance.close();
            });
        },
        savePreviewConfig(type) {
            var payload = {};
            payload.name = this.data.name;
            payload.configs = this.getChangedPlatForms();
            this.$store.dispatch("savePreviewConfig",payload).then((res)=> {
                var self = this;
                this.themeUrl = '';
                setTimeout(function(){
                    self.themeUrl = self.activeContent.url;
                },100);
            }).catch((err)=> {

            });
        },
        getChangedPlatForms() {
            var platforms = JSON.parse(JSON.stringify(this.platforms));
            for(var i=0;i<this.settingData.length;i++) {
                var configs = this.settingData[i].configs;
                for(var j=0;j<configs.length;j++) {
                    var cData = platforms.find(plat=> plat.type==configs[j].type && plat.section==configs[j].section && plat.param==configs[j].param);
                    cData.value = configs[j].value;
                }
            }
            return platforms;
        },
        uploadPicture: function(evt) {
            var self = this;
			var uuid = this.$uuid.v1();
			var file = evt.target.files[0];
			var filename = file.name;
			var data = {};
			data.extension = filename.split('.').pop();
			data.fileKey = this.$store.state.customerId + '/' + uuid + "." + data.extension;
			data.contentType = file.type;
            var loadingInstance = Loading.service({ fullscreen: true });	
			this.$store.dispatch("preSignedRequest",data).then(response => {
				var signedUrl = response.signedRequest;
				var url = response.url;
				var info = {};
				info.url = signedUrl;
				info.data = file;
				this.$store.dispatch("uploadImageToS3",info).then(res => {
					self.currentConfigData.value = url;
                    self.savePreviewConfig('image');
                    loadingInstance.close();
				}).catch(()=>{
					loadingInstance.close();
					this.$notify({
						title: 'Error',
						message: 'Uploading Image Failed!',
						type: 'warning'
                    });
				});
			}).catch(()=>{
				loadingInstance.close();
				this.$notify({
					title: 'Error',
					message: 'Uploading Image Failed!',
					type: 'warning'
				});
			});
        },
        checkURL(url) {
            return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
        },
        exitEditTheme() {
            this.$router.push('/super/customers');
        },
        previewTheme() {
            var currentSection = this.settingData.find(element=> element.value == this.activeNames);
            if(currentSection) {
                window.open(currentSection.url);
            }
        },
        revertTheme() {
            this.reloadData();
        },
        getImageUrl(url) {
            if(this.checkURL(url)) {
                return url;
            }
            return "https://ak0.picdn.net/shutterstock/videos/10376450/thumb/1.jpg";
        },
        elementFocused(e,configData) {
            this.focusedId = configData._id;
            this.focusedText = configData.value;
        },
        elementFocusRemoved(e,configData) {
            if(this.focusedId == configData._id) {
                if(this.focusedText!=e.target.value) {
                    this.savePreviewConfig('text');
                }
            }
        },
        reloadData() {
            var loadingInstance = Loading.service({ fullscreen: true });	
            this.$store.dispatch("getCustomerByID",this.id).then(data => {
                this.data = {
                    name : data.name,
                    platforms :data.platforms,
                    uid : data.uid,
                    status: false,
                    id:data.id,
                };
                this.platforms = this.data.platforms[0].configs;
                this.setConfigs();
                loadingInstance.close();
            }).catch(() => {
                loadingInstance.close();
            });
        }
    },
    created() {
        this.settingData = properties.themeOptions;
        this.id = this.$route.params.id;
        if(this.$route.params.data) {
            this.data = this.$route.params.data;
            this.platforms = this.data.platforms[0].configs;
            this.setConfigs();
        }else {
            this.reloadData();
        }
    }
  }

</script>
<style lang="scss">
    #theme-setting {
        background: #fff;
        width: 20rem;
        border-right: 1px solid #d1d9dc;
        position: fixed;
        left: 0;
        top: 0;
        height: 100%;
        z-index: 10;
        overflow-y: auto;
        div.el-collapse-item__header {
            padding-left: 15px!important;
            color: #447DF7;
        }
        .theme-setting-header {
            background: #b3bfc8;
            padding: 15px;
            label {
                margin-top: 10px;
                line-height: 20px;
                font-weight: bold;
                color: #447DF7;
            }
            button {
                float: right;
                margin-right: 10px;
            }
        }
        .theme-pre-header {
            padding: 15px;
            background: #8699a6;
            button {
                margin-left: 20px;
                background: transparent;
                border: none;
            }
        }
        .theme-config-name {
            font-size: 16px;
        }
        .el-collapse-item__content {
            padding: 15px;
        }
        .config-collapse {
        }
    }
    
    #theme-content {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        padding-left: 20rem;
        background: gray;
        z-index: 1;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    
    .color-value {
        float: left;
        line-height: 40px;
        font-size: 14px;
        margin-left: 12px;
        width: 90px;
    }
    
    .el-color-dropdown__link-btn {
        border: none!important;
        background: none!important;
    }
    
    .el-color-dropdown__value {
        line-height: 50px;
    }
    
    .config-img {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        background: grey;
    }
    
    .upload-config-image {
        cursor: pointer;
        font-size: 30px;
        color: grey;
        position: absolute;
        top: calc(60% - 10px);
        left: calc(50% - 10px);
    }
</style>