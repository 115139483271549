<template>
  <div>
    <div class="d-flex justify-content-between p-3">
      <div>
        <span class="titleCard mb-2">
          {{ pageTitle }}
        </span>
      </div>
      <div class="d-flex justify-content-end">
        <div class="col-md-6">
          <div class="enableSwitch">
            <l-switch v-model="video.isActive" on-text="ON" off-text="OFF"></l-switch>
          </div>
        </div>
      </div>
    </div>
    <card>
      <div class="uploadingOverlay" v-if="uploading">
        <l-spinner class="upload-spinner" :main="true" :title="spinnerTip" size="medium"></l-spinner>
      </div>
      <b-modal ref="importDialog" title="Import Result" ok-variant="danger">
        <strong>{{ message }}</strong>
        <div>
          <p>Failed records:</p>
        </div>
        <textarea rows="6" class="form-control border-input" v-model="errorMessage"></textarea>
      </b-modal>
      <!-- <b-modal ref="videoPlayDialog" size="lg" :title="selectedVideoTitle" @hidden="onVideoPlayerClose">
      <video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions" :playsinline="true"></video-player>
    </b-modal> -->
      <b-modal ref="videoPlayDialog" size="lg" :title="selectedVideoTitle" @hidden="onVideoPlayerClose">
        <video ref="videoPlayer" class="video-js vjs-custom-skin"></video>
      </b-modal>

      <b-modal id="newConfigModal" v-model="editPopupShow" ref="modal" @ok="handleNewConfigOk"
        title="Video Hosting Provider" ok-title="Update">
        <div class="row">
          <div class="col-md-12 py-3">
            <span class="videoHost">Video Hosting Provider</span>
            <el-select class="col-sm-12 col-12 mt-1" size="large" placeholder="Video Hosting Provider"
              v-model="videoDialogProviderConfig.provider">
              <el-option class="videoHost" v-for="(videoProvider, index) in selectProviderList"
                :value="videoProvider.value" :label="videoProvider.label" :key="index">
              </el-option>
            </el-select>
          </div>
          <div v-if="stackPath(videoDialogProviderConfig.provider)" class="col-md-12">
            <fg-input class="col-sm-12 col-12 videoidcontainer" label="Title" placeholder="Enter Video Title"
              v-model="videoDialogProviderConfig.title"></fg-input>
            <div style="position:relative">
              <fg-input class="col-sm-12 col-12 videoidcontainer" label="Video URL" placeholder="Enter Video URL"
                v-model="videoDialogProviderConfig.url"></fg-input>
              <img :src="fourKLogo" class="fourKLogo" v-if="show4KLogo" />
            </div>
          </div>
          <div v-if="!stackPath(videoDialogProviderConfig.provider)" class="col-md-12 videoidcontainer">
            <fg-input class="col-sm-12 col-12 videoidcontainer" label="Video ID" placeholder="Enter Video ID"
              v-model="videoDialogProviderConfig.id"></fg-input>
          </div>
          <div v-if="!stackPath(videoDialogProviderConfig.provider)" class="offset-md-10 col-md-3">
            <a @click="serachVProviderConfig()">Search</a>
          </div>
        </div>
      </b-modal>

      <DeleteConfirmation :currentDeleteMethod="confirmDeleteVideo" ref="confirmLanguageDelete" @delete="$event()" />

      <form @submit.prevent="saveVideo" class="video-form" novalidate>
        <div class="row">
          <div class="col-md-6">
            <el-select v-validate="'required'" class="select-primary mx-3" name="videoType" style="width: 200px"
              placeholder="Video Type" v-model="video_type"
              :class="{ 'is-invalid': submitted && errors.has('videoType') }">
              <el-option v-for="(option, index) in videotypes" class="select-danger" :value="option.value"
                :label="option.label" :key="index">
              </el-option>
            </el-select>
          </div>

          <div class="col-md-12 p-0 thumbvideo-div">
            <div class=" ml-2 thumbnailvideo" :style="{ backgroundImage: 'url(' + pThumbnail + ')' }"></div>
            <div class="row mt-2 ml-2" style="width:285px">
              <div class="col-4">
                <el-button type="primary" class="playthumbbtn" @click.prevent="handleWatchVideo()">
                  Play
                </el-button>
              </div>
              <div class="col-4">
                <el-button type="info" class="updatethumbbtn" @click.prevent="updateVideoOrigin()">
                  Update
                </el-button>
              </div>
            </div>
          </div>
          <div class="col-md-4 name-admin-reference">
            <fg-input label="Name(Admin Reference)" placeholder="Name" name="Name" v-model="video.name"></fg-input>
          </div>
          <div class="col-md-12">
            <card title="Video Details">
              <div class="col-md-12 addVideoDetails">
                <el-button type="primary" style="width: 200px" icon="el-icon-plus" @click="addLanguage">Add Video
                  Details
                </el-button>
                <!--<button class="btn btn-default" @click="addPlatform"></button>-->
              </div>

              <div class="col-xl-12 col-lg-12">
                <template slot="header">
                  <h4 class="card-title">Video Details</h4>
                  <!-- <p class="card-category">Production Apps</p> -->
                </template>
                <el-collapse v-model="activeLanguageItems">
                  <el-collapse-item v-for="(item, index) in video.languages" :title="getTitle(item)" :name="index + 1"
                    :key="index">
                    <Language :ref="'vlang' + index" :currentLanguage="item" @deleteVideo="deleteVideoLanguage(index)"
                      :key="getKey(item)" :metaData="metaData" @uploadStatus="setUploadStatus"></Language>
                  </el-collapse-item>
                </el-collapse>
                <div class="col-md-12 mt-2 p-0">
                  <fg-input :value="video.tags.toString()" @change="tagChanged" label="Tags" placeholder="Tags" class="m-0"></fg-input>
                </div>
                <div class="col-md-12 mt-2 p-0 AssociatCatContainer">
                  <span class="titleAssociatedCatalogue">Associated Catalogue</span>
                  <el-select :multiple="true" :filterable="true" class="select-primary extralarge col-12" size="large"
                    v-model="catalogueIds" placeholder="Associated Catalogue">
                    <el-option v-for="(column, index) in getAllCatalogueList" class="select-primary" :value="column.id"
                      :label="column.name" :key="index">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </card>
          </div>

          <div class="col-md-12" v-if="video_type == 'livestream'">
            <card>
              <div></div>
              <el-checkbox v-model="checked" size="medium">EPG Data</el-checkbox>
              <div class="col-xl-12 col-lg-12" v-if="checked">
                <template slot="header">
                  <h4 class="card-title">Import EPG</h4>
                </template>
                <div class="col-sm-12">
                  <el-table stripe style="width: 100%;" :data="queriedDataEPG" border>
                    <el-table-column v-for="(column, index) in tableColumnsEPG" :key="index"
                      :min-width="column.minWidth" :prop="column.prop" :label="column.label">
                    </el-table-column>
                    <el-table-column :min-width="180" label="Upload Status">
                      <template slot-scope="props">
                        {{ getUploadStatus(props.row) }}
                      </template>
                    </el-table-column>
                    <el-table-column label="Action">
                      <template slot-scope="props">
                        <button class="btn btn-default btn-fill btn-import" @click="openImportEPG($event, props)">
                          Import
                        </button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12 monetizationContainer">
            <card title="Monetization">
              <el-tabs class="ml-2">
                <el-tab-pane label="Free Preview">
                  <el-checkbox v-model="settings.free" @change="enableFreePreview" size="medium">
                    <p>Free Preview Data</p>
                  </el-checkbox>
                </el-tab-pane>

                <el-tab-pane label="AVOD" :disabled="settings.free">
                  <!-- <el-checkbox v-model="settings.AVOD" size="medium">
                    <p>AVOD</p>
                  </el-checkbox> -->
                  <AVOD :videoId="id" :setting="settings" ref="avod"></AVOD>
                </el-tab-pane>

                <el-tab-pane label="TVOD" :disabled="settings.free">
                  <TVOD :videoId="id" :setting="settings"></TVOD>
                </el-tab-pane>
                <el-tab-pane label="SVOD" :disabled="settings.free">
                  <Monetization ref="monetization" :setting="settings" :createmode="createMode"></Monetization>
                </el-tab-pane>
              </el-tabs>
            </card>
          </div>
        </div>

        <input type="file" ref="EPGImportInput" accept=".csv" v-bind:style="{ display: 'none' }"
          @change="importEPGData" />
        <div class="row">
          <div class="col-md-12 col-12 manageTagContainer">
            <card title="Manage Meta Data">
              <ManageMetaData :metaData="metaData"></ManageMetaData>
            </card>
          </div>
        </div>
        <div class="col-md-12  save-video">
          <button class="btn btn-primary " type="submit">
            Save
          </button>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import fourKLogo from "../../../assets/images/4k1.png";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
  Button,
  Tabs,
  TabPane,
  Checkbox
} from "element-ui";
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import Language from "../Components/Language.vue";
import TVOD from "../Components/TVOD.vue";
import AVOD from "../Components/AVOD.vue";
import ManageMetaData from "../Components/ManageMetaData";
import Monetization from "../Components/Monetization.vue";
import "video.js/dist/video-js.css";
import videojs from "video.js";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import Vue from "vue";
window.videojs = videojs;

export default {
  //	props: ['id'],

  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    FgInput,
    bModal,
    Language,
    TVOD,
    AVOD,
    ManageMetaData,
    Monetization,
    bModalDirective,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LSpinner,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LCheckbox,
    LPagination,
    DeleteConfirmation
  },

  data() {
    return {
      fourKLogo,
      player: null,
      playerOptions: {
        playbackRates: [0.5, 1, 1.5, 2],
        height: "360",
        sources: [
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: ""
          }
        ],
        poster: "",
        html5: {
          hls: {
            withCredentials: false
          }
        }
      },
      spinnerTip: "Importing EPG Data...",
      message: "",
      errorMessage: "",
      currentUpload: null,
      createMode: true,
      id: null,
      video_type: "",
      catalogueIds: [],
      epgTableData: [
        { name: "Monday", sendlabel: "monday", status: "" },
        { name: "Tuesday", sendlabel: "tuesday", status: "" },
        { name: "Wednesday", sendlabel: "wednesday", status: "" },
        { name: "Thursday", sendlabel: "thursday", status: "" },
        { name: "Friday", sendlabel: "friday", status: "" },
        { name: "Saturday", sendlabel: "saturday", status: "" },
        { name: "Sunday", sendlabel: "sunday", status: "" }
      ],
      activeLanguageItems: [],
      metaData: [],
      submitted: false,
      selectedVideoTitle: "Play Video",
      selectedUrl: "",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      paginationepg: {
        perPage: 8,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      hostingProviders: [
        { value: "none", label: "None" },
        { value: "zype", label: "Zype" },
        { value: "vimeo", label: "Vimeo" }
      ],
      tvod: false,
      free: false,
      uploading: false,
      spinnerTip: "Uploading Images...",
      editPopupShow: false,
      searchQuery: "",
      propsToSearch: ["name", "type"],
      settings: {},
      videoProviderList: [],
      selectProviderList: [],
      pThumbnail: "https://dummyimage.com/300x180/a1a1a1/fff",
      videoDialogProviderConfig: {
        provider: null,
        title: "",
        url: "",
        id: ""
      },
      videoProviderConfig: {
        provider: null,
        title: "",
        url: "",
        id: ""
      },
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200
        },
        {
          prop: "type",
          label: "Type",
          minWidth: 100
        },
        {
          prop: "parents",
          label: "Parent",
          minWidth: 200
        },
        {
          prop: "itemsCount",
          label: "Items",
          minWidth: 120
        }
      ],
      tableColumnsEPG: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200
        }
      ],
      fuseSearch: null,
      videoSearchResult: {},
      videotypes: [
        {
          value: "livestream",
          label: "Live Stream"
        },
        {
          value: "vod",
          label: "Video On-Demand"
        }
      ],
      video: {
        isActive: true,
        name: "",
        slug: "",
        languages: [],
        catalogueIds: [],
        settings: {
          subscription: false,
          subscriptionIds: [],
          TVOD: false,
          AVOD: false,
          avodConfigIds: [],
          free: false
        },
        tags:""
      },
      switches: {
        defaultOn: true
      },
      checked: false
    };
  },
  created() {
    window.scroll(0, 0);
    this.id = this.$route.params.id;
    this.getAllCatalogue()
    this.$store
      .dispatch("getVideoProviderList")
      .then(data => {
        this.videoProviderList = data.response;
        this.setProviderList();
      })
      .catch(error => { });
    if (this.id) {
      this.$store.commit("setLoader", {
        show: true,
        text: "Fetching Videos Details..."
      });
      this.createMode = false;
      this.$store
        .dispatch("getVideoById", this.id)
        .then(response => {
          this.video = response;
          this.setInitialConfig();
          this.catalogueIds = this.video["catalogueIds"];
          this.settings = this.video["settings"];
          this.video_type = this.video["type"];
          var metaData = this.video.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            this.metaData.push(obj);
          }
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
    } else {
      this.settings = {
        subscription: true,
        subscriptionIds: [],
        TVOD: false,
        AVOD: false,
        avodConfigIds: [],
        free: false
      };
    }
  },
  watch: {
    video_type: function () { }
  },
  computed: {
    tableData() {
      return this.$store.state.catalogue.catalogueList;
    },
    getAllCatalogueList(){
      return this.$store.getters.getAllCatalogueList;
    },
    tableDataEPG() {
      return this.epgTableData;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    pagedDataepg() {
      return this.tableDataEPG.slice(this.fromepg, this.toepg);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedDataEPG() {
      let result = this.tableDataEPG;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
        this.paginationepg.totalepg = result.length;
      }
      return result.slice(this.fromepg, this.toepg);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    toepg() {
      let highBound = this.fromepg + this.paginationepg.perPage;
      if (this.totalepg < highBound) {
        highBound = this.totalepg;
      }
      return highBound;
    },
    fromepg() {
      return this.paginationepg.perPage * (this.paginationepg.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    totalepg() {
      this.paginationepg.total = this.tableDataEPG.length;
      return this.tableDataEPG.length;
    },
    isEdit() {
      return this.$route.path.indexOf("edit") > -1;
    },
    pageTitle() {
      if (this.videoProviderConfig && this.videoDialogProviderConfig.title) {
        return this.isEdit
          ? ` Edit Video - ${this.videoProviderConfig.title}`
          : "Add Video";
      }
      return this.isEdit
        ? ` Edit Video`
        : "Add Video";

    },
    show4KLogo() {
      if (this.$store.state.customerId == "645358e63bd635001afb99fc") {
        return true;
      }
      else {
        return false;
      }
    }

  },
  methods: {
    tagChanged($event){
      this.video.tags = $event.target.value.split(",");
    },
    enableFreePreview() {
      if (this.settings.free == true) {
        Vue.set(this.settings, "TVOD", false);
        Vue.set(this.settings, "AVOD", false);
        Vue.set(this.settings, "subscription", false);
      }
    },
    initilizePlayer: function (options) {
      self = this;
      this.playerOptions = options;
      this.player.src({
        src: options.sources[0].src,
        type: "application/x-mpegURL",
        withCredentials: false
      });
      this.player.play();
      this.$refs.videoPlayDialog.show();
    },
    disposePlayer: function () {
      this.$refs.videoPlayDialog.hide();
      if (this.player) {
        //this.player.stop();
        this.playerOptions = null;
        //this.player = null;
      }
    },
    handleEdit: function (index, props) {
      this.$router.push({ name: "Edit Video", params: { id: props.id } });
    },
    handleWatchVideo: function () {
      this.$store
        .dispatch("getPlayBackUrl", this.id)
        .then(data => {
          var response = data.response;
          this.playerOptions.sources[0].type = "application/x-mpegURL";
          this.selectedUrl = response.url;
          var playerOptionsTosend = {
            playbackRates: [0.5, 1, 1.5, 2],
            height: "360",
            autoplay: false,
            sources: [
              {
                withCredentials: true,
                type: "application/x-mpegURL",
                src: this.selectedUrl
              }
            ],
            poster: "",
            flash: { hls: { withCredentials: true } },
            html5: { hls: { withCredentials: true } }
          };
          this.initilizePlayer(playerOptionsTosend);
        })
        .catch(() => {
          this.playerOptions.sources[0].src = "";
          this.$refs.videoPlayDialog.show();
        });
    },
    handleSwitch: function (index, props) {
      if (props.id) {
        if (index >= 0) {
          var data = this.$store.state.videos.videoList[index];

          var req = {};
          if (data.isActive == false) {
            data.isActive = true;
          } else {
            data.isActive = false;
          }
          var DataToSend = {
            isActive: data.isActive,
            id: data.id
          };
          req.id = data.id;
          req.data = DataToSend;
          req.tags = this.video.tags;

          self = this;

          if (data.id) {
            this.$store
              .dispatch("updateVideo", req)
              .then(response => {
                this.$notify({
                  title: "Success",
                  message: "Video Updated Successfully!",
                  type: "success"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Video Failed!",
                  type: "warning"
                });
                return;
              });
          }
          this.$store.disatch("getVideoList");
        }
      }
    },
    onVideoPlayerClose: function () {
      this.playerOptions.sources[0].src = "";
      //this.playerOptions.poster = this.getItem('thumb',row);
      this.player.pause();
      //this.disposePlayer();
      this.player.src({
        src: "",
        type: "application/x-mpegURL",
        withCredentials: false
      });
      this.$refs.videoPlayDialog.hide();
    },
    deleteVideo: function () {
      if (this.deleteId) {
        this.$store
          .dispatch("deleteVideoById", this.deleteId)
          .then(response => { })
          .catch(() => { });
      }
    },
    handleDelete: function (index, props) {
      this.deleteId = props.id;
    },

    generateErrorMessage: function () {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    openImportEPG: function (evt, row) {
      this.currentUpload = row;
      evt.stopPropagation();
      evt.preventDefault();
      var file = this.$refs.EPGImportInput;
      if (file) {
        file.click();
      }
    },
    importEPGData: function (evt) {
      let file = evt.target.files[0];
      var rowToUpload = this.currentUpload;
      var newName =
        rowToUpload.row.sendlabel + "." + file.name.split(".").pop();
      let formData = new FormData();
      formData.append("file", evt.target.files[0], newName);
      var DataToSend = {
        formData: formData,
        id: this.id
      };
      this.uploading = true;
      self = this;
      this.$store
        .dispatch("importEPGData", DataToSend)
        .then(response => {
          this.uploading = false;
          if (
            response.message &&
            response.errorDatas &&
            response.errorDatas.length > 0
          ) {
            this.message = response.message;
            this.errorsData = response.errorDatas;
            this.generateErrorMessage();
            this.$refs.importDialog.show();
            return;
          }
          this.$notify({
            title: "Success",
            message: "Successfully Imported!",
            type: "success"
          });
          self.epgTableData[rowToUpload.$index].status = response.message;
          self.currentUpload = "";
        })
        .catch(err => {
          this.uploading = false;
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
      self.currentUpload = "";
    },
    openExportEPG: function () { },
    getKey(item) {
      if (item._id) {
        return item._id;
      }
      return item.cId;
    },
    setProviderList() {
      if (
        Array.isArray(this.videoProviderList) &&
        this.videoProviderList.length > 0
      ) {
        for (var i = 0; i < this.videoProviderList.length; i++) {
          var item = {};
          item.value = this.videoProviderList[i].providerId;
          item.label = this.videoProviderList[i].providerName;
          this.selectProviderList.push(item);
        }
        if (this.videoProviderConfig.provider === null) {
          this.videoProviderConfig.provider = this.selectProviderList[0].value;

        }
      }
    },
    onVideoPlayerClose: function () {
      this.playerOptions.sources[0].src = "";
      this.player.pause();
      this.player.src({
        src: "",
        type: "application/x-mpegURL",
        withCredentials: false
      });
      this.$refs.videoPlayDialog.hide();
    },
    addLanguage() {
      var lang = {};
      lang.cId = this.$uuid.v4();
      lang.language = "english";
      lang.order = 1;
      lang.country = [];
      lang.cast = "";
      lang.direction = "";
      lang.title = "";
      lang.description = "";
      lang.releaseDate = "";
      lang.thumbnailLink = {
        small: "https://dummyimage.com/640x360/a1a1a1/fff",
        medium: "https://dummyimage.com/640x360/a1a1a1/fff",
        large: "https://dummyimage.com/640x360/a1a1a1/fff",
        source: "https://dummyimage.com/640x360/a1a1a1/fff"
      };
      lang.coverartLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.bannerImgLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.mobileBannerImgLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.titleImageLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };

      this.video.languages.push(lang);
      this.activeLanguageItems.push(this.video.languages.length);
    },
    setInitialConfig() {
      if (this.video.providerId) {
        this.videoProviderConfig.provider = this.video.providerId;
        if (this.video.providerVideoData && this.video.providerVideoData.title) {
          this.videoProviderConfig.title = this.video.providerVideoData.title;

        }

        this.videoProviderConfig.url = this.video.providerVideoId;
        this.videoProviderConfig.id = this.video.providerId;
      }
    },
    serachVProviderConfig() { },
    updateVideoOrigin() {
      this.videoDialogProviderConfig = JSON.parse(
        JSON.stringify(this.videoProviderConfig)
      );
      this.editPopupShow = true;
    },
    playVideoOrigin() {
      handleWatchVideo();
    },
    stackPath(provider) {
      if (provider) {
        var index = this.findWithAttr(
          this.videoProviderList,
          "providerId",
          provider
        );
        if (index >= 0) {
          var slug = this.videoProviderList[index].providerSlug;
          if (slug == "stackpath" || slug == "none") return true;
        }
      }
      return false;
    },

    isChecked(props) {
      if (this.catalogueIds) {
        return this.catalogueIds.includes(props.row.id);
      }
      return false;
    },
    checkChanged(val, props) {
      if (!this.catalogueIds || !Array.isArray(this.catalogueIds)) {
        this.sIds = [];
      }
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.catalogueIds.includes(id)) {
            this.catalogueIds.push(id);
          }
        } else {
          if (this.catalogueIds.includes(id)) {
            var index = this.catalogueIds.indexOf(id);
            this.catalogueIds.splice(index, 1);
          }
        }
      }
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    saveVideo() {
      this.submitted = true;
      this.$validator.validate().then(result => {
        if (!result) {
          this.$notify({
            title: "Error",
            message: "Some Fields are Invalid Format!",
            type: "warning"
          });
          return;
        } else {
          if (this.video.languages.length < 1) {
            this.$notify({
              title: "Error",
              message: "You need to add language at least one!",
              type: "warning"
            });
            return;
          }
          var data = JSON.parse(JSON.stringify(this.video));
          data.type = this.video_type;
          data.catalogueIds = this.catalogueIds;
          data.languages = [];
          data.settings = this.$refs.monetization.getSettingInfo();
          data.settings.avodConfigIds = this.$refs.avod.getSettingInfo();

          if (this.settings.free != undefined) {
            data.settings.free = this.settings.free;
          }
          if (this.settings.AVOD != undefined) {
            data.settings.AVOD = this.settings.AVOD;
          }
          if (
            this.settings.subscription == false &&
            this.settings.subscriptionIds.length >= 1
          ) {
            this.settings.subscriptionIds = [];
          }
          if (
            this.settings.subscription == true &&
            this.settings.subscriptionIds.length < 1
          ) {
            this.$notify({
              title: "Error",
              message: "You need to choose at least one SVOD!",
              type: "warning"
            });
            return;
          }
          for (var i = 0; i < this.video.languages.length; i++) {
            var vItem = this.$refs["vlang" + i][0];
            if (vItem) {
              data.languages.push(vItem.getLangInfo());
            }
          }
          data.metaData = {};

          for (var j = 0; j < this.metaData.length; j++) {
            var key = this.metaData[j].key;
            data.metaData[key] = this.metaData[j].value;
          }
          for (var i = 0; i < this.video.languages.length; i++) {
            if (
              this.video.languages[i] &&
              this.video.languages[i].bannerImgLink
            ) {
              data.metaData["bannerImage"] = this.video.languages[
                i
              ].bannerImgLink.large;
            }
            if (
              this.video.languages[i] &&
              this.video.languages[i].mobileBannerImgLink
            ) {
              data.metaData["mobileBannerImage"] = this.video.languages[
                i
              ].mobileBannerImgLink.large;
            }
            if (
              this.video.languages[i] &&
              this.video.languages[i].titleImageLink
            ) {
              data.metaData["bannerTitleImage"] = this.video.languages[
                i
              ].titleImageLink.large;
            }
          }

          if (this.videoSearchResult && Object.keys(this.videoSearchResult).length > 0) {
            data.providerId = this.videoSearchResult.providerId;
            data.providerVideoId = this.videoSearchResult.providerVideoId;
            data.providerVideoData = this.videoSearchResult.providerVideoData;
          }

          if (this.createMode && !this.id) {
            data.catalogueIds = this.catalogueIds;
            this.$store
              .dispatch("createVideo", data)
              .then(response => {
                var data = response.data;
                if (data.video && data.video.id) {
                  this.id = data.video.id;
                }
                this.$notify({
                  title: "Success",
                  message: "Video created Successfully!",
                  type: "success"
                });

                this.$router.push({ name: "Videos" });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Create Video Failed!",
                  type: "warning"
                });
                return;
              });
          } else {
            var req = {};
            req.id = this.id;
            data.catalogueIds = this.catalogueIds;
            req.data = data;
            req.tags = this.video.tags;

            if (this.id) {
              this.$store
                .dispatch("updateVideo", req)
                .then(response => {
                  var data = response.data;
                  var video = data.video;
                  if (video) {
                    this.video = video;
                  }
                  this.$notify({
                    title: "Success",
                    message: "Video Updated Successfully!",
                    type: "success"
                  });
                  //this.$router.push({ name: "Videos" });
                })
                .catch(() => {
                  this.$notify({
                    title: "Error",
                    message: "Update Video Failed!",
                    type: "warning"
                  });
                  return;
                });
            }
          }
        }
      });
    },
    handleNewConfigOk() {
      this.videoProviderConfig = JSON.parse(
        JSON.stringify(this.videoDialogProviderConfig)
      );
      if (this.stackPath(this.videoProviderConfig.provider)) {
        var data = {};
        var index = this.findWithAttr(
          this.videoProviderList,
          "providerId",
          this.videoProviderConfig.provider
        );
        data.providerId = this.videoProviderConfig.provider;
        data.slug = this.videoProviderList[index].providerSlug;
        data.title = this.videoProviderConfig.title;
        data.url = this.videoProviderConfig.url;
        this.$store
          .dispatch("searchVideo", data)
          .then(response => {
            this.videoSearchResult = response;
            this.pThumbnail = "https://dummyimage.com/300x180/a1a1a1/fff";
          })
          .catch(() => { });
      }
      else {
        this.videoSearchResult = { providerId: this.videoProviderConfig.provider, providerVideoId: this.videoProviderConfig.id }
      }
    },
    getTitle(item) {
      if (item.title) {
        return `Edit ${item.title} Details`;
      }
      return "Add Video Details";
    },
    jsUcfirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    deleteVideoLanguage(index) {
      this.deletedIndex = index;
      this.$refs["confirmLanguageDelete"].$refs["deleteConfirmation"].show();
    },
    confirmDeleteVideo() {
      if (this.video.languages) {
        this.video.languages.splice(this.deletedIndex, 1);
      }
    },

    setUploadStatus: function (value) {
      this.uploading = value;
    },
    getUploadStatus: function (row) {
      if (row.status) {
        return row.status;
      } else {
        return "NA";
      }
    },
    getAllCatalogue(){
      this.$store
        .dispatch("getAllCatalogue")
        .then(data => {
         
        })
        .catch(() => { });
    },
    },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["name", "email"] });
    this.player = videojs(this.$refs.videoPlayer, function onPlayerReady() {
    });
  }
};
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 18px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 30px;
}

.typewidth {
  width: 30%;
}

.enableSwitch {
  float: right;
  padding-right: 20px;
  position: relative;
  left: 50px;
}

.save-video {
  float: left;
  position: relative;
  right: 5px;
  bottom: 25px;
}

.thumbnailvideo {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.thumbvideo-div {
  min-height: 160px !important;
  margin-top: 15px;
  position: relative;
  left: 20px;
}

.name-admin-reference {
  position: relative;
  left: 20px;
  margin-top: 15px;
}

.playthumbbtn {
  padding: 10px 10px;
  width: 80px;
}

.updatethumbbtn {
  padding: 10px 10px;
  width: 80px;
}

.monetizationcontainer {
  padding-top: 50px;
}

.addMetaData {
  margin-top: 27px;
}

.video-js {
  width: 100% !important;
}

.videoHost {
  font-size: 12px;
  color: #9a9a9a;
  margin-bottom: 5px !important;
  margin-left: 15px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.extralarge {
  width: 100%;
  position: relative !important;
  right: 8px;
}

.titleAssociatedCatalogue {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
}

.AssociatCatContainer {
  position: relative !important;
  left: 3px;
}

.addVideoDetails {
  position: relative;
  right: 3px;
}

.fourKLogo {
  position: absolute;
  bottom: 42px;
  left: 76px;
}
</style>
