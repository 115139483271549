<template> 
    <div class="row">

        <div class="col-12">
            <card title="Actions" v-if="showCard">
                 <div class="row">
                    <div class="col-md-6">
                         <label>SMS Verification Required</label>    
                    
                            <l-switch v-model="currentState.smsTrigger" on-text="ON" off-text="OFF"></l-switch>
            
                    </div>
                    <div class="col-md-6">
                         <label>Email Verification Required</label>    
                        
                            <l-switch v-model="currentState.emailTrigger" on-text="ON" off-text="OFF"></l-switch>
                    </div>
                     <div class="col-md-12">
                                    <button class="btn btn-primary btn-fill btn-m-top" @click.prevent="UpdateStatus">Update</button>
                    </div>
                </div>
            </card>
           
        </div>
    </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui'
import {Switch as LSwitch, Checkbox as LCheckbox } from 'src/components'
import {Pagination as LPagination,Spinner as LSpinner} from 'src/components/index'
import Fuse from 'fuse.js'

export default {
    components: {
        LPagination,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        LSpinner,
        LSwitch,
        LCheckbox
    },
    data (){
        return {
          currentState : null,
          showCard : false
        }
    },

    computed: {

    },

    mounted () {
    },
    created () {
        
         this.$store.dispatch('getCustomerCurrentState').then(response => {
            if(response) {
               this.currentState = response;
               this.showCard = true;
            }
        }).catch(()=> {

        });
        
    },

    methods: {       
        UpdateStatus: function(){
            var DataToSend = {
                emailTrigger: this.currentState.emailTrigger,
                smsTrigger:this.currentState.smsTrigger

            }
        this.$store.dispatch('updateCustomerCurrentState',DataToSend).then(response => {
             this.$notify({
                    title: 'Success',
                    message: 'Successfully Updated!',
                    type: 'success'
                  });
        }).catch(()=> {
            this.$notify({
                    title: 'Error',
                    message: 'Failed to Update!',
                    type: 'warning'
                    });
        });
          
        },        
        generateErrorMessage: function() {
            this.errorMessage = '';
            if(this.errorsData && this.errorsData.length>0) {       
                for(var i=0;i<this.errorsData.length;i++) {
                    this.errorMessage = this.errorMessage + JSON.stringify(this.errorsData[i]);
                }

            }
        },
        openExportip: function() {

        }
    }
}
</script>

<style scoped>

    .add-button {

        padding-bottom: 10px;
        padding-left: 15px;
    }
    
    .btn-m-top {
        margin-top: 20px;;
    }
</style>