<template>
  <div>
    <div slot="header">
      <h4 class="titleCard mb-2">{{ pageTitle }}</h4>
    </div>
    <card>
      <DeleteConfirmation
        :currentDeleteMethod="deleteSubscroption"
        ref="deleteSvodConfirmation"
        @delete="$event()"
      />
      <DeleteConfirmation
        :currentDeleteMethod="deleteConfigData"
        ref="confirmDeletConfigData"
        @delete="$event()"
      />
      <b-modal
        id="newPlatformModal"
        ref="subscriptionModal"
        @ok="handleNewPlatformOk"
        @cancel="exitDialog"
       
        :title="getDialogTitle()"
        ok-title="Add"
      >
          <div class="row">
            <fg-input
              class="col-sm-12 col-12 subtitlecontainer"
              label="Subscription Title"
              v-model="currentData.title"
              :class="{ 'is-invalid': dialogSubmitted && !currentData.title }"
            ></fg-input>
          </div>
          <div class="row">
            <fg-input
              class="col-sm-12 col-12 subtitlecontainer"
              label="Slug Name"
              v-model="currentData.slug"
              :class="{ 'is-invalid': dialogSubmitted && !currentData.slug }"
            ></fg-input>
          </div>
          <div class="row">
            <fg-input
              class="col-sm-12 col-12 subtitlecontainer"
              label="Description"
            >
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                rows="3"
                v-model="currentData.description"
              ></textarea>
            </fg-input>
          </div>
          <div class="row">
            <fg-input
              class="col-md-3 col-3 subtitlecontainer"
              label="Price"
              v-model="currentData.price"
              :class="{ 'is-invalid': dialogSubmitted && !currentData.price }"
            ></fg-input>
            <div
              class="col-md-4"
              :class="{
                'is-invalid': dialogSubmitted && !currentData.currency
              }"
            >
              <label>Currency</label>
              <el-select
                label="Currency"
                class="select-default subtitlecontainer"
                size="large"
                placeholder="Currency"
                v-model="currentData.currency"
                :class="{
                  'is-invalid': dialogSubmitted && !currentData.currency
                }"
              >
                <el-option
                  v-for="curr in currencies"
                  class="select-danger"
                  :value="curr.value"
                  :label="curr.label"
                  :key="curr.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <fg-input
              class="col-md-3 col-3"
              label="Duration"
              v-model="currentData.duration"
              :class="{
                'is-invalid': dialogSubmitted && !currentData.duration
              }"
            ></fg-input>
            <div
              class="col-md-4"
              :class="{
                'is-invalid': dialogSubmitted && !currentData.period
              }"
            >
              <label>Period</label>
              <el-select
                class="select-default"
                size="large"
                placeholder="Period"
                v-model="currentData.period"
                :class="{
                  'is-invalid': dialogSubmitted && !currentData.period
                }"
              >
                <el-option
                  v-for="per in periods"
                  class="select-default"
                  :value="per.value"
                  :label="per.label"
                  :key="per.label"
                ></el-option>
              </el-select>
            </div>
            <div class="col-md-5">
              <label>Trial</label>
              <el-select
                class="select-default"
                size="large"
                placeholder="Trial"
                v-model="currentData.trial"
              >
                <el-option
                  v-for="trial in trials"
                  class="select-default"
                  :value="trial.value"
                  :label="trial.label"
                  :key="trial.label"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Country</label>
              <treeselect
                class="width_class"
                v-model="currentData.country"
                :value-consists-of="valueConsistsOf"
                :multiple="true"
                :options="countries"
              />
              <!--
					<el-select :multiple="true" class="select-primary extralarge" size="large" placeholder="Country" v-model="currentData.country">
						<el-option v-for="count in countries" class="select-danger" :value="count.value" :label="count.label" :key="count.label">
						</el-option>
					</el-select>
          -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label>Manage subscription Meta Data</label>
              <card>
                <div class="row">
                  <div class="col-sm-4">
                    <fg-input
                      label="Key"
                      v-model="subscriptionCurrentMetaData.key"
                    ></fg-input>
                  </div>
                  <div class="col-sm-5">
                    <fg-input
                      label="Value"
                      v-model="subscriptionCurrentMetaData.value"
                    ></fg-input>
                  </div>
                  <div class="col-sm-3">
                    <button
                      class="btn btn-primary meta-button"
                      @click="addSubscriptionMetaData()"
                    >
                      Add
                    </button>
                  </div>
                </div>

                <div>
                  <el-table
                    class="table-bigboy"
                    style="width: 100%;"
                    :data="subscriptionMetaData"
                    border
                  >
                    <el-table-column min-width="120" label="Key">
                      <template slot-scope="{ row }">{{ row.key }}</template>
                    </el-table-column>
                    <el-table-column min-width="140" label="Value">
                      <template slot-scope="{ row }">{{ row.value }}</template>
                    </el-table-column>
                    <el-table-column>
                      <template slot-scope="props">
                        <a
                          v-tooltip.top-center="'Edit'"
                          class="btn-warning btn-simple btn-link"
                          @click="handleEditConfigData(props.$index, props.row)"
                          ><i class="fa fa-edit"></i
                        ></a>
                        <a
                          v-tooltip.top-center="'Delete'"
                          class="btn-danger btn-simple btn-link"
                          @click="handleDeleteConfigData(props.$index)"
                        >
                          <i class="fa fa-trash"></i>
                        </a>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </card>
            </div>
          </div>
      </b-modal>

      <form class="svod-form" @submit.prevent="updatePackage">
        <div class="row">
          <div class="row col-md-12">
            <fg-input
              v-validate="'required'"
              name="packageTitle"
              :class="{ 'is-invalid': submitted && errors.has('packageTitle') }"
              class="col-md-6"
              label="Title"
              placeholder="Package Title"
              v-model="p_title"
            ></fg-input>
            <div class="col-md-6 form-group">
              <label>Language</label>
              <div class="row">
                <div class="col-md-12 p-0">
                  <el-select
                    v-validate="'required'"
                    name="selectLang"
                    :class="{
                      'is-invalid': submitted && errors.has('selectLang')
                    }"
                    class="select-default"
                    size="large"
                    placeholder="Select a language"
                    v-model="p_language"
                    :filterable="true"
                    :clearable="true"
                  >
                    <el-option
                      v-for="lang in languages"
                      class="select-primary"
                      :value="lang.value"
                      :label="lang.label"
                      :key="lang.label"
                    ></el-option>
                  </el-select>
                </div>
              </div>
            </div>
            <fg-input class="col-md-12" label="Description">
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                v-model="p_description"
                rows="3"
              ></textarea>
            </fg-input>

            <!--<button class="btn btn-default" @click="addPlatform"></button>-->
          </div>

          <div class="col-12">
            <card title="Subscription Details">
              <el-button
                type="primary"
                icon="el-icon-plus"
                v-b-modal.newPlatformModal
                @click="addSubscription()"
                >Add Subscription
              </el-button>
              <div class="py-3">
                <el-table
                  stripe
                  style="width: 100%;"
                  :data="queriedData"
                  border
                >
                  <el-table-column
                    v-for="column in tableColumns"
                    :key="column.label"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="column.label"
                  ></el-table-column>
                  <!--
									<el-table-column :min-width="300" fixed="right" label="Country">
										<template slot-scope="props">
											<div>{{getMultiCountryName(props.row)}}</div>
										</template>
									</el-table-column>
                  -->
                  <el-table-column
                    :min-width="120"
                    fixed="right"
                    label="Actions"
                  >
                    <template slot-scope="props">
                      <a
                        v-tooltip.top-center="'Edit'"
                        class="btn-warning btn-simple btn-link"
                        v-b-modal.newPlatformModal
                        @click="handleEdit(props.$index, props.row)"
                      >
                        <i class="fa fa-edit"></i>
                      </a>
                      <a
                        v-tooltip.top-center="'Delete'"
                        class="btn-danger btn-simple btn-link"
                        @click="handleDelete(props.$index, props.row)"
                      >
                        <i class="fa fa-trash"></i>
                      </a>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </card>
            <b-modal
              id="configdatas"
              ref="modal"
              title="Edit Config Data"
              @ok="checkConfigDataValue"
              ok-title="Save"
            >
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-4">
                    <fg-input
                      label="Key"
                      v-model="editConfigData.key"
                    ></fg-input>
                  </div>
                  <div class="col-sm-8">
                    <fg-input
                      label="Value"
                      v-model="editConfigData.value"
                    ></fg-input>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
          <div class="col-md-12 col-12 manageTagContainer">
            <card title="Manage Meta Data">
              <ManageMetaData :metaData="metaData"></ManageMetaData>
            </card>
          </div>

          <div class="col-md-12 saveSVOD">
            <button class="btn btn-primary" type="submit">Save</button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput, Switch as LSwitch } from "src/components";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Collapse,
  CollapseItem,
  Slider,
  Button
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import countries from "../../../config/countries.json";
import currencies from "../../../config/currency.json";
import languages from "../../../config/languages.json";
import { formatForSelectFromJson } from "../../../service/utility";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import ManageMetaData from "../Components/ManageMetaData";
import Vue from "vue";
export default {
  components: {
    FgInput,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LPagination,
    [Slider.name]: Slider,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Treeselect,
    DeleteConfirmation,
    ManageMetaData
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["title", "description", "price", "duration"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    isAdd() {
      return this.$route.path.indexOf("-1") > -1;
    },
    pageTitle() {
      return this.isAdd
        ? ` Add Package
`
        : "Edit Package";
    }
  },
  data() {
    return {
      p_title: "",
      p_description: "",
      p_language: "",
      dMode: "",
      valueConsistsOf: "ALL_WITH_INDETERMINATE",
      submitted: false,
      dialogSubmitted: false,
      sliders: {
        simple: 30
      },
      periods: [
        { value: "day", label: "Days" },
        { value: "month", label: "Months" },
        { value: "year", label: "Years" }
      ],
      trials: [
        { value: "1", label: "1 Day" },
        { value: "7", label: "7 Days" },
        { value: "14", label: "14 Days" },
        { value: "30", label: "30 Days" }
      ],
      languages: [
        { value: "english", label: "English" },
        { value: "french", label: "French" },
        { value: "germany", label: "Germany" },
        { value: "hindi", label: "Hindi" }
      ],
      currencies: [],
      countries: [],
      tableData: [],
      metaData: [],
      currentMetaData: {
        key: "",
        value: ""
      },
      subscriptionMetaData: [],
      subscriptionCurrentMetaData: {
        key: "",
        value: ""
      },
      editConfigData: {
        key: "",
        value: ""
      },
      editConfigDataIndex: "",
      deleteConfigDataIndex: "",
      currentData: {
        title: "",
        slug: "",
        description: "",
        price: 0,
        currency: "",
        country: [],
        duration: "",
        period: "",
        trial: ""
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["name", "subid", "id"],
      tableColumns: [
        {
          prop: "title",
          label: "Name",
          minWidth: 150
        },
        {
          prop: "price",
          label: "Price",
          minWidth: 100
        },
        {
          prop: "duration",
          label: "Duration",
          minWidth: 100
        },
        {
          prop: "slug",
          label: "Slug Name",
          minWidth: 150
        }
      ]
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.countries = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.languages = languages;
    this.currencies = formatForSelectFromJson(currencies);
    if (this.id != "-1") {
      this.$store
        .dispatch("getPackageById", this.id)
        .then(data => {
          this.p_title = data.title;
          this.p_description = data.description;
          this.p_language = data.language;
          var metaData = data.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            this.metaData.push(obj);
          }
          if (data.subscriptions) {
            this.tableData = data.subscriptions;
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    addSubscription() {
      this.dMode = "create";
      this.resetCurrentDataValue();
      this.dialogSubmitted = false;
    },
    handleEdit(index, row) {
      this.dMode = "edit";
      this.currentIndex = index;
      this.currentData = JSON.parse(JSON.stringify(row));
      this.subscriptionMetaData = [];
      if (this.currentData.metaData) {
        Object.keys(this.currentData.metaData).forEach((key, index) => {
          var value = this.currentData.metaData[key];
          this.subscriptionMetaData.push({ key, value });
        });
      }
      this.dialogSubmitted = false;
    },
    handleDelete(index, row) {
      var pageNumbs =
        (this.pagination.currentPage - 1) * this.pagination.perPage;
      this.deleteSubIndex = pageNumbs + index;
      this.$refs["deleteSvodConfirmation"].$refs["deleteConfirmation"].show();
    },
    deleteSubscroption() {
      if (this.deleteSubIndex >= 0) {
        this.tableData.splice(this.deleteSubIndex, 1);
      }
    },
    getDialogTitle() {
      if (this.dMode == "create") {
        return "Create Subscription";
      } else if (this.dMode == "edit") {
        return "Edit Subscription";
      }
      return "";
    },
    resetCurrentDataValue() {
      this.currentData = {
        title: "",
        slug: "",
        description: "",
        price: 0,
        currency: "",
        country: [],
        duration: "",
        period: "",
        trial: ""
      };
      this.currentData.metaData = {};
    },
    exitDialog() {
      this.resetCurrentDataValue();
    },
    handleNewPlatformOk(event) {
      this.dialogSubmitted = true;
      if (
        !this.currentData.title ||
        !this.currentData.slug ||
        !this.currentData.price ||
        !this.currentData.currency ||
        !this.currentData.duration ||
        !this.currentData.period
      ) {
        event.preventDefault();
        
        return;
      }
      if (this.currentData) {
        if (this.dMode == "create") {
          this.currentData.metaData={}
          this.subscriptionMetaData.forEach(item => {
        this.currentData.metaData[item.key] = item.value;
      });
          this.tableData.push(this.currentData);
        } else if (this.dMode == "edit") {
          this.currentData.metaData={}
          this.subscriptionMetaData.forEach(item => {
        this.currentData.metaData[item.key] = item.value;
      });
          this.tableData.splice(this.currentIndex, 1);
          this.tableData.splice(
            this.currentIndex,
            0,
            JSON.parse(JSON.stringify(this.currentData))
          );
        }

        this.resetCurrentDataValue();

        this.$refs.subscriptionModal.hide();
      }
    },
    updatePackage() {
      this.submitted = true;
      this.$validator.validate().then(result => {
        if (!result) {
          this.$notify({
            title: "Error",
            message: "Some Fields are Invalid Format!",
            type: "warning"
          });
          return;
        } else {
          let updateData = {};
          let formData = {};
          formData.title = this.p_title;
          formData.language = this.p_language;
          formData.description = this.p_description;
          formData.customerId = this.$store.state.customerId;
          formData.subscriptions = this.tableData;
          formData.metaData = {};
          for (var j = 0; j < this.metaData.length; j++) {
            var key = this.metaData[j].key;
            formData.metaData[key] = this.metaData[j].value;
          }

          updateData.data = formData;
          if (this.id == -1) {
            this.$store
              .dispatch("createPackage", updateData)
              .then(data => {
                this.$router.push({ name: "SVOD" });
                this.$notify({
                  title: "Success",
                  message: "Created Successfully",
                  type: "success"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Package Failed!",
                  type: "warning"
                });
                return;
              });
          } else {
            updateData.id = this.id;
            this.$store
              .dispatch("updatePackage", updateData)
              .then(data => {
                this.$router.push({ name: "SVOD" });
                this.$notify({
                  title: "Done",
                  message: "Updated Successfully",
                  type: "info"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Package Failed!",
                  type: "warning"
                });
                return;
              });
          }
        }
      });
    },
    getMultiCountryName: function(row) {
      var countries = row.country;
      var str = "";
      if (Array.isArray(countries)) {
        for (var i = 0; i < countries.length; i++) {
          var country = countries[i];

          var cvar = "";

          for (var j = 0; j < this.countries.length; j++) {
            var continent = this.countries[j].children;
            for (var k = 0; k < continent.length; k++) {
              if (country == continent[k].id) {
                cvar = continent[k];
                k = continent.length;
                j = countries.length;
              }
            }
          }

          if (cvar) {
            if (i != 0) {
              str = str + " , ";
            }
            str = str + cvar.label;
          }
        }
      }
      return str;
    },

    addSubscriptionMetaData: function() {
      if (
        this.subscriptionCurrentMetaData.key == "" ||
        this.subscriptionCurrentMetaData.value == ""
      ) {
        this.$notify({
          title: "Error",
          message: "Subscription MetaData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
       if(this.subscriptionCurrentMetaData.key=="paymentGatewaySlug"){
        this.subscriptionCurrentMetaData.value=JSON.parse(this.subscriptionCurrentMetaData.value)
      }
      this.subscriptionMetaData.push(
        JSON.parse(JSON.stringify(this.subscriptionCurrentMetaData))
      );

      this.subscriptionCurrentMetaData = {
        key: "",
        value: ""
      };
      this.subscriptionMetaData.forEach(item => {
        this.currentData.metaData[item.key] = item.value;
      });
    },
    handleDeleteConfigData(index) {
      this.deleteConfigDataIndex = index;
      this.$refs["confirmDeletConfigData"].$refs["deleteConfirmation"].show();
    },
    deleteConfigData() {
      this.subscriptionMetaData.splice(this.deleteConfigDataIndex, 1);
    },
    handleEditConfigData: function(index, row) {
      this.$refs.modal.show("#configdatas");
      if(typeof row.value ==="object"){
        this.editConfigData = row;
        this.editConfigData.value=JSON.stringify(row.value)
         this.editConfigData.isObject=true;
        this.editConfigDataIndex = index;
      }
      if(typeof row.value ==="string"){
      this.editConfigData = JSON.parse(JSON.stringify(row));
       this.editConfigDataIndex = index;
      }
      
    },
    checkConfigDataValue: function(index, row) {
      if (this.editConfigData.key == "" || this.editConfigData.value == "") {
        this.$notify({
          title: "Error",
          message: "Subscription MetaData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      if(this.editConfigData.isObject == true){
        this.editConfigData.value=JSON.parse(this.editConfigData.value)
      }
      else if(this.editConfigData.key=="paymentGatewaySlug"){
          this.editConfigData.value=JSON.parse(this.editConfigData.value) 
         }
      Vue.set(
        this.subscriptionMetaData,
        this.editConfigDataIndex,
        this.editConfigData
      );
      let temp = {};
      for (const metaData of this.subscriptionMetaData) {
        temp[metaData.key] = metaData.value;
      }
      this.currentData.metaData = temp;
    }
  }
};
</script>

<style scoped>
.subcontainer {
  padding-top: 25px;
}

.subtitlecontainer {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.extralarge {
  width: 100%;
}

.card form.svod-form [class*="col-"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.width_class {
  width: 100%;
  margin-left: 0px;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
.saveSVOD {
  float: left;
  position: relative;
  left: 4px;
  bottom: 30px;
}
.meta-button {
  position: relative;
  right: 34px;
  top: 3px;
}
</style>
