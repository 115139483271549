<template>
    <div class="row">
        <div class="uploadingOverlay" v-if="uploading">
            <l-spinner class="upload-spinner" :main="true" title="Importing Csv..." size="medium"></l-spinner>
        </div>
        <b-modal id="newAppConfigModal" ref="modal" @ok="handleNewConfigOk" @cancel="exitDialog" :title="getDialogTitle()">
            <form @submit.stop.prevent="handleNewConfig">
                <b-row class="mb-1">
                    <b-col>Config Type</b-col>
                    <b-col>
                        <el-select label="Type" class="select-default" size="large" placeholder="Type" v-model="currentData.type">
                            <el-option v-for="configType in configTypes" class="select-danger" :value="configType.value" :label="configType.label" :key="configType.label">
                            </el-option>
                        </el-select>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col>Section</b-col>
                    <b-col>
                        <el-select label="Type" class="select-default" size="large" placeholder="Type" v-model="currentData.section">
                            <el-option v-for="sectionType in configSectionTypes" class="select-danger" :value="sectionType.value" :label="sectionType.label"
                                :key="sectionType.label">
                            </el-option>
                        </el-select>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col>Config Name</b-col>
                    <b-col>
                        <b-form-input type="text" placeholder="Config Name" title="Config Name" v-model="currentData.name">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col>Config Parameter</b-col>
                    <b-col>
                        <b-form-input type="text" placeholder="Config Parameter" title="Config Parameter" v-model="currentData.param">
                        </b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mb-1">
                    <b-col>Config Value</b-col>
                    <b-col>
                        <b-form-input v-if="!currentData.type || currentData.type=='text' || currentData.type=='image' || currentData.type=='bool'" type="text" placeholder="Config Value"
                            title="Config Value" v-model="currentData.value">
                        </b-form-input>
                        <div v-if="currentData.type=='color'">
                            <el-color-picker v-model="currentData.value" style="float:left;"></el-color-picker>
                            <b-form-input class="color-value" v-model="currentData.value"></b-form-input>
                        </div>
                        <div v-if="currentData.type=='image'" @click="openImageDialog">
                            <img :src="getImageUrl(currentData.value)" class="config-img"></img>
                            <i class="el-icon-upload upload-config-image"></i>
                        </div>
                    </b-col>
                </b-row>
            </form>
        </b-modal>


        <div class="col-12">
            <card title="">
                <div>
                    <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                        <el-select class="select-default mb-3" style="width: 200px" v-model="pagination.perPage" placeholder="Per page">
                            <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <el-input type="search" class="mb-3" style="width: 200px" placeholder="Search records" v-model="searchQuery" aria-controls="datatables"
                        />
                    </div>
                    <div class="add-button">
                        <button class="btn btn-primary btn-fill" @click="addConfig">Add</button>
                        <button class="btn btn-primary btn-fill import-btn" @click="openFileDialog">Import</button>
                    </div>

                    <div class="col-sm-12">
                        <el-table stripe style="width: 100%;" :data="queriedData" border>
                            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label">
                            </el-table-column>
                            <el-table-column :min-width="100" label="Readonly">
                                <template slot-scope="props">
                                    {{getReadOnly(props.row)}}
                                </template>
                            </el-table-column>
                            <el-table-column :min-width="120" fixed="right" label="Actions">
                                <template slot-scope="props">
                                    <a v-tooltip.top-center="'Edit'" class="btn-warning btn-simple btn-link" @click="handleEdit(props.$index, props.row)"><i class="fa fa-edit"></i></a>
                                    <a v-tooltip.top-center="'Delete'" class="btn-danger btn-simple btn-link" @click="handleDelete(props.$index, props.row)"><i class="fa fa-trash"></i></a>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                    <div class="">
                        <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                    </div>
                    <l-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
                    </l-pagination>
                </div>

            </card>
        </div>
        <input type="file" ref="csvInput" accept=".csv" v-bind:style="{display:'none'}" @change="importCSV($event)" />
        <input type="file" ref="configImageInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{display:'none'}" @change="uploadPicture"
        />
    </div>
</template>


<script>
import appConfigs from '../data/AppConfig';
import {
    Table,
    TableColumn,
    Select,
    Option,
    ColorPicker,
    Icon,
    Loading
} from 'element-ui'
import {
    Pagination as LPagination
    ,Spinner as LSpinner
} from 'src/components/index'
import Fuse from 'fuse.js'
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import swal from 'sweetalert2'
import * as properties from '../../../properties'

//const apps = appConfigs.config;
import * as Papa from 'papaparse';
export default {
    props: {
        apps: Array,
        platformindex: Number,
        customerindex: Number,

    },
    created(){
        this.tableData = this.apps
    },
    components: {
        LPagination,
        LSpinner,
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [ColorPicker.name]:ColorPicker,
        [Icon.name]: Icon,
        appConfigs,
        bModal,
        swal,
        bModalDirective
    },
    data() {
        return {
            currentData : {
                 "readonly":false,
                 "param":"",
                 "value":"",
                 "name":"",
                 "type": "",
                 "section": ""
            },
            configTypes: [
                {
                    "label": "Text",
                    "value": "text"
                },
                {
                    "label": "Color",
                    "value": "color"
                },
                {
                    "label": "Image",
                    "value": "image"
                },                {
                    "label": "Boolean",
                    "value": "bool"
                },
            ],
            configSectionTypes: properties.themeOptions,
            uploading:false,
            prevData : {},
            appIndex: 0,
            show: false,
            dMode:'',
            appConfigsObj: {},
            newConfigName: '',
            newConfigParam: '',
            newConfigValue: '',
            configIsEdit: false,
            ConfigIndex : 0,
            ConfigId : 0,
            tableData : [],
            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [5, 10, 25],
                total: 0
            },
            searchQuery: '',
            propsToSearch: ['configname', 'configparam', 'configvalue'],
            tableColumns: [{
                    prop: 'param',
                    label: 'Config Parameter',
                    minWidth: 150
                },
                {
                    prop: 'name',
                    label: 'Config Name',
                    minWidth: 200
                },
                {
                    prop: 'value',
                    label: 'Config Value',
                    minWidth: 200
                }
            ]
        };
    },
    computed: {

        pagedData() {
            return this.tableData.slice(this.from, this.to)
        },
        /***
         * Searches through table data and returns a paginated array.
         * Note that this should not be used for table with a lot of data as it might be slow!
         * Do the search and the pagination on the server and display the data retrieved from server instead.
         * @returns {computed.pagedData}
         */
        queriedData() {
            let result = this.tableData
            if (this.searchQuery !== '') {
                result = new Fuse(this.tableData, {keys: ['name', 'param', 'value']}).search(this.searchQuery);
                }
            this.pagination.total = result.length
            return result.slice(this.from, this.to)
        },
        to() {
            let highBound = this.from + this.pagination.perPage
            if (this.total < highBound) {
                highBound = this.total
            }
            return highBound
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1)
        },
        total() {
            this.pagination.total = this.tableData.length
            return this.tableData.length
        }
    },

    mounted() {
    },

    methods: {
        getDialogTitle() {
                if(this.dMode == "create") {
                        return "Create config";
                } else if(this.dMode == "edit") {
                        return "Edit Subscription";
                }
                return "";
        },
        exitDialog (){
             this.currentData = this.prevData;       
             this.resetCurrentDataValue();
        },
        checkURL(url) {
            return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
        },
        resetCurrentDataValue() {
            this.currentData = {
                 "readonly":false,
                 "param":"",
                 "value":"",
                 "name":"",
                 "type": "",
                 "section": ""
            }
        },
        getReadOnly(row) {
            if(row.readonly) {
                return "Yes";
            }
            return "No";
        },
        getImageUrl(url) {
            if(this.checkURL(url)) {
                return url;
            }
            return "https://ak0.picdn.net/shutterstock/videos/10376450/thumb/1.jpg";
        },
        handleEdit: function(index, row) {
          
            var id = row._id;
            // this.currentData = props;
            this.configIsEdit = true;
            // this.ConfigIndex = index;
            // this.prevData = props;
            this.dMode = "edit";
            this.currentIndex =  this.getIndexFromArray(id);
            this.currentData = JSON.parse(JSON.stringify(row));
            this.$refs.modal.show();
            // this.ConfigIndex = index;
            // this.ConfigId = props.id;
            // this.configIsEdit = true;
            // //	this.store.commit('updateConfig')
            //  alert("Edit = " + JSON.stringify(props));
            // this.newConfigName = props.name;
            // this.newConfigParam = props.param;
            // this.newConfigValue = props.value;
            // //	this.show = true;
            
        },

        handleDelete (index, row) {
         var id = row._id;
         var rIndex = this.getIndexFromArray(id);
         this.tableData.splice(rIndex,1);
        },
        addConfig: function() {
            this.dMode == "create"
            this.$refs.modal.show();

            //this.$router.push({name:'Edit Customer', params:{id:'-1'}});
        },
        getIndexFromArray(id) {
            for(var i=0;i<this.tableData.length;i++) {
                if(this.tableData[i]._id==id) return i;
            }
            return -1;
        },
        handleNewConfigOk: function(evt) {
            evt.preventDefault();
            if(this.configIsEdit == false){
                if(this.currentData) {
                    this.tableData.push(this.currentData);
                    this.resetCurrentDataValue();
                }
            }else{
                if(this.currentData) {
                    this.tableData.splice(this.currentIndex,1);
                    this.tableData.splice(this.currentIndex,0,JSON.parse(JSON.stringify(this.currentData)));    
                    this.resetCurrentDataValue();
                }
              this.configIsEdit = false

            }
           
             this.$refs.modal.hide()
        },
        openFileDialog: function(evt) {
            evt.stopPropagation();
			evt.preventDefault();
            var file = this.$refs.csvInput;
            if(file) {
                file.click();
            }
        },
        openImageDialog: function(evt) {
            evt.stopPropagation();
			evt.preventDefault();
			var file = this.$refs.configImageInput;
			if(file) {
				file.click();
			}
        },
        uploadPicture: function(evt) {
            var self = this;
			var uuid = this.$uuid.v1();
			var file = evt.target.files[0];
			var filename = file.name;
			var data = {};
			data.extension = filename.split('.').pop();
			data.fileKey = this.$store.state.customerId + '/' + uuid + "." + data.extension;
			data.contentType = file.type;
            var loadingInstance = Loading.service({ fullscreen: true });	
			this.$store.dispatch("preSignedRequest",data).then(response => {
				var signedUrl = response.signedRequest;
				var url = response.url;
				var info = {};
				info.url = signedUrl;
				info.data = file;
				this.$store.dispatch("uploadImageToS3",info).then(res => {
					self.currentData.value = url;
                    loadingInstance.close();
				}).catch(()=>{
					loadingInstance.close();
					this.$notify({
						title: 'Error',
						message: 'Uploading Image Failed!',
						type: 'warning'
                    });
				});
			}).catch(()=>{
				loadingInstance.close();
				this.$notify({
					title: 'Error',
					message: 'Uploading Image Failed!',
					type: 'warning'
				});
			});
        },
        importCSV: function(evt) {
            var self = this;
            var file = evt.target.files[0];
            this.uploading = true;
            Papa.parse(file,{header: true , complete: function(results) {
                self.uploading = false;
                self.addCsvData(results.data);
                self.$notify({
                    title: 'Success',
                    message: 'Successfully Imported!',
                    type: 'success'
                    });             
            },
            error: function(error) {
                self.uploading = false;
                self.$notify({
						title: 'Error',
						message: 'Import Failed!',
						type: 'warning'
                    });
            }
            });
        },
        addCsvData(data) {
            if(data && Array.isArray(data)) {
                var realData = data.filter(elm=> elm.param && elm.name);
                for(var i=0;i<realData.length;i++) {
                    var item = realData[i];
                    if(!item.hasOwnProperty("readonly")) {
                        item.readonly = false;
                    }
                    var index = this.tableData.findIndex(elm=> elm.param == item.param);
                    if(index>=0) {
                        this.tableData[index] = item;
                    }else {
                        this.tableData.push(item);
                    }
                }
            }
        },
        handleNewConfig: function() {

           
            // if (this.configIsEdit == true) {
            // 	var DataToStore = {
            //         CustIndex: this.customerindex,
            //         PlatformsIndex: this.platformindex,
            //         ConfigIndex : this.ConfigIndex,
            //         items: {
            //             readonly:false,
            //             param: this.newConfigParam,
            //             value: this.newConfigValue,
            //             name: this.newConfigName
            //         }
            //     }
            // 	// this.$store.commit('editConfigOfPlatform', DataToStore);
            // 	// this.configIsEdit = false; 
            // 	// this.clearNewConfigName()
            //     this.$refs.modal.hide()
            // } else {
            //     let genrateID = this.$uuid.v4();
            //     var DataToStore = {
            //         CustIndex: this.customerindex,
            //         PlatformsIndex: this.platformindex,
            //         items: {
            //             readonly:false,
            //             param: this.newConfigParam,
            //             value: this.newConfigValue,
            //             name: this.newConfigName
            //         }
            //     }
            //     this.$store.commit('addConfigTocustomer', DataToStore);
            //     //this.appConfigsObj.push(data);
            //     this.clearNewConfigName()
            //     this.$refs.modal.hide()
            // }
        }

    }
}
</script>


<style>
    .add-button {
        padding-bottom: 10px;
        padding-left: 15px;
    }
    
    .import-btn {
        float: right;
        margin-right: 15px;
    }
    .color-value {
        float: left;
        line-height: 40px;
        font-size: 14px;
        margin-left: 12px;
        width: 90px;
    }
    
    .el-color-dropdown__link-btn {
        border: none!important;
        background: none!important;
    }
    
    .el-color-dropdown__value {
        line-height: 50px;
    }
    
    .config-img {
        width: 100%;
        margin-top: 10px;
        cursor: pointer;
        background: grey;
    }
    
    .upload-config-image {
        cursor: pointer;
        font-size: 30px;
        color: grey;
        position: absolute;
        top: calc(60% - 10px);
        left: calc(50% - 10px);
    }
</style>