const apps = {
	config: [
		{
			configparam: "logo",
			configname: "Logo",
			configvalue: "http://localhost/logo.png",
			readonly: true
		},
		{
			configparam: "welcomemsg",
			configname: "Welcome Message",
			configvalue: "Welcome",
			readonly: true
		},
		{
			configparam: "authoptions",
			configname: "authotpion",
			configvalue: "4",
			readonly: true
		},
		{
			configparam: "logo",
			configname: "Logo",
			configvalue: "http://localhost/logo.png",
			readonly: true
		},
		{
			configparam: "logo",
			configname: "Logo",
			configvalue: "http://localhost/logo.png",
			readonly: true
		},
		{
			configparam: "logo",
			configname: "Logo",
			configvalue: "http://localhost/logo.png",
			readonly: true
		}
	]
}

export default apps