<template>
    <div class="row pageui-kit">
        <b-modal id="domain" ref="modal" @ok="handleOk" @cancel="" title="Enter Your Domain Name">
            
                <b-row class="mb-1">    
                    <b-col>
                        <b-form-input type="text" placeholder="Type valid Domain name" title="Domain Name" v-model="currentData.domain">
                        </b-form-input>
                    </b-col>
                </b-row>
        </b-modal>

        <div class="col-12">
            <card>
                <div class="projects-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-8 ml-auto mr-auto text-center">
                                <h2 class="title">Choose a template</h2>
                                <h5 class="description"> Here's some of our premium templates for you to choose from. If you would like to have a custom template designed just for you, contact our team to have a chat now</h5>
                            </div>
                            <!--
                            <div class="project-pills">
                                
                                <ul class="nav nav-pills nav-pills-danger">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="pill" href="#pill-1" role="tab">All categories</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#pill-2" role="tab">Marketing</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#pill-3" role="tab">Development</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#pill-4" role="tab">Productivity</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#pill-5" role="tab">Web Design</a>
                                    </li>
                                </ul>

                                
                                <div class="tab-content">
                                    <div class="tab-pane active" id="pill-1" role="tabpanel"></div>
                                    <div class="tab-pane" id="pill-2" role="tabpanel"></div>
                                    <div class="tab-pane" id="pill-3" role="tabpanel"></div>
                                    <div class="tab-pane" id="pill-4" role="tabpanel"></div>
                                    <div class="tab-pane" id="pill-5" role="tabpanel"></div>
                                    <div class="tab-pane" id="pill-6" role="tabpanel"></div>
                                </div>
                            </div>
                            -->
                        </div>
                        <div class="space-top"></div>
                        <div class="row">
                            <div class="col-md-4" v-for="(templates,index) in tableData">
                                <div class="card" data-background="image" :style="{'background-image': `url(${templates.thumbnail ? templates.thumbnail : ''})`}">
                                    <div class="card-body">
                                        <h6 class="card-category">{{templates.name}}</h6>
                                        <a href="#pablo">
                                            <h3 class="card-title">{{templates.type}}</h3>
                                        </a>
                                        <p class="card-description">
                                           {{templates.description}}
                                        </p>
                                        <br/>
                                        <a  class="btn btn-danger btn-round" @click="placeOrder(templates)">
                                            <i class="fa fa-book" aria-hidden="true"></i> Choose
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>


<script>
import { Loading } from 'element-ui';
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
export default {
    components: {
        bModal,
        bModalDirective,

    },

    data (){
        return {
            currentData : {
                domain : '',
                selectedTemplate : '',
            },
            tableData : [],
            dataOrder : {
                    "productId": "",
                    "customerId": "",
                    "templateId": "",
                    "type": "",
                    "slug": "",
                    "description": "",
                    "metaData": {},
                    "isActive": true
                }

        }

    },

    computed: {

    },

    mounted () {
        this.getProductList();
    },
    created () {
            this.currentData.domain = '';
    },
    methods: {
        getProductList: function() {
            this.$store.dispatch("getTemplateList").then((res)=>{
                if(Array.isArray(res)) {
                    this.tableData = res.filter(item=>!item._isDeleted);
                }
            });
        },
        placeOrder : function(props){
            this.currentData.selectedTemplate = props;
            this.currentData.domain = '';
            this.$refs.modal.show();

        },
        handleOk : function(){
                let loadingInstance = Loading.service({ fullscreen: true });
                this.dataOrder.slug = this.currentData.domain;    
                this.currentData.domain = '';   
                this.dataOrder.templateId = this.currentData.selectedTemplate.id;    
                this.dataOrder.type = this.currentData.selectedTemplate.type;    
                this.dataOrder.description = this.currentData.selectedTemplate.description;    
                this.dataOrder.productId = this.$route.params.id;    
                this.dataOrder.metaData = this.currentData.selectedTemplate.metaData;    
                this.dataOrder.customerId = this.$store.state.customerId;    

                if(this.dataOrder.slug){
                
                this.$store.dispatch('createNewOrder',this.dataOrder).then((res)=>{
                    loadingInstance.close();
                    window.location.href = "//webcatalyzer.appmastery.co/?id="+res.data.platformOrder.id;
                }).catch(()=>{
                    loadingInstance.close();
                });

                }else{
                    this.$refs.modal.show();    
                }
  
        }
       
    }
}
</script>
<style lang="scss">
    [class*="projects-"] {
        padding: 50px 0;
    }
    
    .pageui-kit {
        .title {
            font-weight: 300;
            margin-top: 20px;
            margin-bottom: 20px;
            min-height: 32px;
        }
        h4,
        .h4 {
            font-size: 1.6em;
            line-height: 1.2em;
        }
        h5,
        .h5 {
            font-size: 1.35em;
            line-height: 1.4em;
        }
        .nav-pills .nav-item+.nav-link {
            margin-left: 0;
        }
        .nav-pills .nav-item .nav-link {
            border: 1px solid #66615B;
            border-radius: 0;
            color: #66615B;
            font-weight: 600;
            margin-left: -1px;
            padding: 10px 25px;
        }
        .nav-pills .nav-item.active .nav-link,
        .nav-pills .nav-item.active .nav-link:hover,
        .nav-pills .nav-item.active .nav-link:focus {
            background-color: #66615B;
            color: #FFFFFF;
        }
        .nav-pills .nav-item:first-child .nav-link {
            border-radius: 30px 0 0 30px !important;
            margin: 0;
        }
        .nav-pills .nav-item:last-child .nav-link {
            border-radius: 0 30px 30px 0 !important;
        }
        .nav-pills .nav-item .nav-link.active {
            background-color: #66615B;
            color: #FFFFFF;
        }
        .nav-pills-primary .nav-item .nav-link {
            border: 1px solid #51cbce !important;
            color: #51cbce !important;
        }
        .nav-pills-primary .nav-item .nav-link.active {
            border: 1px solid #51cbce !important;
            color: #FFFFFF !important;
        }
        .nav-pills-danger .nav-item .nav-link {
            border: 1px solid #f5593d !important;
            color: #f5593d !important;
        }
        .nav-pills-danger .nav-item .nav-link.active {
            border: 1px solid #f5593d !important;
            color: #FFFFFF !important;
        }
        .nav-pills-info .nav-item .nav-link {
            border: 1px solid #51bcda !important;
            color: #51bcda !important;
        }
        .nav-pills-info .nav-item .nav-link.active {
            border: 1px solid #51bcda !important;
            color: #FFFFFF !important;
        }
        .nav-pills-success .nav-item .nav-link {
            border: 1px solid #6bd098 !important;
            color: #6bd098 !important;
        }
        .nav-pills-success .nav-item .nav-link.active {
            border: 1px solid #6bd098 !important;
            color: #FFFFFF !important;
        }
        .nav-pills-warning .nav-item .nav-link {
            border: 1px solid #fbc658 !important;
            color: #fbc658 !important;
        }
        .nav-pills-warning .nav-item .nav-link.active {
            border: 1px solid #fbc658 !important;
            color: #FFFFFF !important;
        }
        .nav .nav-item .nav-link:hover,
        .nav .nav-item .nav-link:focus {
            background-color: transparent;
        }
        .nav-pills-default .nav-item.show .nav-link,
        .nav-pills-default .nav-link.active {
            background-color: #66615B !important;
        }
        .nav-pills-primary .nav-item.show .nav-link,
        .nav-pills-primary .nav-link.active {
            background-color: #51cbce !important;
        }
        .nav-pills-info .nav-item.show .nav-link,
        .nav-pills-info .nav-link.active {
            background-color: #51bcda !important;
        }
        .nav-pills-warning .nav-item.show .nav-link,
        .nav-pills-warning .nav-link.active {
            background-color: #fbc658 !important;
        }
        .nav-pills-success .nav-item.show .nav-link,
        .nav-pills-success .nav-link.active {
            background-color: #6bd098 !important;
        }
        .nav-pills-danger .nav-item.show .nav-link,
        .nav-pills-danger .nav-link.active {
            background-color: #f5593d !important;
        }
        .card[data-background="image"] {
            background-position: center center;
            background-size: cover;
            text-align: center;
        }
        .card[data-background="image"] .card-body {
            position: relative;
            z-index: 2;
            min-height: 280px;
            padding-top: 40px;
            padding-bottom: 40px;
            max-width: 440px;
            margin: 0 auto;
            text-align: center;
        }
        .card[data-background="image"] .card-title {
            color: #FFF;
            margin-top: 10px;
        }
        .card[data-background="image"]:after {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            display: block;
            left: 0;
            top: 0;
            content: "";
            background-color: rgba(0, 0, 0, 0.56);
            border-radius: 12px;
        }
        .card[data-background="image"] .card-description,
        .card[data-background="color"] .card-description,
        .card[data-color] .card-description {
            color: rgba(255, 255, 255, 0.8);
        }
        .card[data-background="image"] .card-title,
        .card[data-background="image"] .card-category,
        .card[data-background="image"] .card-text,
        .card[data-background="image"] .card-body,
        .card[data-background="image"] .card-footer,
        .card[data-background="image"] .stats,
        .card[data-background="image"] small,
        .card[data-background="image"] [class*="text-"],
        .card[data-background="image"] ul li b,
        .card[data-background="image"] a:not(.btn),
        .card[data-background="color"] .card-title,
        .card[data-background="color"] .card-category,
        .card[data-background="color"] .card-text,
        .card[data-background="color"] .card-body,
        .card[data-background="color"] .card-footer,
        .card[data-background="color"] .stats,
        .card[data-background="color"] small,
        .card[data-background="color"] [class*="text-"],
        .card[data-background="color"] ul li b,
        .card[data-background="color"] a:not(.btn),
        .card[data-color] .card-title,
        .card[data-color] .card-category,
        .card[data-color] .card-text,
        .card[data-color] .card-body,
        .card[data-color] .card-footer,
        .card[data-color] .stats,
        .card[data-color] small,
        .card[data-color] [class*="text-"],
        .card[data-color] ul li b,
        .card[data-color] a:not(.btn) {
            color: #FFFFFF !important;
        }
        .card[data-background="image"] a:not(.btn):hover,
        .card[data-background="image"] a:not(.btn):focus,
        .card[data-background="color"] a:not(.btn):hover,
        .card[data-background="color"] a:not(.btn):focus,
        .card[data-color] a:not(.btn):hover,
        .card[data-color] a:not(.btn):focus {
            color: #FFFFFF;
        }
        .card[data-background="image"] .card-icon i,
        .card[data-background="color"] .card-icon i,
        .card[data-color] .card-icon i {
            color: #FFFFFF;
            border: 2px solid rgba(255, 255, 255, 0.3);
        }
        .card[data-background="image"].card-with-border:after,
        .card[data-background="color"].card-with-border:after,
        .card[data-color].card-with-border:after {
            border: 1px solid rgba(255, 255, 255, 0.45);
        }
        .card[data-background="image"] .filter {
            opacity: 0.55;
            filter: alpha(opacity=55);
        }
        .card[data-background="image"]:hover .filter {
            opacity: 0.75;
            filter: alpha(opacity=75);
        }
        .projects-1 .project-pills {
            margin: 20px auto;
        }
    }
    
    .projects-1 {
        .card {
            border-radius: 12px;
            box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
            background-color: #FFFFFF;
            color: #333333;
            margin-bottom: 20px;
            position: relative;
            z-index: 1;
            border: 0 none;
            -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
        }
        .card:not(.card-plain):hover {
            box-shadow: 0px 12px 19px -7px rgba(0, 0, 0, 0.3);
           
        }
        .card-category {
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 0px;
        }

        .description {
           /* color: #9A9A9A;*/
            color: #868686;
            font-weight: 400;
        }
    }
</style>