<template>
  <auth-layout pageClass="login-page">
    <b-modal id="newEmailVerifyModal" ref="modal" @ok="handleNewEmailVerifyOk" @shown="clearNewEmailVerify"
      title="Enter your Email">
      <form @submit.stop.prevent="handleNewEmailVerify">
        <b-form-input type="text" placeholder="Enter valid userid" v-model="newEmailVerify">
        </b-form-input>
      </form>
    </b-modal>





    <div class="verifiyuser" v-if="verifying">{{ name }} </div>
    <div class="verifiyuser" v-if="IsVerifiymsg">{{ verifiedmsg }} </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import axios from '../../../service/axios'
import swal from 'sweetalert2'
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'


export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    bModal,
    bModalDirective,
  },
  data() {
    return {
      name: 'verifying your account ....',
      idParamUid: this.$route.query.uid,
      idParamToken: this.$route.query.token,
      verifying: false,
      newEmailVerify: '',
      verifiedmsg: 'your email is verified sucessfully',
      IsVerifiymsg: false,
    }
  },

  methods: {

    handleNewEmailVerifyOk: function (evt) {
      this.verifying = true
      if (!this.newEmailVerify) {
        swal("Please enter a email id")
      } else {
        this.handleVerifyuser()
      }

    },

    clearNewEmailVerify: function () {
      //  this.newEmailVerify = ''
    },

    handleNewEmailVerify: function () {

      // this.$refs.modal.hide()
    },

    handleVerifyuser() {
      self = this

      axios.defaults.baseURL = this.$store.state.base_url;
      var makeUrl = "/api/v1/admin/confirm/" + this.newEmailVerify + "?token=" + this.idParam
      axios({
        method: 'GET',
        url: "/api/v1/admin/confirm/" + self.newEmailVerify + "?token=" + self.idParam,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {

        })
        .catch(function (error, response) {
        })
    }
  },
  beforeDestroy() { },
  mounted() {
    //.this.$refs.modal.show();
    self = this
    self.verifying = true
    axios.defaults.baseURL = this.$store.state.base_url;
    var makeUrl = "/api/v1/admin/confirm/" + this.idParamUid + "/?token=" + this.idParamToken
    axios({
      method: 'GET',
      url: makeUrl,
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(function (response) {
        self.verifying = false;
        self.IsVerifiymsg = true;
        //response = response.data;
        setTimeout(function () {
          self.$router.push({ name: 'Login' });
        }, 3000);

      })
      .catch(function (error, response) {
        self.verifying = false;
        self.IsVerifiymsg = true;
        self.verifiedmsg = 'something went wrong.... :( '
        setTimeout(function () {

          self.$router.push({ name: 'Login' });
        }, 3000);
      })






  },
  created() {

    // this.$refs.modal.show()
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.verifiyuser {
  width: 100%;
  text-align: center;
  font-size: 2em;
  color: #FFFF;
  margin: 0 auto;
}
</style>
