<template>
  <div class="row">
    <DeleteConfirmation :currentDeleteMethod="deleteUser" @delete="$event()" />
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Administrators
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-3 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>

          <div class="mx-2 mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addUser"
              >Add User
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-sm-12 p-3">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="180" label="Phone">
                <template slot-scope="props">
                  {{ getPhone(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="180" label="Last Activity">
                <template slot-scope="props">
                  {{ getLastActive(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-b-modal.confirmDelete
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import moment from "moment";
import { FormGroupInput as FgInput } from "src/components";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },

  data() {
    return {
      customers: {},
      user: {
        name: "",
        email: "",
        password: "",
        cnfpassword: "",
        phone: ""
      },
      selectedDeleteId: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["email", "customer"],
      tableColumns: [
        {
          prop: "id",
          label: "ID",
          minWidth: 150
        },
        {
          prop: "email",
          label: "Email",
          minWidth: 200
        },
        {
          prop: "customerlistCSV",
          label: "Customers",
          minWidth: 300
        }
      ],
      fuseSearch: null
    };
  },

  computed: {
    tableData() {
      var td = this.$store.state.admin.adminUsers.listAll;
      for (var i = 0; i < td.length; i++) {
        td[i]["customerlistCSV"] = this.createListwithCSV(td[i].customers);
      }
      return td;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {
    var self = this;
    this.$store.dispatch("getListOfAdmins").then(data => {
      this.$store.dispatch("getCustomers");
    });
  },

  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["email", "customer"] });
  },

  methods: {
    exitDialog() {
      this.resetCurrentDataValue();
    },
    handleEdit: function(index, props) {
      var pId = props.id;
      this.$router.push({ name: "Edit Admin", params: { id: pId } });
      //this.$router.push({name:'Edit Admin', params:{id:index}});
    },

    handleDelete: function(index, props) {
      if (props) {
        this.selectedDeleteId = props.id;
      }
    },
    deleteUser() {
      if (this.selectedDeleteId) {
        this.$store
          .dispatch("deleteUserById", this.selectedDeleteId)
          .then(data => {
            this.$store.dispatch("getListOfAdmins");
          })
          .catch(() => {});
      }
    },
    resetCurrentDataValue() {
      this.user = {
        name: "",
        email: "",
        password: "",
        cnfpassword: "",
        phone: ""
      };
      //	this.customers.multiple = '';
    },
    addUser: function() {
      this.$router.push({ name: "Edit Admin", params: { id: -1 } });
    },
    createListOfCustomer(array) {
      var ar = [];
      for (var i = 0; array.length; i++) {
        ar.push(array[i].id);
      }
      return ar;
    },
    createListwithCSV(CustArr) {
      var str = "";
      for (var i = 0; i < CustArr.length; i++) {
        str += " " + CustArr[i].name + ", ";
      }
      return str;
    },
    getLastActive: function(row) {
      if (row.updatedAt) {
        return moment(row.updatedAt, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    getPhone: function(row) {
      if (row.phone) {
        return row.phone;
      } else {
        return "NA";
      }
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleVideo {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
</style>
