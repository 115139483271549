<template>
  <auth-layout pageClass="lock-page">
    <div class="content">
      <form method="#" action="#">
        <div class="user-profile">
          <div class="author">
            <img class="avatar" src="static/img/default-avatar.png" alt="...">
          </div>
          <h4>{{getUserName()}}</h4>
          <fg-input type="password" placeholder="Enter Password" v-model="password"></fg-input>
          <button type="button" class="btn btn-round btn-info btn-wd" @click="unlockScreen">Unlock</button>
        </div>
      </form>
    </div>
  </auth-layout>
</template>
<script>
  import AuthLayout from './AuthLayout.vue'
  import UserService from '../../../service/userService'
  import AuthService from '../../../service/authService'
  export default {
    components: {
      AuthLayout
    },
    methods: {
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      getUserName() {
        return UserService.getUserName();
      },
      unlockScreen() {
        var req = {};
        var shajs = require('sha.js');
        var email = UserService.getUserEmail();
        req.email = email;
        req.password = shajs('sha256').update(this.password).digest('hex');
        this.$store.dispatch("login",req).then(response=> {
          var token = response.loginToken;
          AuthService.setAuthData(token);
          this.$router.push({name:'Videos'});
        }).catch(()=>{

        });
      }
    },
    beforeDestroy () {
      this.closeMenu()
    },
    data (){
      return {
        "password": ""
      }
    }
  }
</script>
<style>
</style>
