<template>
  <div class="row">
    <DeleteConfirmation :currentDeleteMethod="deleteVideo" @delete="$event()" />
    <ManualSyncVideosConfirmation @manualSync="manualSyncVideo" />
    <!--       <b-modal ref="videoPlayDialog" size="lg" :title="selectedVideoTitle" @hidden="onVideoPlayerClose">
         <video-player class="vjs-custom-skin" ref="videoPlayer" :options="playerOptions" @ready="playerReadied"></video-player>
    </b-modal>-->
    <!--       <b-modal ref="videoPlayDialog" size="lg" :title="selectedVideoTitle" @hidden="onVideoPlayerClose">
         <video-player :options="playerOptions"/>
    </b-modal>-->
    <b-modal ref="videoPlayDialog" size="lg" :title="selectedVideoTitle" @hidden="onVideoPlayerClose">
      <video ref="videoPlayer" class="video-js vjs-custom-skin"></video>
    </b-modal>
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleVideo mb-2">
            Videos
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center  searchContainer">

          <div class="mx-2 mt-1" v-if="showManualSync">
            <el-button type="primary" v-b-modal.confirmManualSyncVideos>Manual Sync Videos
            </el-button>
          </div>
          <div>
            <el-input type="text" style="width: 200px; " placeholder="Search records" ref="searchKey"
              prefix-icon="el-icon-search" v-model="searchQuery" @input="debounceInput" aria-controls="datatables"
              @clear="clearSearch" clearable />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select class="select-default ml-1" v-model="pagination.perPage" style="width: 80px; "
              placeholder="Per page" :change="changeperpage(pagination.perPage)">
              <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>

          <div class="mx-2 mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addUser">Add Video
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-sm-12 p-3">
            <el-table class="table-bigboy" style="width: 100%;" :data="queriedData" border>
              <el-table-column fixed min-width="200" label="Thumbnail">
                <template slot-scope="{ row }">
                  <div class="thumbnailImg" :style="{
                    backgroundImage: 'url(' + getItem('thumb', row) + ')'
                  }"></div>
                </template>
              </el-table-column>
              <el-table-column min-width="150" label="Title">
                <template slot-scope="{ row }">
                  <p>{{ getItem("name", row) }}</p>
                </template>
              </el-table-column>

              <el-table-column width="230" label="Catalogue">
                <template slot-scope="{ row }">
                  <p>{{ getItem("catalogues", row) }}</p>
                </template>
              </el-table-column>

              <el-table-column min-width="120" label="Status">
                <template slot-scope="props">
                  <span @click="handleSwitch(props.$index, props.row)">
                    <l-switch :value="getIsSDiableTag(props.row)" class="status"></l-switch>
                  </span>
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Actions">
                <template slot-scope="props">
                  <a class="btn-success btn-simple btn-link" v-tooltip.top-center="'Watch video'"
                    @click="handleWatchVideo(props.$index, props.row)">
                    <i class="fa fa-image"></i>
                  </a>
                  <a v-tooltip.top-center="'Edit'" class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a v-tooltip.top-center="'Delete'" v-b-modal.confirmDelete class="btn-danger btn-simple btn-link"
                    @click="handleDelete(props.$index, props.row)">
                    <i class="fa fa-trash"></i>
                  </a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class>
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of
              {{ pagination.total }} entries
            </p>
          </div>
          <l-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total" @input="clickCallback(pagination.currentPage)"></l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import ManualSyncVideosConfirmation from "src/pages/Dashboard/Components/ManualSyncVideosConfirmation.vue";
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import { debounce } from "debounce";
//import VideoPlayer from "@/pages/Dashboard/Videos/VideoPlayer.vue";
import videojs from "video.js";
window.videojs = videojs;
//window.videojs.options.flash.swf = 'videojs-swf/dist/video-js.swf'
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    LSpinner,
    DeleteConfirmation,
    ManualSyncVideosConfirmation
  },
  data() {
    return {
      spinnerTip: "Fetching videos..",
      uploading: false,
      player: null,
      playerOptions: {
        playbackRates: [0.5, 1, 1.5, 2],
        height: "360",
        sources: [
          {
            withCredentials: false,
            type: "application/x-mpegURL",
            src: ""
          }
        ],
        poster: "",
        html5: {
          hls: {
            withCredentials: false
          }
        }
      },
      selectedUrl: "",
      searchQuery: "",
      propsToSearch: ["title", "tags"],
      fuseSearch: null,
      selectedVideoTitle: "",
      allVideos: [],
    };
  },
  computed: {
    pagination() {
      return this.$store.getters.getVideoPagination;
    },
    tableData() {
      //testing cicd
      //alert("updating tbl data")
      var obj = this.$store.state.videos.videoList;
      for (var i = 0; i < obj.length; i++) {
        var title = obj[i].languages[0]["title"];
        obj[i]["title"] = title;
      }
      return obj;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      // if (this.searchQuery !== "") {
      //   result = new Fuse(this.tableData, {
      //     keys: ["name", "title", "languages.description"]
      //   }).search(this.searchQuery);
      //   this.pagination.total = result.length;
      // }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (highBound > this.pagination.perPage) {
        highBound = this.pagination.total;
      }

      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    showManualSync(){
      if (this.$store.state.customerId == "63a0ad6824b9fb001975aff3" || this.$store.state.customerId == "63be794d11d85f001920b276") {
        return true;
      }
      else {
        return false;
      }
    }
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, function onPlayerReady() {
    });
  },
  beforeDestroy() {
    if (this.player) {
      //this.options = null;
      this.player.dispose();
    }
  },
  created() {
    this.$store.commit("setVideoSearchText", "");
    this.$store.commit("setLoader", {
      show: true,
      text: "Fetching Videos..."
    });
    this.$store
      .dispatch("getVideoList")
      .then(() => {
        this.$store.commit("setLoader", { show: false, text: "" });
      })
      .catch(() => {
        this.$store.commit("setLoader", { show: false, text: "" });
      });

  },
  methods: {
    clickCallback: function (pagNum) {
      if (this.$store.state.videos.inputPage != pagNum) {
        this.$store.commit("setVideoInputPage", pagNum);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Videos..."
        });
        this.$store.dispatch("getVideoList").then(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        });
        return true;
      }
    },
    changeperpage: function (perPage) {
      if (this.$store.state.videos.inputSize != perPage) {
        this.$store.commit("setVideoInputPage", 1);
        this.$store.commit("setVideoInputPerpage", perPage);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Videos..."
        });
        this.$store
          .dispatch("getVideoList")
          .then(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          })
          .catch(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          });
        return true;
      }
    },
    initilizePlayer: function (options) {
      self = this;
      this.playerOptions = options;
      this.player.src({
        src: options.sources[0].src,
        type: "application/x-mpegURL",
        withCredentials: false
      });
      if (options.sources[0].src == "") {
        this.player.reset();
      } else {
        this.player.play();
      }
      this.$refs.videoPlayDialog.show();
    },
    disposePlayer: function () {
      this.$refs.videoPlayDialog.hide();
      if (this.player) {
        //this.player.stop();
        this.playerOptions = null;
        //this.player = null;
      }
    },
    handleEdit: function (index, props) {
      this.$router.push({ name: "Edit Video", params: { id: props.id } });
    },
    // playerReadied: function(player){
    // 	var hls = player.tech({IWillNotUseThisInPlugins: true}).hls
    // 	player.tech_.hls.xhr.beforeRequest = function(options){
    // 		return options;
    // 	}
    // },
    handleWatchVideo: function (index, row) {
      this.selectedVideoTitle = this.getItem("name", row);
      this.playerOptions.poster = this.getItem("thumb", row);
      this.selectedId = this.getItem("id", row);
      self = this;
      this.$store
        .dispatch("getPlayBackUrl", this.selectedId)
        .then(data => {
          var response = data.response;
          this.selectedUrl = self.geturl(response);
          var playerOptionsTosend = {
            playbackRates: [0.5, 1, 1.5, 2],
            height: "360",
            autoplay: false,
            sources: [
              {
                withCredentials: true,
                type: "application/x-mpegURL",
                src: this.selectedUrl
              }
            ],
            poster: this.getItem("thumb", row),
            flash: { hls: { withCredentials: true } },
            html5: { hls: { withCredentials: true } }
          };
          this.initilizePlayer(playerOptionsTosend);
          // this.playerOptions.sources[0]['src'] = this.selectedUrl;
        })
        .catch(() => {
          var playerOptionsTosend = {
            playbackRates: [0.5, 1, 1.5, 2],
            height: "360",
            autoplay: false,
            sources: [
              {
                withCredentials: true,
                type: "application/x-mpegURL",
                src: ""
              }
            ],
            poster: this.getItem("thumb", row),
            flash: { hls: { withCredentials: true } },
            html5: { hls: { withCredentials: true } }
          };
          this.initilizePlayer(playerOptionsTosend);
          //			self.$refs.videoPlayDialog.show();
        });
    },
    handleSwitch: function (index, props) {
      if (props.id) {
        if (index >= 0) {
          var data = this.$store.state.videos.videoList[index];
          var req = {};
          if (data.isActive == false) {
            data.isActive = true;
          } else {
            data.isActive = false;
          }
          var DataToSend = {
            isActive: data.isActive,
            id: data.id
          };
          req.id = data.id;
          req.data = DataToSend;
          self = this;
          if (data.id) {
            this.$store
              .dispatch("updateVideo", req)
              .then(response => {
                this.$notify({
                  title: "Success",
                  message: "Video Updated Successfully!",
                  type: "success"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Video Failed!",
                  type: "warning"
                });
                return;
              });
          }
          this.$store.disatch("getVideoList");
        }
      }
    },
    onVideoPlayerClose: function () {
      this.playerOptions.sources[0].src = "";
      //this.playerOptions.poster = this.getItem('thumb',row);
      this.player.pause();
      //this.disposePlayer();
      this.player.src({
        src: "",
        type: "application/x-mpegURL",
        withCredentials: false
      });
      this.$refs.videoPlayDialog.hide();
    },
    deleteVideo: function () {
      if (this.deleteId) {
        this.$store
          .dispatch("deleteVideoById", this.deleteId)
          .then(response => { })
          .catch(() => { });
      }
    },
    handleDelete: function (index, props) {
      this.deleteId = props.id;
    },
    addUser: function () {
      this.$router.push({ name: "Create Video" });
    },
    getIsSDiableTagText: function (item) {
      if (item.isActive == true) {
        return "TRUE";
      } else {
        return "FALSE";
      }
    },
    getIsSDiableTag: function (item) {
      if (item.isActive == true) {
        return true;
      } else {
        return false;
      }
    },
    geturl: function (res) {
      return res["url"];
    },
    getItem: function (key, row) {
      var languages = row.languages;
      var language = languages[0];
      if (language) {
        switch (key) {
          case "name":
            return language["title"];
            break;
          case "id":
            return row["id"];
            break;
          case "description":
            return language["description"];
            break;
          case "thumb":
            return language["thumbnailLink"]["source"];
            break;
          case "catalogues":
            var catalogues = row.catalogues;
            var str = "";
            if (catalogues && Array.isArray(catalogues)) {
              for (var i = 0; i < catalogues.length; i++) {
                var cat = catalogues[i];
                if (i == 0) {
                  str = cat.name;
                } else {
                  str = str + " , " + cat.name;
                }
              }
            }
            return str;
            break;
        }
      }
      return "";
    },
    searchVideo: function () {
      this.$store.commit("setLoader", {
        show: true,
        text: "Searching"
      });
      this.$store.commit("setVideoInputPage", 1);
      this.$store.commit("setVideoSearchText", this.$refs.searchKey.value);
      this.$store
        .dispatch("getVideoList")
        .then(response => {
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
      return true;
    },
    clearSearch: function () {
      if (this.$store.state.videos.searchKey != "") {
        this.$store.commit("setVideoSearchText", "");
        this.$store.dispatch("getVideoList");
      }
    },
    debounceInput: debounce(function () {
      this.searchVideo();
    }, 800),
    
    manualSyncVideo() {
      this.$store
        .dispatch("manualSyncVideo")
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Sync started!",
            type: "success"
          });
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Sync!",
            type: "warning"
          });
        });

    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleVideo {
  margin-left: 15px;
  font-weight: 500;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}

.searchContainer {
  position: relative;
  left: 7px;
}

.thumbnailImg {
  height: 100px;
  width: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.16));
}

.vjs-custom-skin>.video-js {
  width: 100%;
  font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}

.vjs-custom-skin>.video-js .vjs-menu-button-inline.vjs-slider-active,
.vjs-custom-skin>.video-js .vjs-menu-button-inline:focus,
.vjs-custom-skin>.video-js .vjs-menu-button-inline:hover,
.video-js.vjs-no-flex .vjs-menu-button-inline {
  width: 10em;
}

.vjs-custom-skin>.video-js .vjs-controls-disabled .vjs-big-play-button {
  display: none !important;
}

.vjs-custom-skin>.video-js .vjs-control {
  width: 3em;
}

.vjs-custom-skin>.video-js .vjs-control.vjs-live-control {
  width: auto;
  padding-left: 0.5em;
  letter-spacing: 0.7px;
}

.vjs-custom-skin>.video-js .vjs-menu-button-inline:before {
  width: 1.5em;
}

.vjs-menu-button-inline .vjs-menu {
  left: 3em;
}

.vjs-paused.vjs-has-started.vjs-custom-skin>.video-js .vjs-big-play-button,
.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button {
  display: block;
}

.vjs-custom-skin>.video-js .vjs-load-progress div,
.vjs-seeking .vjs-big-play-button,
.vjs-waiting .vjs-big-play-button {
  display: none !important;
}

.vjs-custom-skin>.video-js .vjs-mouse-display:after,
.vjs-custom-skin>.video-js .vjs-play-progress:after {
  padding: 0 0.4em 0.3em;
}

.video-js.vjs-ended .vjs-loading-spinner {
  display: none;
}

.video-js.vjs-ended .vjs-big-play-button {
  display: block !important;
}

.video-js.vjs-ended .vjs-big-play-button,
.video-js.vjs-paused .vjs-big-play-button,
.vjs-paused.vjs-has-started.vjs-custom-skin>.video-js .vjs-big-play-button {
  display: block;
}

.vjs-custom-skin>.video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-left: -1.5em;
  margin-top: -1em;
}

.vjs-custom-skin>.video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  /*border-radius: 50%;*/
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
}

.video-js:hover .vjs-big-play-button,
.vjs-custom-skin>.video-js .vjs-big-play-button:focus,
.vjs-custom-skin>.video-js .vjs-big-play-button:active {
  background-color: rgba(36, 131, 213, 0.9);
}

.vjs-custom-skin>.video-js .vjs-loading-spinner {
  border-color: rgba(36, 131, 213, 0.8);
}

.vjs-custom-skin>.video-js .vjs-control-bar2 {
  background-color: #000000;
}

.vjs-custom-skin>.video-js .vjs-control-bar {
  /*background-color: rgba(0,0,0,0.3) !important;*/
  color: #ffffff;
  font-size: 14px;
}

.vjs-custom-skin>.video-js .vjs-play-progress,
.vjs-custom-skin>.video-js .vjs-volume-level {
  background-color: #2483d5;
}

.vjs-custom-skin>.video-js .vjs-play-progress:before {
  top: -0.3em;
}

.vjs-custom-skin>.video-js .vjs-progress-control:hover .vjs-progress-holder {
  font-size: 1.3em;
}

.vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
  left: 0em;
}

.vjs-custom-skin>.video-js .vjs-menu li {
  padding: 0;
  line-height: 2em;
  font-size: 1.1em;
  font-family: "PingFang SC", "Helvetica Neue", "Hiragino Sans GB", "Segoe UI",
    "Microsoft YaHei", "微软雅黑", sans-serif;
}

.vjs-custom-skin>.video-js .vjs-time-tooltip,
.vjs-custom-skin>.video-js .vjs-mouse-display:after,
.vjs-custom-skin>.video-js .vjs-play-progress:after {
  border-radius: 0;
  font-size: 1em;
  padding: 0;
  width: 3em;
  height: 1.5em;
  line-height: 1.5em;
  top: -3em;
}

.vjs-custom-skin>.video-js .vjs-menu-button-popup .vjs-menu {
  width: 5em;
  left: -1em;
}

.vjs-custom-skin>.video-js .vjs-menu-button-popup.vjs-volume-menu-button-vertical .vjs-menu {
  left: 0;
}

/* .vjs-custom-skin > .video-js .vjs-control-bar .vjs-resolution-button .vjs-menu {
  order: 4;
} */
/*排序顺序*/
.vjs-custom-skin>.video-js .vjs-control-bar .vjs-play-control {
  order: 0;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-time-control {
  min-width: 1em;
  padding: 0;
  margin: 0 0.1em;
  text-align: center;
  display: block;
  order: 1;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.2em;
  line-height: 2.4;
}

.vjs-custom-skin>.video-js .vjs-progress-control.vjs-control {
  order: 2;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-volume-menu-button {
  order: 3;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-resolution-button {
  order: 4;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-resolution-button .vjs-resolution-button-label {
  display: block;
  line-height: 3em;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-playback-rate {
  order: 5;
}

.vjs-custom-skin>.video-js .vjs-control-bar .vjs-fullscreen-control {
  order: 6;
}

.vjs_video_3-dimensions {
  width: 100% !important;
  height: 360px;
}

.video-js {
  width: 100% !important;
}
</style>
