var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('DeleteConfirmation',{attrs:{"currentDeleteMethod":_vm.deleteProduct},on:{"delete":function($event){return $event()}}}),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('card',{attrs:{"title":"Products"}},[_c('div',[_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"},[_c('el-select',{staticClass:"select-default mb-3",staticStyle:{"width":"200px"},attrs:{"placeholder":"Per page"},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-default",attrs:{"label":item,"value":item}})}),1),_vm._v(" "),_c('el-input',{staticClass:"mb-3",staticStyle:{"width":"200px"},attrs:{"type":"search","placeholder":"Search records","aria-controls":"datatables"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_vm._v(" "),_c('div',{staticClass:"add-button"},[_c('button',{staticClass:"btn btn-primary btn-fill",on:{"click":_vm.addProduct}},[_vm._v("\n            Add\n          ")])]),_vm._v(" "),_c('div',{staticClass:"col-sm-12"},[_c('el-table',{staticClass:"table-bigboy",staticStyle:{"width":"100%"},attrs:{"data":_vm.queriedData,"border":""}},[_c('el-table-column',{attrs:{"min-width":"180","label":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.name)+"\n              ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":"100","label":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.type)+"\n              ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":"300","label":"Description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.description)+"\n              ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":"100","label":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v("\n                "+_vm._s(row.price)+"\n              ")]}}])}),_vm._v(" "),_c('el-table-column',{attrs:{"min-width":120,"fixed":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Edit'),expression:"'Edit'",modifiers:{"top-center":true}}],staticClass:"btn-warning btn-simple btn-link",on:{"click":function($event){return _vm.handleEdit(props.$index, props.row)}}},[_c('i',{staticClass:"fa fa-edit"})]),_vm._v(" "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top-center",value:('Delete'),expression:"'Delete'",modifiers:{"top-center":true}},{name:"b-modal",rawName:"v-b-modal.confirmDelete",modifiers:{"confirmDelete":true}}],staticClass:"btn-danger btn-simple btn-link",on:{"click":function($event){return _vm.handleDelete(props.$index, props.row)}}},[_c('i',{staticClass:"fa fa-trash"})])]}}])})],1)],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 d-flex justify-content-center justify-content-sm-between flex-wrap",attrs:{"slot":"footer"},slot:"footer"},[_c('div',{},[_c('p',{staticClass:"card-category"},[_vm._v("\n            Showing "+_vm._s(_vm.from + 1)+" to "+_vm._s(_vm.to)+" of "+_vm._s(_vm.total)+" entries\n          ")])]),_vm._v(" "),_c('l-pagination',{staticClass:"pagination-no-border",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.pagination.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])],1)],1)}
var staticRenderFns = []
export { render, staticRenderFns }