<template>
  <div class="largepopup">
    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive table-full-width">
          <el-table ref="multipleTable" :data="queriedData">
            <el-table-column max-width="50">
              <template slot-scope="props">
                <l-checkbox
                  :checked="isChecked(props.row)"
                  @input="checkChanged($event, props.row)"
                ></l-checkbox>
              </template>
            </el-table-column>
            <el-table-column label="Name">
              <template slot-scope="props">
                {{ getName(props.row) }}
              </template>
            </el-table-column>
            <el-table-column label="Type">
              <template slot-scope="props">
                {{ getType(props.row) }}
              </template>
            </el-table-column>
            <el-table-column
              v-if="type == 'Playlist' || type == 'Season'"
              label="Thumbnail"
            >
              <template slot-scope="props">
                <img :src="getImageUrl(props.row)" style="max-height: 60px;" />
              </template>
            </el-table-column>
          </el-table>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            style="margin-top:20px"
          >
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox as LCheckbox } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Table, TableColumn } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import countries from "../../../config/countries.json";

export default {
  props: ["data", "type"],
  components: {
    LCheckbox,
    bModal,
    bModalDirective,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LPagination
  },
  created() {
  },
  mounted() {
    this.setOrderData();
  },
  data() {
    return {
      searchQuery: "",
      cData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
    };
  },
  watch: {
    data: function() {
      this.setOrderData();
    }
  },
  computed: {
    videoList() {
      return this.$store.state.videos.videoList;
    },
    catalogueList() {
      return this.$store.getters.getCatalogueList;
    },
    tableData() {
      var data = [];
      var tableData = [];
      switch (this.type) {
        case "Playlist":
          data = this.videoList;
          break;
        case "Category":
          data = this.catalogueList.filter(
            cat =>
              cat.type == "Playlist" ||
              cat.type == "Series" ||
              cat.type == "Category"
          );
          break;
        case "Root":
          data = this.catalogueList.filter(
            cat =>
              cat.type == "Playlist" ||
              cat.type == "Series" ||
              cat.type == "Category"
          );
          break;
        case "Series":
          data = this.catalogueList.filter(cat => cat.type == "Season");
          break;
        case "Season":
          data = this.videoList;
          break;
      }
      for (var i = 0; i < data.length; i++) {
        var id = data[i].id;
        var ele = this.data.find(c => c.childId == id);
        if (!ele) {
          tableData.push(data[i]);
        }
      }
      return tableData;
    },
    queriedData() {
      let result = this.tableData;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  methods: {
    
    getName(row) {
      if (row.name) return row.name;
      var video = this.videoList.find(video => video.id == row.id);
      if (video && video.languages && video.languages[0]) {
        return video.languages[0].title;
      }
      return "";
    },
    getImageUrl(row) {
      var video = this.videoList.find(video => video.id == row.id);
      if (video && video.languages && video.languages[0]) {
        return video.languages[0]["thumbnailLink"]["source"];
      }
      return "";
    },
    getType(row) {
      return row.type;
    },
    getCheckedData() {
      return this.cData;
    },
    setOrderData() {
      if (this.data && Array.isArray(this.data))
        this.cData = JSON.parse(JSON.stringify(this.data));
    },
    isChecked(row) {
      var ele = this.cData.find(c => c.childId == row.id);
      if (ele) return true;
      return false;
    },
    checkChanged(val, row) {
      if (val) {
        var ele = this.cData.find(c => c.childId == row.id);
        if (!ele) var item = {};
        item.childId = row.id;
        item.childType = "Video";
        item.order = this.cData.length + 1;
        this.cData.push(item);
      } else {
        var index = this.cData.findIndex(c => c.childId == row.id);
        if (index >= 0) {
          this.cData.splice(index, 1);
        }
      }
    }
  }
};
</script>

<style>
.btn-select {
  color: #ff0000;
  padding-left: 15px;
}

.largepopup {
  width: 750px;
}

label.el-checkbox {
  margin-left: 4px !important;
}
</style>
