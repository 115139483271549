<template>
  <div class="px-2">
    <div class="row" v-if="selectedLangauge.languages">
      <div class="col-md-6">
        <fg-input
          v-model="selectedLangauge.languages[0].title"
          label="Title"
          placeholder="Title"
        ></fg-input>
      </div>
      <div class="col-md-6">
        <fg-input label="Country">
          <treeselect
            class=""
            v-model="selectedLangauge.languages[0].country"
            :value-consists-of="valueConsistsOf"
            :multiple="true"
            :options="country"
          />
        </fg-input>
      </div>
      <div class="col-md-6">
        <fg-input label="Publish Date">
          <el-date-picker
            v-model="selectedLangauge.languages[0].publishDate"
            type="date"
          >
          </el-date-picker>
        </fg-input>
      </div>

      <div class="col-md-6">
        <fg-input label="Language">
          <el-select
            class="select-default dropdownpadding"
            size="large"
            placeholder="Language"
            v-model="selectedLangauge.languages[0].language"
            :filterable="true"
            :clearable="true"
          >
            <el-option
              v-for="item in languages"
              class="select-primary"
              :value="item.value"
              :label="item.label"
              :key="item.label"
            ></el-option>
          </el-select>
        </fg-input>
      </div>
      <div class="col-md-6">
        <fg-input label="Tags">
          <el-select
            class="select-default dropdownpadding"
            size="large"
            placeholder="Add Tags"
            v-model="selectedLangauge.tags"
            multiple
            filterable
            @change="handleTagChange($event)"
            allow-create
          >
            <el-option
              v-for="item in tags.options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </fg-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <fg-input label="Excerpt">
          <textarea
            class="form-control"
            v-model="selectedLangauge.excerpt"
            placeholder="Here can be your nice text"
            rows="3"
          ></textarea>
        </fg-input>
      </div>

      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Thumbnail
        </span>

        <div>
          <div
            class=" ml-2 thumbNail"
            :style="{ backgroundImage: 'url(' + thumbnail + ')' }"
          ></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button
              type="primary"
              class="uploadThumbBtn"
              icon="el-icon-upload"
              @click="openImageDialog($event, 'thumbnail')"
            >
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button
              type="danger"
              class="deleteThumbBtn"
              icon="el-icon-delete"
              @click.prevent="deleteImage('thumbnail')"
            >
              Delete
            </el-button>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <!--    <button class="btn btn-danger delete-lang-btn" @click.prevent="confirmDeleteBlog">Delete</button> -->
      </div>
    </div>
    <input
      type="file"
      ref="thumbnailInput"
      accept=".jpg, .png, .jpeg, .gif"
      v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'thumbnail')"
    />
    <input
      type="file"
      ref="coverartInput"
      accept=".jpg, .png, .jpeg, .gif"
      v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'coverart')"
    />
  </div>
</template>

<script>
import appConfigs from "../data/AppConfig";
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Button
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { FormGroupInput as FgInput } from "src/components";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import countries from "../../../config/countries.json";
import languages from "../../../config/languages.json";

export default {
  props: {
    currentLanguage: Object
  },
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    appConfigs,
    bModal,
    bModalDirective,
    FgInput,
    Treeselect
  },
  data() {
    return {
      serverTags: null,
      tags: {
        options: [],
        value: []
      },
      appIndex: 0,
      show: false,
      newConfigName: "",
      newConfigParam: "",
      newConfigValue: "",
      simple: "",
      valueConsistsOf: "ALL_WITH_INDETERMINATE",
      selectedLangauge: {},
      country: [],
      language: [
        {
          value: "english",
          label: "English"
        },
        {
          value: "french",
          label: "French"
        },
        {
          value: "hindi",
          label: "Hindi"
        }
      ],
      thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff",
      coverart: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      searchQuery: "",
      propsToSearch: ["configname", "configparam", "configvalue"],
      tableColumns: [
        {
          prop: "configparam",
          label: "Config Parameter",
          minWidth: 150
        },
        {
          prop: "configname",
          label: "Config Name",
          minWidth: 200
        },
        {
          prop: "configvalue",
          label: "Config Value",
          minWidth: 200
        },
        {
          prop: "readonly",
          label: "Readonly",
          minWidth: 100
        }
      ],
      fuseSearch: null
    };
  },
  computed: {},

  mounted() {
    this.country = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.languages = languages;
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["configname", "configparam", "configvalue"]
    });
    if (this.currentLanguage) {
      this.selectedLangauge = this.currentLanguage;
      if (
        this.selectedLangauge &&
        this.selectedLangauge.languages[0].thumbnailLink
      ) {
        this.thumbnail = this.selectedLangauge.languages[0].thumbnailLink[
          "source"
        ];
      }
      if (
        this.selectedLangauge &&
        this.selectedLangauge.languages[0].coverartLink
      ) {
        this.coverart = this.selectedLangauge.languages[0].coverartLink[
          "source"
        ];
      }
    }
    this.updateTagList();
  },
  watch: {
    currentLanguage: function() {}
  },
  beforeDestroy() {},
  methods: {
    updateTagList: function() {
      this.tags.options = [];
      this.$store
        .dispatch("getBlogTagList")
        .then(response => {
          this.serverTags = response;
          if (this.serverTags.length) {
            for (var i = 0; i < this.serverTags.length; i++) {
              let opts = {};
              opts["value"] = this.serverTags[i].name;
              opts["label"] = this.serverTags[i].name;
              this.tags.options.push(opts);
            }
          }
        })
        .catch(() => {});
    },
    handleTagChange: function(ev) {
      if (ev.length && ev.length > 0) {
        let lastIndex = ev.length - 1;
        var changedData = ev[lastIndex];
        if (!this.isAvailable(changedData)) {
          this.createnew(changedData);
        }
      }
    },
    createnew(tags) {
      var DataToSend = {};
      DataToSend["name"] = tags;
      this.$store
        .dispatch("createTags", DataToSend)
        .then(response => {
          this.updateTagList();
        })
        .catch(() => {});
    },
    isAvailable(changedData) {
      var Data = this.serverTags.find(({ name }) => name == changedData);
      if (Data != null) {
        return true;
      } else {
        return false;
      }
    },
    resetUrl: function(url, type) {
      if (type == "thumbnail") {
        this.thumbnail = url;
        this.selectedLangauge.languages[0].thumbnailLink.source = url;
      }
    },
    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      self.$emit("uploadStatus", true);
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.resetUrl(url, type);
              self.$emit("uploadStatus", false);
            })
            .catch(() => {
              self.$emit("uploadStatus", false);
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          self.$emit("uploadStatus", false);
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    openImageDialog(evt, type) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        file = this.$refs.thumbnailInput;
      } else {
        file = this.$refs.coverartInput;
      }
      if (file) {
        file.click();
      }
    },
    getLangInfo: function() {
      return this.selectedLangauge;
    },
    deleteImage(type) {
      if (type == "thumbnail") {
        this.resetUrl("https://dummyimage.com/640x360/a1a1a1/fff", type);
      } else {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
    },
    confirmDeleteBlog() {
      this.$emit("deleteBlog");
    }
  }
};
</script>

<style lang="scss">
.languagecontainer {
  padding-top: 20px;
  /*        .ck.ck-editor {
            margin: 0px 20px!important;
        }*/
}

/*    .dropdownpadding {
        padding-bottom: 20px;
    }*/

.thumbnail {
  position: absolute;
  float: left;
  height: 110px;
}

.thumb-div {
  padding-left: 10px;
  min-height: 160px;
}

.uploadthumbbtn {
  position: absolute;
  float: left;
  margin-top: 0px;
  margin-left: 200px;
}

.deletethumbbtn {
  position: absolute;
  float: left;
  margin-top: 50px;
  margin-left: 200px;
}

.delete-lang-btn {
  float: right;
  margin-right: 30px;
}

.card form [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}
.thumbNail {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.thumbDiv {
  min-height: 160px;
}

.uploadThumbBtn {
  padding: 10px 10px;
}

.deleteThumbBtn {
  padding: 10px 10px;
}
.titleThumb {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin-left: 10px;
}
</style>
