<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Subscriber Report
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <card title="">
        <div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
                <label>From</label>
                <el-date-picker v-model="startDate" type="date">
                </el-date-picker>
              </div>
              <div class="col-md-4">
                <label>To</label>
                <el-date-picker v-model="endDate" type="date"> </el-date-picker>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-md-3 UserType">
                <label>Select User Type</label>
                <div class="d-flex justify-content-between">
                  <div>
                    <l-checkbox v-model="usertype.paid">Paid Users</l-checkbox>
                  </div>
                  <div class="trialUser">
                    <l-checkbox v-model="usertype.free">Trial Users</l-checkbox>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <button
                  class="btn btn-primary btn-fill"
                  :disabled="!startDate || !endDate"
                  style="margin-top:2em;"
                  @click="getUserReport"
                >
                  Get Report
                </button>
              </div>
            </div>
          </div>

          <div class="col-sm-12 p-3">
            <el-table
              stripe
              style="width: 100%;margin:20px 0px"
              :data="queriedData"
              border
              v-if="showReport"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="150" label="Package">
                <template slot-scope="props">
                  {{ getPackage(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="150" label="Subscription">
                <template slot-scope="props">
                  {{
                    props.row.subscription ? props.row.subscription.title : "NA"
                  }}
                </template>
              </el-table-column>
              <el-table-column :min-width="150" label="Account Status">
                <template slot-scope="props">
                  {{ getAccountStatus(props.row) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Checkbox
} from "element-ui";
import { Checkbox as LCheckbox } from "src/components";
import { Pagination as LPagination } from "src/components/index";
import { Loading } from "element-ui";
import Fuse from "fuse.js";

import moment from "moment";

export default {
  name: "SubscriberReport",
  components: {
    LCheckbox,
    LPagination,
    [DatePicker.name]: DatePicker,
    [Checkbox.name]: Checkbox,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {},
  data() {
    return {
      usertype: {
        paid: true,
        free: true
      },
      startDate: null,
      endDate: null,
      data: [],
      count: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "user.first_name",
          label: "First Name",
          minWidth: 150
        },
        {
          prop: "user.last_name",
          label: "Last Name",
          minWidth: 150
        },
        {
          prop: "user.email_address",
          label: "Email",
          minWidth: 200
        }
      ],
      showReport: false
    };
  },
  computed: {
    tableData() {
      return this.data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["user.first_name", "user.last_name", "user.email_address"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {},
  methods: {
    getUserReport: function() {
      var form = {};
      this.showReport = true;
      form.fromDate = moment(this.startDate).format("YYYY-MM-DD");
      form.toDate = moment(this.endDate).format("YYYY-MM-DD");
      form.paidSubscribers = this.usertype.paid;
      form.trialUsers = this.usertype.free;
      if (this.usertype.free === false && this.usertype.paid === false) {
        form.paidSubscribers = true;
        form.trialUsers = true;
      }
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("getSubscriberReport", form)
        .then(res => {
          this.count = res.count;
          this.data = res.data;
          loadingInstance.close();
        })
        .catch(() => {
          loadingInstance.close();
        });
    },
    getPackage: function(row) {
      if (row.package) {
        return row.package.title;
      } else {
        return "NA";
      }
    },
    getAccountStatus: function(row) {
      if (this.usertype.paid) {
        return row.type;
      } else if (this.usertype.free) {
        return row.subscription.isActive ? "trial" : "unsubscribe";
      } else {
        return "NA";
      }
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 15px;
}
.trialUser {
  position: relative;
  left: 10px;
}
.UserType {
  max-width: 200px;
}
</style>
