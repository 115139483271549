<template>
  <div class="row">
    <DeleteConfirmation :currentDeleteMethod="deleteUser" @delete="$event()" />
    <b-modal ref="importDialog" title="Import Result" ok-variant="danger">
      <strong>{{ message }}</strong>
      <div>
        <p>Failed records:</p>
      </div>
      <textarea
        rows="6"
        class="form-control border-input"
        v-model="errorMessage"
      ></textarea>
    </b-modal>
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Access Management
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <!-- <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div> -->
          <div class="mx-2 mt-1">
            <el-button type="info" @click="openImportUser">
              Import
            </el-button>
          </div>
          <div class="mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addUser"
              >Add Admin
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-sm-12 p-3">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column fixed :min-width="260" label="User Name">
                <template slot-scope="props">
                  {{ getUserName(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" label="Role">
                <template slot-scope="props">
                  {{ getRole(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="210" label="Status">
                <template slot-scope="props">
                  {{ getStatus(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="180" label="Last Activity">
                <template slot-scope="props">
                  {{ getLastActive(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    v-b-modal.confirmDelete
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
      <input
        type="file"
        ref="userImportInput"
        accept=".csv"
        v-bind:style="{ display: 'none' }"
        @change="importUserData"
      />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import moment from "moment";
import Fuse from "fuse.js";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";

export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },
  data() {
    return {
      message: "",
      errorMessage: "",
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["username", "email"],
      fuseSearch: null,
      tableData: []
    };
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["username", "telephone", "first_name", "last_name", "state"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {},
  created() {
    this.$store.commit("setLoader", {
      show: true,
      text: "Fetching Administration"
    });
    this.$store
      .dispatch("getListOfUserAdmins")
      .then(response => {
        this.tableData = response;
        this.$store.commit("setLoader", { show: false, text: "" });
      })
      .catch(() => {
        this.$store.commit("setLoader", { show: false, text: "" });
      });
  },

  methods: {
    handleEdit: function(index, row) {
      this.$router.push({
        name: "Edit Administration",
        params: { id: row.id }
      });
    },
    handleDelete: function(index, row) {
      if (row) {
        this.deleteId = row.id;
      }
    },

    deleteUser: function() {
      if (this.deleteId) {
        this.$store
          .dispatch("deleteUserAdminById", this.deleteId)
          .then(data => {
            var deletedIndex = this.findWithAttr(
              this.tableData,
              "id",
              this.deleteId
            );
            this.tableData.splice(deletedIndex, 1);
          })
          .catch(() => {});
      }
    },
    addUser: function() {
      this.$router.push({ name: "Edit Administration", params: { id: -1 } });
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    getUserName: function(row) {
      if (row.username) {
        return row.username;
      } else {
        return "NA";
      }
    },
    getRole: function(row) {
      var role = row.role;
      switch (role) {
        case "SUPER_ADMIN":
          return "Super Admin";
        case "ADMIN":
          return "Admin";
        case "MANAGER'":
          return "Manager";
        case "EDITOR":
          return "Editor";
        case "SUPPORT":
          return "Support";
        case "REPORT":
          return "Report";
        default:
          return "NA";
      }
    },
    getStatus: function(row) {
      var status = row.status;
      switch (status) {
        case "EMAIL_VERIFICATION_REQUIRED":
          return "Email Verification Pending";
        case "MOBILE_VERIFICATION_REQUIRED":
          return "Mobile Verification Pending";
        case "FORCE_RESET_PASSWORD":
          return "Force Reset Password";
        case "ACCOUNT_VERIFIED":
          return "Account Verified";
        case "ACCOUNT_DISABLED":
          return "Account Disabled";
        default:
          return "NA";
      }
    },
    getLastActive: function(row) {
      if (row.lastLogin) {
        return moment(row.lastLogin, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    generateErrorMessage: function() {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    openImportUser: function(evt) {
      evt.stopPropagation();
      evt.preventDefault();
      var file = this.$refs.userImportInput;
      if (file) {
        file.click();
      }
    },
    importUserData: function(evt) {
      let file = evt.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      this.$store.commit("setLoader", {
        show: true,
        text: "Importing UserData.."
      });
      this.$store
        .dispatch("importUserData", formData)
        .then(response => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
          if (
            response.message &&
            response.errorDatas &&
            response.errorDatas.length > 0
          ) {
            this.message = response.message;
            this.errorsData = response.errorDatas;
            this.generateErrorMessage();
            this.$refs.importDialog.show();
            return;
          }
          this.$notify({
            title: "Success",
            message: "Successfully Imported!",
            type: "success"
          });
        })
        .catch(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    openExportUser: function() {}
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 12px;
}
</style>
