<template>
  <card>
    <b-modal id="metadatas" ref="modal" title="Manage Meta Data">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <fg-input label="Key" v-model="currentMetaData.key"></fg-input>
          </div>
          <div class="col-sm-5">
            <fg-input label="Value" v-model="currentMetaData.value"></fg-input>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-primary meta-button" @click="addMetaData()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-bigboy"
          style="width: 100%;"
          :data="metaData"
          border
        >
          <el-table-column min-width="120" label="Key">
            <template slot-scope="{ row }">
              {{ row.key }}
            </template>
          </el-table-column>
          <el-table-column min-width="140" label="Value">
            <template slot-scope="{ row }">
              {{ row.value }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="props">
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="deleteMetaData(props.$index)"
                ><i class="fa fa-trash"></i
              ></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <div class="switch enableSwitch">
          <l-switch
            on-text="ON"
            off-text="OFF"
            v-model="currentData.isActive"
          ></l-switch>
        </div>
      </div>
      <div class="col-md-4">
        <fg-input
          label="Product Name"
          v-model="currentData.name"
          placeholder="Product Name"
        ></fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          label="Product Type"
          v-model="currentData.type"
          placeholder="Product Type"
        ></fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          label="Product Price"
          type="number"
          v-model="currentData.price"
          placeholder="Product Price"
        ></fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          label="Slug name"
          v-model="currentData.slug"
          placeholder="Slug name"
        ></fg-input>
      </div>
      <div class="col-md-12">
        <div class="row">
          <fg-input
            class="col-sm-12 col-12 subtitlecontainer"
            label="Description"
          >
            <textarea
              class="form-control"
              v-model="currentData.description"
              placeholder="Description"
              maxlength="50"
              rows="3"
            ></textarea>
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="manage-tag" v-b-modal.metadatas>Manage Tags</p>
        </div>
      </div>
      <div class="col-md-12">
        <button class="btn btn-primary btn-simple" @click="handleSaveBtn">
          Save
        </button>
      </div>
    </div>
  </card>
</template>
<script>
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Loading } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem
} from "element-ui";

export default {
  components: {
    LSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    bModal,
    bModalDirective
  },
  data() {
    return {
      createMode: true,
      currentData: {},
      metaData: [],
      currentMetaData: {
        key: "",
        value: ""
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    self = this;
    if (!this.$route.params.id || this.$route.params.id == -1) {
      this.createMode = true;
    } else {
      this.createMode = false;
      this.$store
        .dispatch("getProductWithId", this.$route.params.id)
        .then(res => {
          self.currentData = res;
          var metaData = res.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            self.metaData.push(obj);
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    handleSaveBtn: function() {
      self = this;
      let loadingInstance = Loading.service({ fullscreen: true });
      this.currentData.metaData = {};
      for (var j = 0; j < this.metaData.length; j++) {
        var key = this.metaData[j].key;
        this.currentData.metaData[key] = this.metaData[j].value;
      }
      if (this.createMode) {
        this.$store
          .dispatch("createProduct", this.currentData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/products/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      } else {
        this.$store
          .dispatch("updateProduct", this.currentData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/products/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      }
    },
    addMetaData: function() {
      this.metaData.push(JSON.parse(JSON.stringify(this.currentMetaData)));
      this.currentMetaData = {
        key: "",
        value: ""
      };
    },
    deleteMetaData: function(index) {
      this.metaData.splice(index, 1);
    }
  }
};
</script>
