import storage from './storage'
import * as properties from "../properties";

class UserService {
    constructor() {
        this.userName = "";
        this.userRole = "";
        if (storage.get(properties.user)) {
            this.userInfo = JSON.parse(storage.get(properties.user));
            if(this.userInfo) {
                this.userName = this.userInfo["username"];
                this.userRole = this.userInfo["role"];
                this.userEmail = this.userInfo["email"];
                this.userId = this.userInfo["id"];
            }
        }
    }
    setUserInfo(user) {
        if (user) {
            storage.set(properties.user, JSON.stringify(user));
        }
    }

    setCustomerId(id) {
        storage.set(properties.customerId , id);
    }

    getCustomerId() {
        var cId = storage.get(properties.customerId);
        if(cId && cId!='null') {
            return cId;
        }
        return '5bc27b3ffd1262442ee05391';
    }

    getUserInfo() {
        return JSON.parse(storage.get(properties.user));
    }

    getUserName() {
        if (this.getUserInfo()) {
            var info = this.getUserInfo();
            this.userName = info["username"];
        }
        return this.userName;
    }
    getUserRole() {
        if (this.getUserInfo()) {
            var info = this.getUserInfo();
            this.userRole = info["role"];
        }
        return this.userRole;
    }
    getUserEmail() {
        if (this.getUserInfo()) {
            var info = this.getUserInfo();
            this.userEmail = info["email"];
        }
        return this.userEmail;
    }
    getUserId() {
        if (this.getUserInfo()) {
            var info = this.getUserInfo();
            this.userId = info["id"];
        }
        return this.userId;
    }
    cleanUserData() {
        storage.set(properties.user, null);
        storage.set(properties.customerId,null);
    }
}

const userService = new UserService();

export default userService;