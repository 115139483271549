<template>
  <div>
    <card title="Payment Gateway Details">
      <div class="table-responsive table-full-width">
        <el-table :data="tableData">
          <el-table-column minWidth="90" label="Receipt ID">
            <template slot-scope="props">
              {{ getReceiptId(props.row) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="payment_gateway.name"
            minWidth="100"
            label="Payment Gateway"
          >
          </el-table-column>
          <el-table-column
            minWidth="90"
            prop="subscriptionName"
            label="Subscription"
          >
          </el-table-column>
          <el-table-column prop="packageName" label="Package">
          </el-table-column>
          <el-table-column label="Payment Status">
            <template slot-scope="props">
              {{ getPaymentStatus(props.row) }}
            </template>
          </el-table-column>
        </el-table>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import moment from "moment";
export default {
  props: ["userInfo"],
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  // watch: {
  //   "userInfo.payment_status": function() {
  //     if (this.userInfo.payment_status) {
  //       this.tableData = this.userInfo.payment_status;
  //     }
  //   }
  // },
  created() {
    if (this.userInfo.payment_status.length > 0) {
      this.tableData = this.userInfo.payment_status;
    }
  },
  data() {
    return {
      tableColumns: [],
      tableData: []
    };
  },

  methods: {
    getStatus(row) {
      var status = row.status;
      if (status) {
        return "Active";
      }
      return "InActive";
    },
    getReceiptId(row) {
      var receipt_id = row.receipt_id;
      if (receipt_id) {
        if (receipt_id.length > 17) {
          return receipt_id.substr(0, 14) + "...";
        }
        return receipt_id;
      }
      return "";
    },

    getPaymentStatus(row) {
      const subscription = this.userInfo.subscriptions.find(item => {
        return item.sub_id == row.subscriptionId;
      });
      if (subscription) {
        return subscription.paymentStatus;
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped></style>
