export function formatForSelectFromJson(json) {
    var formattedArray = [];
    if(json) {
        for(var key in json) {
            var item = {};
            item.label = json[key];
            item.value = key;
            formattedArray.push(item);
        }
    }
    return formattedArray;
}