<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteBlog"
      ref="confirmBlogDelete"
      @delete="$event()"
    />
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">Blogs</span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              ref="searchKey"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>

          <div class="mx-2 mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addBlog"
              >Add Article
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div class="col-sm-12">
          <el-table
            class="table-bigboy"
            style="width: 100%;"
            :data="queriedData"
            border
          >
            <el-table-column min-width="180" label="Title">
              <template slot-scope="{ row }">
                {{ row.title }}
              </template>
            </el-table-column>
            <el-table-column min-width="300" label="Excerpt">
              <template slot-scope="{ row }">
                {{ row.excerpt }}
              </template>
            </el-table-column>
            <el-table-column min-width="300" label="Status">
              <template slot-scope="{ row }">
                {{ row.status }}
              </template>
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" label="Actions">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Edit'"
                  class="btn-warning btn-simple btn-link"
                  @click="handleEdit(props.$index, props.row)"
                  ><i class="fa fa-edit"></i
                ></a>
                <a
                  v-tooltip.top-center="'Delete'"
                  v-b-modal.confirmDelete
                  class="btn-danger btn-simple btn-link"
                  @click="handleDelete(props.$index, props.row)"
                  ><i class="fa fa-trash"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import { Loading } from "element-ui";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },

  data() {
    return {
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      currentDeleteId: null,
      selectedUrl: "",
      searchQuery: "",
      propsToSearch: ["name", "tags"],
      fuseSearch: null,
      selectedVideoTitle: ""
    };
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["title", "excerpt"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {
    this.getBlogList();
  },
  created() {},
  methods: {
    getBlogList: function() {
      this.tableData = [];

      this.$store.commit("setLoader", {
        show: true,
        text: "Fetching Blog List..."
      });
      this.$store
        .dispatch("getBlogList")
        .then(res => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
          if (Array.isArray(res)) {
            var filterData = res.filter(item => !item._isDeleted);
            for (var i = 0; i < filterData.length; i++) {
              let opts = {};
              opts["id"] = filterData[i].id;
              opts["title"] = filterData[i].languages[0].title;
              opts["excerpt"] = filterData[i].excerpt;
              opts["slug"] = filterData[i].slug;
              opts["metaData"] = filterData[i].metaData;
              opts["type"] = filterData[i].type;
              opts["status"] = filterData[i].isActive ? "true" : "false";
              this.tableData.push(opts);
            }
          }
        })
        .catch(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        });
    },
    handleEdit: function(index, props) {
      this.$router.push({ name: "Edit Blog", params: { id: props.slug } });
    },
    deleteBlog: function() {
      let loadingInstance = Loading.service({ fullscreen: true });

      this.$store
        .dispatch("deleteBlogById", this.currentDeleteId)
        .then(res => {
          this.getBlogList();
          loadingInstance.close();

          this.$notify({
            title: "Success",
            message: "Blog Deleted Successfully!",
            type: "success"
          });
        })
        .catch(err => {
          loadingInstance.close();
          this.$notify({
            title: "Error",
            message: "Delete Blog Failed!",
            type: "warning"
          });
        });
    },
    handleDelete: function(index, props) {
      this.currentDeleteId = props.slug;
      this.$refs.confirmBlogDelete.show();
    },
    addBlog: function() {
      this.$router.push({ name: "Edit Blog", params: { id: -1 } });
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
</style>
