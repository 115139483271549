<template>
  <div class="row" v-if="userLoaded">
    <div class="col-md-12">
      <edit-profile-form
        @profileCreated="setId(id)"
        :userInfo="user"
        :metaData="metaData"
      >
      </edit-profile-form>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
//  import UserCard from './UserProfile/UserCard.vue'

import { Loading } from "element-ui";
export default {
  created() {
    if (this.id) {
      var loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("AdminGetAppUserById", this.id)
        .then(data => {
          this.user = data;
          var metaData = this.user.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            this.metaData.push(obj);
          }
          this.userLoaded = true;
          loadingInstance.close();
        })
        .catch(() => {
          this.userLoaded = true;
          loadingInstance.close();
        });
    } else {
      this.userLoaded = true;
    }
  },
  components: {
    EditProfileForm
    //  UserCard,
  },
  methods: {
    setId(id) {
      this.id = id;
    }
  },
  data() {
    return {
      userLoaded: false,
      id: this.$route.params.id,
      user: {},
      metaData: []
    };
  }
};
</script>
<style scoped>
.topMargin {
  margin-top: 40px;
}
</style>
