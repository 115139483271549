<template>
  <div>
    <div>
      <span class="titleCard mb-2">
        Authentication Provider
      </span>
    </div>
    <card class="mt-3">
      <DeleteConfirmation
        :currentDeleteMethod="deleteCustomerHost"
        @delete="$event()"
      />
      <div class="row">
        <div class="col-md-12">
          <card>
            <vue-tabs value="Description" @tab-change="handleTabChange">
              <v-tab
                v-for="host in videoHostArray"
                :title="host.name"
                v-bind:key="host.id"
                class="col-md-12"
              >
                <card class="mt-3">
                  <div>
                    <div class="enableSwitch">
                      <l-switch
                        v-model="curData.isActive"
                        on-text="ON"
                        off-text="OFF"
                      ></l-switch>
                    </div>
                  </div>

                  <div v-for="obj in curData.configObj" v-bind:key="obj.key">
                    <fg-input
                      type="text"
                      :label="obj.label"
                      v-model="obj.value"
                      :placeholder="getPlaceholderText(obj.label)"
                    ></fg-input>
                  </div>
                  <div>
                    <button
                      class="btn btn-primary btn-fill"
                      @click.prevent="saveHostProvider"
                    >
                      Save
                    </button>
                  </div>
                </card>
              </v-tab>
            </vue-tabs>
          </card>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput, Switch as LSwitch } from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    LSwitch,
    DeleteConfirmation
  },
  computed: {},
  data() {
    return {
      tableData: [],
      videoHostArray: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      curData: {
        isActive: true,
        providerId: "",
        configObj: []
      }
    };
  },
  created() {
    this.$store
      .dispatch("getAuthProviderConfigList")
      .then(response => {
        this.tableData = response;
        this.$store
          .dispatch("getAuthProviderList")
          .then(response => {
            if (response) {
              this.videoHostArray = response;
              if (Array.isArray(response) && response.length > 0) {
                var host = response[0];
                if (host) {
                  this.curData.providerId = host.id;
                  this.setConfigData(host);
                }
              }
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  },
  methods: {
    getAuthConfigList: function() {
      this.$store
        .dispatch("getAuthProviderConfigList")
        .then(response => {
          this.tableData = response;
        })
        .catch(() => {});
    },
    saveHostProvider() {
      var data = {};
      if (!this.getEditAuthConfig()) {
        data.isActive = this.curData.isActive;
        data.providerId = this.curData.providerId;
        data.configObj = {};
        for (var i = 0; i < this.curData.configObj.length; i++) {
          var item = this.curData.configObj[i];
          data.configObj[item.key] = item.value;
        }
        this.$store
          .dispatch("createAuthProviderConfig", data)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Auth Config created Successfully!",
              type: "success"
            });
            this.getAuthConfigList();
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Create Auth Config Failed!",
              type: "warning"
            });
          });
      } else {
        var req = {};
        data = this.getEditAuthConfig();
        data.isActive = this.curData.isActive;
        data.configObj = {};
        for (var i = 0; i < this.curData.configObj.length; i++) {
          var item = this.curData.configObj[i];
          data.configObj[item.key] = item.value;
        }
        req.id = data.id;
        req.data = data;
        this.$store
          .dispatch("updateAuthProviderConfig", req)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Auth Config updated Successfully!",
              type: "success"
            });
            this.getAuthConfigList();
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Update Auth Config Failed!",
              type: "warning"
            });
          });
      }
    },
    setConfigData(host) {
      var id = host.id;
      var hostConfig = host.configObj;
      var pData = this.getEditAuthConfig();
      this.curData.configObj = [];
      if (pData) {
        this.curData.isActive = pData.isActive;
      } else {
        this.curData.isActive = true;
      }
      if (hostConfig) {
        for (var key in hostConfig) {
          var item = {};
          item.label = hostConfig[key];
          item.key = key;
          if (pData && pData.configObj && pData.configObj[item.key]) {
            item.value = pData.configObj[item.key];
          } else {
            item.value = "";
          }
          this.curData.configObj.push(item);
        }
      }
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      var host = this.videoHostArray[tabIndex];
      if (host) {
        this.curData.providerId = host.id;
        this.setConfigData(host);
      }
    },
    getEditAuthConfig() {
      if (this.tableData && this.tableData.length > 0) {
        var pId = this.curData.providerId;
        if (pId) {
          for (var i = 0; i < this.tableData.length; i++) {
            var providerId = this.tableData[i].providerId;
            if (pId == providerId) {
              return this.tableData[i];
            }
          }
        }
      }
      return null;
    },
    getPlaceholderText(val) {
      return "Please Add " + val;
    },
    deleteCustomerHost() {}
  },
  mounted() {},
  watch: {}
};
</script>

<style scoped>
.subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
</style>
