<template>
  <div class="content">
    <div class="container-fluid">
      <card v-if="!showUnauthorised">
        <h1 class="text-cen"> You Are Unauthorized For this Action</h1>
      </card>
      <transition name="fade" mode="out-in" v-if="showUnauthorised">
        <!-- your content here -->
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>
<script>
  export default {
    computed:{
      showUnauthorised(){
        return this.$store.state.admin.isauthorised;
      }
    }
  }
</script>
<style>
  .text-cen{
    text-align: center;
    width: 100%;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
