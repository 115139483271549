<template>
  <div>
    <div>
      <span class="centerTitle mb-2">
        Whitelisting
      </span>
    </div>
    <card>
      <WhitelistIp></WhitelistIp>
      <div class="row">
        <DeleteConfirmation
          :currentDeleteMethod="removeUser"
          ref="ConfirmDeleteUser"
          @delete="$event()"
        />
        <b-modal
          id="userSearch"
          ref="userSearchModal"
          title="Add Whitelist App User"
          @ok="addUserToWhiteList"
          ok-title="Add"
          @cancel="exitDialog"
          @hide="exitDialog"
        >
          <div>
            <div class="col-12 d-flex justify-content-end">
              <div>
                <span class="demo-input-label ml-2 perPage"
                  >Results per page:</span
                >
                <el-select
                  class="select-default"
                  style="width: 80px; "
                  v-model="userpagination.perPage"
              placeholder="Per page"
              :change="changeperpage(userpagination.perPage)"
                >
                  <el-option
                    class="select-default"
                    v-for="item in userpagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="ml-2">
                <el-input
                  type="search"
                  style="width: 150px; "
                  placeholder="Search records"
                  ref="searchKey"
                  prefix-icon="el-icon-search"
                  v-model="searchQuery"
                  @input="debounceInput"
                  aria-controls="datatables"
                  @clear="clearSearch"
                  clearable
                />
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table
                stripe
                style="width: 100%;"
                :data="userqueriedData"
                border
              >
                <el-table-column :width="80" fixed="left" label="">
                  <template slot-scope="props">
                    <l-checkbox
                      :checked="userisChecked(props)"
                      @input="usercheckChanged($event, props)"
                    ></l-checkbox>
                  </template>
                </el-table-column>
                <el-table-column
                  v-for="column in usertableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class="">
              <p class="card-category">
                Showing {{ userfrom + 1 }} to {{ userto }} of
                {{userpagination.total }} entries
              </p>
            </div>
            <l-pagination
              class="userpagination-no-border"
              v-model="userpagination.currentPage"
              :per-page="userpagination.perPage"
              :total="userpagination.total"
               @input="clickCallback(userpagination.currentPage)"
            >
            </l-pagination>
          </div>
        </b-modal>



        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center py-3">
            <div>
              <span class="titleCard mb-2">
                Whitelist App User
              </span>
            </div>
            <div
              class="d-flex justify-content-end align-items-center  searchContainer"
            >
              <div>
                <el-input
                  type="search"
                  style="width: 200px; "
                  placeholder="Search records"
                  prefix-icon="el-icon-search"
                  v-model="searchQuery"
                  aria-controls="datatables"
                />
              </div>
              <div>
                <span class="demo-input-label ml-2 perPage"
                  >Results per page:</span
                >
                <el-select
                  class="select-default ml-1"
                  v-model="pagination.perPage"
                  style="width: 80px; "
                  placeholder="Per page"
                >
                  <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>

              <div class="mx-2 mt-1">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  @click="userSearch"
                  >Add User
                </el-button>
              </div>
            </div>
          </div>
          <card>
            <div class="col-sm-12 py-3">
              <el-table stripe style="width: 100%;" :data="queriedData" border>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="120" fixed="right" label="Action">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Delete'"
                      class="btn-danger btn-simple btn-link"
                      @click="handleDelete(props.$index, props.row)"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </card>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import Card from "../../../components/Cards/Card.vue";
import WhitelistIp from "./WhitelistIp.vue";
import { debounce } from "debounce";

export default {
  components: {
    LPagination,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    LCheckbox,
    DeleteConfirmation,
    WhitelistIp
  },

  data() {
    return {
      deletewhitelistedIds: [],
      
      usersearchQuery: "",
      userpropsToSearch: ["username", "email"],
      usertableColumns: [
        {
          prop: "first_name",
          label: "First Name",
          minWidth: 200
        },
        {
          prop: "last_name",
          label: "Last Name",
          minWidth: 200
        },
        {
          prop: "username",
          label: "Username",
          minWidth: 250
        },
        {
          prop: "id",
          label: "Id",
          minWidth: 250
        }
      ],
      userfuseSearch: null,
      addwhitelistedIds: [],
      spinnerTip: "Importing User Data...",
      message: "",
      errorMessage: "",
      uploading: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["username", "email"],
      tableColumns: [
        {
          prop: "first_name",
          label: "First Name",
          minWidth: 200
        },
        {
          prop: "last_name",
          label: "Last Name",
          minWidth: 200
        },
        {
          prop: "username",
          label: "Username",
          minWidth: 250
        },
        {
          prop: "id",
          label: "ID",
          minWidth: 250
        }
      ],
      fuseSearch: null,
      tableData: []
    };
  },

  computed: {
    userpagination() {
      return this.$store.getters.getAppUsersPagination;
    },
   usertableData(){
     var obj = this.$store.state.admin.adminUsers.listAll;
      return obj;
   },
   userpagedData() {
      return this.usertableData.slice(this.userfrom, this.userto);
    },
    userqueriedData() {
      let result = this.usertableData;
      // if (this.usersearchQuery !== "") {
      //   result = new Fuse(this.usertableData, {
      //     keys: ["username", "id", "first_name", "last_name"]
      //   }).search(this.usersearchQuery);
      // }
      // this.userpagination.usertotal = result.length;
      // return result.slice(this.userfrom, this.userto);

      return result;
    },
    userto() {
      let highBound = this.userfrom + this.userpagination.perPage;
      if (this.usertotal < highBound) {
        highBound = this.usertotal;
      }
      return highBound;
    },
    userfrom() {
      return (
        this.userpagination.perPage * (this.userpagination.currentPage - 1)
      );
    },
    
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["username", "id", "first_name", "last_name"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {},
  created() {
    this.getPageData();
  },

  methods: {
    getPageData() {
      self = this;
      this.$store.commit("setLoader", {
        show: true,
        text: "Fetching List..."
      });
      this.$store
        .dispatch("getWhitelistedAppUserList")
        .then(response => {
          //this.tableData = response;
          var resdata = response;

          for (var i = 0; i < resdata.length; i++) {
            if (resdata[i].user != null) {
              resdata[i]["id"] = resdata[i].user.id;
              resdata[i]["username"] = resdata[i].user.username;
              resdata[i]["first_name"] = resdata[i].user.first_name;
              resdata[i]["last_name"] = resdata[i]["user"].last_name;
            }
          }
          this.tableData = resdata;
          this.$store
            .dispatch("getAdminAppUserList")
            .then(response => {
              this.$store.commit("setLoader", { show: false, text: "" });
            })
            .catch(() => {
              this.$store.commit("setLoader", { show: false, text: "" });
            });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
    },
    addUserToWhiteList() {
      this.addUser();
    },
    isChecked(props) {
      if (this.deletewhitelistedIds) {
        return this.deletewhitelistedIds.includes(props.row.id);
      }
      return false;
    },
    checkChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.deletewhitelistedIds.includes(id)) {
            this.deletewhitelistedIds.push(id);
          }
        } else {
          if (this.deletewhitelistedIds.includes(id)) {
            var index = this.deletewhitelistedIds.indexOf(id);
            this.deletewhitelistedIds.splice(index, 1);
          }
        }
      }
    },
    userisChecked(props) {
      if (this.addwhitelistedIds) {
        return this.addwhitelistedIds.includes(props.row.id);
      }
      return false;
    },
    usercheckChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.addwhitelistedIds.includes(id)) {
            this.addwhitelistedIds.push(id);
          }
        } else {
          if (this.addwhitelistedIds.includes(id)) {
            var index = this.addwhitelistedIds.indexOf(id);
            this.addwhitelistedIds.splice(index, 1);
          }
        }
      }
    },
    addUser: function() {
      self = this;
      var Data = {};
      Data["userIds"] = this.addwhitelistedIds;
      this.$store
        .dispatch("addWhitelistUsers", Data)
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Successfully ADDED!",
            type: "success"
          });
          self.getPageData();
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    removeUser: function() {
      self = this;
      var Data = {};
      Data["userIds"] = this.deletewhitelistedIds;
      this.$store
        .dispatch("removeWhitelistUsers", Data)
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Successfully REMOVED!",
            type: "success"
          });
          self.getPageData();
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    generateErrorMessage: function() {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    userSearch() {
     // this.$refs["userSearchModal"].show();
      this.$router.push({ name: "AddWhitelistAppUser" });
    },
    handleDelete(index, row) {
      this.deletewhitelistedIds = row.id;
      this.$refs["ConfirmDeleteUser"].$refs["deleteConfirmation"].show();
    },
    exitDialog() {
      this.addwhitelistedIds = [];
    },
    openExportUser: function() {},
     clickCallback: function(pagNum) {
      if (this.$store.state.admin.adminUsers.inputPage != pagNum) {
        this.$store.commit("setAppUserInputPage", pagNum);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Admin AppUser List..."
        });
        this.$store.dispatch("getAdminAppUserList").then(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        });
        return true;
      }
    },
 changeperpage: function(perPage) {
      if (this.$store.state.admin.adminUsers.inputSize != perPage) {
        this.$store.commit("setAppUserInputPage", 1);
        this.$store.commit("setAppUserInputPerpage", perPage);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Admin AppUser List..."
        });
        this.$store
          .dispatch("getAdminAppUserList")
          .then(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          })
          .catch(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          });
        return true;
      }
    },
    searchUser: function() {
      this.$store.commit("setLoader", {
        show: true,
        text: "Searching"
      });
      this.$store.commit("setAppUserInputPage", 1);
      this.$store.commit("setUserSearchText", this.$refs.searchKey.value);
      this.$store
        .dispatch("searchUserList")
        .then(response => {
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
      return true;
    },
    clearSearch: function() {
      if (this.$store.state.admin.adminUsers.searchKey != "") {
        this.$store.commit("setUserSearchText", "");
        this.$store.dispatch("getAdminAppUserList");
      }
    },
    debounceInput: debounce(function() {
      this.searchUser();
    }, 800)
    
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 300;
  font-size: 18px;
  color: #27282a;
  position: relative;
  right: 16px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
.centerTitle {
  margin-left: 15px;
  font-weight: 500;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
</style>
