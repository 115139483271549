<template>
    <auth-layout pageClass="login-page">
        <b-modal id="resetModal" v-model="resetMessagePopup" ref="modal" title="Message" @cancel="exitDialog"
      @ok="exitDialog">
      <form>
        <div class="row">
          <div class="col-md-12">
            <h6>{{ this.resetMessage }}</h6>
          </div>
        </div>
      </form>
    </b-modal>

        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <form @submit.prevent="updatePassword">
                    <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
                    <fade-render-transition>
                        <card>
                            <div slot="header">
                                <h3 class="card-title text-center">Reset password</h3>
                            </div>
                            <div>
                                <div class="phoneno-input-wrapper relative">
                                    <fg-input label="passsword" :type="textInputType" placeholder="Password"
                                        v-model="password">
                                    </fg-input>
                                    <div class="password-switch-icon">
                                        <img src="../../../assets/images/password-eye-close.png"
                                            v-if="textInputType == 'password'" @click="switchPasswordType('pswd')" />
                                        <img src="../../../assets/images/password-eye-open.png"
                                            v-if="textInputType == 'text'" @click="switchPasswordType('pswd')" />
                                    </div>

                                </div>
                                <p class="error-msg" v-if="showPasswordValid">{{ showPasswordValidErr }}</p>
                                <div class="phoneno-input-wrapper relative">
                                    <fg-input label="confirm passsword" :type="textInputTypeCnf" placeholder="Confirm Password"
                                        v-model="repassword">
                                    </fg-input>

                                    <div class="password-switch-icon">
                                        <img src="../../../assets/images/password-eye-close.png"
                                            v-if="textInputTypeCnf == 'password'"
                                            @click="switchPasswordType('cnfPswd')" />
                                        <img src="../../../assets/images/password-eye-open.png"
                                            v-if="textInputTypeCnf == 'text'" @click="switchPasswordType('cnfPswd')" />
                                    </div>
                                </div>

                                <p class="error-msg" v-if="showPasswordValid">{{ showPasswordValidErr }}</p>


                            </div>
                            <div class="text-center">
                                <button type="submit" class="btn btn-fill btn-info btn-round btn-wd">Submit</button>
                                <br>
                                <div class="forgot">
                                    <router-link to="/login" class="card-category">
                                        Login
                                    </router-link>
                                </div>
                            </div>
                        </card>
                    </fade-render-transition>

                </form>
            </div>
        </div>
    </auth-layout>
</template>


<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index';
import AuthLayout from './AuthLayout.vue';
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'

export default {
    name: "Repassword",
    components: {
        LCheckbox,
        FadeRenderTransition,
        AuthLayout,
        bModal,
        bModalDirective,
    },
    created() {
        this.idParamUid = this.$route.query.uid;
        this.idParamToken = this.$route.query.token;

    },
    methods: {

        gotoSingin() {
            this.$router.push("/signup");
        },
        switchPasswordType(type) {
            if (type == 'pswd') {
                this.textInputType =
                    this.textInputType == "password" ? "text" : "password";
            }
            else if (type == 'cnfPswd') {
                this.textInputTypeCnf =
                    this.textInputTypeCnf == "password" ? "text" : "password";
            }

        },
        exitDialog() {
            this.password = "";
            this.repassword = "";
        },
        validatepassword() {
			if (this.password == "" || this.repassword == "") {
				this.showPasswordValidBlank = true;
				return false;
			}
			if (this.password != this.repassword) {
				this.showPasswordMismatch = true;
				return false;
			} else {
				return true;
			}
		},
	
        updatePassword() {
            if (this.validatepassword()) {
			} else {
				return false;
			}

            const dataToSend = {
                "userId": this.idParamUid,
                "token": this.idParamToken,
                "password": this.password
            };
            this.$store
                .dispatch("updatePassword", dataToSend)
                .then((res) => {
                    this.resetMessagePopup = true;
                    this.resetMessage = response.message;

                })
                .catch((err) => {
                    this.resetMessagePopup = true;
                    this.resetMessage = err.response.data.error.message
                    
                });
        }

    },

    data() {
        return {
            password: "",
            repassword: "",
            textInputType: "password",
            textInputTypeCnf: "password",
            noService: false,
            activeTabEmail: true,
            activeTabPhone: false,
            showloginErr: false,
            loginErr: "",
            isActive: false,
            showresetpasswordErr: false,
            resetpasswordErr: "",
            isActive: false,
            doresetpassword: true,
            userid: "",
            password: "",
            repassword: "",
            idParamUid: "",
            idParamToken: "",
            resetMessage: '',
            resetMessagePopup: false,
            resetPwordMsgPopup: false,
            showPasswordValid: false,
            showPasswordValidErr: "Password can't be blank",
        };
    },
};
</script>
<style>
.passwordd-input-wrapper {
    position: relative;
}

.password-switch-icon {
    position: absolute;
    right: 18px;
    top: 34px;
    color: #c31e1e;
    cursor: pointer;
}

.password-switch-icon img {
    width: 18px;
}

.relative {
    position: relative;
}
</style>