<template>
  <div>
    <div class="d-flex justify-content-between p-3">
      <div>
        <span class="titleCard mb-2">
          {{ pageTitle }}
        </span>
      </div>
      <div class="d-flex justify-content-end">
        <div class="col-md-6">
          <div v-if="blog">
            <l-switch
              v-model="blog.isActive"
              on-text="ON"
              off-text="OFF"
              class="enableSwitch"
            ></l-switch>
          </div>
        </div>
      </div>
    </div>
    <card>
      <div class="uploadingOverlay" v-if="uploading">
        <l-spinner
          class="upload-spinner"
          :main="true"
          :title="spinnerTip"
          size="medium"
        ></l-spinner>
      </div>
      <DeleteConfirmation
        :currentDeleteMethod="confirmDeleteblog"
        ref="confirmLanguageDelete"
        @delete="$event()"
      />
      <form @submit.prevent="saveBlog" class="blog-form">
        <div class="row">
          <div class="col-md-6">
            <el-select
              v-validate="'required'"
              class="select-primary typewidth"
              name="blogType"
              size="large"
              placeholder="Blog Type"
              v-model="blog.type"
              :class="{ 'is-invalid': submitted && errors.has('blogType') }"
            >
              <el-option
                v-for="option in blogtypes"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label"
              >
              </el-option>
            </el-select>
          </div>

          <!--  <div class="col-md-12">
          <card title="Blog details">
            <div class="row">
            <div class="col-md-4">
                <fg-input label="Title" v-model="blog.name" placeholder="Blog Title"></fg-input>
            </div>            
            <div class="col-md-4">
                <fg-input label="Slug name"  v-model="blog.slug" placeholder="Slug name"></fg-input>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <fg-input class="col-sm-12 col-12 subtitlecontainer" label="Excerpt" maxlength="300" show-word-limit="true">
                        <textarea class="form-control" v-model="blog.excerpt" placeholder="type blog's Excerpt" rows="3" ></textarea>
                    </fg-input>
                </div>
            </div>
            </div>
          </card>  
        </div> -->
          <!-- <div class="col-md-12">
          <card title="Associated Catalogue">
            <div>
              <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <el-select class="select-default mb-3" style="width: 200px" v-model="pagination.perPage" placeholder="Per page">
                  <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                <el-input type="search" class="mb-3" style="width: 200px" placeholder="Search records" v-model="searchQuery" aria-controls="datatables"
                />
              </div>
              <div class="col-sm-12">
                <el-table stripe style="width: 100%;" :data="queriedData" border>
                  <el-table-column :min-width="50" fixed="left" label="">
                    <template slot-scope="props">
                      <l-checkbox :checked="isChecked(props)" @input="checkChanged($event,props)"></l-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label">
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <div class="">
                <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
              </div>
              <l-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total">
              </l-pagination>
            </div>
          </card>
        </div> -->
          <div class="col-md-12">
            <card title="Article Details">
              <div class="col-md-12">
                <!--<button class="btn btn-default" @click="addPlatform"></button>-->
              </div>

              <div class="col-xl-12 col-lg-12">
                <template slot="header">
                  <h4 class="card-title">Languages</h4>
                  <!-- <p class="card-category">Production Apps</p> -->
                </template>
                <el-collapse v-model="activeLanguageItems">
                  <el-collapse-item
                    v-for="(item, index) in blog.languages"
                    :title="getTitle(item)"
                    :name="index + 1"
                    :key="index"
                  >
                    <BlogLanguage
                      :currentLanguage="blog"
                      @deleteBlog="deleteBlogLanguage(0)"
                      :key="getKey(blog)"
                      @uploadStatus="setUploadStatus"
                    ></BlogLanguage>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </card>
          </div>

          <div class="col-md-12" v-if="blog">
            <card title="Rich text Editor">
              <vue-editor
                :customModules="customModulesForEditor"
                :editorOptions="editorSettings"
                id="editor"
                v-model="blog.richTextData"
                @image-added="handleImageAdded"
                :useCustomImageHandler="true"
              ></vue-editor>
              <!--  <ckeditor  :editor="editor" v-model="blog.richTextData" :config="editorConfig"></ckeditor> -->
            </card>
          </div>
          <!-- <div class="col-md-12">
          <Monetization ref="monetization" :setting="settings" :createmode="createMode"></Monetization>
        </div> -->

          <div class="col-md-12 col-12">
            <card title="Manage Meta Data">
              <ManageMetaData :metaData="metaData"></ManageMetaData>
            </card>
          </div>
          <div class="col-md-12">
            <button class="btn btn-primary save-blog" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem
} from "element-ui";
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import BlogLanguage from "../Components/BlogLanguage.vue";
import TVOD from "../Components/TVOD.vue";
import Monetization from "../Components/Monetization.vue";
import { VueEditor, Quill } from "vue2-editor";
import ManageMetaData from "../Components/ManageMetaData";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  //	props: ['id'],

  components: {
    FgInput,
    bModal,
    ManageMetaData,
    BlogLanguage,
    TVOD,
    Monetization,
    bModalDirective,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LSpinner,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LCheckbox,
    LPagination,
    VueEditor,
    DeleteConfirmation
  },

  data() {
    return {
      editorSettings: {
        modules: {}
      },
      customModulesForEditor: [],
      createMode: true,
      id: null,
      blog_type: "",
      catalogueIds: [],
      activeLanguageItems: [],
      metaData: [],
      submitted: false,
      selectedblogTitle: "Play blog",
      selectedUrl: "",
      currentMetaData: {
        key: "",
        value: ""
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      hostingProviders: [
        { value: "none", label: "None" },
        { value: "zype", label: "Zype" },
        { value: "vimeo", label: "Vimeo" }
      ],
      tvod: false,
      free: false,
      uploading: false,
      spinnerTip: "Uploading Images...",
      editPopupShow: false,
      searchQuery: "",
      propsToSearch: ["name", "type"],
      settings: {},
      blogProviderList: [],
      selectProviderList: [],
      pThumbnail: "https://dummyimage.com/300x180/a1a1a1/fff",
      blogDialogProviderConfig: {
        provider: null,
        title: "",
        url: "",
        id: ""
      },
      blogProviderConfig: {
        provider: null,
        title: "",
        url: "",
        id: ""
      },
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200
        },
        {
          prop: "type",
          label: "Type",
          minWidth: 100
        },
        {
          prop: "parents",
          label: "Parent",
          minWidth: 200
        },
        {
          prop: "itemsCount",
          label: "Items",
          minWidth: 120
        }
      ],
      fuseSearch: null,
      blogtypes: [
        {
          value: "amp",
          label: "amp"
        },
        {
          value: "social",
          label: "social"
        }
      ],
      blog: {
        isActive: true,
        name: "",
        slug: "",
        type: "",
        languages: [],
        catalogueIds: [],
        settings: {
          subscription: true,
          subscriptionIds: [],
          TVOD: false,
          AVOD: false,
          free: false
        }
      },
      switches: {
        defaultOn: true
      }
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.blog = null;
    if (this.id && this.id != -1) {
      this.createMode = false;
      this.$store
        .dispatch("getBlogBySlug", this.id)
        .then(response => {
          this.blog = response;
          this.metaData = this.blog.metaData;
        })
        .catch(() => {});
    } else {
      this.blog = this.blogLocalData;
      this.settings = {
        subscription: true,
        subscriptionIds: [],
        TVOD: false,
        AVOD: false,
        free: false
      };
    }
  },
  watch: {
    blog_type: function() {}
  },
  computed: {
    blogLocalData() {
      var Data = {
        isActive: true,
        catalogueIds: [],
        excerpt: "",
        richTextData: "",
        tags: [],
        languages: [
          {
            order: 1,
            publishDate: "1/1/2020",
            country: [],
            title: "",
            tags: [],
            thumbnailLink: {
              small: "https://dummyimage.com/600x400/ececec/fff",
              medium: "https://dummyimage.com/600x400/ececec/fff",
              large: "https://dummyimage.com/600x400/ececec/fff",
              source: "https://dummyimage.com/600x400/ececec/fff"
            },
            coverartLink: {
              small: "https://dummyimage.com/600x400/ececec/fff",
              medium: "https://dummyimage.com/600x400/ececec/fff",
              large: "https://dummyimage.com/600x400/ececec/fff",
              source: "https://dummyimage.com/600x400/ececec/fff"
            }
          }
        ],
        settings: {
          subscription: true,
          subscriptionIds: [],
          free: true
        }
      };
      return Data;
    },
    tableData() {
      return this.$store.state.catalogue.catalogueList;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["title", "tags"] }).search(
          this.searchQuery
        );
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    },
    isAdd() {
      return this.$route.path.indexOf("-1") > -1;
    },
    pageTitle() {
      return this.isAdd ? "Add Article" : "Edit Article";
    }
  },
  methods: {
    uploadPicture(file, type) {
      var self = this;
      var UrlToSend = "";
      return new Promise((resolve, reject) => {
        var uuid = this.$uuid.v1();
        var filename = file.name;
        var data = {};
        data.extension = filename.split(".").pop();
        data.fileKey =
          self.$store.state.customerId + "/" + uuid + "." + data.extension;
        data.contentType = file.type;
        self.uploadStatus = true;
        this.$store
          .dispatch("preSignedRequest", data)
          .then(response => {
            var signedUrl = response.signedRequest;
            var url = response.url;
            self.UrlToSend = response.url;
            var info = {};
            info.url = signedUrl;
            info.data = file;
            this.$store
              .dispatch("uploadImageToS3", info)
              .then(res => {
                //self.resetUrl(url,type);
                resolve(self.UrlToSend);
                self.uploadStatus = false;
              })
              .catch(() => {
                self.uploadStatus = false;
                this.$notify({
                  title: "Error",
                  message: "Uploading Image Failed!",
                  type: "warning"
                });
              });
          })
          .catch(() => {
            self.uploadStatus = false;
            this.$notify({
              title: "Error",
              message: "Uploading Image Failed!",
              type: "warning"
            });
          });
      });
    },
    //editorcode
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      self = this;

      this.uploadPicture(file).then(res => {
        Editor.insertEmbed(cursorLocation, "image", res);
        resetUploader();
      });
    },
    getKey(item) {
      if (item && item._id) {
        return item._id;
      } else {
        if (item && item.cId) {
          return item.cId;
        }
      }
    },
    getTitle(item) {
      if (item.title) {
        return `Edit ${item.title} Article Details`;
      }
      return "Add Article Details";
    },
    addLanguage() {
      var lang = {};
      lang.cId = this.$uuid.v4();
      lang.language = "english";
      lang.title = "";
      lang.description = "";
      lang.article = "";
      lang.thumbnail = "https://dummyimage.com/640x360/a1a1a1/fff";
      lang.featuredImage = "https://dummyimage.com/640x360/a1a1a1/fff";
      this.blog.languages.push(lang);
      this.activeLanguageItems.push(this.blog.languages.length);
    },
    isChecked(props) {
      if (this.catalogueIds) {
        return this.catalogueIds.includes(props.row.id);
      }
      return false;
    },
    checkChanged(val, props) {
      if (!this.catalogueIds || !Array.isArray(this.catalogueIds)) {
        this.sIds = [];
      }
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.catalogueIds.includes(id)) {
            this.catalogueIds.push(id);
          }
        } else {
          if (this.catalogueIds.includes(id)) {
            var index = this.catalogueIds.indexOf(id);
            this.catalogueIds.splice(index, 1);
          }
        }
      }
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    saveBlog() {
      this.submitted = true;
      if (this.createMode) {
        this.blog.metaData = this.metaData;
        this.$store
          .dispatch("createBlog", this.blog)
          .then(response => {
            var bData = response.data;
            this.createMode = false;
            this.$notify({
              title: "Success",
              message: "Blog created Successfully!",
              type: "success"
            });
            this.$router.push({ name: "All Blogs" });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Create Blog Failed!",
              type: "warning"
            });
            return;
          });
      } else {
        if (this.id) {
          this.blog.metaData = this.metaData;
          this.$store
            .dispatch("updateBlog", this.blog)
            .then(response => {
              var data = response.data;
              var blog = data.blog;
              if (blog) {
                this.blog = blog;
              }
              this.$notify({
                title: "Success",
                message: "Blog Updated Successfully!",
                type: "success"
              });
              this.$router.push({ name: "All Blogs" });
            })
            .catch(() => {
              this.$notify({
                title: "Error",
                message: "Update Blog Failed!",
                type: "warning"
              });
              return;
            });
        }
      }
    },

    jsUcfirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    deleteBlogLanguage(index) {
      this.deletedIndex = index;
      this.$refs.confirmLanguageDelete.show();
    },
    confirmDeleteblog() {
      if (this.blog.languages) {
        this.blog.languages.splice(this.deletedIndex, 1);
      }
    },

    setUploadStatus: function(value) {
      this.uploading = value;
    }
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["name", "email"] });
  }
};
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 18px;
}

.typewidth {
  width: 30%;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 30px;
}

.enableSwitch {
  float: right;
  position: relative;
  left: 32px;
}

.save-blog {
  float: left;
  position: relative;
  bottom: 32px;
}

.originaldetail {
  padding-left: 15px;
  padding-top: 25px;
}

.originalimage {
  padding: 0px;
  width: 95%;
}

.originalbutton {
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.monetizationcontainer {
  padding-top: 50px;
}

.blogidcontainer {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.card form.blog-form [class*="col-"] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
</style>
