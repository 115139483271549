<template>
  <div>
    <card>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center py-3">
            <div>
              <span class="titleCard mb-2">
                Authentication Provider
              </span>
            </div>
            <div
              class="d-flex justify-content-end align-items-center  searchContainer"
            >
              <div>
                <el-input
                  type="text"
                  style="width: 200px; "
                  placeholder="Search records"
                  ref="searchKey"
                  prefix-icon="el-icon-search"
                  v-model="searchQuery"
                  aria-controls="datatables"
                  clearable
                />
              </div>
              <div>
                <span class="demo-input-label ml-2 perPage"
                  >Results per page:</span
                >
                <el-select
                  class="select-default ml-1"
                  v-model="pagination.perPage"
                  style="width: 80px; "
                  placeholder="Per page"
                >
                  <el-option
                    class="select-default"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  ></el-option>
                </el-select>
              </div>

              <div class="mx-2 mt-1">
                <el-button
                  type="primary"
                  icon="el-icon-plus"
                  v-b-modal.newPlatformModal
                  @click="addAuthProvider"
                  >Add Provider</el-button
                >
              </div>
            </div>
          </div>
          <card>
            <div class="col-sm-12">
              <el-table stripe style="width: 100%;" :data="queriedData" border>
                <el-table-column
                  v-for="column in tableColumns"
                  :key="column.label"
                  :min-width="column.minWidth"
                  :prop="column.prop"
                  :label="column.label"
                >
                </el-table-column>
                <el-table-column :min-width="120" fixed="right" label="Actions">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-warning btn-simple btn-link"
                      v-b-modal.newPlatformModal
                      @click="handleEdit(props.$index, props.row)"
                      ><i class="fa fa-edit"></i
                    ></a>
                    <a
                      v-tooltip.top-center="'Delete'"
                      class="btn-danger btn-simple btn-link"
                      @click="handleDelete(props.$index, props.row)"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div>
          </card>
        </div>
      </div>
      <DeleteConfirmation
        :currentDeleteMethod="deleteVideoHost"
        ref="confirmDeleteVideoHost"
        @delete="$event()"
      />
      <DeleteConfirmation
        :currentDeleteMethod="deleteConfigData"
        ref="confirmDeletConfigData"
        @delete="$event()"
      />
      <b-modal
        id="newPlatformModal"
        ref="modal"
        @ok="saveHostProvider"
        @cancel="exitDialog"
        :title="getDialogTitle()"
        ok-title="Save"
      >
        <form>
          <div class="row">
            <fg-input
              class="col-md-6 col-6"
              label="Name"
              placeholder="Name"
              v-model="curData.name"
            ></fg-input>
            <fg-input
              class="col-md-6 col-6"
              label="Slug"
              placeholder="Slug"
              v-model="curData.slug"
            ></fg-input>
          </div>
          <div class="row">
            <fg-input class="col-md-12" label="Description">
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                v-model="curData.description"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
          <div class="row">
            <fg-input
              class="col-md-5"
              label="Config Name"
              placeholder="Config Name"
              v-model="currentConfigData.key"
            ></fg-input>

            <fg-input
              class="col-md-5"
              label="Config Parameter"
              placeholder="Config Parameter"
              v-model="currentConfigData.value"
            ></fg-input>

            <div class="col-md-2 addBtn">
              <button class="btn btn-primary" @click.prevent="addConfigData()">
                Add
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <el-table
                class="table-bigboy"
                style="width: 100%;"
                :data="configData"
                border
              >
                <el-table-column min-width="120" label="Key">
                  <template slot-scope="{ row }">
                    {{ row.key }}
                  </template>
                </el-table-column>
                <el-table-column min-width="140" label="Value">
                  <template slot-scope="{ row }">
                    {{ row.value }}
                  </template>
                </el-table-column>
                <el-table-column min-width="100" label="Action">
                  <template slot-scope="props">
                    <a
                      v-tooltip.top-center="'Edit'"
                      class="btn-warning btn-simple btn-link"
                      @click="handleEditConfigData(props.$index, props.row)"
                      ><i class="fa fa-edit"></i
                    ></a>
                    <a
                      v-tooltip.top-center="'Delete'"
                      class="btn-danger btn-simple btn-link"
                      @click.prevent="handleDeleteConfigData(props.$index)"
                      ><i class="fa fa-trash"></i
                    ></a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </form>
      </b-modal>
      <b-modal
        id="configdatas"
        ref="modal"
        title="Edit Config Data"
        @ok="checkConfigDataValue"
        ok-title="Save"
      >
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-4">
              <fg-input label="Key" v-model="editConfigData.key"></fg-input>
            </div>
            <div class="col-sm-4">
              <fg-input label="Value" v-model="editConfigData.value"></fg-input>
            </div>
          </div>
        </div>
      </b-modal>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import Vue from "vue";
export default {
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["name", "slug"] }).search(
          this.searchQuery
        );
      }

      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  data() {
    return {
      tableData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      dMode: "",
      searchQuery: "",
      configData: [],
      currentConfigData: {
        key: "",
        value: ""
      },
      editConfigData: {
        key: "",
        value: ""
      },
      editConfigDataIndex: "",
      deleteConfigDataIndex: "",
      curData: {
        name: "",
        slug: "",
        description: "",
        configObj: {}
      },
      authTypes: [
        {
          label: "Api Keys",
          value: "Api keys"
        },
        {
          label: "Client Secret",
          value: "Client Secret"
        }
      ],
      tableColumns: [
        {
          prop: "slug",
          label: "Slug",
          minWidth: 150
        },
        {
          prop: "name",
          label: "Name",
          minWidth: 150
        },
        {
          prop: "description",
          label: "Description",
          minWidth: 250
        }
      ]
    };
  },
  created() {
    this.$store
      .dispatch("getAuthProviderList")
      .then(response => {
        if (response) {
          this.tableData = response;
        }
      })
      .catch(() => {});
  },
  methods: {
    getDialogTitle() {
      if (this.dMode == "create") {
        return "Create Authentication Provider";
      } else if (this.dMode == "edit") {
        return "Update Authentication Provider";
      }
      return "";
    },
    deleteVideoHost() {
      if (this.dMode == "delete" && this.deleteId) {
        this.$store
          .dispatch("deleteAuthProvider", this.deleteId)
          .then(response => {
            this.tableData.splice(this.deleteIndex, 1);
          })
          .catch(() => {});
      }
    },
    exitDialog() {
      this.dMode = "";
      this.curData = {
        name: "",
        slug: "",
        description: "",
        configObj: {}
      };
    },
    saveHostProvider() {
      this.curData.configObj = {};
      for (var i = 0; i < this.configData.length; i++) {
        this.curData.configObj[this.configData[i].key] = this.configData[
          i
        ].value;
      }
      if (this.dMode == "create") {
        this.$store
          .dispatch("createAuthProvider", this.curData)
          .then(response => {
            this.tableData.splice(0, 0, response.data.authProvider);
            this.$notify({
              title: "Success",
              message: "Auth Provider created Successfully!",
              type: "success"
            });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Create Auth Provider Failed!",
              type: "warning"
            });
          });
      } else if (this.dMode == "edit" && this.curData.id) {
        var req = {};
        req.data = this.curData;
        req.id = this.curData.id;
        this.$store
          .dispatch("updateAuthProvider", req)
          .then(response => {
            this.tableData.splice(this.curIndex, 1);
            this.tableData.splice(this.curIndex, 0, response);
            this.$notify({
              title: "Success",
              message: "Auth Provider updated Successfully!",
              type: "success"
            });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Update Auth Provider Failed!",
              type: "warning"
            });
          });
      }
      this.dMode = "";
      this.curData = {
        name: "",
        slug: "",
        description: "",
        configObj: {}
      };
      this.configData = [];
    },
    handleEdit(index, row) {
      this.dMode = "edit";
      var pageNumbs =
        (this.pagination.currentPage - 1) * this.pagination.perPage;
      this.currentConfigData = {
        key: "",
        value: ""
      };
      this.configData = [];
      this.curData = JSON.parse(JSON.stringify(row));
      if (this.curData.configObj) {
        for (var key in this.curData.configObj) {
          var item = {};
          item.key = key;
          item.value = this.curData.configObj[key];
          this.configData.push(item);
        }
      }
      this.curIndex = pageNumbs + index;
    },
    handleDelete(index, row) {
      this.dMode = "delete";
      var pageNumbs =
        (this.pagination.currentPage - 1) * this.pagination.perPage;
      this.deleteId = row.id;
      this.deleteIndex = pageNumbs + index;
      this.$refs["confirmDeleteVideoHost"].$refs["deleteConfirmation"].show();
    },
    addAuthProvider() {
      this.dMode = "create";
      this.currentConfigData = {
        key: "",
        value: ""
      };
      this.configData = [];
    },
    addConfigData() {
      if (
        this.currentConfigData.key == "" ||
        this.currentConfigData.value == ""
      ) {
        this.$notify({
          title: "Error",
          message: "ConfigData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      this.configData.push(JSON.parse(JSON.stringify(this.currentConfigData)));
      this.currentConfigData = {
        key: "",
        value: ""
      };
    },
    handleDeleteConfigData(index) {
      this.deleteConfigDataIndex = index;
      this.$refs["confirmDeletConfigData"].$refs["deleteConfirmation"].show();
    },
    deleteConfigData() {
      this.configData.splice(this.deleteConfigDataIndex, 1);
    },
    handleEditConfigData: function(index, row) {
      this.$refs.modal.show("#configdatas");
      this.editConfigData = JSON.parse(JSON.stringify(row));
      // this.editMetaDataValue = row;
      this.editConfigDataIndex = index;
      //this.metaData[this.editMetaDataIndex] = this.editMetaDataValue;
    },
    checkConfigDataValue: function(index, row) {
      if (this.editConfigData.key == "" || this.editConfigData.value == "") {
        this.$notify({
          title: "Error",
          message: "ConfigData Fields are Invalid Format!",
          type: "warning"
        });

        return;
      }
      Vue.set(this.configData, this.editConfigDataIndex, this.editConfigData);
    }
  },
  mounted() {}
};
</script>

<style scoped>
/* .subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
} */
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 14px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
.addBtn {
  margin-top: 28px;
}
</style>
