<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Payment Gateway Report
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-3">
                <label>From</label>
                <el-date-picker v-model="startDate" type="date">
                </el-date-picker>
              </div>
              <div class="col-md-3">
                <label>To</label>
                <el-date-picker v-model="endDate" type="date"> </el-date-picker>
              </div>
              <div class="col-md-2">
                <label>Status</label>
                <el-select class="select-default" v-model="type">
                  <el-option
                    class="select-default"
                    v-for="item in pgType"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <label>Payment Gateway</label>
                <el-select class="select-default" v-model="gateway">
                  <el-option
                    class="select-default"
                    v-for="item in gatewayOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-2">
                <button
                  class="btn btn-primary btn-fill"
                  :disabled="!startDate || !endDate || !type || !gateway"
                  style="margin-top:2em;"
                  @click="getUserReport"
                >
                  Get Report
                </button>
              </div>
            </div>
          </div>

          <div class="col-sm-12 p-3">
            <el-table
              stripe
              style="width: 100%;"
              :data="queriedData"
              border
              v-if="showReport"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Select,
  Option,
  DatePicker,
  Button
} from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { Loading } from "element-ui";

import moment from "moment";
import Fuse from "fuse.js";

export default {
  name: "PaymentGatewayReport",
  components: {
    [Button.name]: Button,
    LPagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {},
  data() {
    return {
      startDate: null,
      endDate: null,
      type: null,
      gateway: null,
      pgType: [
        {
          label: "Active",
          value: "ACTIVE"
        },
        {
          label: "In-Active",
          value: "INACTIVE"
        }
      ],
      data: [],
      gatewayOptions: [],
      count: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "user.first_name",
          label: "First Name",
          minWidth: 150
        },
        {
          prop: "user.last_name",
          label: "Last Name",
          minWidth: 150
        },
        {
          prop: "user.email_address",
          label: "Email",
          minWidth: 150
        },
        {
          prop: "user.country",
          label: "Country",
          minWidth: 120
        },
        {
          prop: "package.title",
          label: "Package",
          minWidth: 150
        },
        {
          prop: "subscription.title",
          label: "Subscription",
          minWidth: 150
        }
      ],
      showReport: false
    };
  },
  computed: {
    tableData() {
      return this.data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: [
            "user.full_name",
            "user.email_address",
            "subscription.title",
            "package.title"
          ]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {
    this.$store
      .dispatch("getPaymentProviderList")
      .then(response => {
        if (response) {
          var pData = response;
          for (var i = 0; i < pData.length; i++) {
            var item = pData[i];
            if (item.isActive) {
              var option = {};
              option.label = item.name;
              option.value = item.slug;
              this.gatewayOptions.push(option);
            }
          }
        }
      })
      .catch(() => {});
  },
  methods: {
    getUserReport: function() {
      var self = this;
      this.showReport = true;
      var form = {};
      form.fromDate = moment(this.startDate).format("YYYY-MM-DD");
      form.toDate = moment(this.endDate).format("YYYY-MM-DD");
      form.slug = this.gateway;
      form.type = this.type;
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("getPaymentGatewayReport", form)
        .then(res => {
          self.count = res.count;
          self.data = res.data;
          for (var i = 0; i < self.data.length; i++) {
            self.data[i].user["full_name"] =
              self.data[i].user.first_name + " " + self.data[i].user.last_name;
          }
          loadingInstance.close();
        })
        .catch(() => {
          loadingInstance.close();
        });
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 15px;
}
</style>
