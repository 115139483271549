<template>
  <div class="row">
    <b-modal
      id="newPlatformModal"
      v-modal="promoPopupShow"
      @ok="handleNewPlatformOk"
      @shown="clearNewPlatformName"
      title="Add a new platform"
    >
      <form @submit.stop.prevent="handleNewPlatform">
        <div class="row">
          <fg-input
            class="col-sm-12 col-12 subtitlecontainer"
            label="Subscription Title"
            value="title"
          ></fg-input>
        </div>
        <div class="col-md-12">
          <div class="row">
            <fg-input
              class="col-sm-12 col-12 subtitlecontainer"
              label="Description"
            >
              <textarea
                class="form-control"
                placeholder="Here can be your nice text"
                rows="3"
              ></textarea>
            </fg-input>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <fg-input
              class="col-md-3 col-3 subtitlecontainer"
              label="Price"
            ></fg-input>
            <div class="col-md-4">
              <label>Currency</label>
              <el-select
                label="Currency"
                class="select-default subtitlecontainer"
                size="large"
                placeholder="Currency"
                v-model="selectedCurrency"
              >
                <el-option
                  v-for="curr in currencies"
                  class="select-danger"
                  :value="curr.value"
                  :label="curr.label"
                  :key="curr.label"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-md-5">
              <label>Country</label>
              <el-select
                class="select-default subtitlecontainer"
                size="large"
                placeholder="Country"
                v-model="selectedCountry"
              >
                <el-option
                  v-for="count in countries"
                  class="select-danger"
                  :value="count.value"
                  :label="count.label"
                  :key="count.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row">
            <fg-input class="col-md-3 col-3" label="Duration"></fg-input>
            <div class="col-md-4">
              <label>Period</label>
              <el-select
                class="select-default"
                size="large"
                placeholder="Period"
                v-model="selectedPeriod"
              >
                <el-opton
                  v-for="per in period"
                  class="select-default"
                  :value="per.value"
                  :label="per.label"
                  :key="per.label"
                >
                </el-opton>
              </el-select>
            </div>
            <div class="col-md-5">
              <label>Trial</label>
              <el-select
                class="select-default"
                size="large"
                placeholder="Trial"
                v-model="selectedTrial"
              >
                <el-option
                  v-for="trial in trials"
                  class="select-default"
                  :value="trial.value"
                  :label="trial.label"
                  :key="trial.label"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="col-md-12">
      <card title="Promotional Codes">
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div class="add-button">
            <button class="btn btn-primary btn-fill" @click="addPromo">
              Add
            </button>
          </div>
          <div class="col-sm-12">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column :min-width="50" fixed="left" label="">
                <template slot-scope="props">
                  <l-checkbox></l-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Checkbox as LCheckbox } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Table, TableColumn } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";

export default {
  components: {
    LCheckbox,
    bModal,
    bModalDirective,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LPagination
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      promoPopupShow: false,
      searchQuery: "",
      propsToSearch: ["name", "type"],
      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 200
        },
        {
          prop: "type",
          label: "Type",
          minWidth: 100
        },
        {
          prop: "parent",
          label: "Parent",
          minWidth: 200
        },
        {
          prop: "itemcount",
          label: "Items",
          minWidth: 120
        }
      ],

      fuseSearch: null
    };
  },

  computed: {
    tableData() {
      var obj = this.$store.state.monetization.promotion.promotionData;
      return obj;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  methods: {
    handleLike(index, row) {
      alert(`Your want to like ${row.name}`);
    },
    handleEdit(index, row) {
      alert(`Your want to edit ${row.name}`);
    },
    handleDelete(index, row) {
      let indexToDelete = this.tableData.findIndex(
        tableRow => tableRow.id === row.id
      );
      if (indexToDelete >= 0) {
        this.tableData.splice(indexToDelete, 1);
      }
    },
    updateVideoOrigin() {
      this.editPopupShow = true;
    },
    addPromo() {
      this.promoPopupShow = true;
    }
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["name", "email"] });
  }
};
</script>

<style scoped>
.add-button {
}
</style>
