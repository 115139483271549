import Vue from "vue";
import VueRouter from "vue-router";
import LightBootstrap from "./light-bootstrap-main";
import BootstrapVue from "bootstrap-vue";
// Plugins
import App from "./App.vue";
import { store } from "./store/store";
// router setup
import routes from "./routes/routes";

import VeeValidate from "vee-validate";
import UUID from "vue-uuid";
import VueClipboards from "vue-clipboards";
import JsonExcel from "vue-json-excel";
// import CKEditor from '@ckeditor/ckeditor5-vue'

import { VueEditor, Quill } from "vue2-editor";
// plugin setup
Vue.use(VueRouter);
Vue.use(VeeValidate);
Vue.use(LightBootstrap);
Vue.use(BootstrapVue);
Vue.use(UUID);
Vue.use(VueClipboards);
// Vue.use(CKEditor)
Vue.component("downloadExcel", JsonExcel);
// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});
router.beforeEach((to, from, next) => {
  router.app.$store.commit("setAuthorization", true);
  next();
});
/* eslint-disable no-new */
var app = new Vue({
  store: store,
  el: "#app",
  render: h => h(App),
  router
});

export default app;
