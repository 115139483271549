<template>
  <auth-layout pageClass="login-page">
    <b-modal id="newConfigModal" v-model="loginMessagePopup" ref="modal" @ok="handleLoginMessagePopupOK"
      title="Message">
      <form @submit.stop.prevent="handleNewConfig">
        <div class="row">
          <div class="col-md-12">
            <h3>{{ this.loginMessage }}</h3>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form>
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Login</h3>
              </div>
              <div v-if="!showVerificationCodeInput">
                <fg-input label="Email address" placeholder="Enter email" type="email" name="email"
                  v-validate="'required|email'" v-model="model.email">

                </fg-input>
                <p class="error-msg" v-if="showEmailValid">{{ showEmailValidErr }}</p>
                <div class="alerttext">
                  <p v-if="errors.has('email')">{{ errors.first('email') }}</p>
                </div>
                <fg-input label="passsword" type="password" placeholder="Password" v-model="model.password">
                </fg-input>
                <p class="error-msg" v-if="showPasswordValid">{{ showPasswordValidErr }}</p>
                <!--
                <fg-input>
                  <l-checkbox v-model="model.subscribe">
                    Subscribe to newsletter
                  </l-checkbox>
                </fg-input>
              -->
              </div>
              <div v-if="showVerificationCodeInput">
                <div class="form-group">
                  <p class="text-green text-center">
                    Enter the verification code sent to <br />{{ model.email }} to continue
                  </p>
                </div>
                <div class="form-group">
                  <div class="input-group verify">
                    <input class="code-input form-control" v-model="dataCode" />
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button v-if="!showVerificationCodeInput" v-on:click="loginHandler()" type="button"
                  class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                <button v-if="showVerificationCodeInput" v-on:click="loginWithVerificationCode()" type="button"
                  class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                <br>
                <div class="forgot">
                  <router-link to="/forgotpassword" class="card-category">
                    Forgot your password?
                  </router-link>
                </div>
                
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index'
import AuthLayout from './AuthLayout.vue'
import axios from 'axios'
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'

import UserService from '../../../service/userService'
import AuthService from '../../../service/authService'

export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    bModal,
    bModalDirective,
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        subscribe: true
      },
      userId: '',
      role: '',
      loginToken: '',
      loginMessagePopup: false,
      loginMessage: '',
      regEmail: "",
      dataCode: "",
      dataCode1: {
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
      },
      showVerificationCodeInput: false,
      showEmailValid: false,
      showEmailValidErr: "Invalid Email",
      showPasswordValid: false,
      showPasswordValidErr: "Password can't be blank",
    }
  },

  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    loginHandler() {
      var shajs = require('sha.js');
      var password = shajs('sha256').update(this.model.password).digest('hex');

      if (this.ValidateEmail() == true && this.ValidPassword() == true) {
        axios.post(this.$store.state.base_url + '/api/v1/admin/login', {
          email: this.model.email,
          password: password,
        })
          .then((response) => {
            response = response.data;
            this.showVerificationCodeInput = true;
          })
          .catch((err) => {

            this.loginMessage = err.response.data.error.message;
            this.loginMessagePopup = true;

          })
      }
    },
    loginWithVerificationCode() {
      var verificationCode = this.dataCode;
      var shajs = require('sha.js');
      var password = shajs('sha256').update(this.model.password).digest('hex');
      var self = this;
      axios.post(this.$store.state.base_url + '/api/v1/admin/login', {
        email: self.model.email,
        password: password,
        code: verificationCode
      })
        .then(function (response) {
          response = response.data;
          if (response.user.emailVerified) {
            self.userId = response.userId;
            self.role = response.user.role;
            if (response.loginToken) {
              AuthService.setAuthData(response.loginToken);
              UserService.setUserInfo(response.user);
            }
            self.loginToken = response.loginToken;
            self.$store.state.login.token = response.loginToken;
            self.$store.dispatch("getCustomerListByAdminId", response.userId).then(response => {
              if (Array.isArray(response)) {
                if (response[0] && response[0].id) {
                  UserService.setCustomerId(response[0].id);
                  self.$store.commit('setCustomerId', response[0].id);
                }
              }
              self.$router.push({ name: 'Videos' });
            }).catch(() => {

            });
          } else if (!response.user.emailVerified) {
            self.loginMessage = "Your email hasn't been verified yet.";
            self.loginMessagePopup = true;
          }
        })
        .catch(function (err) {
          self.loginMessage = err.response.data.error.message;
          self.loginMessagePopup = true;

        })
    },
    handleLoginMessagePopupOK() {

    },
    ValidateEmail() {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.model.email.match(mailformat)) {
        return true;
      }

      else {
        this.showEmailValid = true;
        return false;
      }
    },
    ValidPassword() {
      if (this.model.password == "") {
        this.showPasswordValid = true;
        return false;
      } else {
        return true;
      }
    },
  },
  beforeDestroy() {
    this.closeMenu()
  },
  mounted() {
    //  axios.get('http://google.ca').then(response=>(this.info = response))

  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.code-input.form-control {
  background-color: #FFFFFF;
  border: 1px solid #E3E3E3;
  border-radius: 4px;
  font-size: 16px;
  color: #565656;
  padding: 3px 3px;
  height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-align: center !important;
  margin: 5px;
}

.code-input.form-control:first-child {
  border: 1px solid #E3E3E3;
}

.code-input.form-control:last-child {
  border: 1px solid #E3E3E3;
}

.error-msg {
  font-size: 10px;
  font-weight: 400;
  color: #333333
}
</style>
