<template>
  <div>
    <div class="uploadingOverlay" v-if="showLoader.show">
      <l-spinner
        class="upload-spinner"
        :main="true"
        :title="showLoader.text"
        size="medium"
      ></l-spinner>
    </div>
    <router-view v-if="$store.state.base_url"></router-view>
  </div>
</template>

<script>
import { Spinner as LSpinner } from "src/components/index";
import { Loading } from "element-ui";
export default {
  components: {
    LSpinner
  },
  computed: {
    showLoader() {
      return this.$store.state.loader;
    }
  },
  beforeCreate() {
    this.$store.dispatch("getConfig");
  },
  created(){
  console.log("App Mastery-CMS(V-3.0.12)")
  }
};
</script>
