<template>
  <div class="row">
    <DeleteConfirmation :currentDeleteMethod="deleteUser" @delete="$event()" />
    <b-modal ref="importDialog" title="Import Result" ok-variant="danger">
      <strong>{{ message }}</strong>
      <div>
        <p>Failed records:</p>
      </div>
      <textarea rows="6" class="form-control border-input" v-model="errorMessage"></textarea>
    </b-modal>

    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Users
          </span>
        </div>
        <div class="d-flex justify-content-end align-items-center searchContainer">
          <div>
            <el-input type="text" style="width: 200px; " placeholder="Search records" ref="searchKey"
              prefix-icon="el-icon-search" v-model="searchQuery" @input="debounceInput" aria-controls="datatables"
              @clear="clearSearch" clearable />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select class="select-default ml-1" v-model="pagination.perPage" style="width: 80px; "
              placeholder="Per page" :change="changeperpage(pagination.perPage)">
              <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
          <div class="mx-2 mt-1">
            <el-button type="info" @click="openImportUser">
              Import
            </el-button>
          </div>
          <div class="mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addUser">Add User
            </el-button>
          </div>
          <div class="mt-1 mx-1">
            <el-button type="info" @click="exportUser">Export
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-sm-12 p-3">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column fixed :min-width="280" label="User Name">
                <template slot-scope="props">
                  {{ getUserName(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="200" label="Name">
                <template slot-scope="props">
                  {{ getName(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="100" label="Country">
                <template slot-scope="props">
                  {{ getCountry(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="230" label="Status">
                <template slot-scope="props">
                  {{ getStatus(props.row) }}
                </template>
              </el-table-column>

              <el-table-column :min-width="200" label="Last Activity">
                <template slot-scope="props">
                  {{ getLastActive(props.row) }}
                </template>
              </el-table-column>
              <el-table-column :min-width="100" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a v-tooltip.top-center="'Edit'" class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"><i class="fa fa-edit"></i></a>
                  <a v-tooltip.top-center="'Delete'" class="btn-danger btn-simple btn-link" v-b-modal.confirmDelete
                    @click="handleDelete(props.$index, props.row)"><i class="fa fa-trash"></i></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of
              {{ pagination.total }} entries
            </p>
          </div>
          <l-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
            :total="pagination.total" @input="clickCallback(pagination.currentPage)">
          </l-pagination>
        </div>
      </card>
      <input type="file" ref="userImportInput" accept=".csv" v-bind:style="{ display: 'none' }"
        @change="importUserData" />
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";
import { debounce } from "debounce";
import moment from "moment";
import countries from "@/config/non-continent-countries.json";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    DeleteConfirmation
  },
  data() {
    return {
      message: "",
      errorMessage: "",
      searchQuery: "",
      propsToSearch: ["username", "email"],

      fuseSearch: null
    };
  },

  computed: {
    pagination() {
      return this.$store.getters.getAppUsersPagination;
    },
    tableData() {
      //testing cicd
      //alert("updating tbl data")
      var obj = this.$store.state.admin.adminUsers.listAll;
      return obj;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      // if (this.searchQuery !== "") {
      // result = new Fuse(this.tableData, {
      // keys: ["username", "telephone", "first_name", "last_name", "state"]
      // }).search(this.searchQuery);
      // }
      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },

  mounted() { },
  created() {
    this.$store.commit("setLoader", {
      show: true,
      text: "Fetching Admin AppUser List..."
    });
    this.$store
      .dispatch("getAdminAppUserList")
      .then(response => {
        this.$store.commit("setLoader", {
          show: false,
          text: ""
        });
        // this.tableData = response;
      })
      .catch(() => {
        this.$store.commit("setLoader", {
          show: false,
          text: ""
        });
      });
  },

  methods: {
    clickCallback: function (pagNum) {
      if (this.$store.state.admin.adminUsers.inputPage != pagNum) {
        this.$store.commit("setAppUserInputPage", pagNum);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Admin AppUser List..."
        });
        this.$store.dispatch("getAdminAppUserList").then(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        });
        return true;
      }
    },
    changeperpage: function (perPage) {
      if (this.$store.state.admin.adminUsers.inputSize != perPage) {
        this.$store.commit("setAppUserInputPage", 1);
        this.$store.commit("setAppUserInputPerpage", perPage);
        this.$store.commit("setLoader", {
          show: true,
          text: "Fetching Admin AppUser List..."
        });
        this.$store
          .dispatch("getAdminAppUserList")
          .then(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          })
          .catch(() => {
            this.$store.commit("setLoader", {
              show: false,
              text: ""
            });
          });
        return true;
      }
    },
    handleEdit: function (index, row) {
      this.$router.push({ name: "Edit User", params: { id: row.id } });
    },
    handleDelete: function (index, row) {
      this.deleteId = row.id;
    },
    deleteUser: function () {
      if (this.deleteId) {
        this.$store
          .dispatch("AdminDeleteAppUser", this.deleteId)
          .then(data => {
            var deletedIndex = this.findWithAttr(
              this.tableData,
              "id",
              this.deleteId
            );
            this.tableData.splice(deletedIndex, 1);
          })
          .catch(() => { });
      }
    },
    addUser: function () {
      this.$router.push({ name: "Add User" });
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    getLastActive: function (row) {
      if (row.lastLogin) {
        return moment(row.lastLogin, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
      } else {
        return "NA";
      }
    },
    getUserName: function (row) {
      if (row.username) {
        return row.username;
      } else {
        return "NA";
      }
    },
    getCountry: function (row) {
      if (row.country) {
        var cont = countries.find(con => {
          return con.value == row.country;
        });
        if (cont) {
          return cont.label;
        }
      } else {
        return "NA";
      }
    },
    getName: function (row) {
      if (row.first_name || row.last_name) {
        if (row.first_name) {
          var firsName = row.first_name;
        } else {
          var firsName = "";
        }
        if (row.first_name) {
          var lastName = row.last_name;
        } else {
          var lastName = "";
        }
        var Username = firsName + " " + lastName;
        return Username;
      } else {
        return "NA";
      }
    },
    getStatus: function (row) {
      var status = row.status;
      switch (status) {
        case "EMAIL_VERIFICATION_REQUIRED":
          return "Email Verification Pending";
        case "MOBILE_VERIFICATION_REQUIRED":
          return "Mobile Verification Pending";
        case "FORCE_RESET_PASSWORD":
          return "Force Reset Password";
        case "ACCOUNT_VERIFIED":
          return "Account Verified";
        case "ACCOUNT_DISABLED":
          return "Account Disabled";
        default:
          return "NA";
      }
    },
    generateErrorMessage: function () {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    openImportUser: function (evt) {
      evt.stopPropagation();
      evt.preventDefault();
      var file = this.$refs.userImportInput;
      if (file) {
        file.click();
      }
    },
    importUserData: function (evt) {
      let file = evt.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      this.$store.commit("setLoader", {
        show: true,
        text: "Importing User Data..."
      });
      this.$store
        .dispatch("importUserData", formData)
        .then(response => {
          this.$store.commit("setLoader", {
            show: false,
            text: " "
          });
          if (
            response.message &&
            response.errorDatas &&
            response.errorDatas.length > 0
          ) {
            this.message = response.message;
            this.errorsData = response.errorDatas;
            this.generateErrorMessage();
            this.$refs.importDialog.show();
            return;
          }
          this.$notify({
            title: "Success",
            message: "Successfully Imported!",
            type: "success"
          });
        })
        .catch(() => {
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    exportUser() {
      this.$store.commit("setLoader", {
        show: true,
        text: "Exporting Users"
      });
      this.$store.dispatch("exportUserData").then(response => {
        var blob = new Blob([response]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "formresults.csv";
        link.click();
        this.$store.commit("setLoader", { show: false, text: "" });
      })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
    },
    searchUser: function () {
      this.$store.commit("setLoader", {
        show: true,
        text: "Searching"
      });
      this.$store.commit("setAppUserInputPage", 1);
      this.$store.commit("setUserSearchText", this.$refs.searchKey.value);
      this.$store
        .dispatch("searchUserList")
        .then(response => {
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
      return true;
    },
    clearSearch: function () {
      if (this.$store.state.admin.adminUsers.searchKey != "") {
        this.$store.commit("setUserSearchText", "");
        this.$store.dispatch("getAdminAppUserList");
      }
    },
    debounceInput: debounce(function () {
      this.searchUser();
    }, 800)
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}

.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
}

.btn-import {
  float: right;
  margin-right: 20px;
}
button:focus {
    outline: none;
}
</style>
