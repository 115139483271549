import Vue from "vue";
import Vuex from "vuex";
import customAxios from "../service/axios";
import axios from "axios";
import moment from "moment";
import UserService from "../service/userService";
import AuthService from "../service/authService";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    loader: { show: false, text: "" },
    login: {
      token: ""
    },
    register: {
      data: {}
    },
    configData: {},
    base_url: "",
    mixPanelToken: "",
    customerId: UserService.getCustomerId(),
    monetization: {
      promotion: {
        promotionData: [
          {
            name: "Breaking Bad Season 1",
            type: "season",
            parent: "Breaking Bad",
            itemcount: 34
          },
          {
            name: "Top Movies",
            type: "playlist",
            parent: "",
            itemcount: 423
          }
        ]
      },
      svod: {
        apps: [
          {
            configparam: "logo",
            configname: "Logo",
            configvalue: "http://localhost/logo.png",
            readonly: true
          },
          {
            configparam: "welcomemsg",
            configname: "Welcome Message",
            configvalue: "Welcome",
            readonly: true
          },
          {
            configparam: "authoptions",
            configname: "authotpion",
            configvalue: "4",
            readonly: true
          },
          {
            configparam: "logo",
            configname: "Logo",
            configvalue: "http://localhost/logo.png",
            readonly: true
          },
          {
            configparam: "logo",
            configname: "Logo",
            configvalue: "http://localhost/logo.png",
            readonly: true
          },
          {
            configparam: "logo",
            configname: "Logo",
            configvalue: "http://localhost/logo.png",
            readonly: true
          }
        ]
      },
      subscription: {
        ListAll: []
      }
    },
    adprovider: [
      {
        configs: [{}],
        isActive: true,
        adProviderId: "Dummyid2",
        slug: "dummyslug1",
        name: "dummy name 1",
        description: " discription",
        type: "xyz",
        metaData: {},
        id: "dsdssdsd5656565dsddsddd65d56d6s5d"
      },
      {
        configs: [{}],
        isActive: true,
        adProviderId: "Dummyid2",
        slug: "dummyslug2",
        name: "dummy name2",
        description: " discription2",
        type: "xyz2",
        metaData: {},
        id: "dsdssdsd5656565dsdd65d56d6s5d"
      },
      {
        configs: [{}],
        isActive: true,
        adProviderId: "Dummyid2",
        slug: "dummyslug",
        name: "dummy name 3",
        description: " discription",
        type: "xyz",
        metaData: {},
        id: "dsdssdsd5656565ds65d5dsdd6d6s5d"
      }
    ],
    videos: {
      videoList: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      inputPage: 1,
      inputSize: 10,
      searchKey: ""
    },
    notifications: {
      providers: [
        {
          id: "1asasas",
          type: "all",
          title: "Send To All",
          configObj: {
            userId: "",
            notification: {
              body: "This is an FCM notification that displays an image.!",
              title: "FCM Notification",
              image: "https://dummyimage.com/1920x1080/a1a1a1/fff",
              click_action: "FLUTTER_NOTIFICATION_CLICK"
            },
            topic: "Game"
          }
        }
      ]
    },
    blogs: [
      {
        isActive: true,
        catalogueIds: [],
        excerpt: "",
        richTextData: "",
        tags: [],
        languages: [
          {
            order: 1,
            publishDate: "10/25/2018",
            country: [],
            title: "blog 1",
            tags: [],
            thumbnailLink: {
              small: "https://dummyimage.com/600x400/ececec/fff",
              medium: "https://dummyimage.com/600x400/ececec/fff",
              large: "https://dummyimage.com/600x400/ececec/fff",
              source: "https://dummyimage.com/600x400/ececec/fff"
            },
            coverartLink: {
              small: "https://dummyimage.com/600x400/ececec/fff",
              medium: "https://dummyimage.com/600x400/ececec/fff",
              large: "https://dummyimage.com/600x400/ececec/fff",
              source: "https://dummyimage.com/600x400/ececec/fff"
            }
          }
        ],
        settings: {
          subscription: true,
          subscriptionIds: [],
          free: true
        }
      }
    ],
    catalogue: {
      catalogueList: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      inputPage: 1,
      inputSize: 10,
      searchKey: ""
    },
    allCatalogueList:[],
    admin: {
      isauthorised: true,
      adminUsers: {
        listAll: [],
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50, 100],
          total: 0
        },
        inputPage: 1,
        inputSize: 10,
        searchKey: ""
      },
      userAdmin: {
        listAll: [],
        listRole: []
      },
      customers: [],
      users: [],
      customerList: [
        {
          value: "lovenature",
          label: "Love Nature"
        },
        {
          value: "fitfusion",
          label: "Fit Fusion"
        },
        {
          value: "naturerelaxation",
          label: "Nature Relaxation"
        },
        {
          value: "dekkoo",
          label: "Dekkoo"
        },
        {
          value: "tentkotta",
          label: "Tentkotta"
        }
      ]
    }
  },
  mutations: {
    setConfigData(state, data) {
      state.configData = data;
      state.base_url = data["base_url"];
      customAxios.defaults.baseURL = state.base_url;
      dispatch("getVideoList");
      dispatch("getCatalogueList");
    },
    setAuthorization(state, data) {
      state.admin.isauthorised = data;
    },
    setLoader(state, payload) {
      this.state.loader.show = payload.show;
      this.state.loader.text = payload.text;
    },
    deleteUserId(state, items) {
      var list = state.admin.adminUsers.listAll;
      for (var i = 0; i < list.length; i++) {
        var obj = list[i];
        if (obj.id == id) {
          state.admin.adminUsers.listAll.splice(i, 1);
        }
      }
    },
    setCustomerId(state, id) {
      state.customerId = id;
    },
    setAdminList(state, items) {
      state.admin.adminUsers.listAll = items.filter(item => !item._isDeleted);
    },
    setUserAdminList(state, items) {
      state.admin.userAdmin.listAll = items.filter(item => !item._isDeleted);
    },
    setCustomerList(state, items) {
      let tempItem = items;

      tempItem.forEach(function(item) {
        item["saved"] = true;
      });
      state.admin.customers = tempItem;
    },
    setMixPanelToken(state, token) {
      state.mixPanelToken = token;
    },
    addItemPlatform(state, item) {
      state.admin.customers[item.index].platforms.push(item.platformData);
    },
    addItemAdminUsers(state, item) {
      state.admin.users.push(item);
    },
    updateCustomer(state, item) {
      //item.index = 1 : new data
      var self = this;
      state.admin.customers[item.index].name = item.name;
      state.admin.customers[item.index].platforms = item.platforms;
      //	state.admin.customers[item.index].saved = true;

      var Tempdata = state.admin.customers[item.index];
      if (Tempdata.saved === false) {
        delete Tempdata.saved;
        delete Tempdata.status;
        // state.admin.customers[item.index].saved = true;
        customAxios({
          method: "post",
          url: "/api/v1/customers",
          data: data,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
          })
          .catch(function(error, response) {
            if (error.response) {
            }
          });
      } else {
        delete Tempdata.saved;
        //    delete Tempdata.status;
        //    state.admin.customers[item.index].saved = true;
        var self = this;
        customAxios({
          method: "PATCH",
          url: "/api/v1/customers/" + self.state.admin.customers[item.index].id,
          data: Tempdata,
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
          })
          .catch(function(error, response) {
            if (error.response) {
            }
          });
      }

    },
    addNewCustomer(state, item) {
      state.admin.customers.push(item);
    },
    addPlatformTocustomer(state, item) {
      state.admin.customers[item.index].platforms.push(item.platformData);
    },
    addConfigTocustomer(state, item) {
      state.admin.customers[item.CustIndex].platforms[
        item.PlatformsIndex
      ].configs.push(item.items);
    },
    editConfigOfPlatform(state, item) {
      state.admin.customers[item.CustIndex].platforms[
        item.PlatformsIndex
      ].configs[item.ConfigIndex] = item.items;
    },
    DeleteConfigOfPlatform(state, item) {
      state.admin.customers[item.CustIndex].platforms[
        item.PlatformsIndex
      ].configs[item.ConfigIndex] = item.items;
    },

    deleteUser(state, item) {
      state.admin.users.splice(item.index, item.index + 1);
    },
    addCust(state, item) {
      state.admin.customers.push(item);
    },
    //////////////users
    addAdminAdminUsers(state, item) {
      state.admin.adminUsers.listAll.push(item.items);
    },
    UpdateSavebtnAdminUsers(state, item) {
      (state.admin.adminUsers.listAll[item.index].saved = true),
        (state.admin.adminUsers.listAll[item.index].userProfile = item.items);
      state.admin.adminUsers.listAll[item.index].firstname =
        item.items.firstName;
      state.admin.adminUsers.listAll[item.index].lastname = item.items.lastName;
      state.admin.adminUsers.listAll[item.index].username = item.items.username;
    },
    deleteAdminAdminUsers(state, item) {
      state.admin.adminUsers.listAll.splice(item.index, item.index + 1);
    },

    setPackageList(state, data) {
      state.monetization.subscription.ListAll = data;
    },
    setCatalogueList(state, data) {
      state.catalogue.catalogueList = data._list;
    },
    setAllCatalogueList(state, data) {
      state.allCatalogueList = data._list;
    },
    setCataloguePagination(state, data) {
      (state.catalogue.pagination.perPage = data.limit),
        (state.catalogue.pagination.currentPage = data.page),
        (state.catalogue.pagination.perPageOptions = [5, 10, 25, 50, 100]),
        (state.catalogue.pagination.total = data.count);
    },
    setCatalogueInputPage(state, data) {
      state.catalogue.inputPage = data;
    },
    setCatalogueInputPerpage(state, data) {
      return new Promise((resolve, reject) => {
        state.catalogue.inputSize = data;
        resolve(true);
      });
    },
    setVideoList(state, data) {
      state.videos.videoList = data.videosList;
    },

    setVideoPagination(state, data) {
      (state.videos.pagination.perPage = data.limit),
        (state.videos.pagination.currentPage = data.page),
        (state.videos.pagination.perPageOptions = [5, 10, 25, 50, 100]),
        (state.videos.pagination.total = data.count);
    },

    setVideoInputPage(state, data) {
      state.videos.inputPage = data;
    },
    setVideoInputPerpage(state, data) {
      return new Promise((resolve, reject) => {
        state.videos.inputSize = data;
        resolve(true);
      });
    },
    //
    setAppUserList(state, data) {
      state.admin.adminUsers.listAll = data.appUsersList;
    },
    setAppUserPagination(state, data) {
      //  state.admin.adminUsers.pagination={perPage:data.limit,currentPage:data.page,perPageOptions:[5, 10, 25, 50, 100],total:data.count}
      // Vue.set(state.admin.adminUsers.pagination)
      state.admin.adminUsers = {
        ...state.admin.adminUsers,
        pagination: {
          perPage: data.limit,
          currentPage: data.page,
          perPageOptions: [5, 10, 25, 50, 100],
          total: data.count
        }
      };
    },

    setAppUserInputPage(state, data) {
      state.admin.adminUsers.inputPage = data;
    },
    setAppUserInputPerpage(state, data) {
      return new Promise((resolve, reject) => {
        state.admin.adminUsers.inputSize = data;
        resolve(true);
      });
    },
    deletePackageId(state, id) {
      var list = state.monetization.subscription.ListAll;
      for (var i = 0; i < list.length; i++) {
        var obj = list[i];
        if (obj.id == id) {
          state.monetization.subscription.ListAll.splice(i, 1);
        }
      }
    },
    deleteCatalogueId(state, id) {
      var list = state.catalogue.catalogueList;
      for (var i = 0; i < list.length; i++) {
        var obj = list[i];
        if (obj.id == id) {
          state.catalogue.catalogueList.splice(i, 1);
        }
      }
    },
    deleteCustomer(state, data) {
      var list = this.state.admin.customers;
      for (var i = 0; i < list.length; i++) {
        var obj = list[i];
        if (obj.id == id) {
          state.admin.customers.splice(i, 1);
        }
      }
    },
    deleteVideo(state, id) {
      var list = state.videos.videoList;
      for (var i = 0; i < list.length; i++) {
        var obj = list[i];
        if (obj.id == id) {
          state.videos.videoList.splice(i, 1);
        }
      }
    },
    SetAdminProfileList(state, data) {
      this.state.admin.users = data;
    },
    setVideoSearchText(state, data) {
      this.state.videos.searchKey = data;
    },
    setUserSearchText(state, data) {
      this.state.admin.adminUsers.searchKey = data;
    },
    setCatalogueSearchText(state, data) {
      this.state.catalogue.searchKey = data;
    },
  },
  getters: {
    adminUsersSaved(state) {
      return state.admin.adminUsers.listAll.filter(item => item.saved);
    },
    superAdminUsersSaved(state) {
      return state.admin.users.filter(item => item.saved);
    },
    getSavedCustomers(state) {
      return state.admin.customers;
    },
    getAdminAuth(state) {
      return state.admin.isauthorised;
    },
    getVideoPagination(state) {
      return state.videos.pagination;
    },
    getCataloguePagination(state) {
      return state.catalogue.pagination;
    },
    getAppUsersPagination(state) {
      return state.admin.adminUsers.pagination;
    },
    getCatalogueList(state) {
      return state.catalogue.catalogueList;
    },
    getAllCatalogueList(state) {
      return state.allCatalogueList;
    }
  },
  actions: {
    getConfig({ commit }, data) {
      axios
        .get("./static/appconfig.json")
        .then(response => {
          commit("setConfigData", response.data);
        })
        .catch(() => {});
    },
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/admin/login",
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    logoff({ commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/admin/logoff"
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getCustomerListByAdminId({ commit, state }, id) {
      axios.defaults.baseURL = state.base_url;
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/admin/" + id + "/customers",
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            if (error.response) {
            }
          });
      });
    },
    getCustomers({ commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/customers",
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              commit("setCustomerList", response.data);
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            if (error.response) {
            }
          });
      });
    },
    deleteCustomerByID({ commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/customers/" + id
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getSubscriptionList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/subscriptions",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    getAllCatalogue({ state, commit }) {
      return new Promise((resolve, reject) => {
      customAxios({
          method: "get",
          url: "/api/v1/catalogues",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            commit("setAllCatalogueList", response.data);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },

    getCatalogueList({ state, commit }) {
      return new Promise((resolve, reject) => {
        var url;
        if (state.catalogue.searchKey != "") {
          url =
            "/api/v1/catalogues?key=" +
            state.catalogue.searchKey +
            "&page=" +
            state.catalogue.inputPage +
            "&limit=" +
            state.catalogue.inputSize;
        } else {
          url =
            "/api/v1/catalogues?page=" +
            state.catalogue.inputPage +
            "&limit=" +
            state.catalogue.inputSize;
        }
        customAxios({
          method: "get",
          url: url,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            commit("setCatalogueList", response.data);
            commit("setCataloguePagination", response.data);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    getMonetizationList({ state, commit }) {
      customAxios({
        method: "get",
        url: "/api/v1/subscription-packages",
        headers: {
          CUSTOMERID: state.customerId
        }
      })
        .then(function(response) {
          commit("setPackageList", response.data);
        })
        .catch(function(error, response) {});
    },
    getCategoryById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/catalogues/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    getCatalogueListByType({ state, commit }, type) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/catalogues?type=" + type,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    createCatalogue({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/catalogues",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateCatalogue({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/catalogues/" + formData.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteCatalogue({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/catalogues/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              commit("deleteCatalogueId", id);
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    preSignedRequest({ commit }, file) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/services/aws-signed-url",
          headers: {
            "Content-Type": "application/json"
          },
          data: file
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    uploadImageToS3({ commit }, info) {
      return new Promise((resolve, reject) => {
        axios({
          method: "put",
          url: info.url,
          data: info.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createPackage({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/subscription-packages",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getPackageById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/subscription-packages/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    updatePackage({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/subscription-packages/" + formData.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deletePackageById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/subscription-packages/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              commit("deletePackageId", id);
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getListOfAdmins({ commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/admin/admin-list"
        })
          .then(function(response) {
            commit("setAdminList", response.data);
            resolve(response.data);
          })
          .catch(function(error, response) {});
      });
    },
    registerUserToAdmin() {
      var Auth = localStorage.getItem("app_mastery_token");
      customAxios({
        method: "get",
        url: "/api/v1/admin/admin-list"
      })
        .then(function(response) {
          // commit('setAdminList', response.data);
        })
        .catch(function(error, response) {});
    },
    getAdminUserById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/admin/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    deleteUserById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/admin/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              //    commit('deleteUserId', id);
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    addSuperUser({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    UpdateSuperAdminUsers({ commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/admin/" + formData.id,
          headers: {
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getVideoProviderList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/videos/provider-list",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getVideoList({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.videos.searchKey != "") {
          var url =
            "/api/v1/videos?key=" +
            state.videos.searchKey +
            "&page=" +
            state.videos.inputPage +
            "&limit=" +
            state.videos.inputSize;
        } else {
          var url =
            "/api/v1/videos?page=" +
            state.videos.inputPage +
            "&limit=" +
            state.videos.inputSize;
        }

        customAxios({
          method: "get",
          url: url,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            commit("setVideoList", response.data);
            commit("setVideoPagination", response.data);
            resolve(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getVideoById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/videos/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getVideoByType({ state, commit }, type) {
      customAxios({
        method: "get",
        url: "/api/v1/videos?type=" + type,
        headers: {
          CUSTOMERID: state.customerId
        }
      })
        .then(function(response) {})
        .catch(function(error, response) {});
    },
    searchVideo({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/videos/search-video",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createVideo({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/videos",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateVideo({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/videos/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteVideoById({ state, commit }, id) {
      customAxios({
        method: "delete",
        url: "/api/v1/videos/" + id,
        headers: {
          CUSTOMERID: state.customerId
        }
      })
        .then(function(response) {
          if (response.status == 200) {
            commit("deleteVideo", id);
          }
        })
        .catch(function(error, response) {});
    },
    getTVODList({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/videos/" + id + "/tvod",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createTvodOnVideo({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/videos/" + req.id + "/tvod",
          headers: {
            CUSTOMERID: state.customerId
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateTvodOnVideo({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/videos/" + req.id + "/tvod/" + req.tId,
          headers: {
            CUSTOMERID: state.customerId
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteTvodOnVideo({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/videos/" + req.id + "/tvod/" + req.tId,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    AddSubscriptionToUser({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/app-users/" + req.id + "/subscriptions",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteSubscriptionToUser({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/app-users/" + req.id + "/subscriptions/" + req.subId,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    AdminGetAppUserById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/app-users/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    AdminCreateAppUser({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/app-users/create",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    AdminUpdateAppUser({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/app-users/" + formData.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    AdminDeleteAppUser({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/app-users/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAdminAppUserList({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.admin.adminUsers.searchKey != "") {
          var url =
            "/api/v1/app-users?query=" +
            state.admin.adminUsers.searchKey +
            "&page=" +
            state.admin.adminUsers.inputPage +
            "&limit=" +
            state.admin.adminUsers.inputSize;
        } else {
          var url =
            "/api/v1/app-users?page=" +
            state.admin.adminUsers.inputPage +
            "&limit=" +
            state.admin.adminUsers.inputSize;
        }

        customAxios({
          method: "get",
          url: url,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              commit("setAppUserList", response.data);
              commit("setAppUserPagination", response.data);
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getCustomerByID({ commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/customers/" + id
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    createCustomer({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/customers",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateCustomer({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "PATCH",
          url: "/api/v1/customers/" + data.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAdminUser({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/admin/create",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getVideoHostProviderList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/providers",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createVideoHostProvider({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/providers",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateVideoHostProvider({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/providers/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteVideoHostProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/providers/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getCustomerProviderList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/customer-providers",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createCustomerProvider({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        var Auth = localStorage.getItem("app_mastery_token");
        customAxios({
          method: "post",
          url: "/api/v1/customer-providers",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateCustomerProvider({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/customer-providers/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteCustomerProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/customer-providers/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getPaymentProviderList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/payment-gateway",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createPaymentProvider({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/payment-gateway",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updatePaymentProvider({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/payment-gateway/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deletePaymentProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/payment-gateway/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getPaymentConfigList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/payment-gateway-config",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createPaymentConfig({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/payment-gateway-config",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updatePaymentConfig({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/payment-gateway-config/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deletePaymentConfig({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/payment-gateway-config/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getSendGridList({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/sendgrid-config",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createSendGrid({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/sendgrid-config",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateSendGrid({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/sendgrid-config/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    importUserData({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/app-users/import-users",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    exportUserData({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/app-users/export-users",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken()
          },
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    importEPGData({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/videos/" + payload.id + "/upload-epg-file",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: "Bearer " + AuthService.getAuthToken()
          },
          data: payload.formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateExpireData({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url:
            "/api/v1/app-users/" +
            req.appUserId +
            "/subscriptions/" +
            req.subscriptionId,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getPlayBackUrl({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/videos/get-playback-url/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    savePreviewConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/template-configs",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    addChildElements({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/catalogues/" + payload.id + "/add-child",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    removeChildElements({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/catalogues/" + payload.id + "/remove-child",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getTotalUserCount({ state, commit }, payload) {
      var url = "/api/v1/app-users/count/";
      if (payload.fromDate && payload.toDate) {
        url =
          url + "?fromDate=" + payload.fromDate + "&toDate=" + payload.toDate;
      }
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: url,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getActiveSubscribers({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/app-user-subscriptions/count?type=ACTIVE",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getTotalWatchedMinutes({ state, commit }) {
      return new Promise((resolve, reject) => {
        var payload = {};
        payload.script =
          'function main(){ return Events({from_date: "2019-01-02",to_date: "2019-01-02"}).filter(function (event) {return event.name == "Stop Playing"}).reduce(mixpanel.reducer.sum(function (e) { return e.properties.duration; }));}';
        axios({
          method: "post",
          url: "https://mixpanel.com/api/2.0/jql",
          headers: {
            Authorization: "Basic " + state.mixPanelToken,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getSubscriberByPaymentGateWay({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/app-user-subscriptions/count-by-payment-gateway",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getTodayPageView({ state, commit }) {
      return new Promise((resolve, reject) => {
        var currentDate = moment().format("YYYY-MM-DD");
        var param =
          "from_date=" +
          currentDate +
          "&to_date=" +
          currentDate +
          "&event=" +
          "Page Viewed";
        axios({
          method: "get",
          url: "https://mixpanel.com/api/2.0/segmentation/?" + param,
          headers: {
            Authorization: "Basic " + state.mixPanelToken,
            "Content-Type": "application/x-www-form-urlencoded"
          }
        })
          .then(function(response) {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    getWeeklyPageByDevice({ state, commit }) {
      return new Promise((resolve, reject) => {
        var payload = {};
        var from_date =
          "from_date: " +
          '"' +
          moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD") +
          '"';
        var to_date = ",to_date:" + '"' + moment().format("YYYY-MM-DD") + '"';
        var param =
          "var params = {" +
          from_date +
          to_date +
          ',event_name: "Page Viewed",segment_property: "$browser",type: "total"};';
        var othercode =
          "var groupKeys = [function(event) {return (new Date(event.time)).toISOString().split('T')[0];}]; if(params.segment_property) groupKeys.push(function(event) {return event.properties[params.segment_property]}); function main(){ var collection = Events({		from_date:params.from_date,	to_date: params.to_date	}).filter(function(event) {return event.name == params.event_name}); if(params.type == 'total') return collection.groupBy(groupKeys, mixpanel.reducer.null()).groupBy([mixpanel.slice('key', 0)], mixpanel.reducer.count());}";

        payload.script = param + othercode;
        axios({
          method: "post",
          url: "https://mixpanel.com/api/2.0/jql",
          headers: {
            Authorization: "Basic " + state.mixPanelToken,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getViewsByVideo({ state, commit }) {
      return new Promise((resolve, reject) => {
        var payload = {};
        var from_date =
          "from_date: " +
          '"' +
          moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD") +
          '"';
        var to_date = ",to_date:" + '"' + moment().format("YYYY-MM-DD") + '"';
        var headCode = "function main(){return Events({";
        var lastCode =
          '}).filter(function(event){return event.name == "Stop Playing"}).groupBy(["properties.video_name"], mixpanel.reducer.sum("properties.duration")).reduce(mixpanel.reducer.top(25));}';
        payload.script = headCode + from_date + to_date + lastCode;
        axios({
          method: "post",
          url: "https://mixpanel.com/api/2.0/jql",
          headers: {
            Authorization: "Basic " + state.mixPanelToken,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getViewsByVideoByDate({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        var payload = {};
        var from_date = "from_date: " + '"' + data.fromDate + '"';
        var to_date = ",to_date:" + '"' + data.toDate + '"';
        var headCode = "function main(){return Events({";
        var lastCode =
          '}).filter(function(event){return event.name == "Stop Playing"}).groupBy(["properties.video_name"], mixpanel.reducer.sum("properties.duration"));}';
        payload.script = headCode + from_date + to_date + lastCode;
        axios({
          method: "post",
          url: "https://mixpanel.com/api/2.0/jql",
          headers: {
            Authorization: "Basic " + state.mixPanelToken,
            "Content-Type": "application/x-www-form-urlencoded"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getUserReport({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url:
            "/api/v1/app-users/get?fromDate=" +
            payload.fromDate +
            "&toDate=" +
            payload.toDate,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getSubscriberReport({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url:
            "/api/v1/app-user-subscriptions/get-subscribers?fromDate=" +
            payload.fromDate +
            "&toDate=" +
            payload.toDate +
            "&trialuser=" +
            payload.trialUsers +
            "&paidsubscriber=" +
            payload.paidSubscribers,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getPaymentGatewayReport({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url:
            "/api/v1/app-user-subscriptions/get-subscribers-by-payment-gateway?fromDate=" +
            payload.fromDate +
            "&toDate=" +
            payload.toDate +
            "&slug=" +
            payload.slug +
            "&type=" +
            payload.type,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAnalyticsList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "api/v1/analytics",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAnalytics({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "api/v1/analytics",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAnalytics({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/analytics/" + payload.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAnalytics({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/analytics/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAnalyticsConfigList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "api/v1/analytics-config",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAnalyticsConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "api/v1/analytics-config",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAnalyticsConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/analytics-config/" + payload.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getMixPanelToken({ state, commit }) {
      var analyticsApi = customAxios({
        method: "get",
        url: "api/v1/analytics",
        headers: {
          CUSTOMERID: state.customerId
        }
      });
      var analyticsConfigApi = customAxios({
        method: "get",
        url: "api/v1/analytics-config",
        headers: {
          CUSTOMERID: state.customerId
        }
      });
      return new Promise((resolve, reject) => {
        Promise.all([analyticsApi, analyticsConfigApi])
          .then(function(values) {
            var analyticsData = values[0].data;
            var analyticsConfigData = values[1].data;
            var MixPanel = analyticsData.find(elm => elm.name == "Mixpanel");
            if (MixPanel) {
              var MixPanelId = MixPanel.id;
              var MixAnalyticsConfig = analyticsConfigData.find(
                elm => elm.providerId == MixPanelId
              );
              if (MixAnalyticsConfig) {
                var configObj = MixAnalyticsConfig.configObj;
                if (configObj["Token"]) {
                  commit("setMixPanelToken", configObj["Token"]);
                  resolve(configObj["Token"]);
                }
              }
            }
            reject();
          })
          .catch(() => {
            reject();
          });
      });
    },
    createProduct({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/products",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateProduct({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/products/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteProduct({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/products/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getProductList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/products",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getProductWithId({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/products/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getTemplateList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/templates/",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createTemplate({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/templates",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateTemplate({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/templates/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getTemplateWithId({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/templates/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteTemplate({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/templates/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getErrorLogConfigList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "api/v1/error-logs",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createNewOrder({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "POST",
          url: "/api/v1/platform-orders",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getListOrders({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/platform-orders",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAvodProvider({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/avod-providers",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAvodProviderList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/avod-providers",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAvodProviderById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/avod-providers/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAvodProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/avod-providers/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAvodProvider({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/avod-providers/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },

    createAvodConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/avod-provider-configs",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json",
            CUSTOMERID: state.customerId
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAvodConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/avod-provider-configs/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json",
            CUSTOMERID: state.customerId
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAvodConfigList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/avod-provider-configs",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAvodConfigById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/avod-provider-configs/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAvodConfig({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/avod-provider-configs/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAdProvider({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/ad-networks",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAdProviderList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/ad-networks",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAdProviderById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/ad-networks/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAdProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/ad-networks/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAdProvider({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/ad-networks/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },

    createAdNetworkConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/ad-network-configs",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json",
            CUSTOMERID: state.customerId
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAdNetworkConfigList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/ad-network-configs",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAdNetworkConfigById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "GET",
          url: "/api/v1/ad-network-configs/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAdNetworkConfig({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "DELETE",
          url: "/api/v1/ad-network-configs/" + id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAdNetworkConfig({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/ad-network-configs/" + payload.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json",
            CUSTOMERID: state.customerId
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAuthProviderList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/auth-providers",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAuthProvider({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/auth-providers",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAuthProvider({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/auth-providers/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAuthProvider({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/auth-providers/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getAuthProviderConfigList({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/auth-provider-configs",
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createAuthProviderConfig({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/auth-provider-configs",
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAuthProviderConfig({ state, commit }, req) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/auth-provider-configs/" + req.id,
          headers: {
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: req.data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteAuthProviderConfig({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/auth-provider-configs/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getWhitelistedAppUserList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/location-whitelist-app-users",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    addWhitelistUsers({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/location-whitelist-app-users/add",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    removeWhitelistUsers({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/location-whitelist-app-users/remove",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getWhitelistedIpsList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/location-whitelist-ips",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    addWhitelistIps({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/location-whitelist-ips/add",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    removeWhitelistIps({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/location-whitelist-ips/remove",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateAdminPassword({ state, commit }, formData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/admin/change-password",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken()
          },
          data: formData
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getBlogTagList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/tags",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getBlogList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/blogs",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createBlog({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/blogs",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getBlogBySlug({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/blogs/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    deleteBlogById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/blogs/" + id,
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateBlog({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/blogs/" + payload.slug,
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: payload
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createTags({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/tags",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    sendFirebaseConfig({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/push-notification/",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    filterUserByQuery({ state, commit }, queryData) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/app-users/filter-list?" + queryData,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    createNotificationGroup({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/push-notification/creategroup",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getGroupList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/push-notification",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    sendNotificationToGroup({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/push-notification/send-notification",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getCustomerCurrentState({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/customers/view/" + state.customerId,
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateCustomerCurrentState({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "PATCH",
          url: "/api/v1/customers/update/" + state.customerId,
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    searchVideoList({ state, commit }) {
      customAxios({
        method: "get",
        url:
          "/api/v1/videos?key=" +
          state.videos.searchKey +
          "&page=" +
          state.videos.inputPage +
          "&limit=" +
          state.videos.inputSize,
        headers: {
          CUSTOMERID: state.customerId
        }
      })
        .then(function(response) {
          commit("setVideoList", response.data);
          commit("setVideoPagination", response.data);
        })
        .catch(function(error, response) {
          alert(error);
        });
    },
    searchUserList({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.admin.adminUsers.searchKey != "") {
          var url =
            "/api/v1/app-users/search-user?query=" +
            state.admin.adminUsers.searchKey +
            "&page=" +
            state.admin.adminUsers.inputPage +
            "&limit=" +
            state.admin.adminUsers.inputSize;

          customAxios({
            method: "post",
            url: url,
            headers: {
              CUSTOMERID: state.customerId
            }
          })
            .then(function(response) {
              if (response.status == 200) {
                commit("setAppUserList", response.data);
                commit("setAppUserPagination", response.data);
                resolve(response.data);
              }
              reject(response.data);
            })
            .catch(function(error, response) {
              //alert(error);
              reject(error);
            });
        } else {
          var url =
            "/api/v1/app-users?page=" +
            state.admin.adminUsers.inputPage +
            "&limit=" +
            state.admin.adminUsers.inputSize;
          customAxios({
            method: "get",
            url: url,
            headers: {
              CUSTOMERID: state.customerId
            }
          })
            .then(function(response) {
              if (response.status == 200) {
                commit("setAppUserList", response.data);
                commit("setAppUserPagination", response.data);
                resolve(response.data);
              }
              reject(response.data);
            })
            .catch(function(error, response) {
              reject(error);
            });
        }
      });
    },
    getListOfUserAdmins({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/admin/admin-list",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            commit("setUserAdminList", response.data);
            resolve(response.data);
          })
          .catch(function(error, response) {});
      });
    },
    registerUserAdmin({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/admin/create",
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json",
            CUSTOMERID: state.customerId
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updateUserAdmin({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "patch",
          url: "/api/v1/admin/" + data.id,
          headers: {
            Authorization: "Bearer " + AuthService.getAuthToken(),
            CUSTOMERID: state.customerId,
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    getUserAdminById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/admin/" + id,
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    getUserAdminRoleList({ state, commit }) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: "/api/v1/roles",
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken(),
            "Content-Type": "application/json"
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });
    },
    deleteUserAdminById({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        customAxios({
          method: "delete",
          url: "/api/v1/admin/" + id,
          headers: {
            CUSTOMERID: state.customerId
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              //    commit('deleteUserId', id);
              resolve(response);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    
    manualSyncVideo({ state, commit }){
      return new Promise((resolve, reject) => {
        customAxios({
          method: "get",
          url: 'https://api.appmastery.co/api/v1/catalogues/sync-vimeo',
          headers: {
            CUSTOMERID: state.customerId,
            Authorization: "Bearer " + AuthService.getAuthToken()
          }
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response);
          })
          .catch(function(error, response) {
            reject(response);
          });
      });

    },
    resetPassword({ state, commit }, data){
      return new Promise((resolve, reject) => {
        customAxios({
          method: "post",
          url: "/api/v1/admin/reset" ,
          headers: {
            "Content-Type": "application/json"
          },
          data: data
        })
          .then(function(response) {
            if (response.status == 200) {
              resolve(response.data);
            }
            reject(response.data);
          })
          .catch(function(error, response) {
            reject(error);
          });
      });
    },
    updatePassword({ commit }, data) {
			var shajs = require("sha.js");
			var password = shajs("sha256")
				.update(data.password)
				.digest("hex");
			var dataToSend = {
				userId: data.userId,
				token: data.token,
				password: password,
			};
			return new Promise((resolve, reject) => {
				customAxios({
					method: "post",
					url: "/api/v1/admin/update-password",
					headers: {
						customerid: this.state.customerid,
						"content-type": "application/json",
					},
					data: dataToSend,
				})
					.then(function(response) {
						if (response.status == 200) {
							resolve(response.data);
						}
						reject(response);
					})
					.catch(function(error, response) {
						reject(error);
					});
			});
		},
  }
});
