<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="removeip"
      ref="ConfirmDeleteIP"
      @delete="$event()"
    />
    <DeleteConfirmation
      :currentDeleteMethod="deleteMetaData"
      ref="ConfirmDeleteMetaData"
      @delete="$event()"
    />
    <b-modal
      id="metadatas"
      ref="addIPModal"
      title="Add IPs to Whitelist"
      @ok="addipToWhiteList"
      ok-title="Save"
      @cancel="exitDialog"
      @hide="exitDialog"
    >
      <div class="col-sm-12">
        <div class="row">
          <fg-input
            class="col-md-6"
            label="Enter Valid IP"
            v-model="currentipAddress"
          ></fg-input>
          <div class="col-md-2 addBtn">
            <button class="btn btn-primary " @click="addMetaData()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-bigboy"
          style="width: 100%;"
          :data="metaDataIP"
          border
        >
          <el-table-column min-width="140" label="Value">
            <template slot-scope="{ row }">
              {{ row }}
            </template>
          </el-table-column>
          <el-table-column min-width="100" label="Action">
            <template slot-scope="props">
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="handelDeleteMetaData(props.$index)"
                ><i class="fa fa-trash"></i
              ></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            Whitelist IP
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>

          <div class="mx-2 mt-1">
            <el-button type="primary" icon="el-icon-plus" @click="addIPs"
              >Add IPs
            </el-button>
          </div>
        </div>
      </div>
      <card>
        <div class="col-sm-12">
          <el-table stripe style="width: 100%;" :data="queriedData" border>
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
            <el-table-column :min-width="120" fixed="right" label="Action">
              <template slot-scope="props">
                <a
                  v-tooltip.top-center="'Delete'"
                  class="btn-danger btn-simple btn-link"
                  @click="handleDelete(props.$index, props.row)"
                  ><i class="fa fa-trash"></i
                ></a>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Button.name]: Button,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    LCheckbox,
    DeleteConfirmation
  },
  data() {
    return {
      deletewhitelistedIds: [],
      iptableData: [],
      addwhitelistedIds: [],
      currentipAddress: "",
      metaDataIP: [],
      deleteMetaDataIndex: "",
      spinnerTip: "Importing ip Data...",
      message: "",
      errorMessage: "",
      uploading: false,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["ip", "id"],
      tableColumns: [
        {
          prop: "ip",
          label: "IP Address",
          minWidth: 200
        },
        {
          prop: "id",
          label: "ID",
          minWidth: 200
        }
      ],
      fuseSearch: null,
      tableData: []
    };
  },

  computed: {
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["ip", "id"] }).search(
          this.searchQuery
        );
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {},
  created() {
    this.getPageData();
  },

  methods: {
    ValidateIPaddress(inputText) {
      let ipformat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (inputText.match(ipformat)) {
        return true;
      } else {
        return false;
      }
    },
    deleteMetaData: function(index) {
      this.metaDataIP.splice(this.deleteMetaDataIndex, 1);
    },
    addMetaData() {
      if (this.ValidateIPaddress(this.currentipAddress)) {
        this.metaDataIP.push(this.currentipAddress);
        this.currentipAddress = "";
      } else {
        this.$notify({
          title: "Error",
          message: "Enter Valid IP Address",
          type: "warning"
        });
      }
    },
    getPageData() {
      self = this;
      this.$store.commit("setLoader", {
        show: true,
        text: "Fetching List..."
      });
      this.$store
        .dispatch("getWhitelistedIpsList")
        .then(response => {
          this.tableData = response;
          this.$store.commit("setLoader", { show: false, text: "" });
        })
        .catch(() => {
          this.$store.commit("setLoader", { show: false, text: "" });
        });
    },
    addipToWhiteList() {
      this.addip();
    },
    isChecked(props) {
      return false;
    },
    checkChanged(val, props) {
      var id = props.row.ip;
      if (id) {
        if (val) {
          if (!this.deletewhitelistedIds.includes(id)) {
            this.deletewhitelistedIds.push(id);
          }
        } else {
          if (this.deletewhitelistedIds.includes(id)) {
            var index = this.deletewhitelistedIds.indexOf(id);
            this.deletewhitelistedIds.splice(index, 1);
          }
        }
      }
    },
    addip: function() {
      self = this;
      var Data = {};
      Data["ips"] = this.metaDataIP;
      this.$store
        .dispatch("addWhitelistIps", Data)
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Successfully ADDED!",
            type: "success"
          });
          self.getPageData();
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
      this.metaDataIP = [];
      this.currentipAddress = "";
    },
    removeip: function() {
      self = this;
      var Data = {};
      Data["ips"] = this.deletewhitelistedIds;
      this.$store
        .dispatch("removeWhitelistIps", Data)
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Successfully REMOVED!",
            type: "success"
          });
          self.getPageData();
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    generateErrorMessage: function() {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    addIPs() {
      this.$refs["addIPModal"].show();
    },
    handleDelete(index, row) {
      this.deletewhitelistedIds = row.ip;
      this.$refs["ConfirmDeleteIP"].$refs["deleteConfirmation"].show();
    },
    exitDialog() {
      this.addwhitelistedIds = [];
    },
    handelDeleteMetaData(index, row) {
      this.deleteMetaDataIndex = index;
      this.$refs["ConfirmDeleteMetaData"].$refs["deleteConfirmation"].show();
    },
    openExportip: function() {}
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 300;
  font-size: 18px;
  color: #27282a;
  position: relative;
  right: 16px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
.addBtn {
  margin-top: 28px;
  position: relative;
  right: 14px;
}
</style>
