<template>
  <auth-layout pageClass="login-page">
    <b-modal id="resetModal" v-model="resetMessagePopup" ref="modal" title="Message" @cancel="exitDialog"
      @ok="exitDialog">
      <form>
        <div class="row">
          <div class="col-md-12">
            <h5>{{ this.resetMessage }}</h5>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-lg-4 col-md-6 col-sm-8">
        <form @submit.prevent="forgotPassword">
          <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Forgot your password?</h3>
              </div>
              <div>
                <fg-input label="Email address" placeholder="Enter email" type="email" name="email"
                  v-validate="'required|email'" v-model="model.email">

                </fg-input>
                <p class="error-msg" v-if="showEmailValid">{{ showEmailValidErr }}</p>

                <div class="alerttext">
                  <p v-if="errors.has('email')">{{ errors.first('email') }}</p>
                </div>

              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-fill btn-info btn-round btn-wd">Send Password</button>
                <br>
                <div class="forgot">
                  <router-link to="/login" class="card-category">
                    Login
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>

        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
import { Checkbox as LCheckbox, FadeRenderTransition } from 'src/components/index';
import AuthLayout from './AuthLayout.vue';
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
export default {
  components: {
    LCheckbox,
    FadeRenderTransition,
    AuthLayout,
    bModal,
    bModalDirective,
  },
  data() {
    return {
      model: {
        email: '',
        password: '',
        subscribe: true,
      },
      showSuccessMsg: false,
      resetMessagePopup: false,
      resetMessage: '',
      showEmailValid: false,
      showEmailValidErr: "Invalid Email",
    }
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open')
    },
    closeMenu() {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    forgotPassword() {
      var dataToSend = {
        email: this.model.email
      }
      if (this.ValidateEmail() == true ) {
      this.$store
        .dispatch("resetPassword", dataToSend)
        .then(response => {
          this.resetMessagePopup = true;
          this.resetMessage = response.message;
        })
        .catch((err) => {
          this.resetMessagePopup = true;
          this.resetMessage = err.response.data.error.message          ;
        });
      }
    },
    ValidateEmail() {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (this.model.email.match(mailformat)) {
        return true;
      }

      else {
        this.showEmailValid = true;
        return false;
      }
    },
    exitDialog() {
      this.email = "";
    },
  },
  beforeDestroy() {
    this.closeMenu()
  }
}
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}

.reset-password-title {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #333333;

}

.error-msg {
  font-size: 10px;
  font-weight: 400;
  color: #333333
}
</style>
