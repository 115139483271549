<template>
  <div class="row">
    <div class="col-12">
      <div class="d-flex justify-content-between align-items-center py-3">
        <div>
          <span class="titleCard mb-2">
            User Report
          </span>
        </div>
        <div
          class="d-flex justify-content-end align-items-center  searchContainer"
        >
          <div>
            <el-input
              type="search"
              style="width: 200px; "
              placeholder="Search records"
              prefix-icon="el-icon-search"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div>
            <span class="demo-input-label ml-2 perPage">Results per page:</span>
            <el-select
              class="select-default ml-1"
              v-model="pagination.perPage"
              style="width: 80px; "
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>
      <card>
        <div>
          <div class="col-12">
            <div class="row">
              <div class="col-md-4">
                <label>From</label>
                <el-date-picker v-model="startDate" type="date">
                </el-date-picker>
              </div>
              <div class="col-md-4">
                <label>To</label>
                <el-date-picker v-model="endDate" type="date"> </el-date-picker>
              </div>
              <div class="col-md-4">
                <button
                  class="btn btn-primary btn-fill"
                  :disabled="!startDate || !endDate"
                  style="margin-top:2em;"
                  @click="getUserReport"
                >
                  Get Report
                </button>
              </div>
            </div>
          </div>

          <div class="col-sm-12 p-3">
            <el-table
              stripe
              style="width: 100%;"
              :data="queriedData"
              border
              v-if="showReport"
            >
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="200" label="Account Status">
                <template slot-scope="props">
                  {{ getStatus(props.row) }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, DatePicker } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import { Loading } from "element-ui";

import moment from "moment";
import Fuse from "fuse.js";

export default {
  name: "UserReport",
  components: {
    LPagination,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {},
  data() {
    return {
      startDate: null,
      endDate: null,
      data: [],
      count: 0,
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      tableColumns: [
        {
          prop: "first_name",
          label: "First Name",
          minWidth: 150
        },
        {
          prop: "last_name",
          label: "Last Name",
          minWidth: 150
        },
        {
          prop: "email_address",
          label: "Email",
          minWidth: 200
        },
        {
          prop: "country",
          label: "Country",
          minWidth: 100
        }
      ],
      showReport: false
    };
  },
  computed: {
    tableData() {
      return this.data;
    },
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["first_name", "last_name", "email_address", "username"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {},
  methods: {
    getUserReport: function() {
      var form = {};
      this.showReport = true;
      form.fromDate = moment(this.startDate).format("YYYY-MM-DD");
      form.toDate = moment(this.endDate).format("YYYY-MM-DD");
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("getUserReport", form)
        .then(res => {
          this.count = res.count;
          this.data = res.data;
          loadingInstance.close();
        })
        .catch(() => {
          loadingInstance.close();
        });
    },
    getStatus: function(row) {
      var status = row.status;
      switch (status) {
        case "EMAIL_VERIFICATION_REQUIRED":
          return "Email Verification Pending";
        case "MOBILE_VERIFICATION_REQUIRED":
          return "Mobile Verification Pending";
        case "FORCE_RESET_PASSWORD":
          return "Force Reset Password";
        case "ACCOUNT_VERIFIED":
          return "Account Verified";
        case "ACCOUNT_DISABLED":
          return "Account Disabled";
        default:
          return "NA";
      }
    }
  }
};
</script>
<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 15px;
}
</style>
