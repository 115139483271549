<template>
    <div class="row pageui-kit">
        <div class="col-12">
            <card class="section-dark">
                <div class="pricing-4 section">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-6 ml-auto mr-auto text-center">
                                <h2 class="title">Pick the best plan for you</h2>
                                <h5 class="description">You have Free Unlimited Updates and Premium Support on each package.</h5>
                            </div>
                        </div>
                        <div class="space-top"></div>
                        <div class="row">
                            <div class="col-md-3" v-for="product in tableData">
                                <div class="card card-pricing card-plain">
                                    <div class="card-body">
                                        <h6 class="card-category text-success">{{product.name}}</h6>
                                        <h2 class="card-title">$ {{product.price}}</h2>
                                        <ul class="">
                                            <li><i class="fa" v-bind:class="[ product.metaData.sharingTools == 'true' ? 'fa-check':'fa-times']" aria-hidden="true"></i> Sharing Tools</li>
                                            <li><i class="fa" v-bind:class="[ product.metaData.designTools == 'true' ? 'fa-check':'fa-times']" aria-hidden="true"></i> Design Tools</li>
                                            <li><i class="fa" v-bind:class="[ product.metaData.privateMessages == 'true' ?  'fa-check':'fa-times']" aria-hidden="true"></i> Private Messages</li>
                                            <li><i class="fa" v-bind:class="[ product.metaData.personalBrand == 'true' ? 'fa-check':'fa-times']" aria-hidden="true"></i> Personal Brand</li>
                                        </ul>
                                        <a @click="handleButton(product)" class="btn btn-outline-neutral btn-round">Choose plan</a>
                                    </div>
                                </div>
                            </div>
                    </div>  

                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>


export default {
    components: {

    },

    data (){
        return {
            tableData : [],
        }

    },

    computed: {

    },

    mounted () {
        this.getProductList();
    },
    created () {
        
    },
    methods: {
        getProductList: function() {
            this.$store.dispatch("getProductList").then((res)=>{
                if(Array.isArray(res)) {
                    this.tableData = res.filter(item=>!item._isDeleted);
                }
            });
        },
        handleButton: function(props){
            this.$router.push({name: 'Choose Template', params:{id:props.id}});
        }
       
    }
}
</script>

<style lang="scss">
    .section-dark {
        background-color: #FFFFFF;
        .card-header {
            background-color: #151f20!important;
        }
        .title {
            color: #333 !important;
            font-family: "Montserrat", "Helvetica", Arial, sans-serif;
        }
        .card {
            color: #333333;
            margin-bottom: 20px;
            position: relative;
            z-index: 1;
            border-radius: 12px;
            border: 0 none;
            -webkit-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -moz-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -o-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            -ms-transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            transition: transform 300ms cubic-bezier(0.34, 2, 0.6, 1), box-shadow 200ms ease;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
        }
        .card.card-plain {
            background-color: #c3bcbc45;
            -webkit-box-shadow: none;
            box-shadow: none;
            border-radius: 10px;
        }
        .card-plain .card-title {
            color: #333 !important;
        }
        .card .card-body .card-title {
            margin: 5px 0 0 0;
        }
        .card h6 {
            font-size: 12px;
        }
        h5 {
            color:  rgb(4, 4, 4) !important;
            font-weight:600;
        }
        .card-pricing {
            text-align: center;
            .card-body {
                flex: 1 1 auto;
                padding: 15px !important;
            }
            .card-category {
                margin: 10px 0 10px;
                font-weight: 600!important;
            }
            .card-title {
                margin-top: 30px !important;
            }
            ul {
                list-style: none;
                padding: 0;
                max-width: 240px;
                margin: 20px auto;
                li {
                    padding: 5px 0;
                    list-style-type: none;
                    font-weight: 300;
                    font-size: 14px;
                }
            }
        }
        .text-success,
        .text-success:hover {
            color: #6bd098 !important;
        }
    }
    
    .pageui-kit {
        h1 {
            font-size: 3.6em;
            -webkit-font-smoothing: antialiased;
            font-family: "Montserrat", "Helvetica", Arial, sans-serif;
            font-weight: 300;
        }
        h5 {
            font-family: "Montserrat", "Helvetica", Arial, sans-serif;
            font-weight: 300;
            font-size: 1.35em;
            line-height: 1.4em;
        }
        h6 {
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1.5em;
            font-family: "Montserrat", "Helvetica", Arial, sans-serif;
        }
        .space-top {
            margin-top: 30px;
        }
        .text-white {
            color: #fff!important;
        }
        .fa {
            width: 18px;
            text-align: center;
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
        }
        .btn-round {
            border-radius: 30px;
        }
        .btn-outline-neutral {
            border-color: #FFFFFF;
            color: #FFFFFF;
            opacity: 1;
            filter: alpha(opacity=100);
        }
        .btn {
            display: inline-block;
            box-sizing: border-box;
            border-width: 2px;
            font-size: 12px;
            font-weight: 600;
            padding: 0.5rem 18px;
            line-height: 1.75;
            cursor: pointer;
            text-transform: uppercase;
            color: #FFFFFF;
            opacity: 1;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            user-select: none;
            filter: alpha(opacity=100);
            -webkit-transition: all 150ms linear;
            -moz-transition: all 150ms linear;
            -o-transition: all 150ms linear;
            -ms-transition: all 150ms linear;
            transition: all 150ms linear;
        }
        .btn[class*="btn-outline-"] {
            background-color: #bcbab7 !important;
            color: #333333d9 !important;
            border-color: #453d3d5e !important;
        }
        .btn:not(:disabled):not(.disabled) {
            cursor: pointer;
        }
        .btn-outline-neutral:hover,
        .btn-outline-neutral:focus,
        .btn-outline-neutral:active,
        .btn-outline-neutral.active,
        .btn-outline-neutral:active:focus,
        .btn-outline-neutral:active:hover,
        .btn-outline-neutral.active:focus,
        .show>.btn-outline-neutral.dropdown-toggle {
            background-color: #72676aa3 !important;
            color: #271b0f !important;
            border-color: #453d3d5e !important;
        }
        .btn-outline-neutral:hover .caret,
        .btn-outline-neutral:focus .caret,
        .btn-outline-neutral:active .caret,
        .btn-outline-neutral.active .caret,
        .btn-outline-neutral:active:focus .caret,
        .btn-outline-neutral:active:hover .caret,
        .btn-outline-neutral.active:focus .caret,
        .show>.btn-outline-neutral.dropdown-toggle .caret {
            border-top-color: rgba(255, 255, 255, 0.8);
        }
        .btn-outline-success {
            border-color: #6bd098;
            color: #6bd098;
            opacity: 1;
        }
        .btn-outline-success:hover,
        .btn-outline-success:focus,
        .btn-outline-success:active,
        .btn-outline-success.active,
        .btn-outline-success:active:focus,
        .btn-outline-success:active:hover,
        .btn-outline-success.active:focus,
        .show>.btn-outline-success.dropdown-toggle {
            background-color: #6bd098 !important;
            color: rgba(255, 255, 255, 0.8);
            border-color: #6bd098 !important;
        }
    }
    
    [class*="pricing-"] {
        padding: 50px 0;
    }
</style>