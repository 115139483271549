<template>
  <div class="row languagecontainer">
    <div class="col-md-6">
      <fg-input class="col-sm-12 col-12" label="Title" placeholder="Title" name="cattitle"
        v-model="selectedLangauge.title" v-validate="'required'"></fg-input>
      <div class="alerttext">
        <p v-if="errors.has('cattitle')">Title is a required field.</p>
      </div>
    </div>
    <div class="col-md-10">
      <fg-input class="col-sm-12 col-12 m-0" label="Description">
        <textarea class="form-control" placeholder="Here can be your nice text" rows="3"
          v-model="selectedLangauge.description"></textarea>
      </fg-input>
    </div>
    <div class="col-md-12 ">
      <fg-input class="col-sm-12 col-12 m-0 " label="Country">
        <treeselect v-model="selectedLangauge.country" :value-consists-of="valueConsistsOf" :multiple="true"
          :options="country" />
      </fg-input>
    </div>
    <div class="col-md-12 d-flex  justify-content-between thumbContainer">
      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Thumbnail Landscape
        </span>

        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + thumbnail + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'thumbnail')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('thumbnail')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Coverart
        </span>

        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + coverart + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'coverart')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('coverart')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex  justify-content-between thumbContainer">
      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Banner Image
        </span>

        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + bannerImg + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'bannerImg')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('bannerImg')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Mobile Banner Image
        </span>

        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + mobileBannerImg + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'mobileBannerImg')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('mobileBannerImg')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 d-flex  justify-content-between thumbContainer">

      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Banner Title Image
        </span>

        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + bannerTitleImg + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'bannerTitleImg')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('bannerTitleImg')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-6 thumbDiv">
        <span class="titleThumb mb-2">
          Thumbnail Portrait
        </span>
        <div>
          <div class=" ml-2 thumbNail" :style="{ backgroundImage: 'url(' + portraitImg + ')' }"></div>
        </div>
        <div class="row mt-2 mx-0" style="width: 300px">
          <div class="col-4 ">
            <el-button type="primary" class="uploadThumbBtn" icon="el-icon-upload"
              @click="openImageDialog($event, 'portraitImg')">
              Upload
            </el-button>
          </div>
          <div class="col-4 ">
            <el-button type="danger" class="deleteThumbBtn" icon="el-icon-delete"
              @click.prevent="deleteImage('portraitImg')">
              Delete
            </el-button>
          </div>
        </div>
      </div>
    </div>



    <div class="col-md-12">
      <el-button class="delete-lang-btn" type="danger" icon="el-icon-delete"
        v-tooltip.top-center="'Delete Catalogue Details'" @click.prevent="confirmDeleteLanguage">Delete</el-button>
    </div>
    <input type="file" ref="thumbnailInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'thumbnail')" />
    <input type="file" ref="coverartInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'coverart')" />
    <input type="file" ref="bannerImgInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'bannerImg')" />
    <input type="file" ref="mobileBannerImgInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'mobileBannerImg')" />
    <input type="file" ref="bannerTitleImgInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'bannerTitleImg')" />
    <input type="file" ref="portraitImgInput" accept=".jpg, .png, .jpeg, .gif" v-bind:style="{ display: 'none' }"
      @change="uploadPicture($event, 'portraitImg')" />
  </div>
</template>

<script>
import appConfigs from "../data/AppConfig";
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { FormGroupInput as FgInput } from "src/components";
import countries from "../../../config/countries.json";
import languages from "../../../config/languages.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

//const apps = appConfigs.config;

export default {
  props: {
    currentLanguage: Object, metaData: Array
  },
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    appConfigs,
    bModal,
    bModalDirective,
    FgInput,
    Treeselect
  },

  data() {
    return {
      appIndex: 0,
      show: false,
      newConfigName: "",
      newConfigParam: "",
      newConfigValue: "",
      simple: "",
      valueConsistsOf: "ALL_WITH_INDETERMINATE",
      selectedLangauge: {},
      country: [],
      languages: [
        {
          value: "english",
          label: "English"
        },
        {
          value: "french",
          label: "French"
        },
        {
          value: "hindi",
          label: "Hindi"
        }
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25],
        total: 0
      },
      thumbnail: "https://dummyimage.com/640x360/a1a1a1/fff",
      coverart: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      bannerImg: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      mobileBannerImg: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      bannerTitleImg: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      portraitImg: "https://dummyimage.com/1920x1080/a1a1a1/fff",
      searchQuery: "",
      propsToSearch: ["configname", "configparam", "configvalue"],
      tableColumns: [
        {
          prop: "configparam",
          label: "Config Parameter",
          minWidth: 150
        },
        {
          prop: "configname",
          label: "Config Name",
          minWidth: 200
        },
        {
          prop: "configvalue",
          label: "Config Value",
          minWidth: 200
        },
        {
          prop: "readonly",
          label: "Readonly",
          minWidth: 100
        }
      ],
      fuseSearch: null
    };
  },
  computed: {
    selectedApp() {
      //	return apps[this.appIndex];
      return this.apps;
    },
    pagedData() {
      return this.apps.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.apps;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.apps.length;
      return this.apps.length;
    }
  },

  mounted() {
    this.country = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.languages = languages;
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ["configname", "configparam", "configvalue"]
    });
    if (this.currentLanguage) {
      this.selectedLangauge = this.currentLanguage;
      if (this.selectedLangauge && this.selectedLangauge.thumbnailLink) {
        this.thumbnail = this.selectedLangauge.thumbnailLink["source"];
      }
      if (this.selectedLangauge && this.selectedLangauge.coverartLink) {
        this.coverart = this.selectedLangauge.coverartLink["source"];
      }
      if (this.metaData) {
        this.metaData.forEach((element) => {
          if (element.key == "bannerImage") {
            this.bannerImg = element.value;
          }
          if (element.key == "mobileBannerImage") {
            this.mobileBannerImg = element.value;
          }
          if (element.key == "bannerTitleImage") {
            this.bannerTitleImg = element.value;
          }
          if (element.key == "portraitImage") {
            this.portraitImg = element.value;
          }
        })

      }
    }
  },
  watch: {
    currentLanguage: function () {
      if (this.currentLanguage) {
        this.selectedLangauge = this.currentLanguage;
        if (this.selectedLangauge && this.selectedLanguage.thumbnailLink) {
          this.thumbnail = this.selectedLanguage.thumbnailLink["source"];
        }
      }
    }
  },
  methods: {
    handleEdit: function (index, props) {
      // alert("Edit = " + JSON.stringify(props));
      this.newConfigName = props.configname;
      this.newConfigParam = props.configparam;
      this.newConfigValue = props.configvalue;

      this.show = true;
    },

    handleDelete: function (index, props) {
      alert("Delete = " + JSON.stringify(props));
    },

    addUser: function () {
      this.$router.push({ name: "Edit Customer", params: { id: "-1" } });
    },

    handleNewConfigOk: function (evt) {
      evt.preventDefault();
      if (!this.newConfigName) {
        swal("Please enter a platform name");
      } else {
        this.handleNewConfig();
      }
    },

    clearNewConfigName: function () {
      this.newConfigName = "";
    },

    handleNewConfig: function () {
      const data = {
        id: this.platforms.length + 1,
        title: this.newConfigName,
        description: ""
      };
      this.platforms.push(data);
      this.clearNewConfigName();
      this.$refs.modal.hide();
    },

    resetUrl: function (url, type) {
      if (type == "thumbnail") {
        this.thumbnail = url;
        this.selectedLangauge.thumbnailLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      } else if (type == "coverart") {
        this.coverart = url;
        this.selectedLangauge.coverartLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      }
      else if (type == "mobileBannerImg") {
        this.mobileBannerImg = url;
        this.selectedLangauge.mobileBannerImgLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      }
      else if (type == "bannerTitleImg") {
        this.bannerTitleImg = url;
        this.selectedLangauge.titleImageLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      }
      else if (type == "portraitImg") {
        this.portraitImg = url;
        this.selectedLangauge.portraitImageLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      }
      else {
        this.bannerImg = url;
        this.selectedLangauge.bannerImgLink = {
          small: url,
          medium: url,
          large: url,
          source: url
        };
      }
    },
    deleteImage(type) {
      if (type == "thumbnail") {
        this.resetUrl("https://dummyimage.com/640x360/a1a1a1/fff", type);
      } else if (type == "coverart") {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
      else if (type == "mobileBannerImg") {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
      else if (type == "bannerTitleImg") {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
      else if (type == "portraitImg") {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
      else {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
    },
    getLangInfo: function () {
      return this.selectedLangauge;
    },
    openImageDialog(evt, type) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        file = this.$refs.thumbnailInput;
      } else if (type == "coverart") {
        file = this.$refs.coverartInput;
      }
      else if (type == "mobileBannerImg") {
        file = this.$refs.mobileBannerImgInput;
      }
      else if (type == "bannerTitleImg") {
        file = this.$refs.bannerTitleImgInput;
      }
      else if (type == "portraitImg") {
        file = this.$refs.portraitImgInput;
      }
      else {
        file = this.$refs.bannerImgInput;
      }
      if (file) {
        file.click();
      }
    },

    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      self.$emit("uploadStatus", true);
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.resetUrl(url, type);
              self.$emit("uploadStatus", false);
            })
            .catch(() => {
              self.$emit("uploadStatus", false);
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          self.$emit("uploadStatus", false);
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    confirmDeleteLanguage() {
      this.$emit("deleteLanguage");
    }
  }
};
</script>

<style scoped>
.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
}

.alerttext {
  margin-left: 15px;
  margin-top: -20px;
  color: red;
}

.languagecontainer {
  padding-top: 20px;
}

.dropdownpadding {
  padding-bottom: 20px;
}

.thumbnail {
  float: left;
  width: 50%;
}

.thumb-div {
  margin-left: 15px;
}

.uploadthumbbtn {
  position: absolute;
  float: left;
  margin-top: 0px;
  left: calc(50% + 40px);
}

.deletethumbbtn {
  position: absolute;
  float: left;
  margin-top: 50px;
  left: calc(50% + 40px);
}

.multi-country {
  margin-bottom: 20px;
}

.delete-lang-btn {
  float: left;
  margin-left: 25px;
  margin-top: 25px;
}

.card form [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

.width_class {
  width: 95%;
  margin-left: 15px;
}

.catthumbNail {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.catUploadThumbBtn {
  padding: 10px 10px;
}

.catDeleteThumbBtn {
  padding: 10px 10px;
}

input::placeholder {
  font-size: 12px;
  opacity: 1;
}

.catTitleThumb {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin-left: 12px;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
}

.catThumbContainer {
  min-height: 200px;
  position: relative;
  left: 6px;
}

.thumbnailvideo {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.titleThumb {
  font-size: 12px;
  font-weight: 400;
  color: #9a9a9a;
  margin-left: 10px;
}

.thumbContainer {
  position: relative;
  right: 8px;
}

.thumbDiv {
  min-height: 160px;
}

.thumbNail {
  height: 110px;
  width: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
}

.uploadThumbBtn {
  padding: 10px 10px;
}

.deleteThumbBtn {
  padding: 10px 10px;
}
</style>
