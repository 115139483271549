<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteProduct"
      @delete="$event()"
    />
    <div class="col-12">
      <card title="Products">
        <div>
          <div
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
            <el-input
              type="search"
              class="mb-3"
              style="width: 200px"
              placeholder="Search records"
              v-model="searchQuery"
              aria-controls="datatables"
            />
          </div>
          <div class="add-button">
            <button class="btn btn-primary btn-fill" @click="addProduct">
              Add
            </button>
          </div>
          <div class="col-sm-12">
            <el-table
              class="table-bigboy"
              style="width: 100%;"
              :data="queriedData"
              border
            >
              <el-table-column min-width="180" label="Name">
                <template slot-scope="{ row }">
                  {{ row.name }}
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="Type">
                <template slot-scope="{ row }">
                  {{ row.type }}
                </template>
              </el-table-column>
              <el-table-column min-width="300" label="Description">
                <template slot-scope="{ row }">
                  {{ row.description }}
                </template>
              </el-table-column>
              <el-table-column min-width="100" label="Price">
                <template slot-scope="{ row }">
                  {{ row.price }}
                </template>
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-tooltip.top-center="'Delete'"
                    v-b-modal.confirmDelete
                    class="btn-danger btn-simple btn-link"
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="pagination.total"
          >
          </l-pagination>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import { Loading } from "element-ui";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },

  data() {
    return {
      tableData: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      currentDeleteId: null,
      selectedUrl: "",
      searchQuery: "",
      propsToSearch: ["name", "tags"],
      fuseSearch: null,
      selectedVideoTitle: ""
    };
  },

  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, {
          keys: ["name", "description"]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {
    this.getProductList();
  },
  created() {},
  methods: {
    getProductList: function() {
      this.$store.dispatch("getProductList").then(res => {
        if (Array.isArray(res)) {
          this.tableData = res.filter(item => !item._isDeleted);
        }
      });
    },
    handleEdit: function(index, props) {
      this.$router.push({ name: "Edit Product", params: { id: props.id } });
    },
    deleteProduct: function() {
      let loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("deleteProduct", this.currentDeleteId)
        .then(res => {
          this.getProductList();
          loadingInstance.close();
        })
        .catch(err => {
          loadingInstance.close();
        });
    },
    handleDelete: function(index, props) {
      this.currentDeleteId = props.id;
    },
    addProduct: function() {
      this.$router.push({ name: "Edit Product", params: { id: -1 } });
    }
  }
};
</script>

<style></style>
