<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-3">
      <div>
        <span class="titleCard mb-2">
          Video Hosting Provider
        </span>
      </div>
      <div
        class="d-flex justify-content-end align-items-center  searchContainer"
      >
        <div class="mx-2">
          <el-input
            type="search"
            style="width: 200px; "
            placeholder="Search records"
            prefix-icon="el-icon-search"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div>

        <div class="mt-1">
          <el-button type="primary" icon="el-icon-plus" @click="addVideoHost"
            >Add Provider
          </el-button>
        </div>
      </div>
    </div>
    <card>
      <b-modal
        id="newPlatformModal"
        ref="videoHostModal"
        @ok="saveHostProvider"
        @cancel="exitDialog"
        @hide="exitDialog"
        :title="getDialogTitle()"
        ok-title="Save"
      >
        <form>
          <div>
            <div class="row px-3">
              <div class="col-md-6">
                <label>Video Host</label>
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="Video Host"
                  v-model="curData.providerId"
                >
                  <el-option
                    v-for="(curr, index) in videoHostArray"
                    class="select-danger"
                    :value="curr.value"
                    :label="curr.label"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="col-md-6">
                <label>Auth Type</label>
                <el-select
                  class="select-default"
                  size="large"
                  placeholder="Auth Type"
                  v-model="curData.configObj.auth_type"
                >
                  <el-option
                    v-for="(curr, index) in authTypes"
                    class="select-danger"
                    :value="curr.value"
                    :label="curr.label"
                    :key="index"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <fg-input
              class="subtitlecontainer"
              label="Api Key"
              placeholder="Api Key"
              v-model="curData.configObj.api_key"
            ></fg-input>
            <fg-input
              class="subtitlecontainer"
              label="Client Id"
              placeholder="Client Id"
              v-model="curData.configObj.client_id"
            ></fg-input>
            <fg-input
              class="subtitlecontainer"
              label="Client Secret"
              placeholder="Client Secret"
              v-model="curData.configObj.client_secret"
            ></fg-input>
            <fg-input
              class="subtitlecontainer"
              label="Product Id"
              placeholder="Product Id"
              v-model="curData.configObj.product_id"
            ></fg-input>
          </div>
        </form>
      </b-modal>

      <DeleteConfirmation
        :currentDeleteMethod="deleteCustomerHost"
        ref="ConfirmDeleteCustomerHost"
        @delete="$event()"
      />
      <div class="row">
        <div class="col-12">
          <card>
            <div>
              <div class="col-sm-12">
                <el-table
                  stripe
                  style="width: 100%;"
                  :data="queriedData"
                  border
                >
                  <el-table-column min-width="180" label="Hosting Provider">
                    <template slot-scope="props">{{
                      getCName(props.row)
                    }}</template>
                  </el-table-column>
                  <el-table-column
                    v-for="(column, index) in tableColumns"
                    :key="index"
                    :min-width="column.minWidth"
                    :prop="column.prop"
                    :label="column.label"
                  >
                  </el-table-column>
                  <el-table-column
                    :min-width="120"
                    fixed="right"
                    label="Actions"
                  >
                    <template slot-scope="props">
                      <a
                        v-tooltip.top-center="'Edit'"
                        class="btn-warning btn-simple btn-link"
                        @click="handleEdit(props.$index, props.row)"
                        ><i class="fa fa-edit"></i
                      ></a>
                      <a
                        v-tooltip.top-center="'Delete'"
                        class="btn-danger btn-simple btn-link"
                        @click="handleDelete(props.$index, props.row)"
                        ><i class="fa fa-trash"></i
                      ></a>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                </p>
              </div>
              <l-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="pagination.total"
              >
              </l-pagination>
            </div> -->
          </card>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    LPagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DeleteConfirmation
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  data() {
    return {
      tableData: [],
      videoHostArray: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      dMode: "",
      searchQuery: "",
      curData: {
        providerId: "",
        configObj: {}
      },
      authTypes: [
        {
          label: "Api Keys",
          value: "Api keys"
        },
        {
          label: "OAuth",
          value: "oauth"
        }
      ],
      tableColumns: [
        {
          prop: "configObj.auth_type",
          label: "Auth Type",
          minWidth: 150
        },
        {
          prop: "configObj.api_key",
          label: "Api Key",
          minWidth: 200
        },
        {
          prop: "configObj.client_id",
          label: "Client ID",
          minWidth: 150
        },
        {
          prop: "configObj.client_secret",
          label: "Client Secret",
          minWidth: 200
        },
        {
          prop: "configObj.product_id",
          label: "Product ID",
          minWidth: 150
        }
      ]
    };
  },
  created() {
    this.$store
      .dispatch("getVideoHostProviderList")
      .then(response => {
        if (response) {
          for (var i = 0; i < response.length; i++) {
            var vh = {};
            vh.label = response[i].name;
            vh.value = response[i].id;
            this.videoHostArray.push(vh);
          }
          if (Array.isArray(response)) {
            this.curData.providerId = response[0].id;
          }
        }
      })
      .catch(() => {});
    this.$store
      .dispatch("getCustomerProviderList")
      .then(response => {
        this.tableData = response;
      })
      .catch(() => {});
  },
  methods: {
    getDialogTitle() {
      if (this.dMode == "create") {
        return "Add Video Host Provider";
      } else if (this.dMode == "edit") {
        return "Edit Video Host Provider";
      }
      return "";
    },
    exitDialog() {
      this.curData = {
        providerId: "",
        configObj: {}
      };
    },
    saveHostProvider() {
      if (this.dMode == "create") {
        this.$store
          .dispatch("createCustomerProvider", this.curData)
          .then(response => {
            this.tableData.splice(0, 0, response.data.customerProviderConfig);
            this.curData = {
              providerId: "",
              configObj: {}
            };
          })
          .catch(() => {});
      } else if (this.dMode == "edit") {
        var req = {};
        req.data = this.curData;
        req.id = this.curData.id;
        this.$store
          .dispatch("updateCustomerProvider", req)
          .then(response => {
            this.tableData.splice(this.curIndex, 1);
            this.tableData.splice(this.curIndex, 0, response);
            this.curData = {
              providerId: "",
              configObj: {}
            };
          })
          .catch(() => {});
      }
    },
    handleEdit(index, row) {
      this.dMode = "edit";
      this.curData = JSON.parse(JSON.stringify(row));
      this.curIndex = index;
      this.$refs["videoHostModal"].show();
    },
    handleDelete(index, row) {
      this.dMode = "delete";
      this.deleteId = row.id;
      this.deleteIndex = index;
      this.$refs["ConfirmDeleteCustomerHost"].$refs[
        "deleteConfirmation"
      ].show();
    },
    deleteCustomerHost() {
      if (this.dMode == "delete" && this.deleteId) {
        this.$store
          .dispatch("deleteCustomerProvider", this.deleteId)
          .then(response => {
            this.tableData.splice(this.deleteIndex, 1);
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Failed To Delete Customer Host",
              type: "warning"
            });

            return;
          });
      }
    },
    addVideoHost() {
      this.dMode = "create";
      this.$refs["videoHostModal"].show();
    },
    getCName(row) {
      var cid = row.providerId;
      if (this.videoHostArray && Array.isArray(this.videoHostArray)) {
        for (var i = 0; i < this.videoHostArray.length; i++) {
          if (this.videoHostArray[i].value == cid) {
            return this.videoHostArray[i].label;
          }
        }
      }
      return "";
    }
  },
  mounted() {
    this.fuseSearch = new Fuse(this.tableData, { keys: ["name", "subid"] });
  }
};
</script>

<style scoped>
.subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 14px;
}
input::placeholder {
  font-size: 12px;
  opacity: 1;
}
</style>
