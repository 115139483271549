export const token = "app_mastery_token";
export const user = "app_mastery_user";
export const customerId = "app_master_customerId";
export const expireDate = "app_mastery_expire";

export const previewSite = "http://d2ztkd15aqgbsv.cloudfront.net/#/";
//export const previewSite = "http://localhost:8081/";
export const previewSuffix = "/?draft=1&preview=1";

export const themeOptions = [
    {
        "label": "Common",
        "value": "common",
        "url": previewSite + "?draft=1&preview=1"
    },
    {
        "label": "Landing Page",
        "value": "main",
        "url": previewSite + "?draft=1&preview=1"
    },
    {
        "label": "Home",
        "value": "home",
        "url": previewSite + "home" + previewSuffix
    },
    {
        "label": "Detail",
        "value": "detail",
        "url": previewSite + "detail/5bc34d123f511b1314af7a53/?type=livestream" + previewSuffix
    },
    {
        "label": "Log In",
        "value": "login",
        "url": previewSite + "login" + previewSuffix
    },
    {
        "label": "Register",
        "value": "register",
        "url": previewSite + "signup" + previewSuffix
    },
    {
        "label": "Forget Password",
        "value": "forgetpassword",
        "url": previewSite + "forgetpassword" + previewSuffix
    },
    {
        "label": "About Us",
        "value": "aboutus",
        "url": previewSite + previewSuffix
    },
    {
        "label": "Contact Us",
        "value": "contactus",
        "url": previewSite + "ContactUs" + previewSuffix
    },
    {
        "label": "FAQs",
        "value": "faqs",
        "url": previewSite + "Faq" + previewSuffix
    }, 
    {
        "label": "PRIVACY POLICIES",
        "value": "privacypolicies",
        "url": previewSite + "PrivacyPolicies" + previewSuffix
    }
]