<template>
  <card>
    <div>
      <fg-input class="col-sm-12 col-12" label="Firebase Configuration">
        <textarea
          class="form-control"
          v-model="firebaseConfig"
          placeholder="Firebase Configuration"
          rows="2"
        ></textarea>
      </fg-input>
    </div>
    <div>
      <fg-input class="col-sm-12 col-12" label="Firebase Identifier">
        <textarea
          class="form-control"
          v-model="firebaseIdentifier"
          placeholder="Firebase Identifier"
          rows="2"
        ></textarea>
      </fg-input>
    </div>

    <div class="add-button">
      <el-button
        type="primary"
        style="width: 150px;"
        @click="sendFirebaseConfig"
      >
        Send
      </el-button>
    </div>
  </card>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";

export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    LCheckbox
  },
  data() {
    return {
      firebaseConfig: "",
      firebaseIdentifier: "",
      spinnerTip: "Sending...",
      message: "",
      errorMessage: "",
      uploading: false,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["ip", "id"],
      tableColumns: [
        {
          prop: "ip",
          label: "IP Address",
          minWidth: 250
        },
        {
          prop: "id",
          label: "Id",
          minWidth: 250
        }
      ],
      fuseSearch: null,
      tableData: []
    };
  },

  computed: {
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["ip", "id"] }).search(
          this.searchQuery
        );
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },

  mounted() {},
  created() {
    this.firebaseConfig = "";
    this.firebaseIdentifier = "";
  },

  methods: {
    sendFirebaseConfig: function() {
      self = this;
      var Data = {};
      var strFyOne = JSON.stringify(this.firebaseConfig);
      var strFyTwo = JSON.stringify(this.firebaseIdentifier);
      var jsonOne = JSON.parse(strFyOne);
      var jsonTwo = JSON.parse(strFyTwo);
      Data["firebaseConfig"] = JSON.parse(jsonOne);
      Data["firebaseIdentifier"] = JSON.parse(jsonTwo);
      this.$store
        .dispatch("sendFirebaseConfig", Data)
        .then(response => {
          this.$notify({
            title: "Success",
            message: "Successfully ADDED!",
            type: "success"
          });
          self.firebaseConfig = "";
          self.firebaseIdentifier = "";
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed to Update!",
            type: "warning"
          });
        });
    },
    generateErrorMessage: function() {
      this.errorMessage = "";
      if (this.errorsData && this.errorsData.length > 0) {
        for (var i = 0; i < this.errorsData.length; i++) {
          this.errorMessage =
            this.errorMessage + JSON.stringify(this.errorsData[i]);
        }
      }
    },
    openExportip: function() {}
  }
};
</script>

<style scoped>
.add-button {
  padding-bottom: 10px;
  padding-left: 15px;
}

.btn-import {
  float: right;
  margin-right: 20px;
}
</style>
