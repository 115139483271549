<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button
          class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block"
          v-tooltip.top-center="'Toggle Sidebar'"
          @click="minimizeSidebar"
        >
          <i
            :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-navicon'"
          ></i>
        </button>
      </div>
      <!--<a class="navbar-brand">{{this.$route.name}}</a>-->
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-expanded="false"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <!--
        <ul class="nav navbar-nav mr-auto">
          <form class="navbar-form navbar-left navbar-search-form" role="search">
            <div class="input-group">
              <label for="searchInput"></label>
              <i class="nc-icon nc-zoom-split"></i>
              <input id="searchInput" type="text" value="" class="form-control" placeholder="Search...">
            </div>
          </form>
        </ul>
        -->

        <ul class="navbar-nav">
          <!--
          <drop-down>
            <i slot="title" class="nc-icon nc-planet"></i>
            <a class="dropdown-item" href="#">Create New Post</a>
            <a class="dropdown-item" href="#">Manage Something</a>
            <a class="dropdown-item" href="#">Do Nothing</a>
            <a class="dropdown-item" href="#">Submit to live</a>
            <li class="divider"></li>
            <a class="dropdown-item" href="#">Another action</a>

          </drop-down>
          <drop-down>
            <template slot="title">
              <i class="nc-icon nc-bell-55"></i>
              <span class="notification">5</span>
              <span class="d-lg-none">Notification</span>
            </template>
            <a class="dropdown-item" href="#">Notification 1</a>
            <a class="dropdown-item" href="#">Notification 2</a>
            <a class="dropdown-item" href="#">Notification 3</a>
            <a class="dropdown-item" href="#">Notification 4</a>
            <a class="dropdown-item" href="#">Another notification</a>
          </drop-down>
          -->
          <drop-down position="right">
            <a class="dropdown-item" href="#" @click="lockScreen">
              <i class="nc-icon nc-lock-circle-open"></i> Lock Screen
            </a>
            <a class="dropdown-item" href="#" @click="resetpassword">
              <i class="nc-icon nc-key-25"></i> Reset password
            </a>
            <a href="#" class="dropdown-item text-danger" @click="logOut">
              <i class="nc-icon nc-button-power"></i> Log out
            </a>
            <div class="divider"></div>
            <a slot="title">
              <user-menu></user-menu>
            </a>
            <a
              v-for="(cItem, index) in customerList"
              @click="setCustomerId(cItem)"
              class="dropdown-item"
              :class="{ 'selected-customer': isSelectedCustomer(cItem) }"
              :key="index"
            >
              <i class="nc-icon"></i> {{ cItem.name }}
            </a>

            <!--<a class="dropdown-item" href="#">
              <i class="nc-icon nc-settings-90"></i> Settings
            </a>
            <div class="divider"></div>-->
          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import AuthService from "../../../service/authService";
import UserService from "../../../service/userService";
import UserMenu from "./Extra/UserMenu.vue";
export default {
  components: { UserMenu },
  beforeCreate() {},
  created() {
    var id = UserService.getUserId();
    if (id) {
      this.$store
        .dispatch("getCustomerListByAdminId", id)
        .then(response => {
          this.customerList = response;
          var cId = UserService.getCustomerId();
          if (cId) {
          } else {
            if (this.customerList[0].id) {
              UserService.setCustomerId(this.customerList[0].id);
              this.$store.commit("setCustomerId", this.customerList[0].id);
            }
          }
        })
        .catch(() => {});
    }
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    }
  },
  data() {
    return {
      activeNotifications: false,
      customerList: []
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    lockScreen() {
      this.$store
        .dispatch("logoff")
        .then(response => {
          AuthService.cleanAuthData();
          this.$router.push({ name: "Lock" });
        })
        .catch(() => {});
    },
    resetpassword() {
      // this.$store.dispatch("logoff").then(response=>{
      AuthService.cleanAuthData();
      this.$router.push({ name: "ForgotPassword" });
      // }).catch(()=>{

      // });
    },
    logOut() {
      this.$store
        .dispatch("logoff")
        .then(response => {
          AuthService.cleanAuthData();
          UserService.cleanUserData();
          this.$router.push({ name: "Login" });
        })
        .catch(() => {});
    },
    isSelectedCustomer(cItem) {
      var itemId = cItem.id;
      var curId = this.$store.state.customerId;
      return itemId == curId;
    },
    setCustomerId(item) {
      var id = item.id;
      this.$store.commit("setCustomerId", id);
      var route = this.$route;
      if (route.name == "All Videos") {
        this.$store.dispatch("getVideoList");
      } else {
        this.$router.push({ name: "All Videos" });
      }
    }
  }
};
</script>
<style>
.selected-customer {
  font-weight: bold;
}
</style>
