<template>
  <div>
    <div>
      <span class="titleCard mb-2">
        Notification
      </span>
    </div>
    <card>
      <el-tabs type="card">
        <el-tab-pane label="Push Notification">
          <SendAll />
        </el-tab-pane>
        <el-tab-pane label="Notification Configuration">
          <NotificationConfig />
        </el-tab-pane>
      </el-tabs>
    </card>
  </div>
</template>

<script>
import { Tabs, TabPane } from "element-ui";
import NotificationConfig from "./NotificationConfig";
import SendAll from "src/pages/Dashboard/Notifications/SendAll.vue";

export default {
  components: {
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    NotificationConfig,
    SendAll
  },
  data() {
    return {};
  },

  computed: {},

  mounted() {},
  created() {},

  methods: {}
};
</script>

<style scoped>
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 16px;
}
</style>
