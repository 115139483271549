<template>
  <div>
    <div class="d-flex justify-content-between p-3">
      <div>
        <span class="pageTitle mb-2">
          {{ pageTitle }}
        </span>
      </div>
      <div class="d-flex justify-content-end">
        <div class="col-md-6">
          <div class="enableSwitch">
            <l-switch v-model="catalogue.isActive" on-text="ON" off-text="OFF"></l-switch>
          </div>
        </div>
      </div>
    </div>
    <card>
      <div class="uploadingOverlay" v-if="uploading">
        <l-spinner class="upload-spinner" :main="true" :title="spinnerTip" size="medium"></l-spinner>
      </div>

      <DeleteConfirmation :currentDeleteMethod="confirmDeleteVideo" ref="confirmCatLanguageDelete" @delete="$event()" />

      <DeleteConfirmation :currentDeleteMethod="confirmChildElementDelete" ref="ChildElementDelete"
        @delete="$event()" />
      <b-modal id="childdialog" size="lg" ref="associateModal" title="Select Child Elements" @ok="addChildElements"
        @shown="childDialogFlag = true" @hidden="closeChildElementDialog" ok-title="Add">
        <AddChildElements v-if="childDialogFlag" ref="addchilddialog" :data="associateItems" :type="catalogue_type">
        </AddChildElements>
      </b-modal>
      <form @submit.prevent="saveCatalogue" class="catalogue-form">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <el-select v-validate="'required'" class="select-primary typewidth" size="large"
                  placeholder="Catalogue Type" v-model="catalogue_type" name="catalogueType" :class="{
                    'is-invalid': submitted && errors.has('catalogueType')
                  }">
                  <el-option v-for="option in cataloguetypes" class="select-danger" :value="option.value"
                    :label="option.label" :key="option.label">
                  </el-option>
                </el-select>
                <el-select :multiple="true" :filterable="true" :filter-method="filterMethod"
                  v-if="catalogue_type && catalogue_type != 'Root'" class="select-primary typewidth second-dropdown"
                  size="large" :class="{
                    'is-invalid':
                      submitted &&
                      secondCatalogue.length < 1 &&
                      catalogue_type == 'Season'
                  }" v-model="secondCatalogue">
                  <el-option v-for="(option, index) in secondDropDown" class="select-danger" :value="option.value"
                    :label="option.label" :key="index">
                  </el-option>
                </el-select>
              </div>

              <div class="spacer col-md-12"></div>
              <div class="col-md-6">
                <fg-input label="Name(Admin Reference)" v-validate="'required'" placeholder="Name" name="Name"
                  v-model="catalogue.name" :class="{ 'is-invalid': submitted && errors.has('Name') }"></fg-input>
              </div>
              <div class="col-md-6">
                <fg-input label="ID" disabled="" v-model="catalogue.id"></fg-input>
              </div>
            </div>
          </div>

          <div class="col-md-12">
            <card>
              <div class="d-flex justify-content-between align-items-center py-2">
                <div>
                  <span class="associateTitle mb-2">
                    Associated Items
                  </span>
                </div>
                <div class="d-flex justify-content-end align-items-center  searchContainer">
                  <div>
                    <el-input type="search" style="width: 200px; " placeholder="Search records"
                      prefix-icon="el-icon-search" v-model="searchQuery" aria-controls="datatables" />
                  </div>
                  <div v-if="!createMode">
                    <span class="demo-input-label ml-3 perPage">Results per page:</span>
                    <el-select class="select-default ml-1" v-model="pagination.perPage" style="width: 80px; "
                      placeholder="Per page">
                      <el-option class="select-default" v-for="item in pagination.perPageOptions" :key="item"
                        :label="item" :value="item"></el-option>
                    </el-select>
                  </div>

                  <div class="mx-2 mt-1">
                    <el-button type="primary" icon="el-icon-plus" v-if="showAddAssociatedItems"
                      @click="createAssociatedItems">Add Associated Items
                    </el-button>
                  </div>
                </div>
              </div>
              <div>
                <div class="col-sm-12" v-if="!createMode">
                  <el-table ref="childelementtable" stripe style="width: 100%;" :data="queriedData" border>
                    <el-table-column label="Name">
                      <template slot-scope="props">
                        <a @click="gotoVideo(props.row)" class="associated-item-name">{{ getChildName(props.row) }}</a>
                      </template>
                    </el-table-column>
                    <el-table-column prop="childType" label="Type">
                    </el-table-column>
                    <el-table-column prop="order" label="Order" width="150">
                      <template slot-scope="{ row }">
                        <fg-input v-model="row.order" @blur="blurChildOrderInput"></fg-input>
                      </template>
                    </el-table-column>
                    <el-table-column v-if="
                      catalogue_type == 'Playlist' ||
                      catalogue_type == 'Season'
                    " label="Thumbnail" max-width="250">
                      <template slot-scope="props">
                        <div class="thumbnailImg" :style="{
                          backgroundImage:
                            'url(' + getImageUrl(props.row) + ')'
                        }"></div>
                      </template>
                    </el-table-column>
                    <el-table-column width="100" fixed="right" label="Action">
                      <template slot-scope="props">
                        <a v-tooltip.top-center="'Delete'" class="btn-danger btn-simple btn-link"
                          @click="deleteChildElement(props.$index, props.row)"><i class="fa fa-trash"></i></a>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div slot="footer" v-if="!createMode"
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
                <div class="">
                  <p class="card-category">
                    Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                  </p>
                </div>
                <l-pagination class="pagination-no-border" v-model="pagination.currentPage"
                  :per-page="pagination.perPage" :total="pagination.total">
                </l-pagination>
              </div>
            </card>
          </div>

          <div class="col-md-12">
            <card title="Catalogue Details">
              <div class="col-md-12 p-0">
                <el-button type="primary" icon="el-icon-plus" @click="addCatalogueDetails">
                  Add Catalogue Details
                </el-button>
                <!--<button class="btn btn-default" @click="addPlatform"></button>-->
              </div>
              <div class="col-md-12 col-md-12 p-0">
                <template slot="header">
                  <h4 class="card-title">Catalogue Details</h4>
                  <!-- <p class="card-category">Production Apps</p> -->
                </template>
                <el-collapse v-model="activeLanguageItems" class="mt-2">
                  <el-collapse-item v-for="(item, index) in catalogue.languages" :title="getTitle(item)"
                    :name="index + 1" :key="index">
                    <CatLanguage :ref="'catlang' + index" :catalougeIndex="Number(paramID)"
                      :langaugeIndex="Number(index)" :currentLanguage="item" @deleteLanguage="deleteLanguage(index)"
                      :key="getKey(item)" :metaData="metaData" @uploadStatus="setUploadStatus"></CatLanguage>
                  </el-collapse-item>
                </el-collapse>
                <div class="col-md-12 mt-2 p-0">
                  <fg-input :value="catalogue.tags.toString()" @change="tagChanged" label="Tags" placeholder="Tags"
                    class="m-0"></fg-input>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12 monetizationContainer">
            <card title="Monetization">
              <el-tabs>
                <el-tab-pane label="Free Preview">
                  <el-checkbox @change="enableFreePreview" v-model="settings.free" size="medium">
                    <p>Free Preview Data</p>
                  </el-checkbox>
                </el-tab-pane>

                <el-tab-pane label="AVOD" :disabled="settings.free">
                  <el-checkbox v-model="settings.AVOD" size="medium">
                    <p>AVOD</p>
                  </el-checkbox>
                </el-tab-pane>

                <el-tab-pane label="SVOD" :disabled="settings.free">
                  <Monetization ref="monetization" :setting="settings" createMode="createMode"></Monetization>
                </el-tab-pane>
              </el-tabs>
            </card>
          </div>

          <div class="col-md-12 col-12 manageTagContainer">
            <card title="Manage Meta Data">
              <ManageMetaData :metaData="metaData"></ManageMetaData>
            </card>
          </div>
          <div class="col-md-12 saveBtn">
            <button class="btn btn-primary" :disabled="errors.has('cattitle')" type="submit">
              Save
            </button>
          </div>
        </div>
      </form>
    </card>
  </div>
</template>

<script>
import { FormGroupInput as FgInput } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem,
  Loading,
  Button,
  Tabs,
  TabPane,
  Checkbox
} from "element-ui";
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import CatLanguage from "../Components/CatLanguage.vue";
import TVOD from "../Components/TVOD.vue";
import Monetization from "../Components/Monetization.vue";
import ManageMetaData from "../Components/ManageMetaData";
import AddChildElements from "../Components/AddChildElements.vue";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
import Vue from "vue";
export default {
  props: ["id"],
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    FgInput,
    bModal,
    CatLanguage,
    TVOD,
    Monetization,
    AddChildElements,
    bModalDirective,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Select.name]: Select,
    [Option.name]: Option,
    LSwitch,
    LSpinner,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LCheckbox,
    LPagination,
    DeleteConfirmation,
    ManageMetaData,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane
  },
  created() {
    this.init();
  },

  mounted() { },
  computed: {
    getVideos() {
      return this.$store.state.videos.videoList;
    },
    getCatalogues() {
      return this.$store.state.catalogue.catalogueList;
    },
    queriedData() {
      let result = this.associateItems;
      result = result.filter(item => item.data.isActive == true);
      if (this.searchQuery !== "") {
        result = new Fuse(this.associateItems, {
          keys: [
            "data.name",
            "data.providerVideoData.title",
            "data.languages.title"
          ]
        }).search(this.searchQuery);
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      let result = this.associateItems;
      result = result.filter(item => item.data.isActive == true);
      this.pagination.total = result.length;
      return result.length;
    },
    isAdd() {
      return this.$route.path.indexOf("-1") > -1;
    },
    pageTitle() {
      return this.isAdd ? ` Add Catalogue` : "Edit Catalogue";
    },
    showAddAssociatedItems() {
      if (
        this.catalogue_type == "Playlist" ||
        this.catalogue_type == "Season"
      ) {
        return false;
      }
      return true;
    }
  },
  methods: {
    tagChanged($event) {
      this.catalogue.tags = $event.target.value.split(",");
    },
    init() {
      this.id = this.$route.params.id;
      this.catalogue_type = '';
      if (!this.id || this.id == -1) {
        this.createMode = true;
        this.associateItems = [];

        this.settings = {
          subscription: true,
          subscriptionIds: [],
          TVOD: false,
          AVOD: false,
          free: false
        };
      } else {
        this.createMode = false;
        this.getCatalogueData();
      }
    },
    enableFreePreview() {
      if (this.settings.free == true) {
        Vue.set(this.settings, "TVOD", false);
        Vue.set(this.settings, "AVOD", false);
        Vue.set(this.settings, "subscription", false);
      }
    },
    blurChildOrderInput: function () {
      this.associateItems = this.associateItems.sort(function (a, b) {
        return a.order - b.order;
      });
      this._computedWatchers.queriedData.run();
      this.$forceUpdate();
    },
    getCatalogueData: function () {
      this.$store.commit("setLoader", {
        show: true,
        text: "Fetching Details"
      });
      this.$store
        .dispatch("getCategoryById", this.id)
        .then(data => {
          this.catalogue = data;
          this.catalogue_type = this.catalogue.type;
          if (
            this.catalogue.childElements &&
            Array.isArray(this.catalogue.childElements)
          ) {
            this.associateItems = this.catalogue.childElements;
          }
          var metaData = this.catalogue.metaData;
          for (var key in metaData) {
            var obj = {};
            obj.key = key;
            obj.value = metaData[key];
            this.metaData.push(obj);
          }
          if (this.catalogue_type != "Root") {
            this.secondIds = data["catalogueIds"];
          }
          this.settings = this.catalogue.settings;
          this.$store.commit("setLoader", {
            show: false,
            text: ""
          });
        })
        .catch(() => { });
    },
    setSecondCatalogue: function (data) {
      this.secondDropDown = [];
      if (data && Array.isArray(data)) {
        for (var i = 0; i < data.length; i++) {
          var item = {};
          item.value = data[i].id;
          item.label = data[i].name;
          this.secondDropDown.push(item);
          this.secondDropDownClone.push(item);
        }
      }
    },
    deleteChildElement(index, row) {
      this.selectedChildElement = row;
      this.$refs["ChildElementDelete"].$refs["deleteConfirmation"].show();
    },
    confirmChildElementDelete() {
      var form = {};
      form.id = this.id;
      form.data = {};
      form.data.childIds = [];
      form.data.childIds.push(this.selectedChildElement.childId);
      var loadingInstance = Loading.service({ fullscreen: true });
      this.$store
        .dispatch("removeChildElements", form)
        .then(res => {
          var fIndex = this.associateItems.findIndex(
            a => a.childId == this.selectedChildElement.childId
          );
          if (fIndex >= 0) {
            this.associateItems.splice(fIndex, 1);
          }
          this.selectedChildElement = null;
          loadingInstance.close();
        })
        .catch(() => {
          this.selectedChildElement = null;
          loadingInstance.close();
        });
    },
    createAssociatedItems() {
      if (this.createMode) {
        this.$notify({
          title: "Update",
          message:
            "Please create a catalogue, click edit to view and add associated catalogue",
          type: "warning"
        });
      } else {
        this.$refs["associateModal"].show();
      }
    },
    addChildElements() {
      var newElements = this.$refs.addchilddialog.getCheckedData();
      if (newElements) {
        var form = {};
        var data = {};
        var arr = [];
        for (var i = 0; i < newElements.length; i++) {
          var ele = this.associateItems.find(
            a => a.childId == newElements[i].childId
          );
          if (!ele) {
            var item = {};
            item.childId = newElements[i].childId;
            item.order = newElements[i].order;
            arr.push(item);
          }
        }
        if (arr.length >= 1) {
          data.childElements = arr;
          form.data = data;
          form.id = this.id;
          var loadingInstance = Loading.service({ fullscreen: true });
          this.$store
            .dispatch("addChildElements", form)
            .then(res => {
              var data = res.data;
              var catalogue = data.catalogue;
              this.associateItems = catalogue.childElements;
              loadingInstance.close();
            })
            .catch(() => {
              loadingInstance.close();
            });
        }
      }
    },
    closeChildElementDialog() {
      this.childDialogFlag = false;
    },
    getChildName(row) {
      if (row.childId) {
        if (row.childType === "Video") {
          if (row.data.languages && row.data.languages.length > 0) {
            return row.data.languages[0].title;
          }
        }
        if (row.childType === "Catalogue") {
          if (row.data.languages && row.data.languages.length > 0) {
            return row.data.languages[0].title;
          }
          return "NA";
        }
        var video = this.getVideos.find(video => video.id == row.childId);
        if (video && video.languages && video.languages.length > 0) {
          return video.languages[0].title;
        }
        var catalogue = this.getCatalogues.find(
          catalogue => catalogue.id == row.childId
        );
        if (catalogue) return catalogue.name;
      }
      return "";
    },
    getImageUrl(row) {
      if (row.childId) {
        var video = this.getVideos.find(video => video.id == row.childId);
        if (video && video.languages && video.languages[0]) {
          return video.languages[0]["thumbnailLink"]["source"];
        }
      }
      return "";
    },
    openChildElementsDialog() { },
    getRightSecondType: function (type) {
      switch (type) {
        case "Playlist":
          return "Root";
        case "Season":
          return "Series";
        case "Series":
          return "Root";
        case "Category":
          return "Category";
      }
      return null;
    },
    addCatalogueDetails: function () {
      var lang = {};
      lang.cId = this.$uuid.v4();
      lang.language = "english";
      lang.order = 1;
      lang.country = [];
      lang.title = "";
      lang.description = "";
      lang.thumbnailLink = {
        small: "https://dummyimage.com/640x360/a1a1a1/fff",
        medium: "https://dummyimage.com/640x360/a1a1a1/fff",
        large: "https://dummyimage.com/640x360/a1a1a1/fff",
        source: "https://dummyimage.com/640x360/a1a1a1/fff"
      };
      lang.coverartLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.bannerImgLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.mobileBannerImgLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.titleImageLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      lang.portraitImageLink = {
        small: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        medium: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        large: "https://dummyimage.com/1920x1080/a1a1a1/fff",
        source: "https://dummyimage.com/1920x1080/a1a1a1/fff"
      };
      this.catalogue.languages.push(lang);
      this.activeLanguageItems.push(this.catalogue.languages.length);
    },
    getKey(item) {
      if (item._id) {
        return item._id;
      }
      return item.cId;
    },
    deleteLanguage: function (index) {
      this.deletedIndex = index;
      this.$refs["confirmCatLanguageDelete"].$refs["deleteConfirmation"].show();
    },
    confirmDeleteVideo: function () {
      if (this.catalogue.languages) {
        this.catalogue.languages.splice(this.deletedIndex, 1);
      }
    },
    getTitle(item) {
      if (item.title) {
        return `Edit ${item.title} Details`;
      }
      return "Add Catalogue Details";
    },
    jsUcfirst(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    saveCatalogue: function () {
      this.submitted = true;
      this.$validator.validate().then(result => {
        if (!result) {
          this.$notify({
            title: "Error",
            message: "Some Fields are Invalid Format!",
            type: "warning"
          });
          return;
        } else {
          if (this.catalogue.languages.length < 1) {
            this.$notify({
              title: "Error",
              message: "You need to add languages at least one!",
              type: "warning"
            });
            return;
          }
          if (
            this.catalogue_type == "Season" &&
            this.secondCatalogue.length < 1
          ) {
            this.$notify({
              title: "Error",
              message: "Some Fields are Invalid Format!",
              type: "warning"
            });
            return;
          }
          var body = JSON.parse(JSON.stringify(this.catalogue));
          var setting = this.$refs.monetization.getSettingInfo();
          if (!setting) {
            setting = JSON.parse(JSON.stringify(this.settings));
          }
          body.type = this.catalogue_type;
          body.catalogueIds = [];
          if (this.secondCatalogue) {
            body.catalogueIds = this.secondCatalogue;
          }
          body.settings = setting;
          if (this.settings.free != undefined) {
            body.settings.free = this.settings.free;
          }
          if (this.settings.AVOD != undefined) {
            body.settings.AVOD = this.settings.AVOD;
          }
          if (
            this.settings.subscription == false &&
            this.settings.subscriptionIds.length >= 1
          ) {
            this.settings.subscriptionIds = [];
          }
          if (
            this.settings.subscription == true &&
            this.settings.subscriptionIds.length < 1
          ) {
            this.$notify({
              title: "Error",
              message: "You need to choose at least one SVOD!",
              type: "warning"
            });
            return;
          }
          body.childElements = this.associateItems;
          body.languages = [];
          body.metaData = {};
          for (var i = 0; i < this.catalogue.languages.length; i++) {
            var cItem = this.$refs["catlang" + i][0];
            if (cItem) {
              body.languages.push(cItem.getLangInfo());
            }
          }
          for (var j = 0; j < this.metaData.length; j++) {
            var key = this.metaData[j].key;
            body.metaData[key] = this.metaData[j].value;
          }
          for (var i = 0; i < this.catalogue.languages.length; i++) {
            if (
              this.catalogue.languages[i] &&
              this.catalogue.languages[i].bannerImgLink
            ) {
              body.metaData["bannerImage"] = this.catalogue.languages[
                i
              ].bannerImgLink.large;
            }
            if (
              this.catalogue.languages[i] &&
              this.catalogue.languages[i].mobileBannerImgLink
            ) {
              body.metaData["mobileBannerImage"] = this.catalogue.languages[
                i
              ].mobileBannerImgLink.large;
            }
            if (
              this.catalogue.languages[i] &&
              this.catalogue.languages[i].titleImageLink
            ) {
              body.metaData["bannerTitleImage"] = this.catalogue.languages[
                i
              ].titleImageLink.large;
            }
            if (
              this.catalogue.languages[i] &&
              this.catalogue.languages[i].portraitImageLink
            ) {
              body.metaData["portraitImage"] = this.catalogue.languages[
                i
              ].portraitImageLink.large;
            }
          }
          body.tags = this.catalogue.tags;
          if (this.createMode) {
            this.$store
              .dispatch("createCatalogue", body)
              .then(data => {
                this.$router.push({ name: "All Catalogue" });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Create Catalogue Failed!",
                  type: "warning"
                });
                return;
              });
          } else {
            var formData = {};
            formData.data = body;
            formData.id = this.id;
            formData.tags = this.catalogue.tags;
            this.$store
              .dispatch("updateCatalogue", formData)
              .then(data => {
                //   this.$router.push({ name: "All Catalogue" });
                this.$notify({
                  title: "Success",
                  message: "Catalogue Updated Successfully!",
                  type: "warning"
                });
              })
              .catch(() => {
                this.$notify({
                  title: "Error",
                  message: "Update Catalogue Failed!",
                  type: "warning"
                });
                return;
              });
          }
        }
      });
    },

    setUploadStatus: function (value) {
      this.uploading = value;
    },
    filterMethod(query) {
      if (query !== "") {
        this.secondDropDown = this.secondDropDownClone.filter(item => {
          return (
            item.label.toLowerCase().startsWith(query.toLowerCase()) === true
          );
        });
      } else {
        this.secondDropDown = this.secondDropDownClone;
      }
    },
    gotoVideo(associatedItem) {
      if (associatedItem.childType == "Video") {
        this.$router.push({
          name: "Edit Video",
          params: { id: associatedItem.childId }
        });
      } else if (associatedItem.childType == "Catalogue") {
        this.$router.push({
          name: "Edit Catalogue",
          params: { id: associatedItem.childId }
        });
      }
    }
  },
  updated() { },
  watch: {
    "$route.params.id"() {
      this.init();
    },
    catalogue_type: function (cur, old) {
      if (this.catalogue_type == "Season") {
        this.secondCatalogue = [];
        var type = this.getRightSecondType(this.catalogue_type);
        if (type) {
          this.$store.commit("setLoader", {
            show: true,
            text: "Fetching Details"
          });
          this.$store
            .dispatch("getCatalogueListByType", type)
            .then(data => {
              this.setSecondCatalogue(data._list);
              if (!old) {
                this.secondCatalogue = this.secondIds;
              }
              this.$store.commit("setLoader", {
                show: false,
                text: ""
              });
            })
            .catch(() => { });
        }
      } else {
        this.secondCatalogue = [];
        var type = this.getRightSecondType(this.catalogue_type);
        if (type) {
          this.$store.commit("setLoader", {
            show: true,
            text: "Fetching Details"
          });
          this.$store
            .dispatch("getAllCatalogue")
            .then(data => {
              this.setSecondCatalogue(data._list);
              this.secondCatalogue = this.secondIds;
              this.$store.commit("setLoader", {
                show: false,
                text: ""
              });
            })
            .catch(() => { });
        }
      }
    }
  },
  data() {
    return {
      createMode: true,
      inputPlaylistName: "",
      paramID: this.$route.params.id,
      inputValId: "",
      catalogue_type: "",
      catalogue: {
        isActive: true,
        languages: [],
        tags: ""
      },
      childDialogFlag: false,
      associateItems: [],
      searchQuery: "",
      uploading: false,
      spinnerTip: "Uploading Images...",
      secondDropDown: [],
      secondDropDownClone: [],
      secondCatalogue: [],
      secondIds: [],
      settings: {},
      selectedChildElement: null,
      metaData: [],
      currentMetaData: {
        key: "",
        value: ""
      },
      activeLanguageItems: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      submitted: false,
      cataloguetypes: [
        {
          value: "Playlist",
          label: "Playlist"
        },
        {
          value: "Series",
          label: "Series"
        },
        {
          value: "Season",
          label: "Season"
        },
        {
          value: "Root",
          label: "Root"
        },
        {
          value: "Category",
          label: "Category"
        }
      ],
      switches: {
        defaultOn: true
      },
      checkedFreePreview: true,
      checkedAvod: true
    };
  }
};
</script>

<style scoped>
.typewidth {
  width: 30%;
}

.originaldetail {
  padding-left: 15px;
  padding-top: 25px;
}

.originalimage {
  padding: 0px;
  width: 95%;
}

.originalbutton {
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.monetizationcontainer {
  padding-top: 50px;
}

.languagecontainer {
  padding-top: 30px;
}

.spacer {
  padding-top: 30px;
}

.second-dropdown {
  margin-left: 20px;
  min-width: 200px;
}

.card form.catalogue-form [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}

#childdialog .modal-dialog {
  width: 800px;
}

.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.associateTitle {
  margin-left: 15px !important;
  font-weight: 300;
  font-size: 16px;
  color: #333333;
}

.saveBtn {
  float: left;
  position: relative;
  bottom: 25px;
}

.pageTitle {
  margin-left: 15px;
  font-weight: 500;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 30px;
}

.enableSwitch {
  float: right;
  padding-right: 20px;
  position: relative;
  left: 50px;
}

.thumbnailImg {
  height: 100px;
  width: 160px;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 5px;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.16));
}

.associated-item-name {
  cursor: pointer;
}

.associated-item-name:hover {
  color: #23ccef;
}
</style>
