<template>
  <auth-layout pageClass="register-page">
    <b-modal 
    id="newConfigModal"
    v-model="registrationMessagePopup"
    ref="modal"
    @ok="handleRegistrationMessagePopupOK"
    @shown="clearNewConfigName"
    title="Message">
      <form @submit.stop.prevent="handleNewConfig">
        <div class="row">
          <div class="col-md-12">
            <h3>{{this.registrationMessage}}</h3>
          </div>
        </div>
      </form>
    </b-modal>






    <div class="row d-flex justify-content-center">
      <div class="col-md-8">
        <div class="header-text">
          <h2>App Mastery</h2>
          <h4>Let's launch your channel!</h4>
          <hr>
        </div>
      </div>
      <div class="col-md-5 ml-auto">
        <!--
        <fade-render-transition :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-circle-09"></i>
              </div>
            </div>
            
            <div class="media-body">
              <h4>Free Account</h4>
              <p>Register for free now.</p>
            </div>
          </div>
        </fade-render-transition>
        -->
        <fade-render-transition :delay="200" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-preferences-circle-rotate"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Flexible</h4>
              <p>Our CMS allows you to organize contents in anyway you would like.</p>
            </div>
          </div>
        </fade-render-transition>
        <fade-render-transition :delay="400" :duration="200">
          <div class="media">
            <div class="media-left">
              <div class="icon">
                <i class="nc-icon nc-planet"></i>
              </div>
            </div>
            <div class="media-body">
              <h4>Global Support</h4>
              <p>We support multiple languages and country level SVOD, TVOD pricing.</p>
            </div>
          </div>
        </fade-render-transition>
      </div>
      <div class="col-md-4 mr-auto">
        <form method="#" action="#">
          <card class="card-plain">
            <div>
              <!--
              <fg-input placeholder="Your First Name"></fg-input>
              <fg-input placeholder="Your Last Name"></fg-input>
              <fg-input placeholder="Company"></fg-input>
            -->

              <fg-input placeholder="Email" type="email" v-model="model.emailValue" v-validate="'required|email'" name="email" data-vv-as="email"></fg-input>
              <div class="alerttext">
                <p v-if="errors.has('email')">{{ errors.first('email')}}</p>
              </div>
              <fg-input placeholder="Password" v-validate="'confirmed:confirmpassword|min:5|required'" name="password" type="password" v-model="model.password"></fg-input>
              <div class="passwordlength">
                <p v-if="errors.has('min')">{{ errors.first('min')}}</p>
              </div>
              <fg-input placeholder="Password confirmation" ref="confirmpassword" name="confirmpassword" type="password" v-model="model.confirmpassword"></fg-input>
              <div class="passwordconfirm">
                <p v-if="errors.has('password')">{{ errors.first('password')}}</p>
              </div>
            </div>
            <div class="text-center">
              <button v-on:click="createAccount()" :disabled="errors.has('email') || errors.has('password') || errors.has('min')" type="button" class="btn btn-fill btn-default btn-neutral btn-wd">Create Account</button>
            </div>
          </card>
        </form>
      </div>
    </div>
  </auth-layout>
</template>
<script>
  import { FadeRenderTransition } from 'src/components/index'
  import AuthLayout from './AuthLayout.vue'
  import axios from '../../../service/axios'
  import bModal from 'bootstrap-vue/es/components/modal/modal'
  import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'


  export default {
    components: {
      FadeRenderTransition,
      AuthLayout
    },
    methods: {
        clearNewConfigName(){},
       handleRegistrationMessagePopupOK(){
        if(this.isregistred == true){
          this.$router.push({name:'Login'});
          this.registrationMessagePopup = true; 
          this.model.emailValue = '';
          this.model.password = ''; 
          this.model.confirmpassword = '';

     
        }else{
          this.model.emailValue = '';
          this.model.password = ''; 
          this.model.confirmpassword = '';
        }

      },

      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      },
      createAccount() {
        var shajs = require('sha.js');
        //alert(shajs('sha256').update('will@appmastery').digest('hex'));
       // alert("send data");
        var password = shajs('sha256').update(this.model.password).digest('hex');
        var _t = this
        let DataParams = {
          "username" : _t.model.emailValue,
          "email" : _t.model.emailValue,
          "password": password

        }
        axios.defaults.baseURL = this.$store.state.base_url;
        
        axios({
            method: 'post', 
            url: '/api/v1/admin/register',
            data: DataParams,
            headers: {
              'Content-Type' : 'application/json'
            }
        })
        .then(function(response){
          //response = response.data;
          _t.$store.state.register.data = response.data
        
          if(response.status != 200){
            _t.isregistred = false
    
               _t.registrationMessage = 'registration failed please try again later';
               _t.registrationMessagePopup = true; 
            
          }else{
            _t.isregistred = true
            _t.registrationMessage = response.data.message;
            _t.registrationMessagePopup = true;
            
          }
      
        })
        .catch(function(error,response){
          
          if (error.response) {
             _t.isregistred = false;
             _t.registrationMessage = error.response.data.error.message;
             _t.registrationMessagePopup = true; 
          }

        })


      }
    },
    data() {
      return{
        model :{
          emailValue: '',
          password: '',
          confirmpassword: '',
        },
        registrationMessagePopup: false,
        registrationMessage: '',
        isregistred : false,

      }
    },
    beforeDestroy () {
      this.closeMenu()
    }
  }
</script>

<style scoped>
.reduceMargin{
  margin: 25px;
}
.alerttext{
  position: absolute;
  top: -15px;
  color: white;
}
.passwordconfirm{
  color: white;
  position: absolute;
  top: 185px;
}
.passwordlength{
  color:white;
}
</style>