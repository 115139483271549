<template>
  <div>
    <div class="d-flex justify-content-between mb-2">
      <div>
        <span class="titleCard mb-2">
          {{ pageTitle }}
        </span>
      </div>
      <div class="d-flex justify-content-end">
        <div class="col-md-6">
          <div class="enableSwitch">
            <l-switch
              v-model="activeStatus"
              on-text="ON"
              off-text="OFF"
            ></l-switch>
          </div>
        </div>
      </div>
    </div>
    <card>
      <div class="d-flex justify-content-end" v-if="!createMode">
        <strong class="mb-2 mx-1  lastLogin">
          Last Login: {{ getActivity() }}
        </strong>
      </div>
      <form @submit.prevent="updateProfile">
        <card>
          <div class="row">
            <div class="col-md-3">
              <fg-input
                v-validate="'required|email'"
                type="email"
                label="Username"
                name="Username"
                placeholder="Username"
                v-model="user.username"
                :class="{ 'is-invalid': submitted && errors.has('Username') }"
              >
              </fg-input>
            </div>
            <div class="col-md-3" v-if="createMode">
              <fg-input
                v-validate="'required'"
                type="password"
                label="Password"
                name="Password"
                placeholder="Password"
                v-model="user.password"
                :class="{ 'is-invalid': submitted && errors.has('Password') }"
              >
              </fg-input>
            </div>
            <div class="col-md-3" v-if="createMode">
              <fg-input
                v-validate="'required'"
                type="password"
                label="Confirm Password"
                name="ConfirmPassword"
                placeholder="Confirm Password"
                v-model="confirmPassword"
                :class="{
                  'is-invalid':
                    submitted &&
                    (errors.has('ConfirmPassword') ||
                      confirmPassword != user.password)
                }"
              >
              </fg-input>
            </div>
            <div class="col-md-3" v-if="!createMode">
              <fg-input
                type="password"
                label="Password"
                placeholder="Password"
                v-model="user.password"
              >
              </fg-input>
            </div>
            <div class="col-md-3" v-if="!createMode">
              <fg-input
                type="password"
                label="Confirm Password"
                placeholder="Confirm Password"
                v-model="confirmPassword"
                :class="{
                  'is-invalid': submitted && confirmPassword != user.password
                }"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <fg-input
                v-validate="'required|email'"
                type="email"
                label="Email"
                name="Email"
                placeholder="Email"
                v-model="user.email_address"
                :class="{ 'is-invalid': submitted && errors.has('Email') }"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Telephone"
                placeholder="Telephone"
                name="Telephone"
                v-model="user.telephone"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <fg-input
                type="text"
                label="First Name"
                placeholder="First Name"
                name="FirstName"
                v-model="user.first_name"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="Last Name"
                placeholder="Last Name"
                name="LastName"
                v-model="user.last_name"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <fg-input
                type="text"
                label="Address"
                placeholder="Home Address"
                name="Address"
                v-model="user.address"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                type="text"
                label="City"
                placeholder="City"
                name="City"
                v-model="user.city"
              >
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <fg-input
                type="text"
                label="State"
                placeholder="State"
                name="State"
                v-model="user.state"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input
                label="Postal Code"
                placeholder="ZIP Code"
                v-model="user.postal_code"
                name="PostalCode"
              >
              </fg-input>
            </div>
            <div class="col-md-3">
              <fg-input label="Country">
                <el-select
                  class="select-default"
                  v-model="user.country"
                  placeholder="Country"
                  name="Country"
                  :filterable="true"
                  :filter-method="filterMethod"
                  :clearable="true"
                >
                  <el-option
                    class="select-default"
                    v-for="item in countries"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </fg-input>
            </div>
          </div>

          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label>Notes</label>
                <textarea
                  rows="5"
                  class="form-control border-input"
                  placeholder="Here can be your description"
                  name="Description"
                  v-model="user.notes"
                >
                </textarea>
              </div>
            </div>
          </div>
        </card>
        <div class="col-md-12">
          <user-subscription :userId="id" :userInfo="user"> </user-subscription>
        </div>
        <div class="col-md-12 p-0">
          <Payment-Details :userInfo="user"> </Payment-Details>
        </div>
        <div class="col-md-12 p-0">
          <card title="Manage Meta Data">
            <ManageMetaData :metaData="metaData"></ManageMetaData>
          </card>
        </div>
        <button type="submit" class="btn btn-info btn-fill saveBtn">
          Save Profile
        </button>
      </form>
    </card>
  </div>
</template>
<script>
import { Select, Option, Table, TableColumn } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import countries from "../../../../config/non-continent-countries.json";
import UserSubscription from "./UserSubscription.vue";
import PaymentDetails from "./PaymentDetails.vue";
import ManageMetaData from "../../Components/ManageMetaData.vue";
import moment from "moment";
import Card from "../../../../components/Cards/Card.vue";
export default {
  props: ["userInfo", "metaData"],
  created() {
    this.countries = countries.sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });

    this.id = this.$route.params.id;
    if (this.id && this.id != -1) {
      this.createMode = false;
      this.user = this.userInfo;
      var metaData = this.user.metaData;

      if (
        this.userInfo.isDisabled == false &&
        this.userInfo.emailVerified == true &&
        this.userInfo.status == "ACCOUNT_VERIFIED"
      ) {
        this.activeStatus = true;
      }
    } else {
      this.createMode = true;
      this.activeStatus = true;
      this.user = {
        username: "",
        email_address: "",
        password: "",
        telephone: "",
        skip_ads: false,
        first_name: "",
        last_name: "",
        address: "",
        country: "",
        city: "",
        state: "",
        postal_code: "",
        notes: ``,
        isDisabled: false
      };
    }
  },
  computed: {},
  components: {
    bModal,
    bModalDirective,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSwitch,
    LCheckbox,
    UserSubscription,
    PaymentDetails,
    ManageMetaData,
    Card
  },
  watch: {
    userInfo: function() {
      this.user = this.userInfo;
    }
  },
  data() {
    return {
      user: {},
      createMode: true,
      submitted: false,
      idParam: this.$route.params.id,
      confirmPassword: "",

      countries: [],
      activeStatus: false
    };
  },
  methods: {
    getActivity() {
      var lastLogin = this.user.lastLogin;
      if (!lastLogin) {
        return "Never logged in";
      }
      return moment(lastLogin, "YYYY-MM-DD[T]HH:mm:ss").format("lll");
    },

    updateProfile() {
      this.submitted = true;
      var user = JSON.parse(JSON.stringify(this.user));
      if (this.activeStatus == true) {
        user.isDisabled = false;
        user.emailVerified = true;
        user.status = "ACCOUNT_VERIFIED";
      } else {
        user.isDisabled = true;
      }
      user.metaData = {};
      for (var j = 0; j < this.metaData.length; j++) {
        var key = this.metaData[j].key;
        user.metaData[key] = this.metaData[j].value;
      }
      this.$validator.validate().then(result => {
        if (!result) {
          this.$notify({
            title: "Error",
            message: "Some Fields are Invalid Format!",
            type: "warning"
          });
          // do stuff if not valid.
        } else {
          if (this.user.password) {
            var shajs = require("sha.js");
            var password = shajs("sha256")
              .update(this.user.password)
              .digest("hex");
          }
          if (this.createMode) {
            if (this.confirmPassword != this.user.password) {
              this.$notify({
                title: "Error",
                message: "Some Fields are Invalid Format!",
                type: "warning"
              });
              return;
            }
            user.password = password;
            this.$store
              .dispatch("AdminCreateAppUser", user)
              .then(req => {
                this.$notify({
                  title: "Success",
                  message: "User created Successfully!",
                  type: "success"
                });
                var data = req.data;
                this.$emit("profileCreated", data.user.id);
                this.submitted = false;
                this.createMode = false;
              })
              .catch(error => {
                var response = error.response;
                var data = response.data;
                if (data && data.error) {
                  var message = data.error.message;
                  this.$notify({
                    title: "Error",
                    message: message,
                    type: "warning"
                  });
                  return;
                }
                this.$notify({
                  title: "Error",
                  message: "Create User Failed",
                  type: "warning"
                });
                return;
              });
          } else {
            if (password) {
              if (this.confirmPassword != this.user.password) {
                this.$notify({
                  title: "Error",
                  message: "Some Fields are Invalid Format!",
                  type: "warning"
                });
                return;
              }
              user.password = password;
            } else {
              delete user.password;
            }
            this.$store
              .dispatch("AdminUpdateAppUser", user)
              .then(data => {
                this.$notify({
                  title: "Success",
                  message: "User updated Successfully!",
                  type: "success"
                });
                this.submitted = false;
              })
              .catch(error => {
                var response = error.response;
                var data = response.data;
                if (data && data.error) {
                  var message = data.error.message;
                  this.$notify({
                    title: "Error",
                    message: message,
                    type: "warning"
                  });
                  return;
                }
                this.$notify({
                  title: "Error",
                  message: "Update User Failed",
                  type: "warning"
                });
                return;
              });
          }
        }
      });
      ////check for update or add
    },
    filterMethod(query) {
      if (query !== "") {
        this.countries = countries.filter(item => {
          return (
            item.label.toLowerCase().startsWith(query.toLowerCase()) === true
          );
        });
      }
    }
  },
  computed: {
    isEdit() {
      return this.$route.path.indexOf("edit") > -1;
    },
    pageTitle() {
      return this.isEdit ? "Edit Profile" : "Add User";
    }
  }
};
</script>

<style>
.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 14px;
}
.enableSwitch {
  float: right;
  padding-right: 20px;
  position: relative;
  left: 36px;
}
.activity-info {
  margin-left: 12px;
  font-size: 16px;
  color: #9a9a9a;
}

.countrydrop {
  width: 100%;
  margin-top: 40px;
}

input::placeholder {
  font-size: 12px;
}
.saveBtn {
  position: relative;
  bottom: 16px;
}
.lastLogin {
  font-weight: bold;
  font-size: 14px;
}
</style>
