<template>
  <div>
    <div class="d-flex justify-content-between align-items-center py-3">
      <div>
        <span class="titleCard">
          Avods
        </span>
      </div>
      <div
        class="d-flex justify-content-end align-items-center  searchContainer"
      >
        <div>
          <el-input
            type="search"
            style="width: 200px; "
            placeholder="Search records"
            ref="searchKey"
            prefix-icon="el-icon-search"
            v-model="searchQuery"
            aria-controls="datatables"
          />
        </div>
        <div>
          <span class="demo-input-label ml-2 perPage">Results per page:</span>
          <el-select
            class="select-default ml-1"
            v-model="pagination.perPage"
            style="width: 80px; "
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>

        <div class="mx-2 mt-1">
          <el-button type="primary" icon="el-icon-plus" @click="addUser"
            >Add Provider
          </el-button>
        </div>
      </div>
    </div>
    <card>
      <DeleteConfirmation
        :currentDeleteMethod="deleteAvod"
        ref="confirmDeleteProvider"
        @delete="$event()"
      />
      <div class="row">
        <div class="col-md-12">
          <div class="col-sm-12">
            <el-table stripe style="width: 100%;" :data="queriedData" border>
              <el-table-column
                v-for="column in tableColumns"
                :key="column.label"
                :min-width="column.minWidth"
                :prop="column.prop"
                :label="column.label"
              >
              </el-table-column>
              <el-table-column :min-width="120" fixed="right" label="Actions">
                <template slot-scope="props">
                  <a
                    v-tooltip.top-center="'Edit'"
                    class="btn-warning btn-simple btn-link"
                    @click="handleEdit(props.$index, props.row)"
                    ><i class="fa fa-edit"></i
                  ></a>
                  <a
                    v-tooltip.top-center="'Delete'"
                    class="btn-danger btn-simple btn-link"
                    v-b-modal.confirmDelete
                    @click="handleDelete(props.$index, props.row)"
                    ><i class="fa fa-trash"></i
                  ></a>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap mt-3"
          >
            <div class="">
              <p class="card-category ">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
            >
            </l-pagination>
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option, Button } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import Fuse from "fuse.js";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";
export default {
  components: {
    [Button.name]: Button,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    bModal,
    bModalDirective,
    DeleteConfirmation
  },
  data() {
    return {
      appIndex: 0,
      tableData: [],
      allNetworkConfigList: [],
      selectedDeleteId: "",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25],
        total: 0
      },
      searchQuery: "",

      tableColumns: [
        {
          prop: "name",
          label: "Name",
          minWidth: 150
        },
        {
          prop: "slug",
          label: "Slug",
          minWidth: 150
        },
        {
          prop: "description",
          label: "Description",
          minWidth: 250
        }
      ],
      fuseSearch: null,
      providers: null
    };
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchQuery !== "") {
        result = new Fuse(this.tableData, { keys: ["name", "slug"] }).search(
          this.searchQuery
        );
      }
      this.pagination.total = result.length;
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      this.pagination.total = this.tableData.length;
      return this.tableData.length;
    }
  },
  created() {
    this.$store
      .dispatch("getAvodProviderList")
      .then(response => {
        if (response) {
          this.tableData = response.data;
        }
      })
      .catch(() => {});
  },
  mounted() {},

  methods: {
    handleEdit: function(index, props) {
      var pId = props.id;
      this.$router.push({ name: "Edit AVOD", params: { id: pId } });
    },

    handleDelete: function(index, props) {
      if (props) {
        this.selectedDeleteId = props.id;
        this.$refs["confirmDeleteProvider"].$refs["deleteConfirmation"].show();
      }
    },
    addUser: function() {
      var pId = -1;
      this.$router.push({ name: "Edit AVOD", params: { id: pId } });
    },
    deleteAvod: function() {
      self = this;

      if (this.selectedDeleteId) {
        this.$store
          .dispatch("deleteAvodProvider", this.selectedDeleteId)
          .then(response => {
            this.$notify({
              title: "Error",
              message: "Provider Deleted Successfully",
              type: "warning"
            });

            this.$store.dispatch("getAvodProviderList").then(response => {
              if (response) {
                this.tableData = response.data;
              }
            });
          })
          .catch(() => {
            this.$notify({
              title: "Error",
              message: "Failed to Delete Provider",
              type: "warning"
            });
          });
      }
    }
  }
};
</script>

<style scoped>
.perPage {
  font-weight: 400;
  font-size: 14px;
  color: #27282a;
  letter-spacing: 0.7px;
}

.titleCard {
  margin-left: 15px;
  font-weight: 400;
  font-size: 22px;
  color: #27282a;
  position: relative;
  right: 14px;
}
.searchContainer {
  position: relative;
  left: 7px;
}
</style>
