<template>
  <card>
    <b-modal id="configObj" ref="modal" title="Manage Meta Data">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <fg-input label="Key" v-model="currentconfigObj.key"></fg-input>
          </div>
          <div class="col-sm-5">
            <fg-input label="Value" v-model="currentconfigObj.value"></fg-input>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-primary meta-button" @click="addconfigObj()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-bigboy"
          style="width: 100%;"
          :data="configObj"
          border
        >
          <el-table-column min-width="120" label="Key">
            <template slot-scope="{ row }">
              {{ row }}
            </template>
          </el-table-column>
          <el-table-column min-width="140" label="Value">
            <template slot-scope="{ row }">
              {{ row.value }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="props">
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="deleteconfigObj(props.$index)"
                ><i class="fa fa-trash"></i
              ></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <div class="row">
      <div class="col-md-12">
        <!--                 <div class="switch enableSwitch">
                    <l-switch on-text="ON" off-text="OFF" v-model="currentData.isActive"></l-switch>
                </div> -->
      </div>
      <div class="col-md-4">
        <fg-input
          label="AdProvider Name"
          v-model="currentData.name"
          placeholder="AdProvider Name"
        ></fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          label="AdProvider id"
          v-model="currentData.id"
          placeholder="AdProvider id"
          disabled=""
        ></fg-input>
      </div>
      <div class="col-md-4">
        <fg-input
          label="Slug name"
          v-model="currentData.slug"
          placeholder="Slug name"
        ></fg-input>
      </div>
      <div class="col-md-12">
        <div class="row">
          <fg-input
            class="col-sm-12 col-12 subtitlecontainer"
            label="Description"
          >
            <textarea
              class="form-control"
              v-model="currentData.description"
              placeholder="Description"
              rows="3"
            ></textarea>
          </fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="manage-tag" v-b-modal.configObj>Manage Config Data</p>
        </div>
      </div>
      <div class="col-md-12">
        <button class="btn btn-primary btn-simple" @click="handleSaveBtn">
          Save
        </button>
      </div>
    </div>
  </card>
</template>
<script>
import {
  Switch as LSwitch,
  Checkbox as LCheckbox,
  Spinner as LSpinner
} from "src/components";
import { Loading } from "element-ui";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import {
  Select,
  Option,
  Table,
  TableColumn,
  Collapse,
  CollapseItem
} from "element-ui";

export default {
  components: {
    LSwitch,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    bModal,
    bModalDirective
  },
  data() {
    return {
      createMode: true,
      currentData: {},
      configObj: [],
      currentconfigObj: {
        key: "",
        value: ""
      }
    };
  },
  computed: {},
  mounted() {},
  created() {
    self = this;
    if (!this.$route.params.id || this.$route.params.id == -1) {
      this.createMode = true;
    } else {
      this.createMode = false;
      this.$store
        .dispatch("getAdProviderById", this.$route.params.id)
        .then(res => {
          self.currentData = res;
          var configObj = res.configObj;
          for (var key in configObj) {
            var obj = {};
            obj.key = key;
            obj.value = configObj[key];
            self.configObj.push(obj);
          }
        })
        .catch(() => {});
    }
  },
  methods: {
    handleSaveBtn: function() {
      self = this;
      let loadingInstance = Loading.service({ fullscreen: true });
      this.currentData.configObj = {};
      for (var j = 0; j < this.configObj.length; j++) {
        var key = this.configObj[j].key;
        this.currentData.configObj[key] = this.configObj[j].value;
      }
      if (this.createMode) {
        this.$store
          .dispatch("createAdProvider", this.currentData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/adprovider/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      } else {
        this.$store
          .dispatch("updateAdProvider", this.currentData)
          .then(res => {
            loadingInstance.close();
            this.$router.push("/adprovider/all");
          })
          .catch(() => {
            loadingInstance.close();
          });
      }
    },
    addconfigObj: function() {
      this.configObj.push(JSON.parse(JSON.stringify(this.currentconfigObj)));
      this.currentconfigObj = {
        key: "",
        value: ""
      };
    },
    deleteconfigObj: function(index) {
      this.configObj.splice(index, 1);
    }
  }
};
</script>
