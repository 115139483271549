<template>
  <div class="row">
    <DeleteConfirmation
      :currentDeleteMethod="deleteCustomerHost"
      @delete="$event()"
    />
    <b-modal id="metadatas" ref="modal" title="Manage Meta Data">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-4">
            <fg-input label="Key" v-model="currentMetaData.key"></fg-input>
          </div>
          <div class="col-sm-5">
            <fg-input label="Value" v-model="currentMetaData.value"></fg-input>
          </div>
          <div class="col-sm-3">
            <button class="btn btn-primary meta-button" @click="addMetaData()">
              Add
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12">
        <el-table
          class="table-bigboy"
          style="width: 100%;"
          :data="metaData"
          border
        >
          <el-table-column min-width="120" label="Key">
            <template slot-scope="{ row }">
              {{ row.key }}
            </template>
          </el-table-column>
          <el-table-column min-width="140" label="Value">
            <template slot-scope="{ row }">
              {{ row.value }}
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="props">
              <a
                v-tooltip.top-center="'Delete'"
                class="btn-danger btn-simple btn-link"
                @click="deleteMetaData(props.$index)"
                ><i class="fa fa-trash"></i
              ></a>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </b-modal>
    <b-modal
      size="xl"
      id="userSearch"
      ref="modal"
      title="Search & Add user To Group"
      @ok=""
    >
      <div class="col-md-12">
        <div class="row">
          <el-select
            class="select-primary typewidth col-md-3"
            name="filterType"
            @focus="inputFocus"
            size="large"
            placeholder="Select Key"
            v-model="filterKey"
          >
            <el-option
              v-for="option in filtertypes"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label"
            >
            </el-option>
          </el-select>
          <el-input
            type="search"
            class="col-md-3"
            @focus="inputFocus"
            placeholder="Query"
            v-model="filterUsersearchQuery"
            aria-controls="datatables"
          />
          <el-input
            type="search"
            class="col-md-3"
            v-if="searchBySub"
            @focus="inputFocus"
            placeholder="enter subcriptionid"
            v-model="searchSubcriptionId"
            aria-controls="datatables"
          />
          <div class="col-md-3">
            <button
              class="btn btn-primary btn-fill"
              @click.prevent="filterAPI()"
            >
              Search User
            </button>
            <l-checkbox
              style="font-size: 12px"
              @input="searchBysubcription($event, props)"
              >Search With Subcription</l-checkbox
            >
            <span class="err" v-if="searchErr" style="color:red;">{{
              errorText
            }}</span>
          </div>
        </div>
      </div>
      <card style="margin-top: 30px;">
        <div class="col-12">
          <button
            class="btn btn-primary btn-fill"
            @click.prevent="resetAlluser()"
          >
            Reset
          </button>
        </div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-default mb-3"
            style="width: 200px"
            v-model="userpagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-default"
              v-for="item in userpagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <l-checkbox class="mb-3" @input="selectAllChange($event)"
            >Select all</l-checkbox
          >
          <el-input
            type="search"
            class="mb-3"
            style="width: 200px"
            placeholder="Search records"
            v-model="usersearchQuery"
            aria-controls="datatables"
          />
        </div>
        <div class="col-sm-12">
          <el-table stripe style="width: 100%;" :data="userqueriedData" border>
            <el-table-column :min-width="50" fixed="left" label=" ">
              <template slot-scope="props">
                <l-checkbox
                  :checked="userisChecked(props)"
                  @input="usercheckChanged($event, props)"
                ></l-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              v-for="column in usertableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
            >
            </el-table-column>
          </el-table>
        </div>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ userfrom + 1 }} to {{ userto }} of
              {{ usertotal }} entries
            </p>
          </div>
          <l-pagination
            class="pagination-no-border"
            v-model="userpagination.currentPage"
            :per-page="userpagination.perPage"
            :total="userpagination.usertotal"
          >
          </l-pagination>
        </div>
      </card>
    </b-modal>
    <div class="col-md-12">
      <card title="Create  Notifications group" subTitle="">
        <div class="col-md-12">
          <p class="manage-tag" v-b-modal.userSearch>Search & add user</p>
        </div>
        <div class="col-md-6">
          <fg-input label="Title" v-model="groupname"></fg-input>
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-primary btn-fill"
            @click.prevent="createNotificatonGroup()"
          >
            {{ getname() }}
          </button>
        </div>
      </card>
      <card title="Send Notifications" subTitle="" v-if="showSendCard">
        <fg-input
          class="subtitlecontainer col-md-12"
          label="Title"
          v-model="curData.configObj.notification.title"
        ></fg-input>
        <fg-input
          class="col-sm-12 col-12 subtitlecontainer"
          label="Body"
          maxlength="300"
          show-word-limit="true"
        >
          <textarea
            class="form-control"
            v-model="curData.configObj.notification.body"
            placeholder="Body"
            rows="3"
          ></textarea>
        </fg-input>
        <div class="col-md-6">
          <div class="col-md-12 col-12 thumb-div">
            <h5 class="card-title">Thumbnail</h5>
            <img
              class="thumbnail"
              :src="curData.configObj.notification.image"
            />
            <div class="uploadthumbbtn">
              <button
                class="btn btn-primary"
                @click="openImageDialog($event, 'thumbnail')"
              >
                Upload
              </button>
            </div>
            <div class="deletethumbbtn">
              <button
                class="btn btn-default"
                @click.prevent="deleteImage('thumbnail')"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
        <input
          type="file"
          ref="thumbnailInput"
          accept=".jpg, .png, .jpeg, .gif"
          v-bind:style="{ display: 'none' }"
          @change="uploadPicture($event, 'thumbnail')"
        />
        <div class="col-md-12">
          <p class="manage-tag" v-b-modal.metadatas>add Metadata</p>
        </div>
        <div class="col-md-6">
          <button
            class="btn btn-primary btn-fill"
            @click.prevent="sendNotification()"
          >
            Send
          </button>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Table, TableColumn, Select, Option } from "element-ui";
import { Switch as LSwitch, Checkbox as LCheckbox } from "src/components";
import DeleteConfirmation from "src/pages/Dashboard/Components/DeleteConfirmation.vue";

import {
  Pagination as LPagination,
  Spinner as LSpinner
} from "src/components/index";
import Fuse from "fuse.js";
export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LSpinner,
    LCheckbox,
    DeleteConfirmation
  },
  data() {
    return {
      metaData: [],
      currentMetaData: {
        key: "",
        value: ""
      },
      currentGroup: null,
      groupList: [],
      showSendCard: false,
      groupname: "",
      searchBySub: false,
      searchSubcriptionId: null,
      searchErr: false,
      errorText: "incorrect Search",
      availList: [],
      filtertypes: [
        {
          value: "country",
          label: "Country"
        },
        {
          value: "subscriptionId",
          label: "subcription Id"
        },
        {
          value: "status",
          label: "status"
        }
      ],
      filterKey: "",
      tableData: [],
      filterUsersearchQuery: null,
      currentHost: null,
      notificationHostArray: [],
      notification_key_name: "",
      userpagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 25, 50, 100],
        usertotal: 0
      },
      usersearchQuery: "",
      userpropsToSearch: ["username", "email"],
      usertableColumns: [
        {
          prop: "username",
          label: "Username",
          minWidth: 250
        },
        {
          prop: "id",
          label: "Id",
          minWidth: 250
        },
        {
          prop: "first_name",
          label: "First Name",
          minWidth: 200
        },
        {
          prop: "last_name",
          label: "Last Name",
          minWidth: 200
        }
      ],
      userfuseSearch: null,
      usertableData: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      curData: {
        isActive: true,
        providerId: "",
        configObj: []
      }
    };
  },
  computed: {
    userpagedData() {
      return this.usertableData.slice(this.userfrom, this.userto);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the userpagination on the server and display the data retrieved userfrom server instead.
     * @returns {computed.userpagedData}
     */
    userqueriedData() {
      let result = this.usertableData;
      if (this.usersearchQuery !== "") {
        result = new Fuse(this.usertableData, {
          keys: ["username", "id", "first_name", "last_name"]
        }).search(this.usersearchQuery);
      }
      this.userpagination.usertotal = result.length;
      return result.slice(this.userfrom, this.userto);
    },
    userto() {
      let highBound = this.userfrom + this.userpagination.perPage;
      if (this.usertotal < highBound) {
        highBound = this.usertotal;
      }
      return highBound;
    },
    userfrom() {
      return (
        this.userpagination.perPage * (this.userpagination.currentPage - 1)
      );
    },
    usertotal() {
      this.userpagination.usertotal = this.usertableData.length;
      return this.usertableData.length;
    }
  },
  created() {
    this.notificationHostArray = this.$store.state.notifications.providers;
    var host = this.notificationHostArray[0];
    if (host) {
      this.curData = host;
    }

    var id = this.$route.params.id;
    if (id == "-1") {
      this.showSendCard = false;
    } else {
      this.$store
        .dispatch("getGroupList")
        .then(res => {
          this.groupList = res;
          this.showSendCard = true;
          this.getCurrentGroup(this.$route.params.id);
        })
        .catch(() => {});
    }
    this.getpageData();
  },
  methods: {
    deleteMetaData: function(index) {
      this.metaData.splice(index, 1);
    },
    addMetaData: function() {
      this.metaData.push(JSON.parse(JSON.stringify(this.currentMetaData)));
      this.currentMetaData = {
        key: "",
        value: ""
      };
    },
    getCurrentGroup(currentId) {
      this.currentGroup = this.groupList.find(ele => {
        return (ele.id = currentId);
      });
      this.availList = this.currentGroup.userId;
      this.groupname = this.currentGroup.notification_key_name;
    },
    getname() {
      var id = this.$route.params.id;
      if (id == "-1") {
        return "Create Group";
      } else {
        return "Update Group";
      }
    },
    inputFocus() {
      this.searchErr = false;
    },
    filterAPI() {
      let query = "";
      if (this.filterKey != "" && this.filterUsersearchQuery != "") {
        if (this.searchBySub && this.searchSubcriptionId != "") {
          query =
            "subscriptionId=" +
            this.searchSubcriptionId +
            "&search[0][key]=" +
            this.filterKey +
            "&search[0][value]=" +
            this.filterUsersearchQuery;
        } else {
          query =
            "search[0][key]=" +
            this.filterKey +
            "&search[0][value]=" +
            this.filterUsersearchQuery;
        }
        this.$store
          .dispatch("filterUserByQuery", query)
          .then(response => {
            self.setUserTableData(response.data);
          })
          .catch(() => {});
      }
    },
    isChecked(props) {
      if (this.availList) {
        return this.availList.includes(props.row.id);
      } else {
        return false;
      }
    },
    resetAlluser() {
      this.availList = [];
      this.getpageData();
    },
    checkChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.availList.includes(id)) {
            this.availList.push(id);
          }
        } else {
          if (this.availList.includes(id)) {
            var index = this.availList.indexOf(id);
            this.availList.splice(index, 1);
          }
        }
      }
    },
    selectAllChange(val) {
      if (val) {
        this.availList = [];
        for (var i = 0; i < this.usertableData.length; i++) {
          if (!this.availList.includes(this.usertableData[i].id)) {
            this.availList.push(this.usertableData[i].id);
          }
        }
      } else {
        this.availList = [];
      }
    },
    searchBysubcription(val) {
      if (val) {
        this.searchBySub = true;
      } else {
        this.searchBySub = false;
      }
    },
    userisChecked(props) {
      if (this.availList) {
        return this.availList.includes(props.row.id);
      } else {
        return false;
      }
    },
    usercheckChanged(val, props) {
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.availList.includes(id)) {
            this.availList.push(id);
          }
        } else {
          if (this.availList.includes(id)) {
            var index = this.availList.indexOf(id);
            this.availList.splice(index, 1);
          }
        }
      }
    },
    getpageData() {
      self = this;
      this.$store
        .dispatch("getAdminAppUserList")
        .then(response => {
          self.setUserTableData(response);
        })
        .catch(() => {});
    },
    setUserTableData(res) {
      this.usertableData = res;
    },
    openImageDialog(evt, type) {
      evt.stopPropagation();
      evt.preventDefault();
      var file;
      if (type == "thumbnail") {
        file = this.$refs.thumbnailInput;
      }
      if (file) {
        file.click();
      }
    },
    deleteImage(type) {
      if (type == "thumbnail") {
        this.resetUrl("https://dummyimage.com/640x360/a1a1a1/fff", type);
      } else {
        this.resetUrl("https://dummyimage.com/1920x1080/a1a1a1/fff", type);
      }
    },
    resetUrl: function(url, type) {
      if (type == "thumbnail") {
        this.curData.configObj.notification.image = url;
      }
    },
    uploadPicture(event, type) {
      var self = this;
      var uuid = this.$uuid.v1();
      var file = event.target.files[0];
      var filename = file.name;
      var data = {};
      data.extension = filename.split(".").pop();
      data.fileKey =
        this.$store.state.customerId + "/" + uuid + "." + data.extension;
      data.contentType = file.type;
      this.$store
        .dispatch("preSignedRequest", data)
        .then(response => {
          var signedUrl = response.signedRequest;
          var url = response.url;
          var info = {};
          info.url = signedUrl;
          info.data = file;
          this.$store
            .dispatch("uploadImageToS3", info)
            .then(res => {
              self.resetUrl(url, type);
            })
            .catch(() => {
              this.$notify({
                title: "Error",
                message: "Uploading Image Failed!",
                type: "warning"
              });
            });
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Uploading Image Failed!",
            type: "warning"
          });
        });
    },
    sendNotification() {
      var Data = {};
      for (var i = 0; i < this.metaData.length; i++) {
        Data["" + this.metaData[i].key] = this.metaData[i].value;
      }
      var DataToSend = {
        notification_key_name: this.groupname,
        message: {
          notification: {
            title: this.curData.configObj.notification.title,
            body: this.curData.configObj.notification.body,
            click_action: "FLUTTER_NOTIFICATION_CLICK",
            image: this.curData.configObj.notification.image
          },
          data: Data
        }
      };
      this.$store
        .dispatch("sendNotificationToGroup", DataToSend)
        .then(res => {
          this.$notify({
            title: "Success",
            message: "Send Successfully!",
            type: "success"
          });
          this.showSendCard = true;
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed Sending",
            type: "warning"
          });
        });
    },
    createNotificatonGroup() {
      var Data = {};
      Data["notification_key_name"] = this.groupname;
      Data["userId"] = this.availList;
      this.$store
        .dispatch("createNotificationGroup", Data)
        .then(res => {
          this.$notify({
            title: "Success",
            message: "Group created Successfully!",
            type: "success"
          });
          this.showSendCard = true;
        })
        .catch(() => {
          this.$notify({
            title: "Error",
            message: "Failed Creating Notificaton",
            type: "warning"
          });
        });
    },
    handleTabChange(tabIndex, newTab, oldTab) {
      var host = this.notificationHostArray[tabIndex];
      if (host) {
        this.curData = host;
      }
    },
    deleteCustomerHost() {}
  },
  mounted() {}
};
</script>

<style scoped>
.subtitlecontainer {
  margin-bottom: 10px;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mt-20 {
  margin-top: 20px;
}
.thumbnail {
  position: absolute;
  float: left;
  height: 110px;
}

.thumb-div {
  padding-left: 10px;
  min-height: 160px;
}

.uploadthumbbtn {
  position: absolute;
  float: left;
  margin-top: 0px;
  margin-left: 200px;
}

.deletethumbbtn {
  position: absolute;
  float: left;
  margin-top: 50px;
  margin-left: 200px;
}

.delete-lang-btn {
  float: right;
  margin-right: 30px;
}
</style>
