<template>
<card>
	<div class="row">
		<div class="col-md-6">
			<fg-input placeholder="Username" label="Username" v-model="user.username"></fg-input>
		</div>
		<div class="col-md-6">
			<fg-input placeholder="Email" label="Email" v-model="user.email"></fg-input>
		</div>
		<div v-if="createMode == true" class="col-md-6">
			<fg-input  placeholder="Password" type="password" label="Password" v-model="user.password"></fg-input>
		</div>
		<div v-if="createMode == true" class="col-md-6">
			<fg-input disable='true'  placeholder="Confirm Password" type="password" label="Configm Password" v-model="user.cnfpassword"></fg-input>
		</div>
		<div class="col-md-6">
			<fg-input placeholder="Phone Number" label="Phone Number" v-model="user.phone"></fg-input>
		</div>
		<div class="col-md-12">
			<span>Customer Access</span>
			<div class="row">
				<div class="col-md-12">
					<el-select :multiple="true" class="select-primary extralarge" size="large" v-model="multipleSelections" placeholder="Select customers to provide access">
					    <el-option v-for="cust in customersList" class="select-primary" :value="cust.id" :label="cust.label" :key="cust.label">
					 	</el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="inbuttons">
				<button v-if="createMode == true" class="btn btn-primary btn-fill" @click.prevent="updateProfile">Save</button>
				<button v-else class="btn btn-primary btn-fill" @click.prevent="updateProfile">Update</button>
				<button class="btn btn-default btn-simple" @click.prevent="handleCancle">Cancel</button>
			</div>
		</div>
	</div>
</card>
</template>


<script>
import {FormGroupInput as FgInput} from 'src/components'
import {Select, Option} from 'element-ui'

export default {
	computed: {

      },
	components: {
		FgInput,
		[Select.name]: Select,
		[Option.name]: Option
	},

	data(){
		return {
			customersList:[],
			createMode : false,
			customers :{},
			idParam : this.$route.params.id,
			user : {},
			multipleSelections: '',

			}
	},
	created() {
		self =this
		this.id = this.$route.params.id;

			self.$store.dispatch("getCustomers").then(data =>{
				self.customersList = data.map(function(item){
						let data  = {
							"label": item.name,
							"value":item.name,
							"id": item.id,
						}
						return data
					});

				if(!this.id || this.id==-1) {
		            this.createMode = true;
					self.user = {
						username:"",
						email:"",
						password:"",
						cnfpassword:"",
						phone:"",
						customerIds:[]
					};

	       		}else {
			            this.createMode = false;
			            this.$store.dispatch("getAdminUserById",this.id).then(data =>{
						self.user = {
							id:data.id,//for update
							username:data.username,
							email:data.email,
							phone:"7848525252",
							customerIds:data.customerIds,
						};
						this.multipleSelections = data.customerIds;
		            }).catch(() => {
		            });
        		}




		});


	},
	methods: {
		handleCancle(e){

            this.$router.push({name:'Admins'});	
            this.createMode = false;
					
		},
		 updateProfile (e) {
		 	self = this


                if(this.createMode == true) {
                		var shajs = require('sha.js');
        				var password = shajs('sha256').update(this.user.password).digest('hex');
        				this.user.password = password;
 		            	this.user.customerIds = this.multipleSelections;
        				this.$store.dispatch('createAdminUser' , this.user).then(data=>{
                        self.$store.dispatch("getListOfAdmins").then(data =>{
                   				self.$router.push({name:'Admins'});	
                   				this.createMode = false;
						});		
                    })
                    .catch(function (error) {
                    });

	       		}else {
			        	this.user.customerIds = this.multipleSelections;
        				this.$store.dispatch('UpdateSuperAdminUsers' , this.user).then(data=>{
                        self.$store.dispatch("getListOfAdmins").then(data =>{
                   				self.$router.push({name:'Admins'});	
                   				this.createMode = false;
						});		
                    })
                    .catch(function (error) {
                    });
        		}
	





      
     	 }

	}
}
</script>

<style scoped>
.extralarge{
	width: 100%;
}
.inbuttons{
	padding-top: 10px;
}
</style>