<template>
  <div class="row">
    <div class="col-md-12">
      <el-checkbox v-model="settings.subscription" size="medium">
        <p>SVOD</p>
      </el-checkbox>
      <div
        class="table-responsive table-full-width"
        v-if="settings.subscription"
      >
        <el-table
          ref="multipleTable"
          :data="tableData"
          @select-all="handleSelectionAllChange"
        >
          <el-table-column type="selection" width="80">
            <template slot-scope="props">
              <l-checkbox
                :checked="isChecked(props)"
                @input="checkChanged($event, props)"
              ></l-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            label="Package"
            property="package.title"
          ></el-table-column>
          <el-table-column
            label="Subscription"
            property="title"
          ></el-table-column>
          <el-table-column label="Price" property="price">
            <template slot-scope="props">
              {{ getPriceCurrency(props.row) }}
            </template>
          </el-table-column>
          <el-table-column
            label="Slug Name"
            property="slug_name"
          ></el-table-column>
          <!--
                                <el-table-column label="Country" property="country" show-overflow-tooltip>
                                    <template slot-scope="props">
                                        {{getMultiCountryName(props.row)}}
                                    </template>
                                </el-table-column>
                                -->
        </el-table>
      </div>
    </div>
  </div>

  <!-- <div class="row">
      <div class="col-md-6">
        <l-checkbox v-model="settings.free">Free Preview</l-checkbox>
        <l-checkbox v-model="settings.AVOD"
          >Advertisement Video On-Demand</l-checkbox
        >
      </div>
      <div class="col-md-6">
        <l-checkbox v-model="settings.TVOD"
          >Transactional Video On-Demand</l-checkbox
        >
        <l-checkbox v-model="settings.subscription"
          >Subscription Video On-Demand
          <a
            v-tooltip.top-center="'Edit'"
            class="btn-select"
            @click="handleEdit($event)"
            >Select</a
          ></l-checkbox
        >
      </div>
    </div> -->
</template>

<script>
import { Checkbox as LCheckbox } from "src/components";
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";
import { Table, TableColumn, Checkbox } from "element-ui";
import countries from "../../../config/countries.json";

export default {
  props: ["setting", "createmode"],
  components: {
    LCheckbox,
    bModal,
    bModalDirective,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Checkbox.name]: Checkbox
  },
  created() {},
  mounted() {
    this.settings = this.setting;

    this.countries = countries.map(country => {
      var temp = country.children.sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });
      return { ...country, children: temp };
    });
    this.$store
      .dispatch("getSubscriptionList")
      .then(data => {
        this.tableData = data;
        this.sIds = JSON.parse(JSON.stringify(this.settings.subscriptionIds));
        if (!this.createmode) {
          if (this.settings.subscriptionIds) {
            if (this.settings.subscriptionIds.length == this.tableData.length) {
              this.$refs.multipleTable.toggleAllSelection();
            }
          }
        }
      })
      .catch(() => {});
  },
  data() {
    return {
      show: false,
      shown: false,
      settings: {
        subscriptionIds: []
      },
      tableData: [],
      sIds: [],
      countries: [],
      checkedSvod: true
    };
  },
  watch: {
    setting: function() {
      this.settings = this.setting;
      if (this.settings.subscriptionIds) {
        this.sIds = JSON.parse(JSON.stringify(this.settings.subscriptionIds));
      }
    }
  },
  methods: {
    handleEdit: function(evt) {
      evt.preventDefault();
      this.$refs.subscriptionSelection.show();
      this.sIds = JSON.parse(JSON.stringify(this.settings.subscriptionIds));

      // this.$refs.multipleTable.clearSelection();
      // if(this.settings.subscriptionIds) {
      //     this.sIds = JSON.parse(JSON.stringify(this.settings.subscriptionIds));
      //     if(this.sIds.length == this.tableData.length) {
      //         this.$refs.multipleTable.toggleAllSelection();
      //         return;
      //     }
      //     this.$nextTick(() => {
      //         for(var i=0;i<this.sIds.length;i++) {
      //             var id = this.sIds[i];
      //             var index = this.findWithAttr(this.tableData,"id",id);
      //             if(index>=0) {
      //                 this.$refs.multipleTable.toggleRowSelection(this.tableData[index]);
      //             }
      //         }
      //     })

      // }
    },
    isChecked(props) {
      if (this.sIds) {
        return this.sIds.includes(props.row.id);
      }
      return false;
    },
    handleNewConfigOk: function() {
      this.settings.subscriptionIds = JSON.parse(JSON.stringify(this.sIds));
    },
    handleShown: function() {},
    getSettingInfo: function() {
      this.settings.subscriptionIds = this.sIds;
      return this.settings;
    },
    checkChanged: function(val, props) {
      this.$refs.multipleTable.clearSelection();
      if (!this.sIds || !Array.isArray(this.sIds)) {
        this.sIds = [];
      }
      var id = props.row.id;
      if (id) {
        if (val) {
          if (!this.sIds.includes(id)) {
            this.sIds.push(id);
          }
        } else {
          if (this.sIds.includes(id)) {
            var index = this.sIds.indexOf(id);
            this.sIds.splice(index, 1);
          }
        }
        if (this.sIds.length == this.tableData.length) {
          this.$refs.multipleTable.toggleAllSelection();
        }
      }
    },
    handleSelectionAllChange: function(val) {
      if (val.length == 0) {
        this.sIds = [];
      } else {
        for (var i = 0; i < this.tableData.length; i++) {
          this.sIds.push(this.tableData[i].id);
        }
      }
    },
    findWithAttr(array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        }
      }
      return -1;
    },
    getPriceCurrency: function(row) {
      var price = row.price;
      var currency = row.currency;
      return price + "   " + currency;
    },
    getMultiCountryName: function(row) {
      var countries = row.country;
      var str = "";
      if (Array.isArray(countries)) {
        for (var i = 0; i < countries.length; i++) {
          var country = countries[i];
          var cvar = this.countries.find(function(element) {
            return element.value == country;
          });
          if (cvar) {
            if (i != 0) {
              str = str + " , ";
            }
            str = str + cvar.label;
          }
        }
      }
      return str;
    }
  }
};
</script>

<style>
.btn-select {
  color: #ff0000;
  padding-left: 15px;
}

.largepopup {
  width: 750px;
}

label.el-checkbox {
  margin-left: 4px !important;
}
.checkItem {
  margin-left: 0px !important;
}
</style>
