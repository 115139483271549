<template>
	<card>
		<div class="row">
			<b-modal id="newPlatformModal" ref="modal" @ok="handleNewPlatformOk" title="Add a new platform">
				<form @submit.stop.prevent="handleNewPlatform">
					<b-form-input type="text" placeholder="Enter a name" v-model="newPlatformName">
					</b-form-input>
				</form>
			</b-modal>

			<div class="col-md-12">
				<div class="switch">
					<l-switch v-model="switches.defaultOn" on-text="ON" off-text="OFF"></l-switch>
				</div>
			</div>
			<div class="col-md-6">
				<fg-input label="Customer Name" placeholder="Customer Name" v-model="customer.name"></fg-input>
			</div>
			<div class="col-md-6">
				<fg-input label="ID" disabled="" v-model="customer.uid"></fg-input>
			</div>


			<div class="col-md-12">
				<b-btn v-b-modal.newPlatformModal>Add Platform</b-btn>
				
				<!--<button class="btn btn-default" @click="addPlatform"></button>-->
			</div>

			<div class="col-xl-12 col-lg-12">
				<card>
					<template slot="header">
						<h4 class="card-title">Platforms
							<b-btn class="edit-theme" @click="editTheme()">Edit Theme</b-btn>
						</h4>
						<!-- <p class="card-category">Production Apps</p> -->
					</template>
					<el-collapse>
						<el-collapse-item v-for="(item, index) in customer.platforms" :title="item.name" :name="item.id">
							<AppConfig :apps="item.configs" :platformindex="Number(index)" :customerindex="Number(id)" :key="'appconfig' + item.id"></AppConfig>
						</el-collapse-item>
					</el-collapse>
				</card>
			</div>

			<div class="col-md-12">
				<button class="btn btn-primary btn-simple" @click="handleSaveBtn">Save</button>
			</div>
		</div>
	</card>
</template>



<script>

import {FormGroupInput as FgInput} from 'src/components'
import {Switch as LSwitch} from 'src/components'
import { Collapse, CollapseItem } from 'element-ui'
import swal from 'sweetalert2'
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import AppConfig from '../Components/AppConfig.vue'

export default {
	props: ['id'],
	components: {
		FgInput,
		LSwitch,
	//	inputValName : '',
		[Collapse.name]: Collapse,
      	[CollapseItem.name]: CollapseItem,
      	bModal,
      	bModalDirective,
      	AppConfig
	},
	created(){
		this.id = this.$route.params.id;
        if(!this.id || this.id==-1) {
            this.createMode = true;
            this.customer = {
                name : '',
				platforms :[],
				uid : this.$uuid.v4(),
				status: false,
            };
        }else {
            this.createMode = false;
            this.$store.dispatch("getCustomerByID",this.id).then(data => {
            this.customer = {
                name : data.name,
				platforms :data.platforms,
				uid : data.uid,
				status: false,
				id:data.id,
            };
            }).catch(() => {
            });
        }
	},
	computed: {
	
		

	},	
	data(){
		return {
			
			newPlatformName : '',
			activeName: 'first',
			switches: {
				defaultOn: true,
			},			
			createMode : false,
			customer:{},
			
		}
	},

	methods: {

		editTheme: function() {
			this.$router.push({"name":"Edit Theme","params":{"id":this.id,"data":this.customer}});
		},
		handleNewPlatformOk: function(evt){
			evt.preventDefault();
			if(!this.newPlatformName){
				swal("Please enter a platform name")
			} else{
				this.handleNewPlatform()
			}
		},

		

		handleNewPlatform: function(){
			
				var platformData = {
				id: this.$uuid.v4(),
				name: this.newPlatformName,			
				configs: [],
			}
		
		this.customer.platforms.push(platformData)
		this.$refs.modal.hide();
		this.newPlatformName = '';
		},
		handleSaveBtn(){


			if(this.createMode == true){
				this.$store.dispatch("createCustomer",this.customer).then(data => {
				//	this.$store.dispatch("getCustomers");
					this.$router.push({name:'Customer'});
				}).catch(() => {
				});

			}else{
				this.$store.dispatch("updateCustomer",this.customer).then(data => {
					this.$store.dispatch("getCustomers").then(data => {
				
					this.$router.push({name:'Customer'});
				}).catch(() => {
				});
					
				}).catch(() => {
				});
			}



			// var data  = {
			// 	platforms : this.platforms,
			// 	name : this.inputValName, 
			// 	index : this.idParam,
			// };
			// if(this.idParam == "-1"){
			// 		var newData = {
			// 			platforms : this.platforms,
			// 			name : this.inputValName, 
			// 			id : this.$store.state.admin.customers.length + 1,
			// 		}
			// 		this.$store.commit('addNewCustomer', newData);
			// 		this.$router.push({name:'Customer'});	
			// }else{
			// 		if(!this.inputValName){
			// 			swal("enter platform name");
			// 		}else{
			// 			this.$store.commit('updateCustomer' ,  data);
			// 			swal("Data saved sucessfully");
			// 		    this.$router.push({name:'Customer'});		
			// 		}
					
					
			// }	
		},
	}
}

</script>


<style scoped>
	.switch {
		float: right;
	}
	.edit-theme {
		float: right;
	}
</style>