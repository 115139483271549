import * as properties from "../properties";

class Storage {
    constructor() {

    }

    get(key, defaultValue) {
        var value = localStorage.getItem(key, defaultValue);
        return value;
    }

    set(key, value) {
        localStorage.setItem(key, value);
    }

    remove(key) {
        localStorage.removeItem(key);
    }

    removeAll() {
        localStorage.clear()
    }

    saveToken(value) {
        this.set(properties.token, value);
    }

    getToken() {
        return this.get(properties.token);
    }

    setExpireDate(value) {
        this.set(properties.expireDate, value);
    }

    getExpireDate() {
        return this.get(properties.expireDate);
    }

    deleteAuthData() {
        this.remove(properties.token);
        this.remove(properties.expireDate);
    }
    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}

const storage = new Storage();

export default storage;